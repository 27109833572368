import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'

export const ProtectedRoute = ({
    isAllowed = true,
    redirectPath = '/auth/user',
    protectedBy = localStorage.token,
    children,
}) => {
    const { pathname } = useLocation()
    if (isAllowed && protectedBy) {
        return children
    }

    return <Navigate to={redirectPath} replace state={{ from: { pathname } }} />
}
