const educationInMultiLang = [
    { value: 1, uz: 'Muhim emas', en: 'Doesn\'t matter', ru: 'Не важно' },
    { value: 2, uz: 'Umumiy ta’lim', en: 'General education', ru: 'Общее образование' },
    // eslint-disable-next-line max-len
    { value: 3, uz: 'Boshlangʻich (kasb-hunar maktabi, kasb-hunar litseyi)', en: 'Primary (vocational school, prof. lyceum)', ru: 'Начальное (ПТУ, проф. лицей)' },
    // eslint-disable-next-line max-len
    { value: 4, uz: 'Oʻrta (texnik maktab, kollej)', en: 'Secondary (technical school, college)', ru: 'Среднее (техникум, колледж)' },
    { value: 5, uz: 'Oliy bakalavr darajasi', en: 'Higher bachelor\'s degree', ru: 'Высшее бакалавриат' },
    { value: 6, uz: 'Oliy mutaxassislik', en: 'Higher specialty', ru: 'Высшее специалитет' },
    { value: 7, uz: 'Oliy magistrlik darajasi', en: 'Higher master\'s degree', ru: 'Высшее магистратура' },
    { value: 8, uz: 'Oliy aspirantura', en: 'Higher postgraduate studies', ru: 'Высшее аспирантура' },
    { value: 9, uz: 'Oliy fan doktori', en: 'Higher doctorate', ru: 'Высшее докторантура' },
]

const generateEducationLvl = (lang = localStorage.lang) => educationInMultiLang.map((edu) => (
    { value: edu.value, label: edu[lang] }
))
export const educationLvl = generateEducationLvl()

export const updateEducationLvl = (lang) => {
    educationLvl.length = 0
    educationLvl.push(...generateEducationLvl(lang))
}

export const genderOptions = [
    { value: 'not_set', ru: 'Не важно', uz: 'Muhim emas', en: 'Doesn\'t matter' },
    { value: 'male', ru: 'Мужской', uz: 'Erkak', en: 'Male' },
    { value: 'female', ru: 'Женский', uz: 'Ayol', en: 'Female' },
]

export const banknoteOptions = [
    { value: 1, label: 'UZS' },
    { value: 2, label: 'USD' },
    { value: 3, label: 'RUB' },
    { value: 4, label: 'EUR' },
]

export const AvailableLanguages = [
    { value: 1, en: 'English', ru: 'Английский', uz: 'Ingliz', short: 'en' },
    { value: 2, en: 'Russian', ru: 'Русский', uz: 'Rus', short: 'ru' },
    { value: 3, en: 'Uzbek', ru: 'Узбекский', uz: 'O\'zbek', short: 'uz' },
    { value: 4, en: 'German', ru: 'Немецкий', uz: 'Nemis', short: 'de' },
    { value: 5, en: 'Spanish', ru: 'Испанский', uz: 'Ispan', short: 'es' },
    { value: 6, en: 'French', ru: 'Французский', uz: 'Fransuz', short: 'fr' },
    { value: 7, en: 'Chinese', ru: 'Китайский', uz: 'Xitoy', short: 'zh' },
    { value: 8, en: 'Korean', ru: 'Корейский', uz: 'Koreys', short: 'ko' },
    { value: 9, en: 'Japanese', ru: 'Японский', uz: 'Yapon', short: 'ja' },
    { value: 10, en: 'Italian', ru: 'Итальянский', uz: 'Italyan', short: 'it' },
    { value: 11, en: 'Ukrainian', ru: 'Украинский', uz: 'Ukrain', short: 'uk' },
    { value: 12, en: 'Kazakh', ru: 'Казахский', uz: 'Qozoq', short: 'kk' },
    { value: 13, en: 'Indonesian', ru: 'Индонезийский', uz: 'Indonez', short: 'id' },
    { value: 14, en: 'Arabic', ru: 'Арабский', uz: 'Arab', short: 'ar' },
    { value: 15, en: 'Portuguese', ru: 'Португальский', uz: 'Portugal', short: 'pt' },
]

export const LanguageLevels = [
    { level: 'A1', en: 'Beginner', ru: 'Начальный', uz: 'Boshlang\'ich darajasi' },
    { level: 'A2', en: 'Elementary', ru: 'Элементарный', uz: 'Boshlang\'ich daraja' },
    { level: 'B1', en: 'Intermediate', ru: 'Средний', uz: 'O\'rta daraja' },
    { level: 'B2', en: 'Intermediate-advanced', ru: 'Средне-продвинутый', uz: 'O\'rta-ilg\'or daraja' },
    { level: 'C1', en: 'Advanced', ru: 'Продвинутый', uz: 'Yuqori daraja' },
    { level: 'C2', en: 'Perfect', ru: 'В совершенстве', uz: 'Mukammal' },
]

// Array for separating incoming skills from api to sort them by group
const options = [
    { value: '1', label: 'Option 1', group: 'Навыки из вакансии' },
    { value: '2', label: 'Option 2', group: 'Навыки из вакансии' },
    { value: '3', label: 'Option 3', group: 'Другие навыки' },
    { value: '4', label: 'Option 4', group: 'Другие навыки' },
    { value: '5', label: 'Option 5', group: 'программы из вакансии' },
    { value: '6', label: 'Option 6', group: 'программы из вакансии' },
    { value: '7', label: 'Option 7', group: 'Другие программы' },
    { value: '8', label: 'Option 8', group: 'Другие программы' },
]

// Sort skills by group
export const groupedPrograms = [
    {
        label: 'программы из вакансии',
        options: options.filter((option) => option.group === 'программы из вакансии'),
    },
    {
        label: 'Другие программы',
        options: options.filter((option) => option.group === 'Другие программы'),
    },
]

// Sort skills by group
export const groupedSkills = [
    {
        label: 'Навыки из вакансии',
        options: options.filter((option) => option.group === 'Навыки из вакансии'),
    },
    {
        label: 'Другие навыки',
        options: options.filter((option) => option.group === 'Другие навыки'),
    },
]

export const statusesInMultiLang = [
    { uz: 'Kutilmoqda', en: 'Pending', ru: 'В ожидании' },
    { uz: 'Rad etildi', en: 'Reject', ru: 'Отказ' },
    { uz: 'Suhbat', en: 'Interview', ru: 'Собеседование' },
    { uz: 'Ishga taklif', en: 'Job Invitation', ru: 'Приглашение на работу' },
    { uz: 'Offer', en: 'Offer', ru: 'Оффер' },
]

export const jobFormats = [
    { value: 1, uz: 'To\'liq kun', ru: 'Полный день', en: 'Full day' },
    { value: 2, uz: 'Smenali ish', ru: 'Сменный график', en: 'Shift schedule' },
    { value: 3, uz: 'Moslashuvchan jadval', ru: 'Гибкий график', en: 'Flexible schedule' },
    { value: 4, uz: 'Masofaviy ish', ru: 'Удаленная работа', en: 'Remote working' },
    { value: 5, uz: 'Aylanma ish jadvali', ru: 'Вахтовый метод', en: 'Rotation method' },
]

export const workSchedule = [
    { value: 1, uz: 'To\'liq bandlik', ru: 'Полная занятость', en: 'Full employment' },
    { value: 2, uz: 'Yarim kunlik ish', ru: 'Частичная занятость', en: 'Part-time employment' },
    { value: 3, uz: 'Loyiha ishi', ru: 'Проектная работа', en: 'Project work' },
    { value: 4, uz: 'Shartnoma', ru: 'Контракт', en: 'Contract' },
    { value: 5, uz: 'Mavsumiy ish', ru: 'Сезонная работа', en: 'Seasonal work' },
    { value: 6, uz: 'Amaliyot', ru: 'Стажировка', en: 'Internship' },
    { value: 7, uz: 'Ko\'ngillilik', ru: 'Волонтерство', en: 'Volunteering' },
]

export const SalaryReceiveOptions = [
    { value: 1, uz: 'Ishbay', en: 'Piecework', ru: 'Сдельная' },
    { value: 2, uz: 'Har kuni', en: 'Every day', ru: 'Каждый день' },
    { value: 3, uz: 'Oyning birinchi sanasida', en: 'First days of the month', ru: 'Первые числа месяца' },
]
