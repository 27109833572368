import React from 'react'
import { Layout } from '../../common/Layout'
import VacancyInfo from './VacancyInfo'

export default function CompanyVacancyInfo() {
    return (
        <Layout>
            <VacancyInfo />
        </Layout>
    )
}
