import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import Verification from './Verification'
import { useMessage } from '../../hooks/message'
import { usePostRequest } from '../../hooks/request'
import { CANDIDATE_SIGNIN, CANDIDATE_VERIFY_SIGNIN } from '../../urls'
import { deFormatPhone } from '../../utils/number'

export default function SignInVerify() {
    const [showMessage] = useMessage()
    const [pageLoading, setPageLoading] = useState(false)
    const { state } = useLocation()
    const verifyLogin = usePostRequest({ url: CANDIDATE_VERIFY_SIGNIN })
    const postLogin = usePostRequest({ url: CANDIDATE_SIGNIN })

    useEffect(() => {
        if (!state || !state.phone) {
            window.history.back()
        }
    }, [])

    const onLoginVerify = async (code) => {
        if (verifyLogin.loading) return
        const { error, response, success } = await verifyLogin.request({ data: {
            phone: state && `+998${deFormatPhone(state.phone)}`,
            code,
        } })

        if (error) {
            showMessage(error.data.errors[0].message || error.data.message, 'error-msg')
            return
        }

        if (success) {
            setPageLoading(true)
            window.location.href = state ? state.redirect : '/'
            localStorage.setItem('token', JSON.stringify(response.data.accessToken))
            localStorage.setItem('refresh_token', JSON.stringify(response.data.refreshToken))
        }
    }

    return (
        <Verification
            $api={postLogin}
            onSubmit={onLoginVerify}
            pageLoading={pageLoading}
            loading={verifyLogin.loading}
            phoneNumber={state && state.phone}
        />
    )
}
