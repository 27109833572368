import React, { useContext } from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import uuid from 'react-uuid'
import { FormattedMessage } from 'react-intl'
import { COLORS } from '../../../utils/colors'
import { DefaultLocation } from '../../svgs/Svgs'
import { LoadingSkeleton } from '../../common/LoadingSkeleton'
import { getFullDate } from '../../../utils/date'
import { BaseContext } from '../../../contexts/BaseContextWrapper'
import { fromToStr } from '../../../utils/string'

export function VacanciesTableItem({ item, info, getVacancies, theme, handleNavigate }) {
    const { lang } = useContext(BaseContext)

    return !getVacancies.loading ? (
        <React.Fragment>
            <tr
                key={item.id}
                className={css(mode(theme).item)}
                onClick={() => handleNavigate('forward', item.slug)}>
                <td>
                    <div className={cn('align-center gap-3', css(s.hero))}>
                        {info.logo && info.logo.length ? (
                            <div className={css(s.logoCont)}>
                                <img src={info.logo[1]} alt="#CompanyLogo" />
                            </div>
                        ) : (
                            <div className={css(mode(theme).defaultLogo)}>
                                <DefaultLocation color={COLORS.white} />
                            </div>
                        )}

                        <div className={css(s.compInfo)}>
                            <h3 className={css(s.positionName)}>
                                {item.name.length > 70 ? `${item.name.slice(0, 70)}...` : item.name}
                            </h3>

                            <h3 className={css(s.depart)}>{item.department}</h3>
                        </div>
                    </div>
                </td>

                <td className={css(s.pubDate)}>
                    {item.createdAt ? getFullDate(item.createdAt, 'DD MMMM YYYY', lang) : null}
                </td>

                {/* <td className={css(s.deadline)}> */}
                {/*     {item.deadline ? getFullDate(item.deadline, 'DD MMMM YYYY', lang) : '———'} */}
                {/* </td> */}

                <td className={css(s.salary)}>
                    <h3>
                        {fromToStr(
                            item.salaryMin,
                            item.salaryMax,
                            item.currency,
                            <FormattedMessage id="basedOnInterview" />,
                            lang,
                        )}
                    </h3>
                </td>

                <td className={css(s.location)}>
                    {(item.location || '———')}
                </td>

                <td />
            </tr>
        </React.Fragment>
    ) : (
        <React.Fragment>
            <tr key={uuid()} className={css(mode(theme).item)}>
                <td>
                    <div className={cn('align-center gap-3', css(s.hero))}>
                        <div className={css(mode(theme).defaultLogo)}>
                            <DefaultLocation color={COLORS.white} />
                        </div>

                        <div className={css(s.compInfo)}>
                            <LoadingSkeleton height={16} />

                            <LoadingSkeleton height={11} />
                        </div>
                    </div>
                </td>

                <td className={css(s.pubDate)}>
                    <LoadingSkeleton height={13} />
                </td>

                <td className={css(s.deadline)}>
                    <LoadingSkeleton height={13} width={70} />
                </td>

                <td className={css(s.salary)}><LoadingSkeleton height={13} /></td>

                <td className={css(s.location)}>
                    <LoadingSkeleton height={13} />
                </td>

                <td />
            </tr>
        </React.Fragment>
    )
}

const s = StyleSheet.create({
    compInfo: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        gap: '4px',
    },
    depart: {
        color: COLORS.gray,
        fontSize: 11,
        fontWeight: '400',
    },
    hero: {
        paddingLeft: 36,
    },
    pubDate: {
        paddingLeft: '1rem',
        textAlign: 'left',
    },
    deadline: {
        paddingLeft: '1rem',
        textAlign: 'left',
    },
    salary: {
        textAlign: 'left',
    },
    location: {
        textAlign: 'left',
    },
    logoCont: {
        width: 40,
        height: 40,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 4,
        boxSizing: 'border-box',
        ':nth-child(1n) > img': {
            width: 'inherit',
            borderRadius: 4,
        },
    },
})

const mode = (theme) => StyleSheet.create({
    item: {
        background: theme === 'dark' ? COLORS.itemDarkModeBg : COLORS.white,
        height: '3.75rem',
        color: theme === 'dark' ? COLORS.lightGray : COLORS.dark,
        fontSize: 13,
        fontWeight: '500',
        userSelect: 'none',
        ':nth-child(1n) > td:last-child': {
            display: 'flex',
            height: 'inherit',
            alignItems: 'center',
            justifyContent: 'right',
        },
        ':nth-child(1n) > td': {
            whiteSpace: 'nowrap',
        },
    },
    positionName: {
        color: theme === 'dark' ? COLORS.lightGray : COLORS.dark,
        fontSize: 13,
        fontWeight: '500',
        textAlign: 'left',
    },
    defaultLogo: {
        width: 40,
        height: 40,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: COLORS.mainColor,
        border: theme === 'dark' ? 'none' : `1px solid ${COLORS.white}`,
        borderRadius: 4,
        boxSizing: 'border-box',
    },
})
