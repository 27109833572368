import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { COLORS } from '../../utils/colors'
import { FacebookLogo, InstagramLogo, LinkedInLogo, TelegramLogo } from '../svgs/Svgs'

const SocialNetworks = {
    facebook: FacebookLogo,
    instagram: InstagramLogo,
    linkedin: LinkedInLogo,
    telegram: TelegramLogo,
}

function Tag({ icon: Icon, label, link }) {
    return (
        <a href={link} target="_blank" rel="noreferrer" style={{ textDecoration: 'none' }}>
            <div className={cn('align-center gap-2', css(s.tag))}>
                <Icon />

                <h3>{label}</h3>
            </div>
        </a>
    )
}

export default function SocialNetworkTags({ tags, loading }) {
    return !loading && tags && (
        <div className="align-center gap-7">
            {Object.entries(SocialNetworks).map(([label, icon]) => (
                tags[label] && <Tag icon={icon} label={label} link={tags[label]} />
            ))}
        </div>
    )
}

const s = StyleSheet.create({
    tag: {
        height: '2.125rem',
        padding: '0 12px',
        borderRadius: 8,
        border: `1px solid ${COLORS.smoothGray}`,
        background: COLORS.white,
        boxShadow: '0px 2px 4px 0px rgba(130, 130, 130, 0.05)',
        color: '#343836',
        fontSize: 16,
        fontWeight: '600',
        textTransform: 'capitalize',
    },
})
