import React, { useContext, useEffect, useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import { CandidateEducation } from './CandidateEducation'
import { CandidateExperience } from './CandidateExperience'
import MainInfoForm from './MainInfoForm'
import { COLORS } from '../../../utils/colors'
import { BaseContext } from '../../../contexts/BaseContextWrapper'
import { LanguagesForm } from '../../common/LanguagesForm'

export default function MainForm({
    info,
    values,
    setFile,
    deletePhoto,
    setValues,
    errors,
    setFieldValue,
    initialValues,
}) {
    // const isEmpty = values.experience.every((exp) => Object.values(exp).every((val) => val !== ''))
    const [isExperienced, setIsExperienced] = useState(false)
    const { theme, lang } = useContext(BaseContext)

    useEffect(() => {
        if (!isExperienced) {
            setFieldValue('experience', [{
                job: '',
                position: '',
                achievements: '',
                start_date: '',
                end_date: '',
            }])
        }
    }, [isExperienced])

    useEffect(() => {
        setIsExperienced(info.experience && info.experience.length > 0)
    }, [info])

    return (
        <div className={css(mode(theme).wrapper)}>
            <MainInfoForm
                lang={lang}
                info={info}
                theme={theme}
                values={values}
                setFile={setFile}
                deletePhoto={deletePhoto}
                setFieldValue={setFieldValue}
            />

            <CandidateEducation
                values={values}
                theme={theme}
                errors={errors}
                setValues={setValues}
                initialValues={initialValues}
                setFieldValue={setFieldValue}
            />

            <CandidateExperience
                theme={theme}
                values={values}
                setValues={setValues}
                initialValues={initialValues}
                setFieldValue={setFieldValue}
                isExperienced={isExperienced}
                setIsExperienced={setIsExperienced}
            />

            {/* <LanguageForm */}
            {/*     theme={theme} */}
            {/*     values={values} */}
            {/*     setFieldValue={setFieldValue} */}
            {/* /> */}
            <LanguagesForm values={values} setFieldValue={setFieldValue} />
        </div>
    )
}

const mode = (theme) => StyleSheet.create({
    wrapper: {
        width: '100%',
        maxWidth: '56rem',
        display: 'flex',
        flexDirection: 'column',
        ':nth-child(1n) > div': {
            background: theme === 'dark' ? COLORS.darkModeBg : COLORS.white,
        },
        gap: 25,
        '@media (min-width: 1200px)': {
            minWidth: '56rem',
        },
    },
})
