import React from 'react'
import cn from 'classnames'
import { css, StyleSheet } from 'aphrodite'
import uuid from 'react-uuid'
import { COLORS } from '../../utils/colors'
import { LoadingSkeleton } from '../common/LoadingSkeleton'
// import Button from '../common/Button'

export default function CompanyPhotos({ photos, theme, loading }) {
    return (
        <div className={cn('is-flex direction-column gap-7', css(s.imgCont))}>
            {/* <div className="justify-between"> */}
            {/*     <div className={cn(css(s.titleWrap), 'align-center gap-2')}> */}
            {/*         <h3 className={css(mode(theme).title)}>Фото компании</h3> */}
            {/*     </div> */}
            {/* </div> */}

            <div className={cn('is-flex', css(s.images))}>
                {!loading && photos ? photos.map((i) => (
                    <div key={uuid()} className={css(mode(theme).imgWrap)}>
                        <div className={imgWrap(i.link && i.link[0])} />
                        <img className={css(mode(theme).img)} src={i.link && i.link[0]} alt="#companyImage" />
                    </div>
                )) : <div className={css(s.imgSkeleton)}><LoadingSkeleton width={258} height={150} /></div>}
            </div>

            {/* <div className={cn('is-flex align-center gap-7', mode(theme).socialLinks)}> */}
            {/*     <Button>Telegram</Button> */}

            {/*     <Button>Telegram</Button> */}

            {/*     <Button>Telegram</Button> */}
            {/* </div> */}
        </div>
    )
}
const s = StyleSheet.create({
    titleWrap: {
        height: '28px',
        ':nth-child(1n) > :last-child': {
            cursor: 'pointer',
            ':nth-child(1n) > path': {
                stroke: COLORS.mainColor,
            },
        },
    },
    imgCont: {
        userSelect: 'none',
    },
    images: {
        gap: 20,
        overflowX: 'auto',
        '::-webkit-scrollbar': {
            display: 'none',
        },
        boxSizing: 'border-box',
    },
    imgSkeleton: {
        paddingTop: 16,
        position: 'relative',
        '@media (max-width: 768px)': {
            paddingTop: 8,
        },
    },
})

const mode = (theme) => StyleSheet.create({
    title: {
        fontSize: 20,
        fontWeight: '700',
        color: theme === 'dark' ? COLORS.white : COLORS.lightBlack,
    },
    img: {
        width: 236,
        height: 126,
        objectFit: 'cover',
        objectPosition: 'center',
        border: `1px solid ${theme === 'dark' ? COLORS.lightBlack : COLORS.midGray}`,
        borderRadius: 4,
        boxSizing: 'border-box',
        zIndex: 2,
        position: 'absolute',
        left: 12,
        clip: 'revert',
        top: 12,
    },
    imgWrap: {
        width: 258,
        height: 150,
        marginTop: 16,
        borderRadius: 4,
        border: `1px solid ${theme === 'dark' ? COLORS.lightBlack : COLORS.midGray}`,
        position: 'relative',
        '@media (max-width: 768px)': {
            marginTop: 8,
        },
    },
})

const imgWrap = (img) => css(StyleSheet.create({
    cont: {
        width: 259,
        height: 151,
        borderRadius: 4,
        border: '12px solid transparent',
        boxSizing: 'border-box',
        // eslint-disable-next-line max-len
        background: `linear-gradient(0deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.50) 100%), url(${img})`,
        backgroundSize: 'cover',
        filter: 'blur(2px)',
        ':nth-child(1n) > span': {
            display: 'none',
        },
    },
}).cont)
