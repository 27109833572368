export const INDUSTRIES_LIST = 'v1/industries'

export const PUBLIC_COMPANY_INFO = 'v1/public/company/{slug}'
export const PUBLIC_COMPANY_VACANCIES = 'v1/public/vacancies/{slug}'
export const PUBLIC_COMPANY_VACANCY = 'v1/public/vacancy/{slug}/{vSlug}'
export const PUBLIC_SKILLS_LIST = 'v1/public/skills'
export const PUBLIC_COMPANIES_BY_INDUSTRY = 'v1/public/company-list/{id}'

export const CANDIDATE_SIGNUP = 'c1/registration'
export const CANDIDATE_VERIFY_SIGNUP = 'c1/registration/confirm'
export const CANDIDATE_SIGNIN = 'c1/login'
export const CANDIDATE_VERIFY_SIGNIN = 'c1/login/confirm'
export const CANDIDATE_PROFILE_INFO = 'c1/profile/view'
export const CANDIDATE_UPDATE_PHONE = 'c1/update-phone-number'
export const CANDIDATE_UPDATE_PHONE_CONFIRM = 'c1/update-phone-number/confirm'
export const CANDIDATE_UPDATE_EMAIL = 'v1/verify-email'
export const CANDIDATE_VERIFY_EMAIL = 'c1/send-email-verification'
export const CANDIDATE_PHOTO = 'c1/profile/photo'
export const DELETE_CANDIDATE_PHOTO = 'c1/profile/photo'
export const VACANCY_APPLY = 'c1/vacancy/apply'
export const CANDIDATE_INFO_UPDATE = 'c1/profile/update'
export const CANDIDATE_EDUCATION_CREATE = 'c1/education/create'
export const CANDIDATE_EDUCATION_DELETE = 'c1/education/{id}'
export const CANDIDATE_EDUCATION_UPDATE = 'c1/education/{id}'
export const CANDIDATE_EXPERIENCE_CREATE = 'c1/experience/create'
export const CANDIDATE_EXPERIENCE_DELETE = 'c1/experience/{id}'
export const CANDIDATE_EXPERIENCE_UPDATE = 'c1/experience/{id}'
export const CANDIDATE_LANGUAGE_CREATE = 'c1/language/create'
export const CANDIDATE_LANGUAGE_DELETE = 'c1/language/{id}'
export const CANDIDATE_LANGUAGE_UPDATE = 'c1/language/{id}'
export const APPLIED_VACANCIES = 'c1/profile/applied'
export const PUBLIC_COMPANIES_LIST = 'c1/company/list'
export const PUBLIC_VACANCIES_LIST = 'c1/vacancies'
export const PROFILE_LOGOUT = 'c1/profile/logout'
export const CANDIDATE_REFRESH_TOKEN = 'c1/refresh-token'
export const CANDIDATE_AGREEMENT_MEETUP = 'c1/vacancy/meetup'
export const CANDIDATE_AGREEMENT_OFFER = 'c1/vacancy/offer'
export const CANDIDATE_AGREEMENT_INVITE = 'c1/vacancy/invite'

export const OFFER_TERMS = '/v1/offer-terms'
