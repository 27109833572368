import React from 'react'
import cn from 'classnames'
import { css, StyleSheet } from 'aphrodite'
import { FormattedMessage } from 'react-intl'
import { COLORS } from '../../../utils/colors'

// function Input({ name, placeholder }) {
//     const [value, setValue] = useState('')
//
//     return (
//         <div
//             id={name}
//             onClick={() => {
//                 document.querySelector(`#${name}`).classList.add(css(s.actSrBtn))
//                 document.querySelector(`#${name} input`).focus()
//             }}>
//             <MagnifierSizeM className={css(s.green_icon)} />
//
//             <div className={css(s.inputWrap)}>
//                 <input
//                     type="text"
//                     autoComplete="off"
//                     name={name}
//                     value={value}
//                     className={css(s.inp)}
//                     placeholder={placeholder}
//                     onChange={(event) => setValue(event.target.value)}
//                     onBlur={() => (
// eslint-disable-next-line max-len
//                         value.length === 0 ? document.querySelector(`#${name}`).classList.remove(css(s.actSrBtn)) : {}
//                     )}
//                 />
//
//                 {value.length > 0 && (
//                     <span className="flex-cent pointer">
//                         <Clear onClick={() => setValue('')} />
//                     </span>
//                 )}
//             </div>
//         </div>
//     )
// }
export function VacanciesTableHeader({ theme }) {
    return (
        <thead className={cn(css(mode(theme).thead))}>
            <tr className={css(s.row)}>
                <th><FormattedMessage id="vacancy" /></th>

                <th className={cn(css(s.pubDate), css(s.srBtn))}>
                    <FormattedMessage id="publicationDate" />
                </th>

                {/* <th className={css(s.deadline)}> */}
                {/*     <FormattedMessage id="deadline" /> */}
                {/* </th> */}

                <th className={css(s.desired_salary)}>
                    <FormattedMessage id="salaryFork" />
                </th>

                <th className={cn(css(s.location), css(s.srBtn))}>
                    <FormattedMessage id="jobLocation" />
                </th>

                <th />
            </tr>
        </thead>
    )
}

const s = StyleSheet.create({
    pubDate: {
        width: '15%',
        paddingLeft: '1rem',
        textAlign: 'left',
    },
    // deadline: {
    //     width: '14%',
    //     paddingLeft: '1rem',
    // },
    desired_salary: {
        width: '20%',
    },
    location: {
        // width: '22%',
    },
})

const mode = (theme) => StyleSheet.create({
    thead: {
        width: '100%',
        color: theme === 'dark' ? COLORS.gray : COLORS.midGray,
        fontSize: 13,
        textTransform: 'uppercase',
        textAlign: 'left',
        ':nth-child(1n) > :first-child': {
            background: theme === 'dark' ? 'rgba(234, 244, 240, 0.10)' : '#EAF4F0',
            ':nth-child(1n) > *': {
                fontWeight: '500',
                padding: '14.5px 0',
            },
            ':nth-child(1n) > :first-child': {
                paddingLeft: 36,
                borderRadius: '4px 0 0 0',
            },
            ':nth-child(1n) > :last-child': {
                borderRadius: '0 4px 0 0',
            },
        },
        whiteSpace: 'nowrap',
    },
})
