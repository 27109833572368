import React, { useContext } from 'react'
import { css, StyleSheet } from 'aphrodite'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import cn from 'classnames'
import { FormattedMessage } from 'react-intl'
import { ChevronNextSizeL } from '../components/svgs/Svgs'
import AccountInfo from '../components/profile/AccountInfo'
import AppliedVacancies from '../components/profile/AppliedVacancies'
import { Layout } from '../components/common/Layout'
import { COLORS } from '../utils/colors'
import { BaseContext } from '../contexts/BaseContextWrapper'

export default function ProfilePage() {
    const { pathname } = useLocation()
    const navigate = useNavigate()
    const { theme } = useContext(BaseContext)

    return (
        <Layout>
            <div className={css(s.mainWrapper)}>
                <div className={css(s.leftWrap)}>
                    <div onClick={() => navigate(-1)} className={css(mode(theme).chevronWrap)}>
                        <ChevronNextSizeL color={theme === 'dark' ? COLORS.white : COLORS.dark} />
                    </div>

                    <div className={css(mode(theme).boxWrap)}>
                        <div onClick={() => navigate(-1)} className={css(s.mobileChevron)}>
                            <ChevronNextSizeL color={theme === 'dark' ? COLORS.white : COLORS.dark} />
                        </div>

                        <div className={css(s.links)}>
                            <Link
                                to="/account/info"
                                className={cn(css(mode(theme).linkTxt),
                                    { [css(mode(theme).activeLink)]: pathname.endsWith('info') })}
                            >
                                <FormattedMessage id="privateInfo" />
                            </Link>

                            <Link
                                to="/account/applies"
                                className={cn(css(mode(theme).linkTxt),
                                    { [css(mode(theme).activeLink)]: pathname.endsWith('applies') })}
                            >
                                <FormattedMessage id="applies" />
                            </Link>
                        </div>
                    </div>
                </div>
                <div className={css(s.rightWrap)}>
                    {pathname.endsWith('/account/info') && <AccountInfo />}

                    {pathname.endsWith('/account/applies') && <AppliedVacancies />}
                </div>
            </div>
        </Layout>
    )
}

const s = StyleSheet.create({
    mainWrapper: {
        display: 'flex',
        alignItems: 'start',
        gap: 24,
        height: 'calc(100% - 57px)',
        '@media (max-width: 1100px)': {
            flexDirection: 'column',
            width: '100%',
            gap: 0,
        },
    },
    leftWrap: {
        flex: 1,
        display: 'flex',
        gap: 16,
        padding: '24px 0 32px 32px',
        '@media (max-width: 1100px)': {
            padding: 0,
            flex: 0,
            width: '100%',
        },
    },
    mobileChevron: {
        cursor: 'pointer',
        ':nth-child(1n) > :first-child': {
            transform: 'rotate(180deg)',
            ':nth-child(1n) > path': {
                strokeWidth: 3,
            },
        },
        '@media (min-width: 1100px)': {
            display: 'none',
        },
    },
    links: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '@media (min-width: 1100px)': {
            display: 'flex',
            flexDirection: 'column',
        },
    },
    rightWrap: {
        flex: 2,
        position: 'relative',
        height: '100%',
        '@media (max-width: 1100px)': {
            width: '100%',
        },
    },
})

const mode = (theme) => StyleSheet.create({
    chevronWrap: {
        ':nth-child(1n) > :first-child': {
            transform: 'rotate(180deg)',
        },
        background: theme === 'dark' ? 'rgba(0, 0, 0, 0.10)' : COLORS.white,
        border: `1px solid ${theme === 'dark' ? 'rgba(255, 255, 255, 0.20)' : COLORS.smoothGray}`,
        borderRadius: 64,
        width: 36,
        height: 36,
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        justifyContent: 'center',
        userSelect: 'none',
        '@media (max-width: 1100px)': {
            display: 'none',
        },
    },
    boxWrap: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        background: theme === 'dark' ? COLORS.darkModeBg : COLORS.white,
        border: theme === 'dark' ? 'none' : `1px solid ${COLORS.smoothGray}`,
        boxShadow: '0px 2px 4px rgba(130, 130, 130, 0.05)',
        borderRadius: 4,
        '@media (max-width: 1100px)': {
            padding: '10.5px 16px',
        },
        '@media (min-width: 1100px)': {
            display: 'flex',
            padding: 0,
        },
    },
    linkTxt: {
        fontWeight: '600',
        fontSize: 20,
        color: COLORS.gray,
        textDecoration: 'none',
        padding: 24,
        ':hover': {
            background: theme === 'dark' ? COLORS.itemDarkModeBg : '#D6F2E5',
            color: theme === 'dark' ? COLORS.white : COLORS.dark,
        },
        '@media (max-width: 1100px)': {
            padding: '8px 12px',
            borderRadius: 4,
        },
        '@media (min-width: 1100px)': {
            width: 'calc(100% - 48px)',
        },
        '@media (max-width: 768px)': {
            fontSize: 16,
        },
    },
    activeLink: {
        background: theme === 'dark' ? COLORS.itemDarkModeBg : '#D6F2E5',
        color: theme === 'dark' ? COLORS.white : COLORS.dark,
    },
})
