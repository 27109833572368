import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import { range } from 'lodash'
import { COLORS } from '../../../utils/colors'
import Checkbox from '../../common/Checkbox'
import { LoadingSkeleton } from '../../common/LoadingSkeleton'

export default function FilterByIndustry({
    loading,
    name,
    options,
    setFilter,
    filter,
    theme,
}) {
    function onCheck(id) {
        if (filter[name]) {
            if (filter[name].includes(id)) {
                if (filter[name].length > 1) {
                    setFilter({
                        ...filter,
                        [name]: filter[name].filter((fil) => fil !== id),
                    })
                } else {
                    const {
                        [name]: sectionFilter,
                        ...clearedFilter
                    } = filter
                    setFilter(clearedFilter)
                }
            } else {
                setFilter({ ...filter, [name]: [...filter[name], id] })
            }
        } else setFilter({ ...filter, [name]: [id] })
    }

    const isChecked = (id) => filter[name] && filter[name].includes(id)

    return (
        <ul className="no_dot_list is-flex direction-column gap-2">
            {!loading ? options.slice(0, 5).map((option) => (
                <li key={option.value} onClick={() => onCheck(option.value)} className="justify-between pointer">
                    <div className="align-center gap-2">
                        <Checkbox
                            className={css(mode(theme).checkBox)}
                            checked={isChecked(option.value)}
                            onClick={() => {}}
                        />

                        <h3 className={css(mode(theme).label,
                            !isChecked(option.value) && mode(theme).labelColor)}>
                            {option.label}
                        </h3>
                    </div>

                    {/* <span className={cn( */}
                    {/*    'flex-cent', css(s.count, !isChecked(option.id) && s.countColor), */}
                    {/* )}> */}
                    {/*    {option.companyCount} */}
                    {/* </span> */}
                </li>
            )) : range(5).map((key) => (
                <li key={key} className="align-center gap-2">
                    <LoadingSkeleton inline width={16} height={16} />
                    <LoadingSkeleton inline width={100} height={13} />
                </li>
            ))}
        </ul>
    )
}

const mode = (theme) => StyleSheet.create({
    label: {
        fontSize: 11,
        fontWeight: '500',
        color: theme === 'dark' ? COLORS.lightGray : COLORS.lightBlack,
    },
    labelColor: {
        color: COLORS.gray,
    },
    checkBox: {
        borderColor: theme === 'dark' ? COLORS.gray : '#E8E8E8',
    },
})
