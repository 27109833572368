import React, { useContext } from 'react'
import { css, StyleSheet } from 'aphrodite'
import { useNavigate } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { COLORS } from '../../../utils/colors'
import { ChevronNextSizeL, DefaultLocation } from '../../svgs/Svgs'
import { OtherVacanciesSkeleton } from './VacancyInfoSkeleton'
import { getFullDate } from '../../../utils/date'
import { BaseContext } from '../../../contexts/BaseContextWrapper'
import { fromToStr } from '../../../utils/string'

export default function OtherVacancies({
    state,
    companyLogo,
    params,
    vacancies,
    loading,
    theme,
    handleNavigate,
}) {
    const navigate = useNavigate()
    const { lang } = useContext(BaseContext)

    return !loading ? (
        <div className={css(s.leftWrap)}>
            <div className={css(mode(theme).chevronWrap)} onClick={() => handleNavigate('back')}>
                <ChevronNextSizeL />
            </div>

            <div className={css(mode(theme).contWrap)}>
                <h3>
                    {state && state.public
                        ? <FormattedMessage id="similarVacancies" />
                        : <FormattedMessage id="otherVacancies" />}
                </h3>

                <div className={css(s.cardsWrap)}>
                    {vacancies.filter((val) => val.slug !== params.cSlug).map((item) => (
                        <div
                            key={item.id}
                            className={css(mode(theme).card)}
                            onClick={() => navigate(`/c/${params.cSlug}/${item.slug}`, { state: { ...state } })}>
                            <div className="align-center gap-3">
                                {companyLogo ? (
                                    <div className={css(s.logoCont)}>
                                        <img src={companyLogo} alt="#CompanyLogo" />
                                    </div>
                                ) : (
                                    <div className={css(mode(theme).defaultLogo)}>
                                        <DefaultLocation color={COLORS.white} />
                                    </div>
                                )}

                                <div className={css(mode(theme).vacTitle)}>
                                    <h3>
                                        {item.name.length > 30 ? item.name.slice(0, 30) : item.name}
                                    </h3>

                                    <p>
                                        {fromToStr(
                                            item.salaryMin,
                                            item.salaryMax,
                                            item.currency,
                                            <FormattedMessage id="basedOnInterview" />,
                                            lang,
                                        )}
                                    </p>
                                </div>
                            </div>

                            <span className={css(s.date)}>
                                {(item.createdAt && getFullDate(item.createdAt, 'DD MMMM YYYY', lang))}
                            </span>
                        </div>
                    ))}

                </div>
            </div>
        </div>
    ) : !vacancies.length > 1 ? null : <OtherVacanciesSkeleton />
}

const s = StyleSheet.create({
    contWrap: {
        maxWidth: '100%',
        background: COLORS.white,
        border: `1px solid ${COLORS.smoothGray}`,
        boxShadow: '0px 2px 4px rgba(130, 130, 130, 0.05)',
        borderRadius: 4,
        boxSizing: 'border-box',
        padding: 24,
        ':nth-child(1n) > :first-child': {
            fontWeight: '600',
            fontSize: 20,
            color: COLORS.dark,
        },
        '@media (max-width: 768px)': {
            width: '100%',
            padding: 12,
            borderRadius: 0,
        },
    },
    leftWrap: {
        // flex: 1,
        // minWidth: '30rem',
        display: 'flex',
        alignItems: 'start',
        gap: 16,
        marginRight: 24,
        '@media (max-width: 1200px)': {
            width: '100%',
        },
    },
    img: {
        width: 40,
        height: 40,
    },
    cardsWrap: {
        width: '100%',
        marginTop: 16,
        display: 'flex',
        flexDirection: 'column',
        gap: 16,
        maxHeight: '22rem',
        overflowX: 'hidden',
        overflowY: 'scroll',
        scrollbarWidth: 'none',
        '::-webkit-scrollbar': {
            display: 'none',
        },
        '-ms-overflow-style': {
            display: 'none',
        },
        '@media (max-width: 768px)': {
            marginTop: 12,
            gap: 12,
        },
    },
    date: {
        fontWeight: '400',
        fontSize: 11,
        textTransform: 'uppercase',
        color: COLORS.gray,
        whiteSpace: 'nowrap',
    },
    logoCont: {
        width: 40,
        height: 40,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 4,
        boxSizing: 'border-box',
        ':nth-child(1n) > img': {
            width: 'inherit',
            borderRadius: 4,
        },
    },
})

const mode = (theme) => StyleSheet.create({
    contWrap: {
        width: 'calc(100% - 48px)',
        background: theme === 'dark' ? COLORS.darkModeBg : COLORS.white,
        border: `1px solid ${theme === 'dark' ? COLORS.itemDarkModeBg : COLORS.smoothGray}`,
        boxShadow: '0px 2px 4px rgba(130, 130, 130, 0.05)',
        borderRadius: 4,
        padding: 24,
        ':nth-child(1n) > h3': {
            fontWeight: '600',
            fontSize: 20,
            color: theme === 'dark' ? COLORS.white : COLORS.dark,
        },
        '@media (max-width: 768px)': {
            width: 'calc(100% - 24px)',
            padding: 12,
            borderRadius: 0,
        },
    },
    card: {
        boxSizing: 'border-box',
        background: theme === 'dark' ? COLORS.itemDarkModeBg : COLORS.white,
        border: `1px solid ${theme === 'dark' ? COLORS.itemDarkModeBg : COLORS.smoothGray}`,
        boxShadow: '0px 2px 4px rgba(130, 130, 130, 0.05)',
        borderRadius: 4,
        padding: '16px 12px',
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'space-between',
        gap: 8,
    },
    chevronWrap: {
        ':nth-child(1n) > :first-child': {
            transform: 'rotate(180deg)',
            ':nth-child(1n) > path': {
                stroke: theme === 'dark' ? COLORS.white : COLORS.dark,
            },
        },
        background: theme === 'dark' ? 'rgba(0, 0, 0, 0.10)' : COLORS.white,
        border: `1px solid ${theme === 'dark' ? 'rgba(255, 255, 255, 0.20)' : COLORS.smoothGray}`,
        boxShadow: theme === 'dark' ? '0px 2px 12px 0px rgba(0, 0, 0, 0.20)' : 'none',
        backdropFilter: theme === 'dark' ? 'blur(6px)' : 'none',
        borderRadius: 64,
        minWidth: 36,
        minHeight: 36,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        '@media (max-width: 1200px)': {
            display: 'none',
        },
    },
    vacTitle: {
        ':nth-child(1n) > :first-child': {
            fontWeight: '700',
            fontSize: 16,
            color: theme === 'dark' ? COLORS.white : COLORS.dark,
        },
        ':nth-child(1n) > :last-child': {
            fontWeight: '400',
            fontSize: 13,
            color: theme === 'dark' ? COLORS.gray : COLORS.lightBlack,
            marginTop: 4,
        },
    },
    defaultLogo: {
        padding: 8,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: theme === 'dark' ? 'none' : `1px solid ${COLORS.white}`,
        background: COLORS.mainColor,
        borderRadius: 4,
        boxSizing: 'border-box',
    },
})
