import React, { memo, useContext } from 'react'
import { css, StyleSheet } from 'aphrodite'
import 'moment/locale/uz-latn'
import Highlighter from 'react-highlight-words'
import { useLocation, useNavigate } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import cn from 'classnames'
import { DefaultLocation, EyeOpen, MapPin, VerifiedSizeL, WalletSizeM } from '../svgs/Svgs'
import { COLORS } from '../../utils/colors'
import { BaseContext } from '../../contexts/BaseContextWrapper'
import { getFullDate } from '../../utils/date'
import { fromToStr } from '../../utils/string'

function MemoizedComponent({ item, keyword, theme }) {
    const navigate = useNavigate()
    const { pathname } = useLocation()
    const { lang } = useContext(BaseContext)

    return (
        <div
            key={item.id}
            className={css(mode(theme).cardWrap)}
            onClick={() => navigate(`/c/${item.companySlug}/${item.vacancySlug}`,
                { state: { public: true, from: pathname } })}
        >
            <div className={cn(css(s.cardBodyWrap), 'is-flex direction-column gap-5')}>
                <div className={css(s.compInfo)}>
                    {item.companyLogo && item.companyLogo.length ? (
                        <div className={css(mode(theme).logoCont)}>
                            <img src={item.companyLogo[1]} alt="#CompanyLogo" />
                        </div>
                    ) : (
                        <div className={css(mode(theme).defaultLogo)}>
                            <DefaultLocation color={COLORS.white} />
                        </div>
                    )}

                    <div className={css(s.comp_titles)}>
                        <Highlighter
                            /* eslint-disable-next-line max-len */
                            textToHighlight={item.name ? `${item.name.length > 27 ? `${item.name.substring(0, 27)}...` : item.name}` : ''}
                            searchWords={[keyword]}
                            className={css(mode(theme).name)}
                            highlightClassName="highlight-bg"
                        />
                        <div className="is-flex align-center gap-1">
                            <Highlighter
                                textToHighlight={`${item.companyName}` || ''}
                                searchWords={[keyword]}
                                className={css(s.companyName)}
                                highlightClassName="highlight-bg"
                            />

                            {item.verified ? <VerifiedSizeL /> : null}
                        </div>
                    </div>
                </div>

                <div className={css(s.detailsWrap)}>
                    <div className={css(mode(theme).detailWrap)}>
                        <WalletSizeM />

                        <h3>
                            {fromToStr(
                                item.salaryMin,
                                item.salaryMax,
                                item.currency,
                                <FormattedMessage id="basedOnInterview" />,
                                lang,
                            )}
                        </h3>
                    </div>

                    <div className={css(mode(theme).detailWrap)}>
                        <MapPin />

                        {item.locationName ? (
                            <h3>
                                {item.locationName && item.locationName.length > 27
                                    ? `${item.locationName.slice(0, 27)}...` : item.locationName}
                            </h3>
                        ) : <h3 style={{ color: COLORS.gray }}><FormattedMessage id="notSpecified" /></h3>}
                    </div>
                </div>
            </div>

            <div className="is-flex align-center justify-between">
                {item.vCreatedAt ? (
                    <h3 className={cn(css(mode(theme).createdDate), 'is-flex align-center justify-start')}>
                        {item.vCreatedAt && getFullDate(item.vCreatedAt, 'DD MMMM YYYY', lang)}
                    </h3>
                ) : null}

                <div className={cn(css(s.viewCont), 'is-flex align-center justify-end')}>
                    <EyeOpen color={theme === 'dark'
                        ? COLORS.mainIconDarkMode : COLORS.mainIconColor} />

                    <h3>{item.view}</h3>
                </div>
            </div>
        </div>
    )
}

export const VacancyCardItem = memo(MemoizedComponent)

const s = StyleSheet.create({
    cardBodyWrap: {
        '@media (max-width: 768px)': {
            gap: 8,
        },
    },
    compInfo: {
        maxHeight: 40,
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        gap: '12px',
        ':nth-child(1n) > img': {
            width: 40,
            height: 40,
        },
    },
    viewCont: {
        gap: 6,
        flex: 1,
        ':nth-child(1n) > h3': {
            color: COLORS.mainIconDarkMode,
            fontSize: 13,
            fontWeight: '500',
        },
    },
    comp_titles: {
        display: 'flex',
        flexDirection: 'column',
        gap: '4px',
        '@media (max-width: 768px)': {
            gap: 2,
            fontSize: 13,
        },
    },
    companyName: {
        fontWeight: 400,
        fontSize: 13,
        color: COLORS.gray,
    },
    detailsWrap: {
        display: 'flex',
        flexDirection: 'column',
        gap: 8,
    },
    extraWrap: {
        '@media (max-width: 768px)': {
            padding: '0 6px',
        },
    },
})

const mode = (theme) => StyleSheet.create({
    cardWrap: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        minHeight: 155,
        minWidth: '19rem',
        gap: 16,
        cursor: 'pointer',
        backgroundColor: theme === 'dark' ? COLORS.darkModeBg : COLORS.white,
        border: `1px solid ${theme === 'dark' ? COLORS.itemDarkModeBg : COLORS.smoothGray}`,
        boxShadow: '0px 2px 4px rgba(130, 130, 130, 0.05)',
        borderRadius: '4px',
        padding: 16,
        ':hover': theme === 'dark' ? 'none' : {
            border: `1px solid ${COLORS.lightGray}`,
            boxShadow: '0px 2px 8px rgba(136, 136, 136, 0.25)',
        },
        '@media (max-width: 768px)': {
            padding: 12,
            gap: 8,
        },
    },
    name: {
        fontWeight: 700,
        fontSize: 16,
        color: theme === 'dark' ? COLORS.white : COLORS.dark,
        '@media (max-width: 768px)': {
            fontSize: 13,
        },
    },
    detailWrap: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 8,
        ':nth-child(1n) > svg': {
            ':nth-child(1n) > :first-child': {
                stroke: COLORS.mainColor,
            },
            ':nth-child(1n) > :last-child': {
                stroke: COLORS.mainColor,
            },
        },
        background: theme === 'dark' ? COLORS.itemDarkModeBg : 'inherit',
        border: theme === 'dark' ? 'none' : `1px solid ${COLORS.smoothGray}`,
        boxShadow: '0px 2px 4px rgba(130, 130, 130, 0.05)',
        borderRadius: '16px',
        padding: '4px 12px',
        fontWeight: 500,
        fontSize: '13px',
        color: theme === 'dark' ? COLORS.gray : COLORS.lightBlack,
        width: 'fit-content',
        '@media (max-width: 768px)': {
            padding: '6px 8px',
            fontSize: 10,
            gap: 4,
        },
        whiteSpace: 'nowrap',
    },
    defaultLogo: {
        width: 40,
        height: 40,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: COLORS.mainColor,
        border: theme === 'dark' ? 'none' : `1px solid ${COLORS.white}`,
        borderRadius: 4,
        boxSizing: 'border-box',
    },
    createdDate: {
        color: theme === 'dark' ? COLORS.gray : COLORS.midGray,
        fontSize: 13,
        fontWeight: '400',
        flex: 1,
    },
    logoCont: {
        width: 40,
        height: 40,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxSizing: 'border-box',
        ':nth-child(1n) > img': {
            width: 'inherit',
            border: theme === 'dark' ? 'none' : `1px solid ${COLORS.white}`,
            borderRadius: 4,
        },
    },
})
