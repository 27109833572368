import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import { FormattedMessage } from 'react-intl'
import cn from 'classnames'
import { COLORS } from '../../utils/colors'
import { CloseSidebar } from '../svgs/Svgs'

export default function WaitModal({ onClose, item, theme }) {
    return (
        <div>
            <div className={css(mode(theme).modalWrap)}>
                <div className={css(mode(theme).modalHeader)}>
                    <h3>{item && item.company.name}</h3>

                    <CloseSidebar color={theme === 'dark' ? COLORS.white : COLORS.dark} onClick={onClose} />
                </div>

                <div className={css(mode(theme).modalBody)}>
                    <div className="is-flex align-center gap-1">
                        <p className={cn(css(mode(theme).statusTxt), 'is-flex align-center gap-1')}>
                            <FormattedMessage id="company" />

                            <h3>{item.company.name}</h3>

                            <FormattedMessage id="isCurrentlyConsidering" />
                        </p> 🙇‍♀️
                    </div>
                </div>
            </div>
        </div>
    )
}

const mode = (theme) => StyleSheet.create({
    modalWrap: {
        width: '37.25rem',
        borderRadius: '4px 4px 0px 0px',
        '@media (max-width: 768px)': {
            width: '90vw',
        },
    },
    modalHeader: {
        padding: '20px 24px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        background: theme === 'dark' ? COLORS.itemDarkModeBg : COLORS.white,
        borderBottom: `1px solid ${theme === 'dark' ? COLORS.lightBlack : COLORS.lightGray}`,
        borderRadius: '4px 4px 0px 0px',
        ':nth-child(1n) > h3': {
            fontWeight: '600',
            fontSize: 20,
            color: theme === 'dark' ? COLORS.white : COLORS.dark,
        },
        ':nth-child(1n) > :last-child': {
            cursor: 'pointer',
        },
        '@media (max-width: 768px)': {
            padding: '14px 16px',
        },
    },
    modalBody: {
        padding: 24,
        background: theme === 'dark' ? COLORS.darkModeBg : COLORS.white,
        '@media (max-width: 768px)': {
            padding: 16,
        },
    },
    statusTxt: {
        color: COLORS.gray,
        fontSize: 14,
        fontStyle: 'italic',
        fontWeight: '500',
    },
})
