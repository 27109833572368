import React, { memo, useEffect, useRef, useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import { isEmpty } from 'lodash'
import cn from 'classnames'
import { FormattedMessage, useIntl } from 'react-intl'
import { COLORS } from '../../../utils/colors'
import FormInput from '../../common/FormInput'
import { Calendar, EditSizeL, LightingSizeM, PlusRoundedSizeL, XCircleSizeL } from '../../svgs/Svgs'
import Checkbox from '../../common/Checkbox'
import InputDate from '../../common/InputDate'
import { required } from '../../../utils/validators'

const defaultExpKeys = { job: '', position: '', start_date: '', end_date: '', achievements: '' }

function CandidateExperienceForm({ ind, setFieldValue, values, removeMode, isExperienced, theme, setValues }) {
    const [multiDate, setMultiDate] = useState(false)
    const ref = useRef()
    const intl = useIntl()

    useEffect(() => {
        if (values.experience[ind] && values.experience[ind].start_date !== null
            && values.experience[ind].end_date === null) {
            setMultiDate(false)
        } else {
            setMultiDate(true)
        }
    }, [ind])

    function removeField(index) {
        setValues((prev) => {
            const updatedExp = prev.experience.filter((_, i) => i !== index)
            return { ...prev, experience: updatedExp }
        })
        // ref.current.remove()
    }

    return (
        <div ref={ref} key={ind} className={css(mode(theme).formBody)}>
            <div
                style={{ borderColor: 'transparent' }}
                className={cn(css(s.formInputs), { [css(s.removable_field)]: removeMode && ind !== 0 })}>
                {removeMode && ind !== 0 && <XCircleSizeL onClick={() => removeField(ind)} />}

                <FormInput
                    validate={required}
                    errorWithMessage={false}
                    value={isExperienced ? (values.experience[ind] && values.experience[ind].job) : ''}
                    labelClassName={css(mode(theme).label)}
                    className={css(mode(theme).input)}
                    name={`experience[${ind}].job`}
                    placeholder={intl.formatMessage({ id: 'enterCompNamePlaceHolder' })}
                    label={ind > 0 ? <FormattedMessage id="office" /> : <FormattedMessage id="lastOffice" />}
                />

                <InputDate
                    isMulti={multiDate}
                    theme={theme}
                    errorWithMessage={false}
                    validate={values.experience[ind] && values.experience[ind].job && required}
                    icon={<Calendar />}
                    fieldClassName={css(s.fld)}
                    className={css(mode(theme).dateInput)}
                    labelClassName={css(mode(theme).dateInputLabel)}
                    placeholder={['дд.мм.гггг', 'дд.мм.гггг']}
                    label={(
                        <h3><FormattedMessage id="startEndDate" /></h3>
                    )}
                    checkBox={(
                        <div
                            className="align-center gap-1">
                            <Checkbox
                                className={css(s.labelCheckBox)}
                                onClick={() => {
                                    setMultiDate(!multiDate)
                                    setFieldValue(`experience[${ind}].end_date`, null)
                                }}
                                checked={!multiDate} /> <FormattedMessage id="untilToday" />
                        </div>
                    )}
                    name={[`experience[${ind}].start_date`, `experience[${ind}].end_date`]}
                />

                <FormInput
                    label={<FormattedMessage id="position" />}
                    placeholder={intl.formatMessage({ id: 'enter' })}
                    labelClassName={css(mode(theme).label)}
                    className={css(mode(theme).input)}
                    name={`experience[${ind}].position`}
                />

                <FormInput
                    value={values.experience[ind] ? values.experience[ind].achievements : ''}
                    placeholder={intl.formatMessage({ id: 'enter' })}
                    labelClassName={css(mode(theme).label)}
                    className={css(mode(theme).input)}
                    name={`experience[${ind}].achievements`}
                    label={<FormattedMessage id="achievements" />}
                />
            </div>
        </div>
    )
}

function MemoizedComponent({
    values,
    theme,
    setFieldValue,
    isExperienced,
    setValues,
    setIsExperienced,
    initialValues,
}) {
    const [count, setCount] = useState(1)
    const [removeMode, setRemoveMode] = useState(false)

    useEffect(() => {
        if (!isEmpty(initialValues) && initialValues.experience.length !== 0) {
            setCount(initialValues.experience.length > count ? initialValues.experience.length : count)
        }
    }, [initialValues.experience])

    const addNewField = () => {
        setCount(count + 1)
        setValues({ ...values, experience: [...values.experience, { id: count + 1, ...defaultExpKeys }] })
    }

    useEffect(() => {
        if (isExperienced) {
            addNewField()
        }
    }, [])

    return (
        <div className={css(mode(theme).formWrap)}>
            <div className={css(mode(theme).formHeader)}>
                <div className={cn('align-center gap-2', css(s.headTitle))}>
                    <LightingSizeM />

                    <h3><FormattedMessage id="experience" /></h3>

                    <div className={css(s.checkbox)}>
                        <Checkbox
                            checked={isExperienced}
                            setChecked={setIsExperienced}
                        />

                        <h3 onClick={() => setIsExperienced(!isExperienced)}>
                            <FormattedMessage id="hasExperience" />
                        </h3>
                    </div>
                </div>

                {isExperienced ? (
                    <div className="align-center gap-6">
                        {count > 1 && <EditSizeL onClick={() => setRemoveMode(!removeMode)} className="pointer" />}

                        <PlusRoundedSizeL onClick={addNewField} className="pointer" />
                    </div>
                ) : null}
            </div>

            {isExperienced ? (
                values.experience.map((edu, ind) => (
                    <CandidateExperienceForm
                        key={edu.id}
                        ind={ind}
                        theme={theme}
                        values={values}
                        setValues={setValues}
                        removeMode={removeMode}
                        isExperienced={isExperienced}
                        setFieldValue={setFieldValue}
                    />
                ))
            ) : null}
        </div>
    )
}

export const CandidateExperience = memo(MemoizedComponent)

const s = StyleSheet.create({
    headTitle: {
        '@media (max-width: 768px)': {
            gap: 4,
        },
    },
    formInputs: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridGap: 24,
        alignItems: 'start',
        borderWidth: 1,
        borderStyle: 'solid',
        borderRadius: 5,
        position: 'relative',
        ':nth-child(1n) > *': {
            flex: 1,
        },
        '@media (max-width: 768px)': {
            gridTemplateColumns: '1fr',
        },
    },
    removable_field: {
        borderColor: COLORS.lightRed,
        ':nth-child(1n) > :first-child': {
            cursor: 'pointer',
            position: 'absolute',
            right: -12,
            top: -12,
        },
    },
    fld: {
        position: 'relative',
    },
    checkbox: {
        fontWeight: '500',
        fontSize: 12,
        color: COLORS.mainColor,
        display: 'flex',
        alignItems: 'center',
        gap: 8,
        textTransform: 'none',
        marginLeft: 16,
    },
    labelCheckBox: {
        background: COLORS.white,
    },
})

const mode = (theme) => StyleSheet.create({
    formWrap: {
        border: theme === 'dark' ? 'none' : `1px solid ${COLORS.smoothGray}`,
        background: COLORS.white,
        boxShadow: '0px 2px 4px rgba(130, 130, 130, 0.05)',
        borderRadius: 4,
        width: '100%',
    },
    formHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '14px 24px',
        fontWeight: '600',
        fontSize: 13,
        textTransform: 'uppercase',
        color: theme === 'dark' ? COLORS.white : COLORS.dark,
        borderBottom: `1px solid ${theme === 'dark' ? COLORS.lightBlack : COLORS.lightGray}`,
        borderRadius: '4px 4px 0px 0px',
        ':nth-child(1n) > :last-child': {
            cursor: 'pointer',
        },
        ':nth-child(1n) > :first-child': {
            ':nth-child(1n) > :first-child': {
                ':nth-child(1n) > path': {
                    stroke: theme === 'dark' ? COLORS.gray : '#333333',
                },
            },
        },
        '@media (max-width: 768px)': {
            padding: '14px 16px',
        },
    },
    formBody: {
        padding: '16px 24px',
        display: 'flex',
        flexDirection: 'column',
        gap: 24,
        borderBottom: `1px solid ${theme === 'dark' ? COLORS.lightBlack : COLORS.lightGray}`,
        ':last-of-type': {
            borderBottom: 'none',
            paddingBottom: 24,
        },
        '@media (max-width: 768px)': {
            padding: 16,
            gap: 16,
        },
    },
    dateInputLabel: {
        marginBottom: 5,
        fontWeight: '500',
        fontSize: 12,
        color: theme === 'dark' ? COLORS.lightGray : COLORS.midGray,
    },
    dateInput: {
        backgroundColor: theme === 'dark' ? COLORS.itemDarkModeBg : COLORS.white,
        border: theme === 'dark' ? 'none' : `1px solid ${COLORS.lightGray}`,
        color: theme === 'dark' ? COLORS.white : COLORS.dark,
        ':nth-child(1n) > div': {
            ':nth-child(1n) > input': {
                backgroundColor: theme === 'dark' ? COLORS.itemDarkModeBg : COLORS.white,
                color: theme === 'dark' ? COLORS.white : COLORS.dark,
            },
            ':nth-child(1n) > div': {
                color: theme === 'dark' ? COLORS.white : COLORS.dark,
            },
        },
        fontSize: 14,
    },
    dateLabel: {
        letterSpacing: 0,
        color: theme === 'dark' ? COLORS.lightGray : COLORS.midGray,
    },
    label: {
        marginBottom: 8,
        fontWeight: '500',
        fontSize: 12,
        color: theme === 'dark' ? COLORS.lightGray : COLORS.midGray,
    },
    input: {
        position: 'relative',
        border: theme === 'dark' ? 'none' : `1px solid ${COLORS.lightGray}`,
        backgroundColor: theme === 'dark' ? COLORS.itemDarkModeBg : COLORS.white,
        borderRadius: 4,
        fontSize: 14,
        fontWeight: '500',
        color: theme === 'dark' ? COLORS.white : COLORS.dark,
    },
})
