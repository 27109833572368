import React, { useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { FormattedMessage } from 'react-intl'
import { Oval } from 'react-loader-spinner'
import { COLORS } from '../../utils/colors'
import { CloseSidebar } from '../svgs/Svgs'
import Button from '../common/Button'
import ScrollAnimation from '../common/ScrollAnimation'
import { useMessage } from '../../hooks/message'
import { usePostRequest } from '../../hooks/request'
import { CANDIDATE_AGREEMENT_OFFER } from '../../urls'

export default function OfferInfoModal({
    onClose,
    status,
    showModal,
    info,
    options,
    theme,
    lang,
    getData,
    notRegistered,
    onSubmitNotReg,
}) {
    const startTime = showModal.item.offer.startTime !== '00:00:00' && showModal.item.offer.startTime
    const endTime = showModal.item.offer.endTime !== '00:00:00' && showModal.item.offer.endTime
    const [showMessage] = useMessage()
    const [loading, setLoading] = useState({ acc: false, rej: false })
    const sendAgreement = usePostRequest({ url: CANDIDATE_AGREEMENT_OFFER })
    const answers = [
        { value: 2, label: <FormattedMessage id="youAcceptedOffer" /> },
        { value: 3, label: <FormattedMessage id="youRejectedOffer" /> },
    ]
    const hasAnswered = answers.find((ans) => ans.value === showModal.item.offer.status)

    const response = async (vId, id, res) => {
        if (hasAnswered || loading.acc || loading.rej) return

        setLoading({ acc: !!res, rej: !res })
        try {
            const { success, error } = await sendAgreement.request({
                data: { vacancy_id: vId, accept: res },
            })

            if (success) {
                setLoading({ acc: false, rej: false })
                showMessage(res ? answers[0].label : answers[1].label, 'success-msg')
                getData.setResponse((prev) => ({
                    ...getData.response,
                    data: prev.data.map((item) => (item.id === id
                        ? {
                            ...item,
                            offer: {
                                ...item.offer,
                                status: res ? 2 : 3,
                            },
                        } : item)),
                }))

                onClose()
            } else if (error) {
                setLoading({ acc: false, rej: false })
                showMessage(error.data.errors[0].message || error.data.message, 'error-msg')
            }
        } catch (err) {
            console.log(err)
        }
    }

    const formats = [
        { value: 1, uz: 'Onlayn', en: 'Online', ru: 'Онлайн' },
        { value: 2, uz: 'Oflayn', en: 'Offline', ru: 'Оффлайн' },
    ]

    return (
        <ScrollAnimation
            contClassName={css(s.modalWrap)}
            headerChildren={(
                <React.Fragment>
                    <div className="is-flex align-center gap-2">
                        <h3>{status[showModal.st - 1].status}</h3>

                        <h3>{showModal.item && showModal.item.company.name}</h3>
                    </div>

                    <CloseSidebar onClick={onClose} color={theme === 'dark' ? COLORS.white : COLORS.dark} />
                </React.Fragment>
            )}
            headerClassName={css(mode(theme).modalHeader)}
            bodyClassName={cn(css(mode(theme).modalBody), 'is-flex direction-column gap-7')}
            bodyChildren={(
                <React.Fragment>
                    <div className={cn(css(s.statusTxt), 'is-flex align-center gap-1 is-flex-wrap full-width')}>
                        <h3>{info.firstName}</h3>

                        <h3>{info.lastName},</h3>

                        <FormattedMessage id="company" />

                        <h3>{showModal.item.company.name}</h3>

                        <h3><FormattedMessage id="sentYouOfferStaticText" /></h3>

                        <FormattedMessage id="pleaseMindThisIsPrivate" />
                    </div>

                    <div className={cn(css(mode(theme).offerInfo), 'is-flex direction-column gap-7')}>
                        {/* <h3>Предложение о работе</h3> */}

                        {/* <p> */}
                        {/*     Уважаемый {info.firstName} {info.lastName}, */}
                        {/*     Компания {showModal.item.company.name} рада пригласить вас на */}
                        {/*     должность {showModal.item.vacancy.name}. */}
                        {/* </p> */}

                        {Object.values(showModal.item.offer) && (
                            <div>
                                <FormattedMessage id="workingConditions" />

                                <div className={css(s.offersList)}>
                                    <ul>
                                        {(startTime && endTime) && (
                                            <li>
                                                <FormattedMessage id="workingTime" />

                                                <span> — </span>

                                                {startTime.substring(0, 5)} - {endTime.substring(0, 5)}
                                            </li>
                                        )}

                                        {showModal.item.offer.schedule && (
                                            <li>
                                                <FormattedMessage id="workSchedule" />

                                                <span> — </span>

                                                {showModal.item.offer.schedule}
                                            </li>
                                        )}

                                        {showModal.item.offer.salary && (
                                            <li>
                                                <h3><FormattedMessage id="workSalary" /></h3>

                                                <span> — </span>

                                                {showModal.item.offer.salary
                                                    .toLocaleString('en-US').replace(/,/gi, ' ')} UZS
                                            </li>
                                        )}

                                        {showModal.item.offer.receiveSalary && (
                                            <li>
                                                <FormattedMessage id="salaryTime" />

                                                <span> — </span>

                                                {options[showModal.item.offer.receiveSalary - 1][lang]}
                                            </li>
                                        )}
                                        {showModal.item.offer.prepayment && (
                                            <li>
                                                <FormattedMessage id="advance" />

                                                <span> — </span>

                                                {showModal.item.offer.prepayment}
                                            </li>
                                        )}

                                        {showModal.item.offer.probation && (
                                            <li>
                                                <FormattedMessage id="testPeriod" />

                                                <span> — </span>

                                                {showModal.item.offer.probation}
                                            </li>
                                        )}

                                        {showModal.item.offer.holiday && (
                                            <li>
                                                <FormattedMessage id="vacationDays" />

                                                <span> — </span>

                                                {showModal.item.offer.holiday}
                                            </li>
                                        )}

                                        {showModal.item.offer.sick && (
                                            <li>
                                                <FormattedMessage id="sickDays" />

                                                <span> — </span>

                                                {showModal.item.offer.sick}
                                            </li>
                                        )}

                                        {showModal.item.offer.director && (
                                            <li>
                                                <FormattedMessage id="supervisor" />

                                                <span> — </span>

                                                {showModal.item.offer.director}
                                            </li>
                                        )}

                                        {showModal.item.offer.headOfEmployee && (
                                            <li>
                                                <FormattedMessage id="subordinates" />

                                                <span> — </span>

                                                {showModal.item.offer.headOfEmployee}
                                            </li>
                                        )}

                                        {showModal.item.offer.location && (
                                            <li>
                                                <FormattedMessage id="officeLocation" />

                                                <span> — </span>

                                                {showModal.item.offer.location}
                                            </li>
                                        )}

                                        <li>
                                            <FormattedMessage id="workingFormat" />

                                            <span> — </span>

                                            {showModal.item.offer.remote
                                                ? formats[0][lang]
                                                : formats[1][lang] }
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        )}

                        {showModal.item.offer.description && (
                            <div>
                                <h3><FormattedMessage id="jobResponsibilities" /></h3>

                                <p>{showModal.item.offer.description}</p>
                            </div>
                        )}

                        {(showModal.item.offer.phone || showModal.item.offer.email) && (
                            <div>
                                <h3><FormattedMessage id="ifYouAreSatisfied" /></h3>

                                <div className={css(s.offersList)}>
                                    <ul>
                                        {showModal.item.offer.phone
                                                && <li>{showModal.item.offer.phone}</li>}

                                        {showModal.item.offer.email
                                                && <li>{showModal.item.offer.email}</li>}
                                    </ul>
                                </div>
                            </div>
                        )}
                    </div>
                </React.Fragment>
            )}
            footerClassName={cn(css(mode(theme).modalFooter), 'is-flex direction-column gap-3')}
            footerChildren={(
                <React.Fragment>
                    <h3><FormattedMessage id="areYouAgreeWithOffer" /></h3>

                    <div className="is-flex align-center gap-5">
                        <Button
                            className={cn(css(s.rejectBtn),
                                { [css(s.disabled)]: hasAnswered }, 'is-flex align-center justify-center gap-2')}
                            onClick={() => (!notRegistered
                                ? response(showModal.item.vacancy.id, showModal.item.id, false)
                                : onSubmitNotReg(false))}>
                            { hasAnswered
                                ? (
                                    <div className="full-width is-flex align-center justify-center gap-2">
                                        {answers.find((ans) => ans.value === showModal.item.offer.status).label}
                                    </div>
                                ) : !loading.rej
                                    ? <FormattedMessage id="notSatisfiedWithOffer" />
                                    : <FormattedMessage id="sending" />}

                            {loading.rej ? (
                                <Oval
                                    height={16}
                                    width={16}
                                    color={COLORS.lightMainColor}
                                    visible
                                    ariaLabel="oval-loading"
                                    secondaryColor={COLORS.mainColor}
                                    strokeWidth={7}
                                    strokeWidthSecondary={7}
                                />
                            ) : null}
                        </Button>

                        {!hasAnswered && (
                            <Button
                                className={cn(css(s.agreeBtn), 'is-flex align-center justify-center gap-2')}
                                onClick={() => (!notRegistered
                                    ? response(showModal.item.vacancy.id, showModal.item.id, true)
                                    : onSubmitNotReg(true))}>
                                <FormattedMessage id="yesSatisfiedWithOffer" />

                                {loading.acc ? (
                                    <Oval
                                        height={16}
                                        width={16}
                                        color={COLORS.white}
                                        visible
                                        ariaLabel="oval-loading"
                                        secondaryColor={COLORS.white}
                                        strokeWidth={7}
                                        strokeWidthSecondary={7}
                                    />
                                ) : null}
                            </Button>
                        )}
                    </div>
                </React.Fragment>
            )}
        />
    )
}

const s = StyleSheet.create({
    modalWrap: {
        width: '37.25rem',
        borderRadius: '4px 4px 0px 0px',
        '@media (max-width: 768px)': {
            width: '90vw',
        },
    },
    statusTxt: {
        color: COLORS.gray,
        fontSize: 14,
        fontStyle: 'italic',
        fontWeight: '500',
    },
    offersList: {
        paddingLeft: 24,
    },
    rejectBtn: {
        flex: 1,
        color: COLORS.gray,
        border: `1px solid ${COLORS.gray}`,
        background: 'transparent',
        padding: '0 16px',
        boxShadow: '0px 2px 4px rgba(130, 130, 130, 0.05)',
        borderRadius: '4px',
        cursor: 'pointer',
        height: 40,
        ':hover': {
            background: COLORS.smoothGray,
        },
        '@media (max-width: 768px)': {
            display: 'none',
        },
    },
    agreeBtn: {
        flex: 1,
        fontSize: 14,
        fontWeight: '500',
        color: COLORS.white,
        border: 'none',
        padding: '0 16px',
        background: `linear-gradient(180deg, ${COLORS.mainColor} 0%, ${COLORS.lightMainColor} 100%)`,
        // eslint-disable-next-line max-len
        boxShadow: '0px 4px 12px rgba(44, 67, 50, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.25), inset 0px 1px 0px rgba(255, 255, 255, 0.1)',
        borderRadius: '4px',
        cursor: 'pointer',
        height: 40,
        ':hover': {
            background: `linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
            linear-gradient(180deg, ${COLORS.mainColor} 0%, ${COLORS.lightMainColor} 100%)`,
            boxShadow: `0px 4px 12px rgba(44, 67, 50, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.25), '
            inset 0px 1px 0px rgba(255, 255, 255, 0.1)`,
        },
        '@media (max-width: 768px)': {
            padding: '0 8px',
        },
    },
    disabled: {
        cursor: 'not-allowed',
    },
})

const mode = (theme) => StyleSheet.create({
    modalHeader: {
        padding: '20px 24px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        background: theme === 'dark' ? COLORS.itemDarkModeBg : COLORS.white,
        borderBottom: `1px solid ${theme === 'dark' ? COLORS.lightBlack : COLORS.lightGray}`,
        borderRadius: '4px 4px 0px 0px',
        ':nth-child(1n) > :first-child': {
            fontWeight: '600',
            fontSize: 20,
            color: theme === 'dark' ? COLORS.smoothGray : COLORS.dark,
        },
        ':nth-child(1n) > :last-child': {
            cursor: 'pointer',
        },
        '@media (max-width: 768px)': {
            padding: '14px 16px',
        },
    },
    modalBody: {
        overflowY: 'scroll',
        scrollBehavior: 'smooth',
        maxHeight: '30rem',
        padding: 24,
        background: theme === 'dark' ? COLORS.darkModeBg : COLORS.white,
        '@media (max-width: 768px)': {
            padding: 16,
        },
    },
    offerInfo: {
        color: theme === 'dark' ? COLORS.smoothGray : COLORS.dark,
        fontSize: 14,
        fontWeight: '400',
    },
    modalFooter: {
        background: theme === 'dark' ? COLORS.itemDarkModeBg : COLORS.white,
        borderTop: `1px solid ${theme === 'dark' ? COLORS.lightBlack : COLORS.lightGray}`,
        borderRadius: '0px 0px 4px 4px',
        padding: '16px 24px',
        ':nth-child(1n) > h3': {
            color: theme === 'dark' ? COLORS.white : COLORS.dark,
            fontSize: 16,
            fontWeight: 600,
        },
    },
})
