import axios from 'axios'
import humps from 'humps'
import { CANDIDATE_PROFILE_INFO } from '../urls'

export const domain = process.env.REACT_APP_BASE_URL || `${window.location.protocol}//${window.location.hostname}`
//  Add Base URL and change snake_case to camelCase
const baseAxios = axios.create({
    baseURL: `${domain}`,
    transformResponse: [
        ...axios.defaults.transformResponse,
        humps.camelizeKeys,
    ],
    transformRequest: [
        decamelize,
        ...axios.defaults.transformRequest,

    ],
})

baseAxios.interceptors.request.use((config) => ({
    ...config,
    params: humps.decamelizeKeys(config.params),
}))

export default baseAxios

// eslint-disable-next-line consistent-return
function decamelize(object) {
    if (!(object && !(object instanceof File))) return object

    if (object instanceof FormData) {
        const formData = new FormData()
        // eslint-disable-next-line no-restricted-syntax
        for (const [key, value] of object.entries()) {
            formData.append(humps.decamelize(key), value)
        }
        return formData
    }

    if (typeof object === 'object') {
        return humps.decamelizeKeys(object)
    }
}

export async function fetchUserInfo(setUserLoading, setUser, userLoading) {
    setUserLoading(true)
    setUser({ data: {}, loading: true })
    const { data } = await axios.get(`${domain + CANDIDATE_PROFILE_INFO}`, {
        headers: {
            Authorization: `Bearer ${JSON.parse(localStorage.token)}`,
            'accept-language': 'uz',
        },
        transformResponse: [
            ...axios.defaults.transformResponse,
            humps.camelizeKeys,
        ],
    })
    setUserLoading(false)

    if (data.success) {
        setUser({ ...data, loading: userLoading })
    }
}

export const handleSendCodeAgain = async (api, phone, setMessage, ref, message) => {
    const { error, success } = await api.request({
        data: {
            phone,
            clientId: process.env.REACT_APP_CLIENT_ID,
            clientSecret: process.env.REACT_APP_CLIENT_SECRET,
        },
    })

    if (error) {
        setMessage(error.data.message, 'error-msg')
        return
    }

    if (success) {
        ref.current.sendCodeAgain()
        setMessage(message, 'success-msg')
    }
}

export const resendVerification = async (api, email) => {
    try {
        const { success, error } = await api.request({ data: { email } })

        return { success, error }
    } catch (e) {
        return { success: false, error: e }
    }
}
