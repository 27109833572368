import React, { useContext, useEffect, useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { COLORS } from '../../../utils/colors'
import FullSection from './FullSection'
import VacanciesFilterSection from './VacanciesFilterSection'
import { BaseContext } from '../../../contexts/BaseContextWrapper'
import { updateEducationLvl } from '../../../utils/options'

export default function VacanciesFilterBoard({ filter, setFilter, data, width, rounded, height, vacancyAdditional }) {
    const [fullSection, setFullSection] = useState(false)
    const { theme, lang } = useContext(BaseContext)

    useEffect(() => {
        updateEducationLvl(lang)
    }, [])

    return (
        <div className={cn('is-flex direction-column', css(cont(width, rounded, height, theme)))}>
            <div style={{ height: '100%' }}>
                {!fullSection ? (
                    <div id="sec" className={cn('is-flex direction-column gap-5 overflowY-auto', css(s.sections))}>
                        {data.map((section) => (
                            <VacanciesFilterSection
                                vacancyAdditional={vacancyAdditional}
                                key={section.id}
                                section={section}
                                filter={filter}
                                theme={theme}
                                setFilter={setFilter}
                                setFullSection={setFullSection}
                            />
                        ))}
                    </div>
                ) : (
                    <div style={{ height: 'calc(100vh - 52px)' }}>
                        <FullSection
                            section={fullSection.section}
                            name={fullSection.section.name}
                            filter={filter}
                            theme={theme}
                            setFilter={setFilter}
                            setFullSection={setFullSection}
                        />
                    </div>
                )}
            </div>
        </div>
    )
}

const s = StyleSheet.create({
    sections: {
        height: '100%',
        boxSizing: 'border-box',
        padding: '24px 32px',
        scrollbarGutter: 'stable',
        overflowY: 'auto',
        '@media(max-width: 768px)': {
            padding: '12px 16px',
        },
    },
})

const cont = (width, rounded, height, theme) => StyleSheet.create({
    cont: {
        backgroundColor: theme === 'dark' ? COLORS.darkModeBg : COLORS.white,
        // height,
        width,
        borderRadius: rounded ? '4px 4px 0 0' : 0,
    },
}).cont
