import React, { useContext } from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { FormattedMessage, useIntl } from 'react-intl'
import { ChevronDownSizeS, ChevronNextRounded, ChevronPrevRounded, ChevronUpSizeS } from '../svgs/Svgs'
import { COLORS } from '../../utils/colors'
import { BaseContext } from '../../contexts/BaseContextWrapper'

const sizes = [10, 20]

export default function Pagination({ count, pageSize, page, setPageSize, loading, setPage, pageCount }) {
    const { theme, lang } = useContext(BaseContext)
    const intl = useIntl()
    function onChangeSize(mode) {
        if (mode === 'increase') return setPageSize(pageSize !== 20 ? sizes[sizes.indexOf(pageSize) + 1] : pageSize)
        return setPageSize(pageSize !== 10 ? sizes[sizes.indexOf(pageSize) - 1] : pageSize)
    }

    const p = page - 1

    return (
        <div className={cn('align-center justify-end', css(s.pg))}>
            <h3 className={css(s.count)}>
                {count && lang !== 'uz'
                    ? `${(p * pageSize) + 1} - 
                    ${((p + 1) * pageSize) < count ? ((p + 1) * pageSize) : count} 
                    ${intl.formatMessage({ id: 'of' })} ${count}`

                    : `${count} 
                    ${intl.formatMessage({ id: 'of' })} ${(p * pageSize) + 1} - 
                    ${((p + 1) * pageSize) < count ? ((p + 1) * pageSize) : count}`}
            </h3>

            <span className={cn('flex-cent dis_sl', css(s.showMore))}>
                <h5 className={css(mode(theme).show)}><FormattedMessage id="showRows" />: {pageSize}</h5>

                <span className={css(s.upDown)}>
                    <span className="flex-cent">
                        <ChevronUpSizeS onClick={() => (!loading ? onChangeSize('increase') : {})} />
                    </span>

                    <span className="flex-cent">
                        <ChevronDownSizeS onClick={() => (!loading ? onChangeSize('decrease') : {})} />
                    </span>
                </span>
            </span>

            <span
                onClick={() => (!loading && page > 1 ? setPage(page - 1) : {})}
                className={cn('flex-cent pointer', css(s.contBtn), css(s.backBtn), { [css(s.disBtn)]: page <= 1 })}>
                <ChevronPrevRounded />
            </span>

            <span
                onClick={() => (!loading && page < pageCount ? setPage(page + 1) : {})}
                className={cn('flex-cent pointer', css(s.contBtn), { [css(s.disBtn)]: page >= pageCount })}>
                <ChevronNextRounded />
            </span>
        </div>
    )
}

const s = StyleSheet.create({
    pg: {
        paddingTop: 16,
    },
    showMore: {
        height: 32,
        paddingLeft: 8,
        marginLeft: 28,
        gap: 10,
        border: `1px solid ${COLORS.lightGray}`,
        borderRadius: 4,
    },
    backBtn: {
        marginLeft: 24,
        marginRight: 12,
    },
    contBtn: {
        background: `linear-gradient(180deg, ${COLORS.mainColor} 0%, ${COLORS.lightMainColor} 100%)`,
        width: 32,
        height: 32,
        borderRadius: 4,
        ':nth-child(1n) > :first-child': {
            ':nth-child(1n) > :first-child': {
                strokeOpacity: 1,
            },
        },
    },
    disBtn: {
        backgroundColor: COLORS.green,
    },
    count: {
        fontSize: 12,
        fontWeight: '500',
        color: COLORS.gray,
    },
    upDown: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: 32,
        height: 32,
        borderLeft: '1px solid #ccc',
        ':nth-child(1n) > *': {
            height: '40%',
            ':nth-child(1n) > :first-child': {
                cursor: 'pointer',
                ':nth-child(1n) > :first-child': {
                    ':hover': {
                        fill: '#000',
                    },
                },
            },
        },
    },
})

const mode = (theme) => StyleSheet.create({
    show: {
        fontSize: 12,
        fontWeight: '500',
        color: theme === 'dark' ? COLORS.white : COLORS.dark,
    },
})
