import React, { memo, useContext, useRef } from 'react'
import { Form } from 'formik'
import { css, StyleSheet } from 'aphrodite'
import { FormattedMessage, useIntl } from 'react-intl'
import cn from 'classnames'
import { isEmpty } from 'lodash'
import { Oval } from 'react-loader-spinner'
import { AddPhoto, Calendar, CloseSidebar, Trash, XCircleSizeL } from '../svgs/Svgs'
import { COLORS } from '../../utils/colors'
import Button from '../common/Button'
import { NoUserImage } from '../svgs/SvgPictures'
import NoImg from '../../static/images/NoUserImg.png'
import FormInput from '../common/FormInput'
import { email, minLength, phone, required, validator } from '../../utils/validators'
import FormInputPhone from '../common/FormInputPhone'
import { deFormatPhone } from '../../utils/number'
import { strCut } from '../../utils/string'
import VerifyCodeInput from '../common/VerifyCodeInput'
import { CountDown } from '../../pages/auth/Verification'
import { handleSendCodeAgain } from '../../utils/request'
import InputDate from '../common/InputDate'
import SelectForm from '../common/SelectForm'
import { banknoteOptions, genderOptions } from '../../utils/options'
import FormInputCurrency from '../common/FormInputCurrency'
import ToggleSwitch from '../common/ToggleSwitch'
import { BaseContext } from '../../contexts/BaseContextWrapper'
import { useMessage } from '../../hooks/message'
import VerifyEmail from '../common/VerifyEmail'

function MemoizedComponent({
    img,
    info,
    errors,
    values,
    setImg,
    onClose,
    onChange,
    isSuccess,
    putRequest,
    updatePhone,
    deletePhoto,
    verifyPhone,
    handleChange,
    setFieldValue,
    updatePhoneNumber,
    verifyPhoneNumber,
    imageUploadLoading,
}) {
    const { lang, theme } = useContext(BaseContext)
    const inputRef = useRef(null)
    const countDownRef = useRef()
    const [showMessage] = useMessage()
    const intl = useIntl()

    return (
        <Form>
            <div className={css(s.modalWrap)}>
                <div className={css(mode(theme).modalHeader)}>
                    <h3><FormattedMessage id="updateMainInfos" /></h3>

                    <CloseSidebar color={theme === 'dark' ? COLORS.white : COLORS.dark} onClick={onClose} />
                </div>

                <div className={css(mode(theme).modalBody)}>
                    <div className={cn('align-center', 'gap-7', css(s.imageFormWrap))}>
                        {img ? (
                            <React.Fragment>
                                <img
                                    src={img}
                                    alt="#avatar"
                                    className={css(mode(theme).avatarImg)}
                                />

                                <div className={cn('direction-column', css(mode(theme).btn_group))}>
                                    <Button type="button" onClick={() => inputRef.current.click()}>
                                        <FormattedMessage id="uploadAnotherPhoto" />
                                    </Button>

                                    <Button
                                        type="button"
                                        onClick={() => {
                                            setImg('')
                                            inputRef.current.value = ''
                                        }}
                                    >
                                        <Trash /> <FormattedMessage id="deletePhoto" />
                                    </Button>
                                </div>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <div className={css(mode(theme).avatarIcon)}>
                                    <NoUserImage color={theme === 'dark' ? COLORS.midGray : COLORS.lightGray} />

                                    <img src={NoImg} alt="#NoImgIcon" />
                                </div>

                                <div
                                    onClick={() => inputRef.current.click()}
                                    className={cn('align-center pointer', css(mode(theme).loadPh))}
                                >
                                    <AddPhoto />

                                    <h3><FormattedMessage id="uploadPhoto" /></h3>
                                </div>
                            </React.Fragment>
                        )}
                    </div>

                    <div className={css(s.formInputs)}>
                        <FormInput
                            name="firstName"
                            label={<FormattedMessage id="firstName" />}
                            validate={required}
                            errorWithMessage={false}
                            className={css(mode(theme).input)}
                            labelClassName={css(mode(theme).label)}
                            placeholder={intl.formatMessage({ id: 'enterYourNamePlaceHolder' })}
                        />

                        <FormInput
                            name="lastName"
                            label={<FormattedMessage id="lastName" />}
                            validate={required}
                            errorWithMessage={false}
                            className={css(mode(theme).input)}
                            labelClassName={css(mode(theme).label)}
                            placeholder={intl.formatMessage({ id: 'enterYourLastNamePlaceholder' })}
                        />

                        <div className="is-flex direction-column gap-2">
                            <FormInput
                                name="email"
                                value={values.email}
                                label={<FormattedMessage id="email" />}
                                labelClassName={css(mode(theme).label)}
                                className={css(mode(theme).input)}
                                validate={email}
                                placeholder={intl.formatMessage({ id: 'exampleEmailPlaceholder' })}
                                onChange={handleChange}
                            />

                            {values.email ? (
                                <React.Fragment>
                                    {info.emailConfirmed && values.email === info.email
                                        ? <p className={css(s.confirmedMsg)}><FormattedMessage id="mailVerified" /></p>
                                        : (
                                            <VerifyEmail
                                                value={values.email}
                                                limit={info.emailConfirmLimit}
                                                isChanged={values.email !== info.email} />
                                        )}
                                </React.Fragment>
                            ) : null}
                        </div>

                        <FormInputPhone
                            type="tel"
                            name="phone"
                            withBtn={!isSuccess.phone && (isEmpty(errors.phone)
                                && deFormatPhone(values.phone) !== strCut(info.phoneNumber, 4, true))}
                            onClick={() => updatePhoneNumber(values.phone)}
                            inputMode="numeric"
                            isLoading={updatePhone.loading}
                            value={values.phone}
                            onChange={handleChange}
                            errorWithMessage={false}
                            placeholder="01 234 56 78"
                            validate={validator(required, phone)}
                            className={css(mode(theme).inputPhone)}
                            btnClassName={isEmpty(errors.phone) ? s.saveBtn : s.disabledBtn}
                            label={<FormattedMessage id="phoneNumber" />}
                            labelClassName={css(mode(theme).inputPhoneLabel)}
                            fieldClassName={cn('is-flex direction-column gap-2', css(mode(theme).field))}
                        />

                        {isSuccess.phone && !isSuccess.code ? (
                            <div className="direction-column gap-2">
                                <VerifyCodeInput
                                    isLoading={verifyPhone.loading}
                                    name="verify_code"
                                    validate={(value) => minLength(4)(value)}
                                    btnClassName={isEmpty(errors.verify_code) ? s.saveBtn : s.disabledBtn}
                                    btnText={isSuccess.phone
                                        ? <FormattedMessage id="sendCode" />
                                        : <FormattedMessage id="getCode" />}
                                    labelClassName={css(mode(theme).label)}
                                    fieldClassName={css(s.fld)}
                                    onClick={() => verifyPhoneNumber(values.phone, values.verify_code)}
                                    onChange={({ target }) => {
                                        setFieldValue('verify_code', target.value.replace(/[^0-9]/g, ''))
                                    }}
                                    placeholder={intl.formatMessage({ id: 'enterFourDigit' })}
                                />

                                <div className={cn('is-flex justify-between', css(s.sendCodeCnt))}>
                                    <p className={css(mode(theme).inputPhoneLabel)}>
                                        <FormattedMessage id="confirmationCodeSent" />
                                    </p>

                                    <div className="flex-1 justify-end">
                                        <CountDown
                                            theme={theme}
                                            ref={countDownRef}
                                            submit={() => handleSendCodeAgain(
                                                updatePhone,
                                                `+998${deFormatPhone(values.phone)}`,
                                                showMessage,
                                                countDownRef,
                                                <FormattedMessage id="codeSent" />,
                                            )} />
                                    </div>
                                </div>
                            </div>
                        ) : null}

                        <FormInput
                            name="address"
                            label={<FormattedMessage id="address" />}
                            errorWithMessage={false}
                            className={css(mode(theme).input)}
                            labelClassName={css(mode(theme).label)}
                            placeholder={intl.formatMessage({ id: 'addressPlaceHolder' })}
                        />

                        <InputDate
                            name={['birth_date']}
                            icon={<Calendar />}
                            label={<FormattedMessage id="birthDate" />}
                            placeholder={intl.formatMessage({ id: 'dateFormat' })}
                            className={css(mode(theme).input)}
                            errorWithMessage={false}
                            fieldClassName={css(s.fld)}
                            labelClassName={css(mode(theme).label)}
                        />

                        <SelectForm
                            label={<FormattedMessage id="gender" />}
                            name="gender"
                            theme={theme}
                            value={values.gender
                                ? genderOptions.find((gen) => gen.value === values.gender)
                                : null}
                            options={genderOptions}
                            placeholder="Выберите пол"
                            indicatorStyle={{ paddingRight: 10 }}
                            valueContainerStyles={{ padding: '11.5px 16px' }}
                            onChange={({ value }) => setFieldValue('gender', value)}
                            getOptionLabel={(option) => option[lang]}
                            className={css(mode(theme).selectInp)}
                            placeholderStyles={{
                                color: COLORS.gray,
                                fontFamily: 'Roboto, sans-serif',
                            }}
                        />

                        <FormInputCurrency
                            // isSelectable
                            maxLength={11}
                            theme={theme}
                            name="desiredSalary"
                            errorWithMessage={false}
                            options={banknoteOptions}
                            placeholder="Введите сумму"
                            fieldClassName={css(s.fld)}
                            className={cn(css(mode(theme).salaryInp))}
                            label={<FormattedMessage id="desiredSalary" />}
                            labelClassName={css(mode(theme).salaryInpLab)}
                        />

                        <div className={css(mode(theme).toggle)}>
                            <XCircleSizeL />

                            <h3><FormattedMessage id="remotePossible" /></h3>

                            <ToggleSwitch
                                name="remote"
                                isChecked={values.remote}
                                onClick={() => setFieldValue('remote', !values.remote)}
                            />
                        </div>
                    </div>
                </div>

                <div className={css(mode(theme).modalFooter)}>
                    <Button
                        type="button"
                        onClick={onClose}
                        className={cn(css(mode(theme).cancelBtn), css(s.btn))}>
                        <FormattedMessage id="cancel" />
                    </Button>

                    <Button
                        type="submit"
                        className={cn(css(s.saveBtn), css(s.btn), { [css(s.disabledBtn)]: !isEmpty(errors) })}
                    >
                        {!putRequest.loading && !imageUploadLoading && !deletePhoto.loading
                            ? <FormattedMessage id="save" /> : <FormattedMessage id="saving" />}

                        {putRequest.loading || imageUploadLoading || deletePhoto.loading ? (
                            <Oval
                                height={16}
                                width={16}
                                color={COLORS.white}
                                visible
                                ariaLabel="oval-loading"
                                secondaryColor={COLORS.white}
                                strokeWidth={7}
                                strokeWidthSecondary={7}
                            />
                        ) : null}
                    </Button>
                </div>

                <input
                    id="file"
                    type="file"
                    ref={inputRef}
                    readOnly="readonly"
                    className="not-visible"
                    accept="image/png, image/jpg"
                    onChange={(e) => onChange(e)} />
            </div>
        </Form>
    )
}

export const MainInfoModalForm = memo(MemoizedComponent)

const s = StyleSheet.create({
    modalWrap: {
        width: '37.25rem',
        height: '80vh',
        borderRadius: '4px 4px 0px 0px',
        '@media (max-width: 768px)': {
            width: '90vw',
        },
    },
    imageFormWrap: {
        '@media (max-width: 768px)': {
            gap: 12,
        },
    },
    formInputs: {
        marginTop: 24,
        display: 'flex',
        flexDirection: 'column',
        gap: 24,
        '@media (max-width: 768px)': {
            marginTop: 16,
            gap: 16,
        },
    },
    confirmedMsg: {
        color: COLORS.lightBlack,
        fontSize: 12,
        fontWeight: '400',
    },
    fld: {
        position: 'relative',
    },
    btn: {
        borderRadius: 4,
        padding: '12px 16px',
        fontWeight: '500',
        fontSize: 14,
        display: 'flex',
        alignItems: 'center',
        gap: 8,
    },
    saveBtn: {
        color: COLORS.white,
        background: `linear-gradient(180deg, ${COLORS.mainColor} 0%, ${COLORS.lightMainColor} 100%)`,
        // eslint-disable-next-line max-len
        boxShadow: '0px 4px 12px rgba(44, 67, 50, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.25), inset 0px 1px 0px rgba(255, 255, 255, 0.1)',
    },
    disabledBtn: {
        background: COLORS.gray,
        border: 'none',
        // eslint-disable-next-line max-len
        boxShadow: '0px 4px 12px rgba(44, 67, 50, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.25), inset 0px 1px 0px rgba(255, 255, 255, 0.1)',
        cursor: 'not-allowed',
        fontWeight: '500',
        fontSize: 14,
        color: COLORS.white,
    },
    sendCodeCnt: {
        marginTop: 10,
        ':nth-child(1n) > :first-child': {
            width: '70%',
        },
        '@media (max-width: 786px)': {
            flexDirection: 'column',
            alignItems: 'start',
            gap: 8,
            ':nth-child(1n) > :first-child': {
                width: '100%',
            },
        },
    },
})

const mode = (theme) => StyleSheet.create({
    modalHeader: {
        padding: '20px 24px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        background: theme === 'dark' ? COLORS.itemDarkModeBg : COLORS.white,
        borderBottom: `1px solid ${theme === 'dark' ? COLORS.lightBlack : COLORS.lightGray}`,
        borderRadius: '4px 4px 0px 0px',
        ':nth-child(1n) > h3': {
            fontWeight: '700',
            fontSize: 20,
            color: theme === 'dark' ? COLORS.white : COLORS.dark,
            '@media (max-width: 768px)': {
                fontSize: 16,
            },
        },
        ':nth-child(1n) > :last-child': {
            cursor: 'pointer',
        },
        '@media (max-width: 768px)': {
            padding: '14px 16px',
        },
    },
    modalBody: {
        height: '70%',
        overflowY: 'auto',
        padding: 24,
        background: theme === 'dark' ? COLORS.darkModeBg : COLORS.smoothGray,
        '@media (max-width: 768px)': {
            height: '60vh',
            padding: 16,
        },
    },
    avatarImg: {
        border: `1px solid ${theme === 'dark' ? COLORS.midGray : COLORS.lightGray}`,
        borderRadius: 4,
        width: 96,
        height: 96,
        ':nth-child(1n) > img': {
            objectFit: 'cover',
            objectPosition: 'center',
        },
        '@media (max-width: 768px)': {
            width: 40,
            height: 40,
        },
    },
    avatarIcon: {
        border: `1px solid ${theme === 'dark' ? COLORS.midGray : COLORS.lightGray}`,
        borderRadius: 4,
        width: 96,
        height: 96,
        ':nth-child(1n) > :last-child': {
            '@media (min-width: 768px)': {
                display: 'none',
            },
        },
        ':nth-child(1n) > :first-child': {
            '@media (max-width: 768px)': {
                display: 'none',
            },
        },
        '@media (max-width: 768px)': {
            width: 40,
            height: 40,
        },
    },
    btn_group: {
        ':nth-child(1n) > :first-child': {
            background: theme === 'dark' ? 'rgba(250, 255, 252, 0.10)' : COLORS.white,
            border: theme === 'dark' ? 'none' : '1px solid #CCEADB',
            borderRadius: 4,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontWeight: 500,
            fontSize: 14,
            color: COLORS.mainColor,
            gap: 8,
            height: 40,
            width: '100%',
            '@media (max-width: 414px)': {
                fontSize: 12,
            },
        },
        ':nth-child(1n) > :last-child': {
            background: theme === 'dark' ? COLORS.itemDarkModeBg : COLORS.white,
            border: theme === 'dark' ? 'none' : `1px solid ${COLORS.smoothGray}`,
            borderRadius: 4,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontWeight: '500',
            fontSize: 13,
            color: COLORS.gray,
            gap: 8,
            height: 40,
            width: '100%',
            marginTop: 12,
            '@media (max-width: 414px)': {
                fontSize: 12,
            },
        },
    },
    loadPh: {
        background: theme === 'dark' ? 'rgba(250, 255, 252, 0.10)' : COLORS.white,
        border: theme === 'dark' ? 'none' : '1px solid #E3F8EE',
        boxShadow: '0px 2px 20px rgba(137, 152, 141, 0.02)',
        borderRadius: 4,
        padding: '10px 16px',
        fontWeight: '500',
        fontSize: 14,
        color: COLORS.mainColor,
        gap: 8,
        ':hover': {
            boxShadow: '0px 2px 4px rgba(168, 168, 168, 0.15)',
            border: '1px solid rgba(46, 169, 125, 0.5)',
        },
    },
    toggle: {
        marginTop: 20,
        padding: '0 16px',
        height: 42,
        border: theme === 'dark' ? 'none' : `1px solid ${COLORS.lightGray}`,
        borderRadius: 4,
        background: theme === 'dark' ? COLORS.itemDarkModeBg : COLORS.white,
        fontWeight: '500',
        fontSize: 14,
        color: theme === 'dark' ? COLORS.white : COLORS.dark,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        position: 'relative',
        ':nth-child(1n) > :first-child': {
            ':nth-child(1n) > path': {
                fill: COLORS.lightRed,
            },
            position: 'absolute',
            right: -12,
            top: -12,
            cursor: 'pointer',
            display: 'none',
        },
    },
    label: {
        color: theme === 'dark' ? COLORS.lightGray : COLORS.midGray,
        marginBottom: 8,
        fontWeight: '500',
        fontSize: 12,
    },
    inputPhoneLabel: {
        fontWeight: 500,
        fontSize: 12,
        color: theme === 'dark' ? COLORS.lightGray : COLORS.midGray,
    },
    // phoneInpFld: {
    //     position: 'relative',
    //     ':nth-child(1n) > :last-child': {
    //         background: theme === 'dark' ? COLORS.itemDarkModeBg : COLORS.white,
    //     },
    // },
    inputPhone: {
        width: '100%',
        fontWeight: 500,
        fontSize: '14px',
        color: theme === 'dark' ? COLORS.white : COLORS.dark,
        backgroundColor: 'inherit',
        ':placeholder': {
            color: COLORS.gray,
        },
    },
    field: {
        position: 'relative',
        ':nth-child(1n) > :last-child': {
            background: theme === 'dark' ? COLORS.itemDarkModeBg : COLORS.white,
            // border: theme === 'dark' ? 'none' : `1px solid ${COLORS.lightGray}`,
        },
    },
    input: {
        background: theme === 'dark' ? COLORS.itemDarkModeBg : COLORS.white,
        border: theme === 'dark' ? 'none' : `1px solid ${COLORS.lightGray}`,
        color: theme === 'dark' ? COLORS.white : COLORS.dark,
        position: 'relative',
        borderRadius: 4,
        fontSize: 14,
        '-webkit-appearance': 'none',
        boxSizing: 'border-box',
        minWidth: 'calc(100% - 16px)',
        fontWeight: '500',
    },

    selectInp: {
        ':nth-child(1n) > div': {
            // styles for div inside select component
            background: theme === 'dark' ? COLORS.itemDarkModeBg : COLORS.white,
            border: theme === 'dark' ? 'none' : `1px solid ${COLORS.lightGray}`,
            color: theme === 'dark' ? COLORS.white : COLORS.dark,
            ':nth-child(1n) *': {
                color: theme === 'dark' ? COLORS.white : COLORS.dark,
            },
        },
    },
    salaryInp: {
        position: 'relative',
        borderRadius: 4,
        background: theme === 'dark' ? COLORS.itemDarkModeBg : COLORS.white,
        fontSize: 14,
        fontWeight: '500',
        ':nth-child(1n) input': {
            background: 'transparent',
            color: theme === 'dark' ? COLORS.white : COLORS.dark,
        },
    },
    salaryInpLab: {
        color: theme === 'dark' ? COLORS.lightGray : COLORS.midGray,
        marginBottom: 8,
        fontWeight: '500',
        fontSize: 12,
    },
    cancelBtn: {
        color: COLORS.gray,
        border: `1px solid ${COLORS.gray}`,
        background: 'inherit',
    },
    modalFooter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'end',
        gap: 16,
        background: theme === 'dark' ? COLORS.itemDarkModeBg : COLORS.white,
        borderTop: `1px solid ${theme === 'dark' ? COLORS.lightBlack : COLORS.lightGray}`,
        borderRadius: '0px 0px 4px 4px',
        padding: '16px 24px',
        '@media (max-width: 768px)': {
            padding: '8px 16px',
        },
    },
})
