import React, { memo, useEffect, useRef, useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import { isEmpty } from 'lodash'
import cn from 'classnames'
import { FormattedMessage, useIntl } from 'react-intl'
import SelectForm from '../../common/SelectForm'
import { COLORS } from '../../../utils/colors'
import FormInput from '../../common/FormInput'
import { BachelorHat, Calendar, EditSizeL, PlusRoundedSizeL, XCircleSizeL } from '../../svgs/Svgs'
import { educationLvl } from '../../../utils/options'
import Checkbox from '../../common/Checkbox'
import InputDate from '../../common/InputDate'
import { required } from '../../../utils/validators'

const defaultEduKeys = { level: '', start_date: '', end_date: '', institution: '', additional: '' }

function CandidateEducationForm({ ind, setFieldValue, values, removeMode, theme, setValues }) {
    const [multiDate, setMultiDate] = useState(false)
    const ref = useRef()
    const intl = useIntl()

    useEffect(() => {
        if (values.education[ind] && values.education[ind].start_date
            && !values.education[ind].end_date) {
            setMultiDate(false)
        } else {
            setMultiDate(true)
        }
    }, [ind, values.education])

    function removeField(index) {
        setValues((prev) => {
            const updatedEducation = prev.education.filter((_, i) => i !== index)
            return { ...prev, education: updatedEducation }
        })

        // ref.current.remove()
    }

    return (
        <div key={ind} ref={ref} className={css(mode(theme).formBody)}>
            <div
                style={{ borderColor: 'transparent' }}
                className={cn(css(s.formInputs), { [css(s.removable_field)]: removeMode && ind !== 0 })}>
                {removeMode && ind !== 0
                    && <XCircleSizeL onClick={() => removeField(ind)} />}

                <SelectForm
                    validate={required}
                    theme={theme}
                    errorWithMessage={false}
                    isSearchable={false}
                    placeholderStyles={{ color: COLORS.gray, fontFamily: 'Roboto, sans-serif' }}
                    value={values.education[ind]
                        ? educationLvl.find((edu) => edu.value === values.education[ind].level)
                        : null}
                    valueContainerStyles={{ padding: '11.5px 16px' }}
                    indicatorStyle={{ paddingRight: 10 }}
                    label={<FormattedMessage id="education" />}
                    onChange={({ value }) => setFieldValue(`education[${ind}].level`, value)}
                    placeholder={<FormattedMessage id="exampleEduPlaceHolder" />}
                    className={css(mode(theme).selectInp)}
                    name={`education[${ind}].level`}
                    options={educationLvl}
                />

                <FormInput
                    validate={required}
                    errorWithMessage={false}
                    value={values.education[ind] ? values.education[ind].institution : ''}
                    label={<FormattedMessage id="institution" />}
                    labelClassName={css(mode(theme).label)}
                    className={css(mode(theme).input)}
                    name={`education[${ind}].institution`}
                    placeholder={intl.formatMessage({ id: 'enterInstitutionPlaceHolder' })}
                />

                <InputDate
                    validate={required}
                    isMulti={multiDate}
                    theme={theme}
                    icon={<Calendar />}
                    fieldClassName={css(s.fld)}
                    errorWithMessage={false}
                    key={`education-${ind}-date-picker`}
                    className={css(mode(theme).dateInput)}
                    labelClassName={css(mode(theme).dateInputLabel)}
                    placeholder={['дд.мм.гггг', 'дд.мм.гггг']}
                    label={(
                        <h3><FormattedMessage id="startEndDate" /></h3>
                    )}
                    checkBox={(
                        <div
                            className="align-center gap-1">
                            <Checkbox
                                className={css(s.labelCheckBox)}
                                onClick={() => {
                                    setMultiDate(!multiDate)
                                }}
                                checked={!multiDate} /> <FormattedMessage id="untilToday" />
                        </div>
                    )}
                    name={[`education[${ind}].start_date`, `education[${ind}].end_date`]}
                />

                <FormInput
                    value={values.education[ind] ? values.education[ind].additional : ''}
                    labelClassName={css(mode(theme).label)}
                    className={css(mode(theme).input)}
                    name={`education[${ind}].additional`}
                    label=<FormattedMessage id="additional" />
                    placeholder={intl.formatMessage({ id: 'exampleEduAdditionalPlaceHolder' })}
                />
            </div>
        </div>
    )
}

function MemoizedComponent({ setFieldValue, values, theme, initialValues, setValues }) {
    const [count, setCount] = useState(1)
    const [removeMode, setRemoveMode] = useState(false)

    useEffect(() => {
        if (!isEmpty(initialValues) && initialValues.education.length !== 0) {
            setCount(initialValues.education.length > count ? initialValues.education.length : count)
        }
    }, [initialValues.education])

    const addNewEduField = () => {
        setCount(count + 1)
        setValues({ ...values, education: [...values.education, { id: count + 1, ...defaultEduKeys }] })
    }
    return (
        <div className={css(mode(theme).formWrap)}>
            <div className={css(mode(theme).formHeader)}>
                <div className={cn('align-center gap-2', css(s.headTitle))}>
                    <BachelorHat />

                    <h3><FormattedMessage id="education" /></h3>
                </div>

                <div className="align-center gap-6">
                    {count > 1 && <EditSizeL onClick={() => setRemoveMode(!removeMode)} className="pointer" />}

                    <PlusRoundedSizeL onClick={addNewEduField} className="pointer" />
                </div>
            </div>

            {values.education.map((edu, ind) => (
                <CandidateEducationForm
                    key={edu.id}
                    ind={ind}
                    theme={theme}
                    values={values}
                    setValues={setValues}
                    removeMode={removeMode}
                    setFieldValue={setFieldValue}
                />
            ))}
        </div>
    )
}

export const CandidateEducation = memo(MemoizedComponent)

const s = StyleSheet.create({
    headTitle: {
        '@media (max-width: 768px)': {
            gap: 4,
        },
    },
    formHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '14px 24px',
        fontWeight: '600',
        fontSize: 13,
        textTransform: 'uppercase',
        color: COLORS.dark,
        borderBottom: `1px solid ${COLORS.lightGray}`,
        borderRadius: '4px 4px 0px 0px',
        ':nth-child(1n) > :last-child': {
            cursor: 'pointer',
        },
        ':nth-child(1n) > :first-child': {
            ':nth-child(1n) > :first-child': {
                ':nth-child(1n) > path': {
                    stroke: '#333333',
                },
            },
        },
        '@media (max-width: 768px)': {
            padding: '14px 16px',
        },
    },
    formBody: {
        padding: '16px 24px',
        display: 'flex',
        flexDirection: 'column',
        gap: 24,
        borderBottom: `1px solid ${COLORS.lightGray}`,
        ':last-of-type': {
            borderBottom: 'none',
            paddingBottom: 24,
        },
        '@media (max-width: 768px)': {
            padding: 16,
            gap: 16,
        },
    },
    formInputs: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridGap: 24,
        alignItems: 'start',
        borderWidth: 1,
        borderStyle: 'solid',
        borderRadius: 5,
        position: 'relative',
        ':nth-child(1n) > *': {
            flex: 1,
        },
        '@media (max-width: 768px)': {
            gridTemplateColumns: '1fr',
        },
    },
    removable_field: {
        borderColor: COLORS.lightRed,
        ':nth-child(1n) > :first-child': {
            cursor: 'pointer',
            position: 'absolute',
            right: -12,
            top: -12,
        },
    },
    fld: {
        position: 'relative',
    },
    labelCheckBox: {
        background: COLORS.white,
    },
})

const mode = (theme) => StyleSheet.create({
    formWrap: {
        border: theme === 'dark' ? 'none' : `1px solid ${COLORS.smoothGray}`,
        background: COLORS.white,
        boxShadow: '0px 2px 4px rgba(130, 130, 130, 0.05)',
        borderRadius: 4,
        width: '100%',
    },
    formHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '14px 24px',
        fontWeight: '600',
        fontSize: 13,
        textTransform: 'uppercase',
        color: theme === 'dark' ? COLORS.white : COLORS.dark,
        borderBottom: `1px solid ${theme === 'dark' ? COLORS.lightBlack : COLORS.lightGray}`,
        borderRadius: '4px 4px 0px 0px',
        ':nth-child(1n) > :last-child': {
            cursor: 'pointer',
        },
        ':nth-child(1n) > :first-child': {
            ':nth-child(1n) > :first-child': {
                ':nth-child(1n) > path': {
                    stroke: theme === 'dark' ? COLORS.gray : '#333333',
                },
            },
        },
        '@media (max-width: 768px)': {
            padding: '14px 16px',
        },
    },
    formBody: {
        padding: '16px 24px',
        display: 'flex',
        flexDirection: 'column',
        gap: 24,
        borderBottom: `1px solid ${theme === 'dark' ? COLORS.lightBlack : COLORS.lightGray}`,
        ':last-of-type': {
            borderBottom: 'none',
            paddingBottom: 24,
        },
        '@media (max-width: 768px)': {
            padding: 16,
            gap: 16,
        },
    },
    dateInputLabel: {
        marginBottom: 5,
        fontWeight: '500',
        fontSize: 12,
        color: theme === 'dark' ? COLORS.lightGray : COLORS.midGray,
    },
    dateInput: {
        backgroundColor: theme === 'dark' ? COLORS.itemDarkModeBg : COLORS.white,
        color: theme === 'dark' ? COLORS.white : COLORS.dark,
        ':nth-child(1n) > div': {
            ':nth-child(1n) > input': {
                backgroundColor: theme === 'dark' ? COLORS.itemDarkModeBg : COLORS.white,
                color: theme === 'dark' ? COLORS.white : COLORS.dark,
            },
            ':nth-child(1n) > div': {
                color: theme === 'dark' ? COLORS.white : COLORS.dark,
            },
        },
        fontSize: 14,
    },
    dateLabel: {
        letterSpacing: 0,
        color: theme === 'dark' ? COLORS.lightGray : COLORS.midGray,
    },
    label: {
        marginBottom: 8,
        fontWeight: '500',
        fontSize: 12,
        color: theme === 'dark' ? COLORS.lightGray : COLORS.midGray,
    },
    input: {
        position: 'relative',
        border: theme === 'dark' ? 'none' : `1px solid ${COLORS.lightGray}`,
        backgroundColor: theme === 'dark' ? COLORS.itemDarkModeBg : COLORS.white,
        borderRadius: 4,
        fontSize: 14,
        fontWeight: '500',
        color: theme === 'dark' ? COLORS.white : COLORS.dark,
    },
    selectInp: {
        ':nth-child(1n) > div': {
            // styles for div inside select component
            background: theme === 'dark' ? COLORS.itemDarkModeBg : COLORS.white,
            // border: theme === 'dark' ? 'none' : `1px solid ${COLORS.lightGray}`,
            color: theme === 'dark' ? COLORS.white : COLORS.dark,
            ':nth-child(1n) *': {
                color: theme === 'dark' ? COLORS.white : COLORS.dark,
            },
        },
    },
})
