import React, { useEffect, useRef, useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { Oval } from 'react-loader-spinner'
import { FormattedMessage, useIntl } from 'react-intl'
import FormInput from '../../common/FormInput'
import { AddPhoto, Calendar, CheckUpList, Trash } from '../../svgs/Svgs'
import ToggleSwitch from '../../common/ToggleSwitch'
import { COLORS } from '../../../utils/colors'
import Button from '../../common/Button'
import FormInputCurrency from '../../common/FormInputCurrency'
import { banknoteOptions, genderOptions } from '../../../utils/options'
import { useMessage } from '../../../hooks/message'
import NoUserImage from '../../../static/images/noUserImg.svg'
import SelectForm from '../../common/SelectForm'
import InputDate from '../../common/InputDate'

export default function MainInfoForm({ values, setFieldValue, info, deletePhoto, theme, setFile, lang }) {
    const [showMessage] = useMessage()
    const intl = useIntl()
    const inputRef = useRef(null)
    const [img, setImg] = useState('')
    const MAX_FILE_SIZE = 3145728
    const allowedFormats = ['jpg', 'jpeg', 'png']

    const onChange = async (e) => {
        if (e.target.files[0]) {
            const currentFile = e.target.files[0].name.split('.')
            const fileExtension = currentFile[currentFile.length - 1]
            if (allowedFormats.includes(fileExtension)) {
                if (e.target.files[0].size <= MAX_FILE_SIZE) {
                    setImg(URL.createObjectURL(e.target.files[0]))
                    setFile(e.target.files[0])
                } else {
                    showMessage(<FormattedMessage id="largeFile" />, 'error-msg')
                }
            } else {
                showMessage(<FormattedMessage id="currentTypeIsNotSupported" />, 'error-msg')
            }
        }
    }

    useEffect(() => {
        if (info.photo) setImg(info.photo[2])
    }, [info])

    const onDeleteImage = async () => {
        if (deletePhoto.loading) return

        const { error, success } = await deletePhoto.request()
        if (success) {
            setImg('')
        } else {
            showMessage(error.data.message || error.data.errors[0].message, 'error-msg')
        }
        inputRef.current.value = ''
    }

    return (
        <div className={css(mode(theme).formWrap)}>
            <div className={css(mode(theme).formHeader)}>
                <div className={cn('align-center gap-2', css(s.headTitle))}>
                    <CheckUpList />

                    <h3><FormattedMessage id="mainInfos" /></h3>
                </div>
            </div>

            <div className={css(s.formBody)}>
                <div className={cn('align-center', 'gap-7', css(s.imageFormWrap))}>
                    {img ? (
                        <React.Fragment>
                            <img
                                src={img}
                                alt="#avatar"
                                className={css(mode(theme).avatarImg)}
                            />

                            <div className={cn('direction-column', css(mode(theme).btn_group))}>
                                <Button type="button" onClick={() => inputRef.current.click()}>
                                    <FormattedMessage id="uploadPhoto" />
                                </Button>

                                <Button
                                    type="button"
                                    onClick={() => onDeleteImage()}>
                                    {deletePhoto.loading ? (
                                        <Oval
                                            height={16}
                                            width={16}
                                            color={theme === 'dark' ? COLORS.white : COLORS.mainColor}
                                            visible
                                            ariaLabel="oval-loading"
                                            secondaryColor={theme === 'dark' ? COLORS.white : COLORS.lightMainColor}
                                            strokeWidth={7}
                                            strokeWidthSecondary={7}

                                        />
                                    ) : <Trash />} {deletePhoto.loading
                                        ? <FormattedMessage id="deleting" />
                                        : <FormattedMessage id="delete" />} <FormattedMessage id="photo" />
                                </Button>
                            </div>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <div className={css(mode(theme).avatarIcon)}>
                                <img src={NoUserImage} alt="#NoUserImage" />
                            </div>

                            <div
                                onClick={() => inputRef.current.click()}
                                className={cn('align-center pointer', css(mode(theme).loadPh))}
                            >
                                <AddPhoto />

                                <h3><FormattedMessage id="uploadPhoto" /></h3>
                            </div>
                        </React.Fragment>
                    )}
                </div>

                <div className={css(mode(theme).formInputs)}>
                    <InputDate
                        maxLength={10}
                        name="birth_date"
                        min="1001-01-01"
                        max="2199-12-31"
                        iconStyles={{ top: 34 }}
                        icon={<Calendar />}
                        value={values.birth_date}
                        placeholder={intl.formatMessage({ id: 'dateFormat' })}
                        className={css(mode(theme).input)}
                        labelClassName={css(mode(theme).label)}
                        label={<FormattedMessage id="birthDate" />}
                        fieldClassName={css(s.fld)}
                    />

                    <SelectForm
                        label={<FormattedMessage id="gender" />}
                        name="gender"
                        theme={theme}
                        value={values.gender
                            ? genderOptions.find((gen) => gen.value === values.gender)
                            : null}
                        options={genderOptions}
                        placeholder={intl.formatMessage({ id: 'selectGender' })}
                        indicatorStyle={{ paddingRight: 10 }}
                        valueContainerStyles={{ padding: '11.5px 16px' }}
                        getOptionLabel={(option) => option[lang]}
                        onChange={({ value }) => setFieldValue('gender', value)}
                        className={css(mode(theme).selectInp)}
                        placeholderStyles={{ color: COLORS.gray, fontFamily: 'Roboto, sans-serif' }}
                    />

                    <FormInput
                        name="address"
                        label={<FormattedMessage id="address" />}
                        errorWithMessage={false}
                        placeholder={intl.formatMessage({ id: 'addressPlaceHolder' })}
                        fieldClassName={css(s.fld)}
                    />

                    <FormInputCurrency
                        // isSelectable
                        maxLength={11}
                        theme={theme}
                        name="desiredSalary"
                        options={banknoteOptions}
                        placeholder={intl.formatMessage({ id: 'enterAmountPlaceholder' })}
                        fieldClassName={css(s.fld)}
                        className={cn(css(mode(theme).salaryInp))}
                        label={<FormattedMessage id="desiredSalary" />}
                        labelClassName={css(mode(theme).salaryInpLab)}
                    />

                    <div className={css(mode(theme).remoteOption)}>
                        <h3><FormattedMessage id="remotePossible" /></h3>

                        <ToggleSwitch
                            name="remote"
                            isChecked={values.remote}
                            onClick={() => setFieldValue('remote', !values.remote)}
                        />
                    </div>
                </div>
            </div>

            <input
                id="file"
                type="file"
                ref={inputRef}
                readOnly="readonly"
                className="not-visible"
                accept="image/png, image/jpg"
                onChange={(e) => onChange(e)} />
        </div>

    )
}

const s = StyleSheet.create({
    formBody: {
        padding: '16px 24px 24px 24px',
        display: 'flex',
        flexDirection: 'column',
        gap: 24,
        '@media (max-width: 768px)': {
            padding: 16,
            gap: 16,
        },
    },
    headTitle: {
        '@media (max-width: 768px)': {
            gap: 4,
        },
    },
    imageFormWrap: {
        '@media (max-width: 768px)': {
            gap: 12,
        },
    },
    fld: {
        position: 'relative',
    },
    fldWrap: {
        border: '1px solid transparent',
        position: 'relative',
        borderRadius: 4,
        ':nth-child(1n) > :first-child': {
            ':nth-child(1n) > path': {
                fill: COLORS.lightRed,
            },
            position: 'absolute',
            right: -12,
            top: -12,
            cursor: 'pointer',
            display: 'none',
        },
    },
    editFld: {
        borderColor: COLORS.lightRed,
        ':nth-child(1n) > :first-child': {
            display: 'block',
        },
    },
})

const mode = (theme) => StyleSheet.create({
    formWrap: {
        boxShadow: '0px 2px 4px rgba(130, 130, 130, 0.05)',
        border: theme === 'dark' ? 'none' : `1px solid ${COLORS.smoothGray}`,
        borderRadius: 4,
        width: '100%',
    },
    formHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '14px 24px',
        fontWeight: '600',
        fontSize: 13,
        textTransform: 'uppercase',
        color: theme === 'dark' ? COLORS.white : COLORS.dark,
        borderBottom: `1px solid ${theme === 'dark' ? COLORS.lightBlack : COLORS.lightGray}`,
        borderRadius: '4px 4px 0px 0px',
        ':nth-child(1n) > :last-child': {
            cursor: 'pointer',
        },
        ':nth-child(1n) > :first-child': {
            ':nth-child(1n) > :first-child': {
                ':nth-child(1n) > :first-child': {
                    fill: theme === 'dark' ? COLORS.gray : '#333333',
                },
                ':nth-child(1n) > :last-child': {
                    stroke: theme === 'dark' ? COLORS.gray : '#333333',
                },
            },
        },
        '@media (max-width: 768px)': {
            padding: '14px 16px',
        },
    },
    avatarIcon: {
        border: `1px solid ${theme === 'dark' ? COLORS.midGray : COLORS.lightGray}`,
        borderRadius: 4,
        width: 96,
        height: 96,
        ':nth-child(1n) > img': {
            width: 'inherit',
            height: 'inherit',
        },
        // '@media (max-width: 768px)': {
        //     width: 40,
        //     height: 40,
        // },
    },
    btn_group: {
        ':nth-child(1n) > :first-child': {
            background: theme === 'dark' ? 'rgba(250, 255, 252, 0.10)' : COLORS.white,
            border: theme === 'dark' ? 'none' : '1px solid #CCEADB',
            borderRadius: 4,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontWeight: 500,
            fontSize: 14,
            color: COLORS.mainColor,
            gap: 8,
            height: 40,
            width: '100%',
            '@media (max-width: 414px)': {
                fontSize: 12,
            },
        },
        ':nth-child(1n) > :last-child': {
            background: theme === 'dark' ? COLORS.itemDarkModeBg : COLORS.white,
            border: theme === 'dark' ? 'none' : `1px solid ${COLORS.smoothGray}`,
            borderRadius: 4,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontWeight: '500',
            fontSize: 13,
            color: COLORS.gray,
            gap: 8,
            height: 40,
            width: '100%',
            marginTop: 12,
            '@media (max-width: 414px)': {
                fontSize: 12,
            },
        },
    },
    avatarImg: {
        borderBottom: `1px solid ${theme === 'dark' ? COLORS.lightBlack : COLORS.lightGray}`,
        borderRadius: 4,
        width: 96,
        height: 96,
        ':nth-child(1n) > img': {
            objectFit: 'cover',
            objectPosition: 'center',
        },
    },
    loadPh: {
        background: theme === 'dark' ? COLORS.itemDarkModeBg : COLORS.white,
        border: theme === 'dark' ? 'none' : '1px solid #E3F8EE',
        boxShadow: '0px 2px 20px rgba(137, 152, 141, 0.02)',
        borderRadius: 4,
        padding: '10px 16px',
        fontWeight: '500',
        fontSize: 14,
        color: COLORS.mainColor,
        gap: 8,
        ':hover': {
            boxShadow: '0px 2px 4px rgba(168, 168, 168, 0.15)',
            border: '1px solid rgba(46, 169, 125, 0.5)',
        },
    },
    label: {
        color: theme === 'dark' ? COLORS.lightGray : COLORS.midGray,
        marginBottom: 8,
        fontWeight: '500',
        fontSize: 12,
    },
    input: {
        background: theme === 'dark' ? COLORS.itemDarkModeBg : COLORS.white,
        border: theme === 'dark' ? 'none' : `1px solid ${COLORS.lightGray}`,
        color: theme === 'dark' ? COLORS.white : COLORS.dark,
        position: 'relative',
        borderRadius: 4,
        fontSize: 14,
        '-webkit-appearance': 'none',
        boxSizing: 'border-box',
        minWidth: 'calc(100% - 16px)',
        fontWeight: '500',
    },
    formInputs: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        alignItems: 'center',
        gridColumnGap: 25,
        gridRowGap: 24,
        boxSizing: 'border-box',
        ':nth-child(1n) > *': {
            flex: 1,
            // Here only birthDay and address inputs are styled
            ':nth-child(1n) > div': {
                ':nth-child(1n) > input': {
                    background: theme === 'dark' ? COLORS.itemDarkModeBg : COLORS.white,
                    border: theme === 'dark' ? 'none' : `1px solid ${COLORS.lightGray}`,
                    color: theme === 'dark' ? COLORS.white : COLORS.dark,
                },
                ':nth-child(1n) > label': {
                    color: theme === 'dark' ? COLORS.lightGray : COLORS.midGray,
                    marginBottom: 8,
                    fontWeight: '500',
                    fontSize: 12,
                },
            },
            // =============================
        },
        '@media (max-width: 768px)': {
            gridTemplateColumns: '1fr',
        },
    },
    selectInp: {
        ':nth-child(1n) > div': {
            // styles for div inside select component
            background: theme === 'dark' ? COLORS.itemDarkModeBg : COLORS.white,
            border: theme === 'dark' ? 'none' : `1px solid ${COLORS.lightGray}`,
            color: theme === 'dark' ? COLORS.white : COLORS.dark,
            ':nth-child(1n) *': {
                color: theme === 'dark' ? COLORS.white : COLORS.dark,
            },
        },
    },
    salaryInp: {
        position: 'relative',
        borderRadius: 4,
        background: theme === 'dark' ? COLORS.itemDarkModeBg : COLORS.white,
        fontSize: 14,
        fontWeight: '500',
        ':nth-child(1n) input': {
            background: 'transparent',
            color: theme === 'dark' ? COLORS.white : COLORS.dark,
        },
        ':nth-child(1n) > div': {
            // styles for div inside select component
            background: theme === 'dark' ? COLORS.itemDarkModeBg : COLORS.white,
            border: theme === 'dark' ? 'none' : `1px solid ${COLORS.lightGray}`,
            color: theme === 'dark' ? COLORS.white : COLORS.dark,
            ':nth-child(1n) *': {
                color: theme === 'dark' ? COLORS.white : COLORS.dark,
            },
        },
    },
    salaryInpLab: {
        marginBottom: 8,
    },
    remoteOption: {
        marginTop: 20,
        padding: '0 16px',
        height: 42,
        border: theme === 'dark' ? 'none' : `1px solid ${COLORS.lightGray}`,
        borderRadius: 4,
        fontWeight: '500',
        fontSize: 14,
        color: theme === 'dark' ? COLORS.white : COLORS.dark,
        background: theme === 'dark' ? COLORS.itemDarkModeBg : COLORS.white,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
})
