import React, { createContext, useContext, useEffect, useLayoutEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import axios from 'axios'
import { usePersistState } from '../hooks/state'
import { useGetRequest, useLoad } from '../hooks/request'
import { CANDIDATE_PROFILE_INFO, CANDIDATE_UPDATE_EMAIL, INDUSTRIES_LIST } from '../urls'
import { industries } from '../utils/industries'
import { BaseContext } from './BaseContextWrapper'
import { checkUser } from '../utils/auth'
import { useQueryParams } from '../hooks/queryString'
import { useMessage } from '../hooks/message'
import { domain } from '../utils/request'

export const Context = createContext()

export default function GlobalContext({ children }) {
    const { token, type } = useQueryParams()
    const [showMessage] = useMessage()
    const [vacanciesFilter, setVacanciesFilter] = usePersistState('_v_fl', { sortBy: '-v_created_at' })
    const getInfo = useGetRequest({ url: CANDIDATE_PROFILE_INFO })
    const info = getInfo.response ? getInfo.response.data : []
    const loadIndustries = useLoad({ url: INDUSTRIES_LIST })
    const companyIndustries = loadIndustries.response ? loadIndustries.response.data : []
    const industryLoading = loadIndustries.loading
    const { lang, setText, setClassName } = useContext(BaseContext)
    const [userLoading, setUserLoading] = useState(false)

    const matchedIndustries = companyIndustries.map((industry, ind) => {
        const { Icon } = industries[ind]
        return {
            value: industry.id,
            label: industries[ind][lang],
            companyCount: industry.companyCount,
            Icon,
        }
    })

    const verifyUserEmail = async () => {
        if (type === 'verify_candidate_email') {
            axios.get(domain + CANDIDATE_UPDATE_EMAIL, { params: { token, type } }).then(({ data }) => {
                if (data.success) {
                    showMessage(<FormattedMessage id="emailVerified" />, 'success-msg')
                }
            }).catch(({ response }) => {
                showMessage(response.data.message, 'error-msg')
            })
        }
    }

    useLayoutEffect(() => {
        if (type && token) verifyUserEmail()
    }, [])

    useEffect(() => {
        const getUserInfo = async () => {
            setUserLoading(true)
            try {
                const { success, data } = await checkUser(setText, setClassName)
                if (success) {
                    getInfo.setResponse(data)
                    setUserLoading(false)
                }
            } catch (e) {
                console.log('ERROR:', e)
            }
        }
        if (localStorage.token) getUserInfo()
    }, [])

    return (
        <Context.Provider
            value={{
                info,
                getInfo,
                userLoading,
                vacanciesFilter,
                matchedIndustries,
                companyIndustries,
                setVacanciesFilter,
                industryLoading,
            }}
        >
            {children}
        </Context.Provider>
    )
}
