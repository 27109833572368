import React, { useContext, useEffect } from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { useNavigate } from 'react-router-dom'
import Slider from 'react-slick'
import { range } from 'lodash'
import { FormattedMessage } from 'react-intl'
import { COLORS } from '../../utils/colors'
import { ArrowNextSizeM, ChevronNextSizeL, DefaultLocation, WalletSizeM } from '../svgs/Svgs'
import { VacancyCardItem } from './VacancyCardItem'
import { NoItemsImageSizeM } from '../svgs/SvgPictures'
import { useGetRequest } from '../../hooks/request'
import { PUBLIC_VACANCIES_LIST } from '../../urls'
import { LoadingSkeleton } from '../common/LoadingSkeleton'
import { BaseContext } from '../../contexts/BaseContextWrapper'

export default function HotVacancies() {
    const navigate = useNavigate()
    const getVacancies = useGetRequest({ url: PUBLIC_VACANCIES_LIST })
    const vacancies = getVacancies.response ? getVacancies.response.data : []
    const { theme } = useContext(BaseContext)

    useEffect(() => {
        getVacancies.request()
    }, [])

    function NextArrow({ onClick }) {
        return (
            <div style={{ display: onClick === null ? 'none' : 'block' }}>
                <div
                    onClick={onClick}
                    className={css(s.nextIcon)}>
                    <ChevronNextSizeL />
                </div>
            </div>
        )
    }

    function PrevArrow({ onClick }) {
        return (
            <div style={{ display: onClick === null ? 'none' : 'block' }}>
                <div
                    onClick={onClick}
                    className={css(s.prevIcon)}>
                    <ChevronNextSizeL />
                </div>
            </div>
        )
    }

    const settings = {
        arrows: true,
        dots: false,
        infinite: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        cssEase: 'linear',
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                },
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 1440,
                settings: {
                    slidesToShow: 3,
                },
            },
        ],
    }

    return (
        <div className={cn(css(s.contWrap), 'is-flex direction-column gap-6')}>
            <div className={cn(css(mode(theme).contHeader), 'is-flex align-center justify-between')}>
                <h3>🔥 <FormattedMessage id="hotVacancies" /></h3>

                {vacancies.length ? (
                    <div
                        className="is-flex align-end justify-center gap-2"
                        onClick={() => navigate('/vacancies')}
                    >
                        <h3><FormattedMessage id="showAll" /></h3>

                        <ArrowNextSizeM />
                    </div>
                ) : null}
            </div>

            {!getVacancies.loading ? (
                <React.Fragment>
                    <div className={css(s.slider)}>
                        <Slider {...settings}>
                            {vacancies.slice(0, 6).map((item) => (
                                <VacancyCardItem theme={theme} key={item.id} item={item} />
                            ))}
                        </Slider>
                    </div>

                    <div className={css(s.mobile)}>
                        {vacancies.slice(0, 6).map((item) => (
                            <VacancyCardItem theme={theme} key={item.id} item={item} />
                        ))}
                    </div>
                </React.Fragment>
            ) : (
                <div className={cn('is-flex align-center gap-7', css(s.skeletonWrap))}>
                    {range(0, 4).map((i) => (
                        <div key={i} className={css(mode(theme).cardWrap)}>
                            <div className={css(s.cardBodyWrap)}>
                                <div>
                                    <div className={css(s.compInfo)}>
                                        <div className={css(mode(theme).defaultLogo)}>
                                            <DefaultLocation color={COLORS.white} />
                                        </div>

                                        <div className={css(s.comp_titles)}>
                                            <LoadingSkeleton height={16} />

                                            <LoadingSkeleton width={100} height={13} />
                                        </div>
                                    </div>

                                    <div className={css(s.detailsWrap)}>
                                        <div className={css(mode(theme).detailWrap)}>
                                            <WalletSizeM />

                                            <LoadingSkeleton height={13} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <LoadingSkeleton height={13} />
                        </div>
                    ))}
                </div>
            )}

            {!vacancies.length && !getVacancies.loading ? (
                <div className={cn(css(mode(theme).emptyCont), 'is-flex direction-column align-center justify-center')}>
                    <NoItemsImageSizeM />

                    <h3><FormattedMessage id="noCompanyVacanciesYet" /></h3>
                </div>
            ) : null}
        </div>
    )
}

const s = StyleSheet.create({
    contWrap: {
        width: '100%',
        gap: 18,
        '@media (max-width: 1024px)': {
            gap: 13,
        },
    },
    prevIcon: {
        left: 5,
        width: 46,
        zIndex: 2,
        top: '40%',
        height: 46,
        transform: 'rotate(-180deg)',
        cursor: 'pointer',
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 64,
        border: '1px solid rgba(139, 139, 139, 0.20)',
        background: 'rgba(255, 255, 255, 0.80)',
        boxShadow: '0px 2px 12px 0px rgba(0, 0, 0, 0.20)',
        backdropFilter: 'blur(6px)',
        ':nth-child(1n) > :only-child': {
            ':nth-child(1n) > path': {
                stroke: COLORS.dark,
                strokeWidth: 3,
            },
        },
        '@media (max-width: 1024px)': {
            display: 'none',
        },
    },
    nextIcon: {
        right: 5,
        left: 'auto',
        width: 46,
        zIndex: 2,
        top: '40%',
        height: 46,
        cursor: 'pointer',
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 64,
        transform: 'rotate(360deg)',
        border: '1px solid rgba(139, 139, 139, 0.20)',
        background: 'rgba(255, 255, 255, 0.80)',
        boxShadow: '0px 2px 12px 0px rgba(0, 0, 0, 0.20)',
        backdropFilter: 'blur(6px)',
        ':nth-child(1n) > :only-child': {
            ':nth-child(1n) > path': {
                stroke: COLORS.dark,
                strokeWidth: 3,
            },
        },
        '@media (max-width: 1024px)': {
            display: 'none',
        },
    },
    slider: {
        '@media (max-width: 1024px)': {
            display: 'none',
        },
    },
    mobile: {
        paddingLeft: 16,
        display: 'none',
        gap: 12,
        overflowX: 'scroll',
        scrollbarWidth: 'none', // Firefox
        '-ms-overflow-style': 'none', // Internet Explorer
        '::-webkit-scrollbar': {
            display: 'none', // Safari and Chrome
        },
        '@media (max-width: 1024px)': {
            display: 'flex',
        },
    },
    cardWrap: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        minHeight: 155,
        minWidth: '20rem',
        cursor: 'pointer',
        backgroundColor: COLORS.white,
        border: `1px solid ${COLORS.smoothGray}`,
        boxShadow: '0px 2px 4px rgba(130, 130, 130, 0.05)',
        borderRadius: '4px',
        padding: 16,
        ':hover': {
            border: `1px solid ${COLORS.lightGray}`,
            boxShadow: '0px 2px 8px rgba(136, 136, 136, 0.25)',
        },
        '@media (max-width: 768px)': {
            padding: 12,
            gap: 8,
        },
    },
    cardBodyWrap: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    compInfo: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        gap: '12px',
        marginBottom: '16px',
        ':nth-child(1n) > img': {
            width: 40,
            height: 40,
        },
    },
    comp_titles: {
        display: 'flex',
        flexDirection: 'column',
        gap: '4px',
        '@media (max-width: 768px)': {
            gap: 2,
            fontSize: 13,
        },
    },
    detailsWrap: {
        display: 'flex',
        flexDirection: 'column',
        gap: 8,
    },
    skeletonWrap: {
        margin: '0 32px',
        overflowX: 'scroll',
        scrollbarWidth: 'none', // Firefox
        '-ms-overflow-style': 'none', // Internet Explorer
        '::-webkit-scrollbar': {
            display: 'none', // Safari and Chrome
        },
        '@media (max-width: 1024px)': {
            margin: '0 16px',
            gap: 12,
        },
    },
})

const mode = (theme) => StyleSheet.create({
    contHeader: {
        padding: '0 32px',
        color: theme === 'dark' ? COLORS.white : COLORS.lightBlack,
        '@media (max-width: 1024px)': {
            padding: '0 16px',
        },
        ':nth-child(1n) > :first-child': {
            fontSize: 24,
            fontWeight: '600',
            '@media (max-width: 1024px)': {
                fontSize: 16,
            },
        },
        ':nth-child(1n) > :last-child': {
            cursor: 'pointer',
            ':nth-child(1n) > :first-child': {
                fontSize: 15,
                fontWeight: '600',
                '@media (max-width: 1024px)': {
                    fontSize: 12,
                    fontWeight: '500',
                },
            },
            ':nth-child(1n) > :last-child': {
                ':nth-child(1n) > path': {
                    stroke: theme === 'dark' ? COLORS.white : COLORS.lightBlack,
                },
            },
        },
    },
    cardWrap: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        minHeight: 155,
        minWidth: '20rem',
        cursor: 'pointer',
        backgroundColor: theme === 'dark' ? COLORS.darkModeBg : COLORS.white,
        border: `1px solid ${theme === 'dark' ? COLORS.itemDarkModeBg : COLORS.smoothGray}`,
        boxShadow: '0px 2px 4px rgba(130, 130, 130, 0.05)',
        borderRadius: '4px',
        padding: 16,
        ':hover': {
            border: `1px solid ${COLORS.lightGray}`,
            boxShadow: '0px 2px 8px rgba(136, 136, 136, 0.25)',
        },
        '@media (max-width: 768px)': {
            padding: 12,
            gap: 8,
        },
    },
    name: {
        fontWeight: 700,
        fontSize: 16,
        color: theme === 'dark' ? COLORS.white : COLORS.dark,
        '@media (max-width: 768px)': {
            fontSize: 13,
        },
    },
    detailWrap: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 8,
        ':nth-child(1n) > svg': {
            ':nth-child(1n) > :first-child': {
                stroke: COLORS.mainColor,
            },
            ':nth-child(1n) > :last-child': {
                stroke: COLORS.mainColor,
            },
        },
        background: theme === 'dark' ? COLORS.itemDarkModeBg : 'inherit',
        border: theme === 'dark' ? 'none' : `1px solid ${COLORS.smoothGray}`,
        boxShadow: '0px 2px 4px rgba(130, 130, 130, 0.05)',
        borderRadius: '16px',
        padding: '4px 12px',
        fontWeight: 500,
        fontSize: '13px',
        color: theme === 'dark' ? COLORS.gray : COLORS.lightBlack,
        width: 'fit-content',
        '@media (max-width: 768px)': {
            padding: '6px 8px',
            fontSize: 10,
            gap: 4,
        },
        whiteSpace: 'nowrap',
    },
    defaultLogo: {
        width: 40,
        height: 40,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: COLORS.mainColor,
        border: theme === 'dark' ? 'none' : `1px solid ${COLORS.white}`,
        borderRadius: 4,
        boxSizing: 'border-box',
    },
    createdDate: {
        color: theme === 'dark' ? COLORS.gray : COLORS.midGray,
        fontSize: 13,
        fontWeight: '400',
    },
    logoCont: {
        width: 40,
        height: 40,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxSizing: 'border-box',
        ':nth-child(1n) > img': {
            width: 'inherit',
            border: theme === 'dark' ? 'none' : `1px solid ${COLORS.white}`,
            borderRadius: 4,
        },
    },
    emptyCont: {
        padding: 32,
        ':nth-child(1n) > :last-child': {
            color: COLORS.midGray,
            fontSize: 13,
            fontWeight: '600,',
        },
    },
})
