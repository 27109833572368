import React, { memo, useEffect, useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { Form, Formik } from 'formik'
import { Oval } from 'react-loader-spinner'
import { FormattedMessage, useIntl } from 'react-intl'
import { COLORS } from '../../utils/colors'
import { Calendar, CloseSidebar } from '../svgs/Svgs'
import Button from '../common/Button'
import FormInput from '../common/FormInput'
import { educationLvl } from '../../utils/options'
import SelectForm from '../common/SelectForm'
import Checkbox from '../common/Checkbox'
import InputDate from '../common/InputDate'
import { date, required, validator } from '../../utils/validators'

function EducationForm({ onClose, requiredFields, index, setFieldValue, showModal, values, theme, loading }) {
    const [dateChecked, setDateChecked] = useState(false)
    const intl = useIntl()

    useEffect(() => {
        if (values.education[index] && values.education[index].start_date !== null
            && values.education[index].end_date === null) {
            setDateChecked(true)
        } else {
            setDateChecked(false)
        }
    }, [index])

    return (
        <Form>
            <div className={css(s.modalWrap)}>
                <div className={css(mode(theme).modalHeader)}>
                    <h3>
                        {showModal.type === 'add'
                            ? <FormattedMessage id="addEducation" />
                            : <FormattedMessage id="editEducation" />}
                    </h3>

                    <CloseSidebar color={theme === 'dark' ? COLORS.white : COLORS.dark} onClick={onClose} />
                </div>

                <div className={css(mode(theme).modalBody)}>
                    <div className={css(s.formInputs)}>
                        <SelectForm
                            validate={required}
                            errorWithMessage={false}
                            isSearchable={false}
                            theme={theme}
                            placeholderStyles={{ color: COLORS.gray, fontFamily: 'Roboto, sans-serif' }}
                            value={values.education[index]
                                ? educationLvl.find((edu) => edu.value === values.education[index].level)
                                : null}
                            valueContainerStyles={{ padding: '11.5px 16px' }}
                            indicatorStyle={{ paddingRight: 10 }}
                            label={<FormattedMessage id="education" />}
                            onChange={({ value }) => setFieldValue(`education[${index}].level`, value)}
                            placeholder={intl.formatMessage({ id: 'exampleEduPlaceHolder' })}
                            name={`education[${index}].level`}
                            className={css(mode(theme).selectInp)}
                            options={educationLvl} />

                        <FormInput
                            validate={required}
                            errorWithMessage={false}
                            value={values.education[index] ? values.education[index].institution : ''}
                            label={<FormattedMessage id="institution" />}
                            className={css(mode(theme).input)}
                            labelClassName={css(mode(theme).label)}
                            name={`education[${index}].institution`}
                            placeholder={intl.formatMessage({ id: 'enterInstitutionPlaceHolder' })}
                        />

                        <InputDate
                            validate={validator(required, date)}
                            errorWithMessage={false}
                            isMulti={!dateChecked}
                            icon={<Calendar />}
                            fieldClassName={css(s.fld)}
                            className={css(mode(theme).dateInput)}
                            labelClassName={css(mode(theme).dateInputLabel)}
                            label={(
                                <h3><FormattedMessage id="startEndDate" /></h3>
                            )}
                            checkBox={(
                                <div
                                    className="align-center gap-1">
                                    <Checkbox
                                        className={css(s.checkBox)}
                                        onClick={() => {
                                            setDateChecked(!dateChecked)
                                        }}
                                        checked={dateChecked} /> <FormattedMessage id="untilToday" />
                                </div>
                            )}
                            name={[`education[${index}].start_date`, `education[${index}].end_date`]}
                        />

                        <FormInput
                            value={values.education[index] ? values.education[index].additional : ''}
                            labelClassName={css(mode(theme).label)}
                            className={css(mode(theme).input)}
                            name={`education[${index}].additional`}
                            label={<FormattedMessage id="additional" />}
                            placeholder={intl.formatMessage({ id: 'exampleEduAdditionalPlaceHolder' })}
                        />
                    </div>
                </div>

                <div className={css(mode(theme).modalFooter)}>
                    <Button type="button" onClick={onClose} className={cn(css(mode(theme).cancelBtn), css(s.btn))}>
                        <FormattedMessage id="cancel" />
                    </Button>

                    <Button
                        type="submit"
                        className={cn(css(requiredFields ? s.disabledBtn : s.saveBtn, s.btn))}
                    >
                        {!loading ? <FormattedMessage id="save" /> : <FormattedMessage id="saving" />}

                        {loading ? (
                            <Oval
                                height={16}
                                width={16}
                                color={COLORS.white}
                                visible
                                ariaLabel="oval-loading"
                                secondaryColor={COLORS.white}
                                strokeWidth={7}
                                strokeWidthSecondary={7}
                            />
                        ) : null}
                    </Button>
                </div>
            </div>
        </Form>
    )
}

function MemoizedComponent({ onClose, showModal, ind, initialValues, onSubmit, theme, loading }) {
    const index = showModal.type === 'update' ? ind : 0

    return (
        <Formik
            enableReinitialize
            initialValues={{
                education: [{ level: '', start_date: '', end_date: '', institution: '', additional: '' }],
                ...initialValues,
            }} onSubmit={(values) => onSubmit(values, index, showModal.id)}>
            {({ setFieldValue, values }) => {
                // eslint-disable-next-line max-len
                const requiredFields = Object.values(values.education[index]).every((value) => value === '' || value === null)

                return (
                    <EducationForm
                        values={values}
                        theme={theme}
                        showModal={showModal}
                        index={index}
                        onClose={onClose}
                        loading={loading}
                        requiredFields={requiredFields}
                        setFieldValue={setFieldValue}
                    />
                )
            }}
        </Formik>
    )
}

export const EducationModal = memo(MemoizedComponent)

const s = StyleSheet.create({
    modalWrap: {
        width: '37.25rem',
        borderRadius: '4px 4px 0px 0px',
        '@media (max-width: 768px)': {
            width: '90vw',
        },
    },
    formInputs: {
        display: 'flex',
        flexDirection: 'column',
        gap: 24,
        '@media (max-width: 768px)': {
            gap: 16,
        },
    },
    checkBox: {
        background: COLORS.white,
    },
    fld: {
        position: 'relative',
    },
    btn: {
        borderRadius: 4,
        padding: '12px 16px',
        fontWeight: '500',
        fontSize: 14,
        display: 'flex',
        alignItems: 'center',
        gap: 8,
    },
    saveBtn: {
        color: COLORS.white,
        cursor: 'pointer',
        background: `linear-gradient(180deg, ${COLORS.mainColor} 0%, ${COLORS.lightMainColor} 100%)`,
        // eslint-disable-next-line max-len
        boxShadow: '0px 4px 12px rgba(44, 67, 50, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.25), inset 0px 1px 0px rgba(255, 255, 255, 0.1)',
    },
    disabledBtn: {
        background: COLORS.gray,
        border: 'none',
        // eslint-disable-next-line max-len
        boxShadow: '0px 4px 12px rgba(44, 67, 50, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.25), inset 0px 1px 0px rgba(255, 255, 255, 0.1)',
        cursor: 'not-allowed',
        fontWeight: '500',
        fontSize: 14,
        color: COLORS.white,
    },
})

const mode = (theme) => StyleSheet.create({
    modalHeader: {
        padding: '20px 24px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        background: theme === 'dark' ? COLORS.itemDarkModeBg : COLORS.white,
        borderBottom: `1px solid ${theme === 'dark' ? COLORS.lightBlack : COLORS.lightGray}`,
        borderRadius: '4px 4px 0px 0px',
        ':nth-child(1n) > h3': {
            fontWeight: '700',
            fontSize: 20,
            color: theme === 'dark' ? COLORS.white : COLORS.dark,
            '@media (max-width: 768px)': {
                fontSize: 16,
            },
        },
        ':nth-child(1n) > :last-child': {
            cursor: 'pointer',
        },
        '@media (max-width: 768px)': {
            padding: '14px 16px',
        },
    },
    modalBody: {
        maxHeight: '28rem',
        padding: 24,
        background: theme === 'dark' ? COLORS.darkModeBg : COLORS.smoothGray,
        '@media (max-width: 768px)': {
            height: '60vh',
            padding: 16,
        },
    },
    dateInputLabel: {
        marginBottom: 5,
        fontWeight: '500',
        fontSize: 12,
        color: theme === 'dark' ? COLORS.lightGray : COLORS.midGray,
    },
    dateInput: {
        backgroundColor: theme === 'dark' ? COLORS.itemDarkModeBg : COLORS.white,
        border: theme === 'dark' ? 'none' : `1px solid ${COLORS.lightGray}`,
        color: theme === 'dark' ? COLORS.white : COLORS.dark,
        ':nth-child(1n) > div': {
            ':nth-child(1n) > input': {
                backgroundColor: theme === 'dark' ? COLORS.itemDarkModeBg : COLORS.white,
                color: theme === 'dark' ? COLORS.white : COLORS.dark,
            },
            ':nth-child(1n) > div': {
                color: theme === 'dark' ? COLORS.white : COLORS.dark,
            },
        },
        fontSize: 14,
    },
    label: {
        color: theme === 'dark' ? COLORS.lightGray : COLORS.midGray,
        marginBottom: 8,
        fontWeight: '500',
        fontSize: 12,
    },
    input: {
        background: theme === 'dark' ? COLORS.itemDarkModeBg : COLORS.white,
        // border: theme === 'dark' ? 'none' : `1px solid ${COLORS.lightGray}`,
        color: theme === 'dark' ? COLORS.white : COLORS.dark,
        position: 'relative',
        borderRadius: 4,
        fontSize: 14,
        '-webkit-appearance': 'none',
        boxSizing: 'border-box',
        minWidth: 'calc(100% - 16px)',
        fontWeight: '500',
    },
    selectInp: {
        ':nth-child(1n) > div': {
            // styles for div inside select component
            background: theme === 'dark' ? COLORS.itemDarkModeBg : COLORS.white,
            // border: theme === 'dark' ? 'none' : `1px solid ${COLORS.lightGray}`,
            color: theme === 'dark' ? COLORS.white : COLORS.dark,
            ':nth-child(1n) *': {
                color: theme === 'dark' ? COLORS.white : COLORS.dark,
            },
        },
    },
    cancelBtn: {
        color: COLORS.gray,
        border: `1px solid ${COLORS.gray}`,
        background: 'inherit',
    },
    modalFooter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'end',
        gap: 16,
        background: theme === 'dark' ? COLORS.itemDarkModeBg : COLORS.white,
        borderTop: `1px solid ${theme === 'dark' ? COLORS.lightBlack : COLORS.lightGray}`,
        borderRadius: '0px 0px 4px 4px',
        padding: '16px 24px',
        '@media (max-width: 768px)': {
            padding: '8px 16px',
        },
    },
})
