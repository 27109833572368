import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { SalaryReceiveOptions, statusesInMultiLang } from '../../utils/options'
import { BaseContext } from '../../contexts/BaseContextWrapper'
import OfferInfoModal from './OfferInfoModal'
import Modal from '../common/Modal'
import { Context } from '../../contexts/GlobalContext'
import { useLoad, usePostRequest } from '../../hooks/request'
import { OFFER_TERMS } from '../../urls'
import LoadingSpinner from '../common/LoadingSpinner'
import { useMessage } from '../../hooks/message'

export default function OfferToNotRegistered({ token }) {
    const { info } = useContext(Context)
    const navigate = useNavigate()
    const loadOfferInfo = useLoad({ url: OFFER_TERMS, params: { token } })
    const acceptOffer = usePostRequest({ url: OFFER_TERMS, params: { token } })
    const { theme, lang } = useContext(BaseContext)
    const [showMessage] = useMessage()
    const [modal, setShowModal] = useState({ act: true })

    const offerStatus = [{ status: statusesInMultiLang[4][lang] }]

    useEffect(() => {
        if (loadOfferInfo.loading) return
        if (loadOfferInfo.response) setShowModal({ act: true, item: loadOfferInfo.response.data })
        else if (loadOfferInfo.error.data) {
            navigate('/')
            showMessage(loadOfferInfo.error.data.message || 'ERROR', 'error-msg')
        }
    }, [loadOfferInfo.loading])

    async function onSubmit(accept) {
        const { success, error } = await acceptOffer.request({ data: { accept } })

        if (success) showMessage('Успешно', 'success-msg')
        else showMessage(error.data.message || 'Error', 'error-msg')
        navigate('/')
    }

    return (
        <Modal
            closeOnEscape
            closeOnDocumentClick
            open={modal.act}
            overlayStyle={{ top: 52 }}
            onClose={() => {
                navigate('/')
                setShowModal({ act: false })
            }}
        >
            {!loadOfferInfo.loading ? loadOfferInfo.response ? (
                <OfferInfoModal
                    theme={theme}
                    onClose={() => setShowModal({ act: false })}
                    status={offerStatus}
                    info={info}
                    notRegistered
                    // eslint-disable-next-line react/jsx-no-bind
                    onSubmitNotReg={onSubmit}
                    lang={lang}
                    options={SalaryReceiveOptions}
                    showModal={{ item: loadOfferInfo.response.data, st: 1 }}
                    getData={loadOfferInfo}
                />
            ) : null : <LoadingSpinner width="25vw" />}
        </Modal>
    )
}
