import { format } from './number'

export function removeExtraSpaces(str) {
    return str.replace(/\s+/g, ' ').trim()
}

export function fromToStr(from, to, helpText, noText, lang) {
    const formattedFor = from ? format(from) : ''
    const formattedTo = to ? format(to) : ''

    const fromTxt = { uz: ' dan', en: 'from ', ru: 'от ' }
    const toTxt = { uz: ' gacha', en: 'to ', ru: 'до ' }

    return formattedFor && formattedTo
        ? `${lang !== 'uz' ? fromTxt[lang] : ''}
        ${formattedFor}${lang === 'uz' ? fromTxt[lang] : ''} - 
        ${lang !== 'uz' ? toTxt[lang] : ''}${formattedTo}${lang === 'uz' ? toTxt[lang] : ''} ${helpText}`
        : formattedFor
            ? `${lang !== 'uz' ? fromTxt[lang]
                : ''}${formattedFor}${lang === 'uz'
                ? fromTxt[lang] : ''} ${helpText}`
            : formattedTo
                ? `${lang !== 'uz' ? toTxt[lang]
                    : ''}${formattedTo}${lang === 'uz'
                    ? toTxt[lang] : ''} ${helpText}`
                : (noText || '')
}

export function strCut(str, start, end) { // if end is true then cut from start to str.length
    if (!str || typeof str !== 'string') return str

    if (end && typeof end === 'boolean') {
        return str.slice(start, str.length)
    }

    return str.slice(start, end)
}
