import React, { useRef, useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import axios from 'axios'
import uuid from 'react-uuid'
import { FormattedMessage } from 'react-intl'
import { COLORS } from '../../utils/colors'
import { BachelorHatSizeL,
    ChevronNext,
    PlusRoundedSizeL,
    ThreeDotsMenuHorizontalSizeL } from '../svgs/Svgs'
import educationLogo from '../../static/temporarily-images/education_logo.png'
import { Popup } from '../Popup'
import Modal from '../common/Modal'
import { EducationModal } from './EducationModal'
import { educationLvl } from '../../utils/options'
import { useMessage } from '../../hooks/message'
import AmazingTooltip from '../common/AmazingTooltip'
import { CANDIDATE_EDUCATION_CREATE, CANDIDATE_EDUCATION_DELETE, CANDIDATE_EDUCATION_UPDATE } from '../../urls'
import { domain } from '../../utils/request'
import { usePostRequest } from '../../hooks/request'
import { LoadingSkeleton } from '../common/LoadingSkeleton'
import { auth } from '../../utils/auth'

const Skeleton = ({ theme }) => (
    <div className={css(mode(theme).boxWrap)}>
        <div className={css(s.boxHeader)}>
            <div className="is-flex align-center gap-3">
                <BachelorHatSizeL color={theme === 'dark' ? COLORS.white : COLORS.dark} />

                <h3 className={css(mode(theme).headTitle)}><FormattedMessage id="education" /></h3>
            </div>
        </div>

        <div className={css(mode(theme).educationsWrap)}>
            <div className="is-flex align-center justify-between">
                <div className={cn('is-flex align-center gap-7', css(s.right))}>
                    <img className={css(s.logo)} src={educationLogo} alt="#EducatinLogo" />

                    <div className="is-flex direction-column gap-2">
                        <LoadingSkeleton height={21} width={200} />

                        <LoadingSkeleton height={19} width={146} />
                    </div>
                </div>

                <div className={cn('is-flex align-center gap-7', css(s.left), css(s.mobSkeleton))}>
                    <LoadingSkeleton height={19} width={135} />
                </div>
            </div>
        </div>
    </div>
)

export default function EducationInfo({
    info,
    getInfo,
    options,
    theme,
}) {
    const popUpRef = useRef()
    const [showMessage] = useMessage()
    const [showModal, setShowModal] = useState({
        act: false,
        type: '',
    })
    // eslint-disable-next-line max-len
    const eduCreate = usePostRequest({ url: CANDIDATE_EDUCATION_CREATE })

    const initialValues = {
        ...info,
        education: info.education ? info.education.map((edu) => ({
            level: edu.level,
            end_date: edu.endDate,
            start_date: edu.startDate,
            additional: edu.additional,
            institution: edu.institution,
        })) : [],
    }

    const onCreate = async (values, index) => {
        if (Object.values(values.education[index]).every((value) => value === '' || value === null)) return

        const data = {
            level: values.education[index].level,
            startDate: values.education[index].start_date,
            endDate: values.education[index].end_date,
            institution: values.education[index].institution,
            additional: values.education[index].additional,
        }

        const { success, error } = await eduCreate.request({ data })

        if (success) {
            getInfo.request()
            setShowModal({ act: false, type: '' })
            showMessage(<FormattedMessage id="successFullyAdded" />, 'success-msg')
        } else {
            showMessage(error.data.errors[0].message || error.data.message, 'error-msg')
        }
    }

    const onUpdate = (values, index, id) => {
        if (Object.values(values.education[index]).every((value) => value === '' || value === null)) return

        const data = {
            level: values.education[index].level,
            startDate: values.education[index].start_date,
            endDate: values.education[index].end_date,
            institution: values.education[index].institution,
            additional: values.education[index].additional,
        }

        const existingData = info.education.find((edu) => edu.id === id)

        const hasChanges = (
            data.level !== existingData.level
            || data.startDate !== existingData.startDate
            || data.endDate !== existingData.endDate
            || data.institution !== existingData.institution
            || data.additional !== existingData.additional
        )

        if (!hasChanges) {
            setShowModal({ act: false, type: '' })
            return
        }

        axios.put(
            domain + CANDIDATE_EDUCATION_UPDATE.replace('{id}', id), data, auth(),
        ).then((res) => {
            if (res.data.success) {
                getInfo.setResponse((prevState) => ({
                    ...getInfo.response,
                    data: {
                        ...prevState.data,
                        education: info.education.map((edu) => (edu.id === id ? { ...edu, ...data } : edu)),
                    },
                }))
                setShowModal({ act: false, type: '' })
                showMessage(<FormattedMessage id="successFullyUpdated" />, 'success-msg')
            }
        }).catch(({ response }) => showMessage(response.data.errors[0].message || response.data.message, 'error-msg'))
    }

    const onDelete = async (id) => {
        axios.delete(domain + CANDIDATE_EDUCATION_DELETE.replace('{id}', id), auth())
            .then(({ data }) => {
                if (data.success) {
                    getInfo.setResponse((prevState) => ({
                        ...getInfo.response,
                        data: {
                            ...prevState.data,
                            education: info.education.filter((edu) => edu.id !== id),
                        },
                    }))
                    showMessage(<FormattedMessage id="successFullyDeleted" />, 'success-msg')
                }
            }).catch(({ response }) => {
                showMessage(response.data.message, 'error-msg')
            })
    }

    return !getInfo.loading ? (
        <div className={css(mode(theme).boxWrap)}>
            <div className={css(s.boxHeader)}>
                <div className="is-flex align-center gap-3">
                    <BachelorHatSizeL color={theme === 'dark' ? COLORS.white : COLORS.dark} />

                    <h3 className={css(mode(theme).headTitle)}><FormattedMessage id="education" /></h3>
                </div>

                <div className={css(s.toolWrap)}>
                    <AmazingTooltip
                        position="right"
                        className={css(s.tooltip)}
                        trigger={(
                            <div onClick={() => setShowModal({
                                act: true,
                                type: 'add',
                            })} className={css(mode(theme).plusIcon)}>
                                <PlusRoundedSizeL />
                            </div>
                        )}>
                        <h3 style={{ whiteSpace: 'nowrap' }}><FormattedMessage id="addEducation" /></h3>
                    </AmazingTooltip>
                </div>

                <div className={css(s.mobileIcon)}>
                    <div
                        onClick={() => setShowModal({
                            act: true,
                            type: 'add',
                        })}
                        className={css(mode(theme).plusIcon)}
                    >
                        <PlusRoundedSizeL />
                    </div>
                </div>
            </div>

            {info.education && info.education.length ? (
                <div className={css(mode(theme).educationsWrap)}>
                    {info.education.map((edu, ind) => (
                        <div key={uuid()} className="is-flex align-center justify-between">
                            <div className={cn('is-flex align-center gap-7', css(s.right))}>
                                <img className={css(s.logo)} src={educationLogo} alt="#EducatinLogo" />

                                <div className="is-flex direction-column gap-2">
                                    <h3 className={css(mode(theme).institution)}>{edu.institution}</h3>

                                    <h3 className={css(mode(theme).educationLvl)}>
                                        {educationLvl[edu.level - 1] && educationLvl[edu.level - 1].label}
                                    </h3>

                                    <h3 className={css(mode(theme).additional)}>{edu.additional}</h3>
                                </div>
                            </div>

                            <div className={cn('is-flex align-center gap-7', css(s.left))}>
                                {edu.startDate && (
                                    <div className={css(mode(theme).periods)}>
                                        <span>{edu.startDate && edu.startDate.replace(/-/gi, '/')}</span>

                                        <span>-</span>

                                        <span>
                                            {edu.endDate
                                                ? edu.endDate.replace(/-/gi, '/')
                                                : <FormattedMessage id="untilToday" />}
                                        </span>
                                    </div>
                                )}

                                <Popup
                                    ref={popUpRef}
                                    items={options}
                                    activeTriggerClassName={css(mode(theme).activeTrigger)}
                                    trigger={(
                                        <div className={css(mode(theme).editTrigger)}>
                                            <ThreeDotsMenuHorizontalSizeL />
                                        </div>
                                    )}
                                    renderItem={({
                                        value,
                                        type,
                                        Icon,
                                        onClick,
                                        navigate,
                                    }, setShowPopUp) => (
                                        <li
                                            key={uuid()}
                                            className={cn('justify-between', css(s.cardSetting))}
                                            onClick={
                                                type === 'delete'
                                                    ? () => onClick(onDelete, edu.id, setShowPopUp(false))
                                                    // eslint-disable-next-line max-len
                                                    : () => onClick(setShowModal, true, 'update', ind, edu.id, setShowPopUp(false))
                                            }
                                        >
                                            <div className="align-center" style={{ gap: 8 }}>
                                                <Icon /> {value}
                                            </div>

                                            {navigate && <ChevronNext />}
                                        </li>
                                    )}
                                />
                            </div>
                        </div>
                    ))}
                </div>
            ) : null}

            <Modal
                closeOnEscape
                open={showModal.act}
                closeOnDocumentClick
                contentStyle={{ height: 'calc(100% - 24px)', zIndex: 10 }}
                overlayStyle={{ top: 52 }}
                onClose={() => setShowModal({ act: false, type: '' })}
            >
                <EducationModal
                    ind={showModal.ind}
                    theme={theme}
                    showModal={showModal}
                    loading={eduCreate.loading}
                    initialValues={showModal.type === 'update' ? initialValues : {}}
                    /* eslint-disable-next-line max-len */
                    onSubmit={showModal.type === 'update' ? onUpdate : onCreate}
                    onClose={() => setShowModal({ act: false, type: '' })} />
            </Modal>
        </div>
    ) : <Skeleton theme={theme} />
}

const s = StyleSheet.create({
    boxHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '14px 16px 14px 24px',
        '@media (max-width: 768px)': {
            padding: '10px 8px 10px 16px',
        },
    },
    mobileIcon: {
        '@media (min-width: 1100px)': {
            display: 'none',
        },
    },
    right: {
        '@media (max-width: 768px)': {
            gap: 12,
        },
    },
    left: {
        '@media (max-width: 768px)': {
            gap: 8,
        },
    },
    logo: {
        width: 40,
        height: 40,
    },
    cardSetting: {
        width: '10rem',
        userSelect: 'none',
    },
    toolWrap: {
        '@media (max-width: 1100px)': {
            display: 'none',
        },
    },
    tooltip: {
        zIndex: 0,
    },
    mobSkeleton: {
        '@media (max-width: 768px)': {
            ':nth-child(1n) > :only-child': {
                width: 80,
            },
        },
    },
})

const mode = (theme) => StyleSheet.create({
    boxWrap: {
        background: theme === 'dark' ? COLORS.darkModeBg : COLORS.white,
        border: theme === 'dark' ? 'none' : `1px solid ${COLORS.smoothGray}`,
        boxShadow: '0px 2px 4px rgba(130, 130, 130, 0.05)',
        borderRadius: 4,
    },
    headTitle: {
        fontWeight: '600',
        fontSize: 16,
        textTransform: 'uppercase',
        color: theme === 'dark' ? COLORS.white : COLORS.dark,
        '@media (max-width: 768px)': {
            fontSize: 14,
        },
    },
    plusIcon: {
        padding: '8px 8px 4px 8px',
        cursor: 'pointer',
        border: theme === 'dark' ? 'none' : `1px solid ${COLORS.white}`,
        ':hover': {
            border: theme === 'dark' ? 'none' : `1px solid ${COLORS.smoothGray}`,
            background: theme === 'dark' ? 'rgba(255, 255, 255, 0.10)' : 'inherit',
            boxShadow: '0px 2px 4px rgba(130, 130, 130, 0.05)',
            borderRadius: 4,
            ':nth-child(1n) > :only-child': {
                ':nth-child(1n) > path': {
                    stroke: theme === 'dark' ? COLORS.white : COLORS.gray,
                },
            },
        },
    },
    institution: {
        fontWeight: '700',
        fontSize: 18,
        color: theme === 'dark' ? COLORS.white : COLORS.dark,
        '@media (max-width: 768px)': {
            fontSize: 13,
        },
    },
    educationLvl: {
        fontWeight: '500',
        fontSize: 16,
        color: theme === 'dark' ? COLORS.gray : COLORS.lightBlack,
        '@media (max-width: 768px)': {
            fontSize: 12,
        },
    },
    additional: {
        fontSize: 13,
        fontStyle: 'italic',
        fontWeight: '400',
        color: theme === 'dark' ? COLORS.gray : COLORS.midGray,
    },
    periods: {
        fontStyle: 'italic',
        fontWeight: '700',
        fontSize: 16,
        display: 'flex',
        alignItems: 'center',
        gap: 3,
        color: theme === 'dark' ? COLORS.lightGray : COLORS.midGray,
        '@media (max-width: 768px)': {
            fontSize: 10,
        },
    },
    educationsWrap: {
        padding: '0 24px 24px 24px',
        ':nth-child(1n) > *': {
            borderBottom: `1px solid ${theme === 'dark' ? COLORS.midGray : '#EBEBEB'}`,
            padding: '24px 0 24px 0',
        },
        ':nth-child(1n) > :last-child': {
            borderBottom: 'none',
            paddingBottom: 0,
        },
        ':nth-child(1n) > :first-child': {
            paddingTop: 0,
        },
        '@media (max-width: 768px)': {
            padding: '0 16px 16px 16px',
        },
    },
    activeTrigger: {
        ':nth-child(1n) > :first-child': {
            ':nth-child(1n) > :first-child': {
                ':nth-child(1n) > path': {
                    stroke: theme === 'dark' ? COLORS.white : COLORS.dark,
                },
            },
        },
    },
    editTrigger: {
        ':nth-child(1n) > :first-child': {
            cursor: 'pointer',
            ':hover': {
                ':nth-child(1n) > path': {
                    stroke: theme === 'dark' ? COLORS.gray : COLORS.dark,
                },
            },
        },
    },
})
