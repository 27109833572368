import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import uuid from 'react-uuid'
import { ProtectedRoute } from './components/common/ProtectedRoute'
import { protectedRoutes, routes } from './utils/routes'
import BaseContextWrapper from './contexts/BaseContextWrapper'
import NotFound from './components/errors/NotFound'
import GlobalContext from './contexts/GlobalContext'
import { Layout } from './components/common/Layout'

export default function App() {
    return (
        <BrowserRouter>
            <BaseContextWrapper>
                <GlobalContext>
                    <Routes>
                        {protectedRoutes.map(({
                            path,
                            protectedBy,
                            redirectPath,
                            component: Component,
                        }) => (
                            <Route
                                key={uuid()}
                                path={path}
                                element={(
                                    <ProtectedRoute protectedBy={protectedBy} redirectPath={redirectPath}>
                                        <Component />
                                    </ProtectedRoute>
                                )} />
                        ))}

                        {routes.map(({ path, component: Component }) => (
                            <Route key={uuid()} path={path} element={<Component />} />
                        ))}

                        <Route path="*" element={<Layout><NotFound /></Layout>} />
                    </Routes>
                </GlobalContext>
            </BaseContextWrapper>
        </BrowserRouter>
    )
}
