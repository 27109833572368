import React, { useRef, useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import axios from 'axios'
import uuid from 'react-uuid'
import { FormattedMessage } from 'react-intl'
import { COLORS } from '../../utils/colors'
import { ChevronNext, LanguageSizeL,
    PlusRoundedSizeL,
    ThreeDotsMenuHorizontalSizeL } from '../svgs/Svgs'
import { Popup } from '../Popup'
import Modal from '../common/Modal'
import LanguageModal from './LanguageModal'
import { AvailableLanguages } from '../../utils/options'
import { useMessage } from '../../hooks/message'
import AmazingTooltip from '../common/AmazingTooltip'
import { usePostRequest } from '../../hooks/request'
import { CANDIDATE_LANGUAGE_CREATE, CANDIDATE_LANGUAGE_DELETE,
    CANDIDATE_LANGUAGE_UPDATE } from '../../urls'
import { domain } from '../../utils/request'
import { LoadingSkeleton } from '../common/LoadingSkeleton'
import { auth } from '../../utils/auth'

const Skeleton = ({ theme }) => (
    <div className={css(mode(theme).boxWrap)}>
        <div className={css(s.boxHeader)}>
            <div className="is-flex align-center gap-3">
                <LanguageSizeL />

                <h3 className={css(mode(theme).headTitle)}><FormattedMessage id="languagesKnowledge" /></h3>
            </div>
        </div>

        <div className={css(s.languagesWrap)}>
            <div className="is-flex align-center justify-between">
                <LoadingSkeleton width={200} height={21} />
            </div>
        </div>
    </div>
)

export default function LanguageInfo({
    info,
    getInfo,
    options,
    theme,
    lang,
}) {
    const popUpRef = useRef()
    const modalRef = useRef()
    const [showMessage] = useMessage()
    const [showModal, setShowModal] = useState({
        act: false,
        type: '',
    })

    const createLang = usePostRequest({ url: CANDIDATE_LANGUAGE_CREATE })
    // eslint-disable-next-line max-len

    const initialValues = {
        ...info.languages,
        languageLevel: info.languages,
    }

    const onCreate = async (values, ind) => {
        const required = Object.values(values.languageLevel[ind]).some((val) => val === '')
        if (required || createLang.loading) return

        const data = {
            lang: values.languageLevel[ind].lang,
            level: values.languageLevel[ind].level,
        }

        const { success, error } = await createLang.request({ data })

        if (success) {
            getInfo.request()
            setShowModal({ act: false, type: '' })
            showMessage(<FormattedMessage id="successFullyAdded" />, 'success-msg')
        } else {
            showMessage(error.data.errors[0].message || error.data.message, 'error-msg')
        }
    }
    const onUpdate = (values, index, id) => {
        if (Object.values(values.languageLevel[index]).every((value) => value === '' || value === null)) return

        const data = {
            lang: values.languageLevel[index].lang,
            level: values.languageLevel[index].level,
        }

        const existingData = info.languages.find((language) => language.id === id)

        const hasChanges = (
            data.lang !== existingData.lang || data.level !== existingData.level
        )

        if (!hasChanges) {
            setShowModal({ act: false, type: '' })
            return
        }

        axios.put(
            domain + CANDIDATE_LANGUAGE_UPDATE.replace('{id}', id), data, auth(),
        ).then((res) => {
            if (res.data.success) {
                getInfo.setResponse((prevState) => ({
                    ...getInfo.response,
                    data: {
                        ...prevState.data,
                        languages: info.languages.map((language) => (language.id === id
                            ? { ...language, ...data } : language)),
                    },
                }))
                setShowModal({ act: false, type: '' })
                showMessage(<FormattedMessage id="successFullyUpdated" />, 'success-msg')
            }
        }).catch(({ response }) => showMessage(response.data.errors[0].message || response.data.message, 'error-msg'))
    }

    const onDelete = (id) => {
        axios.delete(domain + CANDIDATE_LANGUAGE_DELETE.replace('{id}', id), auth())
            .then(({ data }) => {
                if (data.success) {
                    getInfo.setResponse((prevState) => ({
                        ...getInfo.response,
                        data: {
                            ...prevState.data,
                            languages: info.languages.filter((language) => language.id !== id),
                        },
                    }))
                    showMessage(<FormattedMessage id="successFullyDeleted" />, 'success-msg')
                }
            }).catch(({ response }) => {
                showMessage(response.data.message, 'error-msg')
            })
    }

    return !getInfo.loading ? (
        <div className={css(mode(theme).boxWrap)}>
            <div className={css(s.boxHeader)}>
                <div className="is-flex align-center gap-3">
                    <LanguageSizeL color={theme === 'dark' ? COLORS.white : COLORS.dark} />

                    <h3 className={css(mode(theme).headTitle)}><FormattedMessage id="languagesKnowledge" /></h3>
                </div>

                <div className={css(s.toolWrap)}>
                    <AmazingTooltip
                        className={css(s.tooltip)}
                        trigger={(
                            <div onClick={() => setShowModal({
                                act: true,
                                type: 'add',
                            })} className={css(mode(theme).plusIcon)}>
                                <PlusRoundedSizeL />
                            </div>
                        )}
                    >
                        <h3 style={{ whiteSpace: 'nowrap' }}><FormattedMessage id="addLanguage" /></h3>
                    </AmazingTooltip>
                </div>

                <div className={css(s.mobileIcon)}>
                    <div
                        onClick={() => setShowModal({
                            act: true,
                            type: 'add',
                        })}
                        className={css(mode(theme).plusIcon)}
                    >
                        <PlusRoundedSizeL />
                    </div>
                </div>
            </div>

            {info.languages && info.languages.length ? (
                <div className={css(s.languagesWrap)}>
                    {info.languages.map((language, ind) => (
                        <div key={uuid()} className="is-flex align-center justify-between">
                            <div className="is-flex align-center gap-2">
                                <h3 className={css(mode(theme).languageType)}>
                                    {AvailableLanguages.filter((e) => e.short === language.lang)[0][lang]}
                                </h3>

                                <span className={css(mode(theme).languageType)}>—</span>

                                <h3 className={css(mode(theme).languageType)}>{language.level}</h3>
                            </div>

                            <div className="is-flex align-center gap-7">
                                <Popup
                                    ref={popUpRef}
                                    items={options}
                                    activeTriggerClassName={css(mode(theme).activeTrigger)}
                                    trigger={(
                                        <div className={css(mode(theme).trigger)}>
                                            <ThreeDotsMenuHorizontalSizeL />
                                        </div>
                                    )}
                                    renderItem={({
                                        value,
                                        type,
                                        Icon,
                                        onClick,
                                        navigate,
                                    }, setShowPopUp) => (
                                        <li
                                            key={uuid()}
                                            className={cn('justify-between', css(s.cardSetting))}
                                            onClick={
                                                type === 'delete'
                                                    ? () => onClick(onDelete, language.id, setShowPopUp(false))
                                                    // eslint-disable-next-line max-len
                                                    : () => onClick(setShowModal, true, 'update', ind, language.id, setShowPopUp(false))
                                            }
                                        >
                                            <div className="align-center" style={{ gap: 8 }}>
                                                <Icon /> {value}
                                            </div>

                                            {navigate && <ChevronNext />}
                                        </li>
                                    )}
                                />
                            </div>
                        </div>
                    ))}
                </div>
            ) : null}

            <Modal
                closeOnEscape
                // closeOnDocumentClick
                open={showModal.act}
                contentStyle={{ height: 'calc(100% - 24px)', zIndex: 10 }}
                overlayStyle={{ top: 52 }}
                onClose={() => setShowModal({ act: false, type: '' })}
            >
                <LanguageModal
                    info={info}
                    num={showModal.ind}
                    modalRef={modalRef}
                    showModal={showModal}
                    loading={createLang.loading}
                    onClose={() => setShowModal({ act: false, type: '' })}
                    onSubmit={showModal.type === 'update' ? onUpdate : onCreate}
                    initialValues={showModal.type === 'update' ? initialValues : {}} />
            </Modal>
        </div>
    ) : <Skeleton theme={theme} />
}

const s = StyleSheet.create({
    boxHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingTop: 16,
        padding: '14px 16px 14px 24px',
        '@media (max-width: 768px)': {
            padding: '10px 8px 10px 16px',
        },
    },
    toolWrap: {
        '@media (max-width: 1100px)': {
            display: 'none',
        },
    },
    tooltip: {
        zIndex: 0,
    },
    mobileIcon: {
        '@media (min-width: 1100px)': {
            display: 'none',
        },
    },
    languagesWrap: {
        padding: '0 24px 24px 24px',
        ':nth-child(1n) > *': {
            paddingTop: 24,
        },
        ':nth-child(1n) > :first-child': {
            paddingTop: 0,
        },
        '@media (max-width: 768px)': {
            padding: '0 16px 16px 16px',
        },
    },
    logo: {
        width: 40,
        height: 40,
    },
    activeTrigger: {
        ':nth-child(1n) > :first-child': {
            ':nth-child(1n) > :first-child': {
                ':nth-child(1n) > path': {
                    stroke: COLORS.dark,
                },
            },
        },
    },
    trigger: {
        ':nth-child(1n) > :first-child': {
            cursor: 'pointer',
            ':hover': {
                ':nth-child(1n) > path': {
                    stroke: COLORS.dark,
                },
            },
        },
    },
    cardSetting: {
        width: '10rem',
        userSelect: 'none',
    },
    popUpWrap: {
        borderRadius: 4,
        margin: 0,
        ':nth-child(1n) > *': {
            color: '#fff',
            fontSize: 12,
            background: 'rgba(65, 70, 68, 0.9)',
            backdropFilter: 'blur(8px)',
            fontWeight: '500',
            padding: '10px 12px',
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            borderBottom: '1px solid #5e6360',
            ':hover': {
                background: 'rgba(87, 90, 89, 0.9)',
            },
            ':nth-child(1n) > :first-child': {
                marginRight: 8,
            },
        },
        ':nth-child(1n) > :last-child': {
            borderBottomWidth: 0,
            borderBottomLeftRadius: 4,
            borderBottomRightRadius: 4,
        },
        ':nth-child(1n) > :first-child': {
            borderTopLeftRadius: 4,
            borderTopRightRadius: 4,
        },
    },
})

const mode = (theme) => StyleSheet.create({
    boxWrap: {
        background: theme === 'dark' ? COLORS.darkModeBg : COLORS.white,
        border: theme === 'dark' ? 'none' : `1px solid ${COLORS.smoothGray}`,
        boxShadow: '0px 2px 4px rgba(130, 130, 130, 0.05)',
        borderRadius: 4,
    },
    headTitle: {
        fontWeight: '600',
        fontSize: 16,
        textTransform: 'uppercase',
        color: theme === 'dark' ? COLORS.white : COLORS.dark,
        '@media (max-width: 768px)': {
            fontSize: 14,
        },
    },
    plusIcon: {
        padding: '8px 8px 4px 8px',
        cursor: 'pointer',
        border: theme === 'dark' ? 'none' : `1px solid ${COLORS.white}`,
        ':hover': {
            border: theme === 'dark' ? 'none' : `1px solid ${COLORS.smoothGray}`,
            background: theme === 'dark' ? 'rgba(255, 255, 255, 0.10)' : 'inherit',
            boxShadow: '0px 2px 4px rgba(130, 130, 130, 0.05)',
            borderRadius: 4,
            ':nth-child(1n) > :only-child': {
                ':nth-child(1n) > path': {
                    stroke: theme === 'dark' ? COLORS.white : COLORS.gray,
                },
            },
        },
    },
    languageType: {
        fontWeight: '700',
        fontSize: 18,
        color: theme === 'dark' ? COLORS.white : COLORS.dark,
    },
    activeTrigger: {
        ':nth-child(1n) > :first-child': {
            ':nth-child(1n) > :first-child': {
                ':nth-child(1n) > path': {
                    stroke: theme === 'dark' ? COLORS.white : COLORS.dark,
                },
            },
        },
    },
    trigger: {
        ':nth-child(1n) > :first-child': {
            cursor: 'pointer',
            ':hover': {
                ':nth-child(1n) > path': {
                    stroke: theme === 'dark' ? COLORS.gray : COLORS.dark,
                },
            },
        },
    },
})
