import React, { useContext } from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { FormattedMessage } from 'react-intl'
import { COLORS } from '../../utils/colors'
import { FileSymlinkSizeL, LightingSizeL, MagnifierSizeL, RocketSizeL } from '../svgs/Svgs'
import { BaseContext } from '../../contexts/BaseContextWrapper'

export default function HowItWorks() {
    const { theme } = useContext(BaseContext)
    const items = [
        {
            id: 1,
            Icon: MagnifierSizeL,
            title: <FormattedMessage id="findSuitableVacancy" />,
            subtitle: <FormattedMessage id="findSuitableSubText" />,
        },
        {
            id: 2,
            Icon: FileSymlinkSizeL,
            title: <FormattedMessage id="applyForIt" />,
            subtitle: <FormattedMessage id="applyForSubText" />,
        },
        {
            id: 3,
            Icon: LightingSizeL,
            title: <FormattedMessage id="watchProcess" />,
            subtitle: <FormattedMessage id="watchProcessSubText" />,
        },
        {
            id: 4,
            Icon: RocketSizeL,
            title: <FormattedMessage id="workInDreamComp" />,
            subtitle: <FormattedMessage id="workInDreamCompSubText" />,
        },
    ]

    return (
        <div className={cn(css(s.cont), 'is-flex direction-column')}>
            <div className={cn(css(mode(theme).contHeader), 'is-flex align-center justify-start')}>
                <h3>🤔 <FormattedMessage id="howItWorks" /></h3>
            </div>

            <div className={cn(css(s.itemsWrap))}>
                {items.map((item) => (
                    <div key={item.id} className={cn(css(s.cardWrap), 'is-flex direction-column gap-5')}>
                        <div className="is-flex align-center gap-5">
                            <div className={css(s.iconsWrap)}>
                                <div className={css(s.backLayer)} />

                                <div className={cn(css(s.icon), 'is-flex align-center justify-center')}>
                                    <item.Icon />
                                </div>
                            </div>

                            <div className={css(s.line)} />
                        </div>

                        <div className={cn(css(mode(theme).infoWrap), 'is-flex direction-column gap-2')}>
                            <h3>{item.title}</h3>

                            <p>{item.subtitle}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

const s = StyleSheet.create({
    cont: {
        width: 'inherit',
        gap: 18,
        '@media (max-width: 1024px)': {
            gap: 13,
        },
    },
    itemsWrap: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        gap: 24,
        overflowX: 'scroll',
        '@media (max-width: 1024px)': {
            gap: 12,
        },
        ':nth-child(1n) > :first-child': {
            marginLeft: 32,
            '@media (max-width: 1024px)': {
                marginLeft: 16,
            },
        },
        scrollbarWidth: 'none', // Firefox
        '-ms-overflow-style': 'none', // Internet Explorer
        '::-webkit-scrollbar': {
            display: 'none', // Safari and Chrome
        },
    },
    cardWrap: {
        padding: 12,
        minWidth: 326,
    },
    iconsWrap: {
        width: 40,
        height: 40,
        position: 'relative',
    },
    backLayer: {
        width: 40,
        height: 40,
        borderRadius: 4,
        transform: 'rotate(10deg)',
        background: `linear-gradient(180deg, ${COLORS.mainColor} 0%, ${COLORS.lightMainColor} 100%)`,
    },
    icon: {
        width: 40,
        height: 40,
        borderRadius: 4,
        position: 'absolute',
        top: 5,
        right: 10,
        transform: 'rotate(10deg)',
        background: 'rgba(193, 229, 216, 0.50)',
        backdropFilter: 'blur(6px)',
        ':nth-child(1n) > :only-child': {
            ':nth-child(1n) > path': {
                stroke: COLORS.white,
            },
        },
    },
    line: {
        height: 1,
        flex: 1,
        background: COLORS.lightGray,
    },
})

const mode = (theme) => StyleSheet.create({
    contHeader: {
        padding: '0 32px',
        ':nth-child(1n) > :first-child': {
            color: theme === 'dark' ? COLORS.white : COLORS.lightBlack,
            fontSize: 24,
            fontWeight: '600',
            '@media (max-width: 1024px)': {
                fontSize: 16,
            },
        },
        '@media (max-width: 1024px)': {
            padding: '0 16px',
        },
    },
    infoWrap: {
        ':nth-child(1n) > :first-child': {
            color: theme === 'dark' ? COLORS.white : COLORS.dark,
            fontSize: 18,
            fontWeight: '600',
        },
        ':nth-child(1n) > :last-child': {
            color: theme === 'dark' ? COLORS.gray : COLORS.midGray,
            fontSize: 14,
            fontWeight: '500',
        },
    },
})
