import React, { useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import { FormattedMessage } from 'react-intl'
import Modal from '../common/Modal'
import { EditSizeL, RocketSizeL } from '../svgs/Svgs'
import { COLORS } from '../../utils/colors'
import SkillsModal from './SkillsModal'
import AmazingTooltip from '../common/AmazingTooltip'
import { LoadingSkeleton } from '../common/LoadingSkeleton'

const Skeleton = ({ theme }) => (
    <div className={css(mode(theme).boxWrap)}>
        <div className={css(s.boxHeader)}>
            <div className="is-flex align-center gap-3">
                <RocketSizeL />

                <h3 className={css(mode(theme).headTitle)}><FormattedMessage id="proSkills" /></h3>
            </div>

            <div className={css(s.toolWrap)}>
                <div className={css(s.editIcon)}>
                    <EditSizeL />
                </div>
            </div>
        </div>

        <div className={css(s.boxBody)}>
            <div className="direction-column is-flex gap-3">
                <h3 className={css(s.category)}><FormattedMessage id="skills" /></h3>

                <div className="is-flex align-center gap-5 is-flex-wrap">
                    <LoadingSkeleton width={146} height={31} />
                </div>
            </div>
        </div>
    </div>
)

export default function SkillsInfo({ info, putInfo, getInfo, theme }) {
    const [showModal, setShowModal] = useState(false)

    return !getInfo.loading ? (
        <div className={css(mode(theme).boxWrap)}>
            <div className={css(s.boxHeader)}>
                <div className="is-flex align-center gap-3">
                    <RocketSizeL color={theme === 'dark' ? COLORS.white : COLORS.dark} />

                    <h3 className={css(mode(theme).headTitle)}><FormattedMessage id="proSkills" /></h3>
                </div>

                <div className={css(s.toolWrap)}>
                    <AmazingTooltip
                        className={css(s.tooltip)}
                        trigger={(
                            <Modal
                                trigger={(
                                    <div className={css(mode(theme).editIcon)}>
                                        <EditSizeL />
                                    </div>
                                )}
                                open={showModal}
                                closeOnEscape
                                closeOnDocumentClick
                                contentStyle={{ height: 'calc(100% - 24px)', zIndex: 10 }}
                                overlayStyle={{ top: 52 }}
                                onClose={() => setShowModal(false)}
                                onOpen={() => setShowModal(true)}
                            >
                                <SkillsModal
                                    info={info}
                                    putInfo={putInfo}
                                    getInfo={getInfo}
                                    showModal={showModal}
                                    onClose={() => setShowModal(false)}
                                />
                            </Modal>
                        )}
                    >
                        <h3><FormattedMessage id="edit" /></h3>
                    </AmazingTooltip>
                </div>

                <div className={css(s.mobileIcon)}>
                    <div
                        className={css(mode(theme).editIcon)}
                        onClick={() => setShowModal(true)}
                    >
                        <EditSizeL />
                    </div>
                </div>
            </div>

            {info.skills && info.skills.length ? (
                <div className={css(s.boxBody)}>
                    <div className="direction-column is-flex gap-3">
                        <h3 className={css(mode(theme).category)}><FormattedMessage id="skills" /></h3>

                        <div className="is-flex align-center gap-5 is-flex-wrap">
                            {info.skills && info.skills.map((skill) => (
                                <h3 key={skill.value} className={css(mode(theme).skill)}>{skill.label}</h3>
                            ))}
                        </div>
                    </div>
                </div>
            ) : null}
        </div>
    ) : <Skeleton />
}

const s = StyleSheet.create({
    boxHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '14px 16px 14px 24px',
        '@media (max-width: 768px)': {
            padding: '10px 8px 10px 16px',
        },
    },
    mobileIcon: {
        '@media (min-width: 1100px)': {
            display: 'none',
        },
    },
    tooltip: {
        zIndex: 0,
    },
    boxBody: {
        padding: '0 24px 24px 24px',
        display: 'flex',
        flexDirection: 'column',
        gap: 12,
        '@media (max-width: 768px)': {
            padding: '0 16px 16px 16px',
        },
    },
    toolWrap: {
        '@media (max-width: 1100px)': {
            display: 'none',
        },
    },
})

const mode = (theme) => StyleSheet.create({
    boxWrap: {
        background: theme === 'dark' ? COLORS.darkModeBg : COLORS.white,
        border: theme === 'dark' ? 'none' : `1px solid ${COLORS.smoothGray}`,
        boxShadow: '0px 2px 4px rgba(130, 130, 130, 0.05)',
        borderRadius: 4,
    },
    headTitle: {
        fontWeight: '600',
        fontSize: 16,
        textTransform: 'uppercase',
        color: theme === 'dark' ? COLORS.white : COLORS.dark,
        '@media (max-width: 768px)': {
            fontSize: 14,
        },
    },
    editIcon: {
        padding: '8px 8px 4px 8px',
        cursor: 'pointer',
        border: theme === 'dark' ? 'none' : `1px solid ${COLORS.white}`,
        ':hover': {
            border: theme === 'dark' ? 'none' : `1px solid ${COLORS.smoothGray}`,
            background: theme === 'dark' ? 'rgba(255, 255, 255, 0.10)' : 'inherit',
            boxShadow: '0px 2px 4px rgba(130, 130, 130, 0.05)',
            borderRadius: 4,
            ':nth-child(1n) > :only-child': {
                ':nth-child(1n) > path': {
                    stroke: theme === 'dark' ? COLORS.white : COLORS.gray,
                },
            },
        },
    },
    category: {
        fontWeight: '700',
        fontSize: 18,
        color: theme === 'dark' ? COLORS.white : COLORS.dark,
    },
    skill: {
        background: theme === 'dark' ? 'rgba(46, 169, 125, 0.03)' : '#E7FBF2',
        border: theme === 'dark' ? '1px solid rgba(46, 169, 125, 0.20)' : 'none',
        borderRadius: 4,
        padding: 8,
        color: COLORS.mainColor,
        fontWeight: '500',
        fontSize: 13,
    },
})
