import { useState, useRef } from 'react'

export default function useVerification() {
    const [digits, setDigits] = useState(['', '', '', ''])
    const inputRefs = [useRef(), useRef(), useRef(), useRef()]
    const [focusedInputIndex, setFocusedInputIndex] = useState(0)

    const handleChange = (e, index) => {
        const enteredValue = e.target.value

        if (/^\d*$/.test(enteredValue)) {
            const newDigits = [...digits]
            newDigits[index] = enteredValue
            setDigits(newDigits)

            if (enteredValue && index < 3) {
                inputRefs[index + 1].current.focus()
                setFocusedInputIndex(index + 1)
            }
        }
    }

    const handlePaste = (e, index) => {
        e.preventDefault()

        const pastedText = e.clipboardData.getData('text/plain')

        if (/^\d{1,4}$/.test(pastedText)) {
            const newDigits = [...digits]
            const pastedDigits = pastedText.split('').slice(0, 4)

            for (let i = 0; i < pastedDigits.length && index + i < 4; i++) {
                newDigits[index + i] = pastedDigits[i]
            }

            setDigits(newDigits)

            for (let i = index; i < 4; i++) {
                if (!newDigits[i] && inputRefs[i].current) {
                    inputRefs[i].current.focus()
                    setFocusedInputIndex(i)
                    break
                }
            }
        }
    }

    const getCode = () => Number(digits.join(''))

    return {
        digits,
        handleChange,
        handlePaste,
        inputRefs,
        focusedInputIndex,
        setDigits,
        getCode,
    }
}
