import cn from 'classnames'
import { css, StyleSheet } from 'aphrodite'
import React, { useEffect, useRef } from 'react'
import { useIntl } from 'react-intl'
import { InputFromTo } from '../../common/InputFromTo'
import { COLORS } from '../../../utils/colors'

export default function FilterBySalary({ name, setFilter, filter, theme }) {
    const innerRef = useRef()
    const intl = useIntl()

    const onChange = (event, type) => {
        const value = event.target.value.replace(/\s/g, '')
        if (!value) {
            const {
                [name]: sectionFilter,
                ...clearedFilter
            } = filter
            setFilter(clearedFilter)
            return
        }
        if (type === 'from') {
            setFilter({ ...filter, [name]: `${value}:${filter[name] ? filter[name].split(':')[1] : ''}` })
        } else if (type === 'to') {
            setFilter({ ...filter, [name]: `${filter[name] ? filter[name].split(':')[0] : ''}:${value}` })
        }
    }

    useEffect(() => {
        const fromInput = document.getElementsByName(`${name}.from`)[0]
        const toInput = document.getElementsByName(`${name}.to`)[0]
        if (!filter[name]) innerRef.current.clearValue()
        else if (document.activeElement !== fromInput && document.activeElement !== toInput) {
            const value = filter[name].split(':')

            // eslint-disable-next-line prefer-destructuring
            fromInput.value = value[0]
            // eslint-disable-next-line prefer-destructuring
            toInput.value = value[1]
        }
    }, [filter[name]])

    return (
        <InputFromTo
            ref={innerRef}
            name={name}
            defaultValue={filter.salary ? [filter.salary.split(':')[0], filter.salary.split(':')[1]] : ['', '']}
            placeholder={[intl.formatMessage({ id: 'from' }), intl.formatMessage({ id: 'to' })]}
            className={cn(css(mode(theme).rangeInput))}
            onChange={onChange}
        />
    )
}

const mode = (theme) => StyleSheet.create({
    rangeInput: {
        height: 34,
        padding: '8px 16px',
        boxSizing: 'border-box',
        display: 'flex',
        alignItems: 'center',
        background: theme === 'dark' ? COLORS.darkModeBg : COLORS.white,
        ':nth-child(1n) > :first-child': {
            ':nth-child(1n) > *': {
                display: 'flex',
                alignItems: 'center',
                fontColor: theme === 'dark' ? COLORS.white : COLORS.gray,
                ':nth-child(1n) > h3': {
                    fontSize: 11,
                },
            },
            ':nth-child(1n) > :first-child': {
                ':nth-child(1n) > input': {
                    background: 'inherit',
                    color: theme === 'dark' ? COLORS.white : COLORS.dark,
                },
            },
            ':nth-child(1n) > :last-child': {
                ':nth-child(1n) > input': {
                    background: 'inherit',
                    color: theme === 'dark' ? COLORS.white : COLORS.dark,
                },
            },
            ':nth-child(1n) > h3': {
                color: theme === 'dark' ? COLORS.white : COLORS.gray,
            },
        },
    },
})
