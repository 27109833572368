import React, { useContext, useLayoutEffect, useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { useLocation, useNavigate } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { Oval } from 'react-loader-spinner'
import { COLORS } from '../../utils/colors'
import { ChevronPrev } from '../svgs/Svgs'
import { BaseContext } from '../../contexts/BaseContextWrapper'
import { Layout } from '../common/Layout'
import { getFullDate } from '../../utils/date'
import { usePostRequest } from '../../hooks/request'
import { CANDIDATE_AGREEMENT_MEETUP } from '../../urls'
import { useMessage } from '../../hooks/message'
import Button from '../common/Button'

export default function MeetUpInfo() {
    const navigate = useNavigate()
    const { state } = useLocation()
    const [showMessage] = useMessage()
    const { theme, lang } = useContext(BaseContext)
    const [loading, setLoading] = useState({ acc: false, rej: false })
    const sendAgreement = usePostRequest({ url: CANDIDATE_AGREEMENT_MEETUP })
    const meetTime = state.item.meetup.time && state.item.meetup.time.substring(0, 5)

    const answers = [
        { value: 2, label: <FormattedMessage id="youAssignedMeetUp" /> },
        { value: 3, label: <FormattedMessage id="youRejectedMeetUp" /> },
    ]
    const hasAnswered = answers.find((ans) => ans.value === state.item.meetup.status)

    const response = async (vId, res) => {
        if (hasAnswered || loading.acc || loading.rej) return

        setLoading({ acc: !!res, rej: !res })
        try {
            const { success, error } = await sendAgreement.request({
                data: { vacancy_id: vId, accept: res },
            })

            if (success) {
                setLoading({ acc: false, rej: false })
                showMessage(res ? answers[0].label : answers[1].label, 'success-msg')
                navigate(-1)
            } else if (error) {
                setLoading({ acc: false, rej: false })
                showMessage(error.data.errors[0].message || error.data.message, 'error-msg')
            }
        } catch (e) {
            console.log(e)
        }
    }

    const options = [
        { value: 1, uz: 'Onlayn', en: 'Online', ru: 'Онлайн' },
        { value: 2, uz: 'Oflayn', en: 'Offline', ru: 'Оффлайн' },
    ]

    useLayoutEffect(() => {
        if (state === null) navigate(-1)
    }, [])

    return (
        <Layout>
            <div className={css(mode(theme).wrapper)}>
                <div className={css(mode(theme).contHeader)}>
                    <div onClick={() => navigate(-1)} className={css(s.chevronWrap)}>
                        <ChevronPrev color={theme === 'dark' ? COLORS.white : COLORS.dark} />
                    </div>

                    <h3>
                        {state.st} {state && state.item.company.name}
                    </h3>
                </div>

                <div className={cn(css(mode(theme).contBody), 'is-flex direction-column gap-7')}>
                    <div className={cn(css(s.statusTxt), 'is-flex direction-column gap-5')}>
                        <h3><FormattedMessage id="congrats" /> 🎉</h3>

                        <div className="is-flex align-center is-flex-wrap gap-1 full-width">
                            <FormattedMessage id="company" />

                            <h3>{state.item.company.name}</h3>

                            <FormattedMessage id="invitesForMeetUp" />

                            <h3>{state.item.vacancy.name}</h3>
                        </div>
                    </div>

                    <div>
                        <FormattedMessage id="meetUpDetails" />

                        {state.item.meetup.date && (
                            <div className="is-flex align-center gap-1">
                                <FormattedMessage id="meetUpDate" />

                                <span>—</span>

                                {getFullDate(state.item.meetup.date, 'DD MMMM YYYY', lang)}
                            </div>
                        )}

                        {meetTime && <p><FormattedMessage id="meetUpTime" /> — {meetTime}</p>}

                        {state.item.meetup.format && (
                            <div className="is-flex align-center gap-1">
                                <FormattedMessage id="meetUpFormat" />

                                <span>—</span>

                                {options[state.item.meetup.format - 1][lang]}
                            </div>
                        )}

                        {state.item.meetup.location && (
                            <div className="is-flex align-center gap-1">
                                <FormattedMessage id="meetUpLocation" />

                                <span>—</span>

                                {state.item.meetup.location.startsWith('http') ? (
                                    <a className={css(s.meetLink)} href={state.item.meetup.location}>
                                        {state.item.meetup.location}
                                    </a>
                                ) : state.item.meetup.location}
                            </div>
                        )}

                        {/* {state.item.meetup.otherParticipants && ( */}
                        {/*     <p>Место встречи */}
                        {/*         — {state.item.meetup.otherParticipants} */}
                        {/*     </p> */}
                        {/* )} */}
                    </div>
                    {state.item.meetup.description ? (
                        <div>
                            <h3><FormattedMessage id="messageFromCompany" /></h3>
                            {state.item.meetup.description && <p>{state.item.meetup.description}</p>}
                        </div>
                    ) : null}

                    {(state.item.meetup.phone || state.item.meetup.email) && (
                        <div>
                            <FormattedMessage id="contactInformation" />

                            <div className={css(s.offersList)}>
                                <ul>
                                    {state.item.meetup.phone
                                        && <li>{state.item.meetup.phone}</li>}

                                    {state.item.meetup.email
                                        && <li>{state.item.meetup.email}</li>}
                                </ul>
                            </div>
                        </div>
                    )}
                </div>

                <div className={cn(css(mode(theme).contFooter), 'is-flex direction-column gap-3')}>
                    <h3><FormattedMessage id="willYouComeToMeetUp" /></h3>

                    <div className="is-flex align-center gap-5">
                        <Button
                            className={cn(css(s.rejectBtn),
                                { [css(s.disabled)]: hasAnswered }, 'is-flex align-center justify-center gap-2')}
                            onClick={() => response(state.item.vacancy.id, false)}>
                            {hasAnswered
                                ? (
                                    <div className="full-width is-flex align-center justify-center gap-2">
                                        {answers.find((ans) => ans.value === state.item.meetup.status).label}
                                    </div>
                                ) : !loading.rej
                                    ? <FormattedMessage id="noWontCome" />
                                    : <FormattedMessage id="sending" />}

                            {loading.rej ? (
                                <Oval
                                    height={16}
                                    width={16}
                                    color={COLORS.lightMainColor}
                                    visible
                                    ariaLabel="oval-loading"
                                    secondaryColor={COLORS.mainColor}
                                    strokeWidth={7}
                                    strokeWidthSecondary={7}
                                />
                            ) : null}
                        </Button>

                        {!hasAnswered && (
                            <Button className={cn(css(s.agreeBtn), 'is-flex align-center justify-center gap-2')}
                                onClick={() => response(state.item.vacancy.id, true)}>
                                {!loading.acc
                                    ? <FormattedMessage id="yesWillCome" />
                                    : <FormattedMessage id="sending" />}

                                {loading.acc ? (
                                    <Oval
                                        height={16}
                                        width={16}
                                        color={COLORS.white}
                                        visible
                                        ariaLabel="oval-loading"
                                        secondaryColor={COLORS.white}
                                        strokeWidth={7}
                                        strokeWidthSecondary={7}
                                    />
                                ) : null}
                            </Button>
                        )}
                    </div>
                </div>
            </div>
        </Layout>
    )
}

const s = StyleSheet.create({
    statusTxt: {
        color: COLORS.gray,
        fontSize: 14,
        fontStyle: 'italic',
        fontWeight: '500',
    },
    chevronWrap: {
        ':nth-child(1n) > :first-child': {
            cursor: 'pointer',
            ':nth-child(1n) > path': {
                strokeWidth: 3,
            },
        },
    },
    offersList: {
        paddingLeft: 24,
    },
    meetLink: {
        color: COLORS.skyblue,
    },
    rejectBtn: {
        flex: 1,
        color: COLORS.gray,
        border: `1px solid ${COLORS.gray}`,
        background: 'transparent',
        padding: '0 16px',
        boxShadow: '0px 2px 4px rgba(130, 130, 130, 0.05)',
        borderRadius: '4px',
        cursor: 'pointer',
        height: 40,
        ':hover': {
            background: COLORS.smoothGray,
        },
    },
    agreeBtn: {
        flex: 1,
        fontSize: 14,
        fontWeight: '500',
        color: COLORS.white,
        border: 'none',
        padding: '0 16px',
        background: `linear-gradient(180deg, ${COLORS.mainColor} 0%, ${COLORS.lightMainColor} 100%)`,
        // eslint-disable-next-line max-len
        boxShadow: '0px 4px 12px rgba(44, 67, 50, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.25), inset 0px 1px 0px rgba(255, 255, 255, 0.1)',
        borderRadius: '4px',
        cursor: 'pointer',
        height: 40,
        ':hover': {
            background: `linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
            linear-gradient(180deg, ${COLORS.mainColor} 0%, ${COLORS.lightMainColor} 100%)`,
            boxShadow: `0px 4px 12px rgba(44, 67, 50, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.25), '
            inset 0px 1px 0px rgba(255, 255, 255, 0.1)`,
        },
    },
    disabled: {
        cursor: 'not-allowed',
    },
})

const mode = (theme) => StyleSheet.create({
    wrapper: {
        height: 'calc(100% - 58px)',
        background: theme === 'dark' ? COLORS.darkModeBody : COLORS.white,
    },
    contHeader: {
        padding: '20px 24px',
        display: 'flex',
        alignItems: 'center',
        gap: 16,
        justifyContent: 'left',
        borderBottom: `1px solid ${theme === 'dark' ? COLORS.lightBlack : COLORS.smoothGray}`,
        background: theme === 'dark' ? COLORS.itemDarkModeBg : COLORS.white,
        ':nth-child(1n) > h3': {
            fontWeight: '700',
            fontSize: 16,
            color: theme === 'dark' ? COLORS.smoothGray : COLORS.dark,
        },
        ':nth-child(1n) > :last-child': {
            cursor: 'pointer',
        },
        '@media (max-width: 768px)': {
            padding: '14px 16px',
        },
    },
    contBody: {
        height: 'calc(100% - 153px)',
        marginTop: 16,
        padding: 24,
        borderTop: `1px solid ${theme === 'dark' ? COLORS.lightBlack : COLORS.smoothGray}`,
        borderBottom: `1px solid ${theme === 'dark' ? COLORS.lightBlack : COLORS.smoothGray}`,
        background: theme === 'dark' ? COLORS.darkModeBg : COLORS.white,
        boxShadow: '0px 2px 4px 0px rgba(130, 130, 130, 0.05)',
        '@media (max-width: 768px)': {
            padding: 16,
        },
        color: theme === 'dark' ? COLORS.smoothGray : COLORS.dark,
        fontSize: 14,
        fontWeight: '400',
    },
    contFooter: {
        background: theme === 'dark' ? COLORS.itemDarkModeBg : COLORS.white,
        borderTop: `1px solid ${theme === 'dark' ? COLORS.lightBlack : COLORS.lightGray}`,
        borderRadius: '0px 0px 4px 4px',
        padding: 16,
        ':nth-child(1n) > h3': {
            color: theme === 'dark' ? COLORS.white : COLORS.dark,
            fontSize: 16,
            fontWeight: 600,
        },
    },
})
