/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './Router'
import 'moment/locale/ru'
import './static/css/styles.css'
import 'react-loading-skeleton/dist/skeleton.css'
import './static/css/calendar.css'
import 'react-day-picker/dist/style.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const app = ReactDOM.createRoot(document.getElementById('root'))
app.render(<App />)
