import React, { useContext } from 'react'
import cn from 'classnames'
import { Field, useFormikContext } from 'formik'
import { css, StyleSheet } from 'aphrodite'
import { FormattedMessage } from 'react-intl'
import { Oval } from 'react-loader-spinner'
import ValidationErrorMessage from './ValidationErrorMessage'
import { formatPhone } from '../../utils/number'
import { COLORS } from '../../utils/colors'
import { BaseContext } from '../../contexts/BaseContextWrapper'
import Button from './Button'
import { CheckSizeM } from '../svgs/Svgs'

export default function FormInputPhone({
    name,
    help,
    type,
    label,
    withBtn = false,
    onClick,
    loading,
    validate,
    onChange,
    disabled,
    className,
    isLoading,
    placeholder,
    btnClassName,
    fieldClassName,
    noRequiredValidation,
    labelClassName,
    errorWithMessage = true,
    ...attributes
}) {
    const { errors, touched, setFieldValue } = useFormikContext()
    const isValid = !errorWithMessage && errors[name] && touched[name]
    const isRequired = typeof validate === 'function' && !noRequiredValidation
    const { theme } = useContext(BaseContext)

    return (
        <div className={fieldClassName}>
            {label ? (
                <label className={labelClassName} htmlFor={name}>
                    {label} {isRequired && (
                        <span style={{ color: isValid ? COLORS.lightRed : COLORS.midGray }}>
                            *
                        </span>
                    )}
                </label>
            ) : null}

            <div className={cn(
                mode(theme, isValid),
                css(s.field, disabled && s.disabled),
            )}>
                <h3 className={css(s.countryCode)}>+998</h3>

                <Field
                    id={name}
                    name={name}
                    maxLength={12}
                    validate={validate}
                    type={type || 'text'}
                    placeholder={placeholder}
                    onChange={(event) => {
                        const value = formatPhone(event.target.value, true)
                        setFieldValue(name, value)
                    }}
                    disabled={disabled}
                    className={cn(css(s.inp, disabled && s.disabled), 'form-inp_pl', className)}
                    {...attributes} />

                {withBtn && (
                    <Button type="button" onClick={onClick} className={cn(css(s.btn, btnClassName), 'flex-cent')}>
                        {isLoading ? (
                            <Oval
                                height={16}
                                width={16}
                                color={COLORS.white}
                                visible
                                ariaLabel="oval-loading"
                                secondaryColor={COLORS.white}
                                strokeWidth={7}
                                strokeWidthSecondary={7} />
                        ) : (
                            <span className="flex-cent gap-2">
                                <FormattedMessage id="verify" /> <CheckSizeM color={COLORS.white} />
                            </span>
                        )}
                    </Button>
                )}
            </div>

            {errorWithMessage && <ValidationErrorMessage name={name} />}
        </div>
    )
}

const s = StyleSheet.create({
    countryCode: {
        fontWeight: '500',
        fontSize: 13,
        color: COLORS.gray,
    },
    btn: {
        minHeight: 32,
        borderRadius: 4,
        fontWeight: '500',
        fontSize: 14,
        whiteSpace: 'nowrap',
        padding: '5px 14px !important',
        '@media (max-width: 786px)': {
            fontSize: 12,
            padding: '4px 7px !important',
        },
    },
    field: {
        borderWidth: 1,
        borderStyle: 'solid',
        borderRadius: 4,
        display: 'flex',
        alignItems: 'center',
        height: 42,
        gap: 8,
        padding: '0 4px 0 16px',
    },
    inp: {
        border: 'none',
    },
    inpField: {
        borderWidth: 0,
        outline: 'none',
        width: '50%',
        padding: 0,
        fontSize: 14,
    },
    disabled: {
        backgroundColor: '#f2f2f2',
    },
    dng: {
        borderColor: COLORS.lightRed,
    },
    brdCl: {
        borderColor: COLORS.lightGray,
    },
    areaCode: {
        color: COLORS.gray,
        fontWeight: '500',
        fontSize: 14,
        marginRight: 8,
    },
    formHint: {
        marginTop: 4,
        fontSize: 12,
        fontWeight: '400',
        color: COLORS.lightBlack,
    },
    dngTxt: {
        fontWeight: '400',
        fontSize: 10,
        color: COLORS.lightRed,
        marginTop: 4,
    },
})

const mode = (theme, valid) => css(StyleSheet.create({
    inp: {
        borderRadius: 4,
        height: '42px',
        outline: 'none',
        border: `1px solid ${valid ? COLORS.lightRed : theme === 'dark' ? 'transparent' : COLORS.lightGray}`,
        ':disabled': {
            backgroundColor: '#f2f2f2',
        },
    },
}).inp)
