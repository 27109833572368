import React, { useContext } from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { useNavigate } from 'react-router-dom'
import { range } from 'lodash'
import { useIntl } from 'react-intl'
import { COLORS } from '../../utils/colors'
import { ArrowNextSizeM } from '../svgs/Svgs'
import IndustryItem from './IndustryItem'
import { LoadingSkeleton } from '../common/LoadingSkeleton'
import { BaseContext } from '../../contexts/BaseContextWrapper'

export default function IndustryGrid({ industryOptions, loading }) {
    const navigate = useNavigate()
    const { theme } = useContext(BaseContext)
    const intl = useIntl()

    return (
        <div className={cn(css(s.industryWrap), 'is-flex direction-column gap-6')}>
            <div className={cn(css(mode(theme).contHeader), 'is-flex align-center justify-between')}>
                <h3>💼 {intl.formatMessage({ id: 'jobIndustries' })}</h3>

                {!loading ? (
                    <div
                        className="is-flex align-end justify-center gap-2"
                        onClick={() => navigate('/industries')}
                    >
                        <h3>{intl.formatMessage({ id: 'showAll' })}</h3>
                        <ArrowNextSizeM />
                    </div>
                ) : null}
            </div>

            <div className={css(s.industries)}>
                {!loading ? (
                    <div className={css(s.industryGrid)}>
                        {industryOptions.slice(0, 6).map((item) => (
                            <IndustryItem theme={theme} key={item.value} item={item} />
                        ))}
                    </div>
                ) : (
                    <div className={css(s.industryGrid)}>
                        {range(0, 6).map((i) => (
                            <div key={i} className={cn(css(mode(theme).cardWrap), 'is-flex align-center')}>
                                <LoadingSkeleton height={40} width={40} />

                                <div className="is-flex direction-column gap-2">
                                    <LoadingSkeleton height={21} />

                                    <LoadingSkeleton height={18} />
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    )
}

const s = StyleSheet.create({
    industryWrap: {
        width: '100%',
        '@media (max-width: 1024px)': {
            gap: 13,
        },
    },
    industries: {
        overflowX: 'scroll',
        msOverflowX: 'scroll',
        padding: '0 32px',
        '@media (max-width: 1024px)': {
            padding: '0 16px',
        },
        scrollbarWidth: 'none', // Firefox
        '-ms-overflow-style': 'none', // Internet Explorer
        '::-webkit-scrollbar': {
            display: 'none', // Safari and Chrome
        },
    },
    industryGrid: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        gap: 24,
        '@media (max-width: 1024px)': {
            gap: 12,
        },
    },
})

const mode = (theme) => StyleSheet.create({
    contHeader: {
        padding: '0 32px',
        color: theme === 'dark' ? COLORS.white : COLORS.lightBlack,
        '@media (max-width: 1024px)': {
            padding: '0 16px',
        },
        ':nth-child(1n) > :first-child': {
            fontSize: 24,
            fontWeight: '600',
            '@media (max-width: 1024px)': {
                fontSize: 16,
            },
        },
        ':nth-child(1n) > :last-child': {
            cursor: 'pointer',
            ':nth-child(1n) > :first-child': {
                fontSize: 15,
                fontWeight: '600',
                '@media (max-width: 1024px)': {
                    fontSize: 12,
                    fontWeight: '500',
                },
            },
            ':nth-child(1n) > :last-child': {
                ':nth-child(1n) > path': {
                    stroke: theme === 'dark' ? COLORS.white : COLORS.lightBlack,
                },
            },
        },
    },
    cardWrap: {
        minWidth: '14rem',
        borderRadius: 4,
        border: `1px solid ${theme === 'dark' ? COLORS.itemDarkModeBg : COLORS.smoothGray}`,
        background: theme === 'dark' ? COLORS.darkModeBg : COLORS.white,
        boxShadow: '0px 2px 4px 0px rgba(130, 130, 130, 0.05)',
        padding: '20px 24px',
        gap: 20,
        '@media (max-width: 1024px)': {
            padding: 12,
            gap: 8,
        },
    },
})
