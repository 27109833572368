import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import { COLORS } from '../../utils/colors'

export default function ScrollAnimation({
    contClassName,
    headerClassName,
    bodyClassName,
    bodyChildren,
    headerChildren,
    footerChildren,
    footerClassName,
    style,
}) {
    const onScrollHandler = (e) => {
        const header = document.querySelector('#header')
        const footer = document.querySelector('#footer')

        if (e.target.scrollTop > 1) {
            header.classList.add(css(s.onScroll))
            if (footer) footer.classList.add(css(s.onScroll))
        } else {
            header.classList.remove(css(s.onScroll))
            if (footer) footer.classList.remove(css(s.onScroll))
        }
    }

    return (
        <div style={style} className={contClassName}>
            <div id="header" className={headerClassName}>{headerChildren}</div>

            <div onScroll={(e) => onScrollHandler(e)} className={bodyClassName}>
                {bodyChildren}
            </div>

            {footerChildren && (
                <div id="footer" className={footerClassName}>{footerChildren}</div>
            )}
        </div>
    )
}

const s = StyleSheet.create({
    onScroll: {
        borderBottom: `1px solid ${COLORS.lightGray} !important`,
        boxShadow: '0px 4px 16px rgba(62, 89, 78, 0.15) !important',
        // padding: 24,
        '@media (max-width: 768px)': {
            padding: 0,
        },
    },
})
