import React, { useLayoutEffect } from 'react'
import { useLocation } from 'react-router-dom'
import Verification from './Verification'
import { usePostRequest } from '../../hooks/request'
import { CANDIDATE_SIGNUP, CANDIDATE_VERIFY_SIGNUP } from '../../urls'
import { useMessage } from '../../hooks/message'
import { deFormatPhone } from '../../utils/number'

export default function SignUpVerify() {
    const { state } = useLocation()
    const [showMessage] = useMessage()
    const verifyRegistration = usePostRequest({ url: CANDIDATE_VERIFY_SIGNUP })
    const postRegister = usePostRequest({ url: CANDIDATE_SIGNUP })

    useLayoutEffect(() => {
        if (!state || !state.phone) {
            window.history.back()
        }
    }, [])

    const onRegisterVerify = async (code) => {
        if (verifyRegistration.loading) return
        const { response, error, success } = await verifyRegistration.request({ data: {
            phone: state && `+998${deFormatPhone(state.phone)}`,
            code,
        } })

        if (error) {
            showMessage(error.data.errors[0].message || error.data.message, 'error-msg')
            return
        }

        if (success) {
            window.location.href = state ? state.redirect : '/'
            localStorage.setItem('token', JSON.stringify(response.data.accessToken))
            localStorage.setItem('refresh_token', JSON.stringify(response.data.refreshToken))
        }
    }

    return (
        <Verification
            onSubmit={onRegisterVerify}
            $api={postRegister}
            phoneNumber={state && state.phone}
            loading={verifyRegistration.loading}
        />
    )
}
