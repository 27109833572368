import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import { FormattedMessage } from 'react-intl'
import { COLORS } from '../../utils/colors'
import NotFoundImg from '../../static/images/not-found.png'
import defaultBg from '../../static/images/noBannerImg.png'

export default function CompanyNotFound({ info, theme }) {
    return (
        <div className={css(mode(theme).notFoundBody)}>
            <div className={css(mode(theme).boxWrap)}>
                <div className={css(mode(theme).banner)}>
                    <img src={defaultBg} alt="#DefaultBanner" />
                </div>

                <div className={css(mode(theme).infoWrap)}>
                    <img src={NotFoundImg} alt="#NotFound" />

                    <h3><FormattedMessage id="noInfoAboutCompany" /></h3>

                    <div className="is-flex align-center gap-2">
                        <FormattedMessage id="company" />

                        <span>{info && info.name}</span>

                        <FormattedMessage id="pausedPublishInfo" />
                    </div>
                </div>
            </div>
        </div>
    )
}

const mode = (theme) => StyleSheet.create({
    notFoundBody: {
        padding: '0 32px',
        '@media (max-width: 1024px)': {
            padding: 0,
        },
    },
    boxWrap: {
        background: theme === 'dark' ? COLORS.darkModeBg : COLORS.white,
        boxShadow: '0px 2px 4px rgba(130, 130, 130, 0.05)',
        borderRadius: 4,
        '@media (max-width: 1024px)': {
            borderRadius: 0,
        },
    },
    infoWrap: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        padding: '40px 24px 71.24px 24px',
        ':nth-child(1n) > img': {
            '@media (max-width: 1024px)': {
                width: '100%',
                height: 'auto',
            },
            width: '40rem',
        },
        ':nth-child(1n) > h3': {
            fontWeight: '700',
            fontSize: 32,
            color: theme === 'dark' ? COLORS.smoothGray : COLORS.dark,
            marginTop: 29,
            '@media (max-width: 1024px)': {
                fontWeight: '600',
                fontSize: 22,
                marginTop: 16,
            },
        },
        ':nth-child(1n) > :last-child': {
            fontWeight: '000',
            fontSize: 13,
            color: theme === 'dark' ? COLORS.gray : COLORS.midGray,
            marginTop: 12,
        },
        '@media (max-width: 1024px)': {
            padding: '40px 16px 71.24px 16px',
        },
    },
    banner: {
        background: `linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), ${COLORS.mainColor}`,
        borderRadius: 4,
        height: 192,
        width: '100%',
        ':nth-child(1n) > img': {
            objectFit: 'cover',
            objectPosition: 'top',
            height: 'inherit',
            width: 'inherit',
        },
        '@media (max-width: 1024px)': {
            borderRadius: 0,
        },
    },
})
