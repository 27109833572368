import React, { useContext, useRef, useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { useLocation, useNavigate } from 'react-router-dom'
import { Form, Formik } from 'formik'
// import { isEmpty } from 'lodash'
import { isEmpty } from 'lodash'
import { FormattedMessage } from 'react-intl'
import { SearchForCandidate, SearchForJob } from '../../components/svgs/Svgs'
import { Layout } from '../../components/common/Layout'
import SignIn from './SignIn'
import SignUp from './SignUp'
import { COLORS } from '../../utils/colors'
import { usePostRequest } from '../../hooks/request'
import { CANDIDATE_SIGNIN, CANDIDATE_SIGNUP } from '../../urls'
import { useMessage } from '../../hooks/message'
import { BaseContext } from '../../contexts/BaseContextWrapper'
import { deFormatPhone } from '../../utils/number'

export default function Auth() {
    const { pathname, state } = useLocation()
    const navigate = useNavigate()
    const [errorMsg, setErrorMsg] = useState('')
    const [showMessage] = useMessage()
    const postLogin = usePostRequest({ url: CANDIDATE_SIGNIN })
    const postRegister = usePostRequest({ url: CANDIDATE_SIGNUP })
    const { theme } = useContext(BaseContext)
    const recaptchaRef = useRef(null)
    const [captchaValue, setCaptchaValue] = useState('')

    const onRecaptchaChange = (value) => {
        setCaptchaValue(value)
    }

    const onSubmitLogin = async (values) => {
        if (postLogin.loading || isEmpty(values.phoneNumber)) return

        const phone = `+998${deFormatPhone(values.phoneNumber)}`
        const { error, success } = await postLogin.request({ data: {
            phone,
            client_id: process.env.REACT_APP_CLIENT_ID || '',
            client_secret: process.env.REACT_APP_CLIENT_SECRET || '',
        } })

        if (success) {
            navigate('/confirmation/login', { state: {
                phone: values.phoneNumber,
                from: pathname,
                redirect: state && state.from ? state.from.pathname : '/',
            } })
            return
        }

        showMessage(error.data.errors[0].message || error.data.message, 'error-msg')
        setErrorMsg(error.data.errors ? error.data.errors[0].message : undefined)
    }

    const onSubmitSignUp = async (values) => {
        const { phoneNumber, firstName, lastName } = values
        const reCaptchaValue = recaptchaRef.current.getValue()

        if (isEmpty(phoneNumber || firstName || lastName) || !reCaptchaValue || postRegister.loading) return

        const phone = `+998${deFormatPhone(phoneNumber)}`

        const { success, error } = await postRegister.request({ data: {
            phone,
            first_name: firstName,
            last_name: lastName,
            email: values.email,
            client_id: process.env.REACT_APP_CLIENT_ID || '',
            client_secret: process.env.REACT_APP_CLIENT_SECRET || '',
        } })

        if (success) {
            navigate('/confirmation/register', {
                state: {
                    first_name: values.firstName,
                    last_name: values.lastName,
                    email: values.email,
                    phone: values.phoneNumber,
                    from: pathname,
                    redirect: state ? state.redirect : '/',
                },
            })
            return
        }

        showMessage(error.data.message || error.data.errors[0].message, 'error-msg')
    }

    const onSubmit = (values) => {
        if (pathname === '/auth/user') {
            onSubmitLogin(values)
        } else {
            onSubmitSignUp(values)
        }
    }

    const initialValues = {
        ...state,
        phoneNumber: state ? state.phone : '',
        lastName: state ? state.last_name : '',
        firstName: state ? state.first_name : '',
        email: state ? state.email : '',
    }

    return (
        <Layout>
            <Formik enableReinitialize initialValues={{
                email: '',
                lastName: '',
                firstName: '',
                phoneNumber: '',
                ...initialValues,
            }} onSubmit={onSubmit}>
                {({ values, handleChange, handleBlur, errors }) => {
                    const { phoneNumber, firstName, lastName } = values
                    const requiredSignUpFields = { phoneNumber, firstName, lastName }

                    return (
                        <Form className={css(s.right)}>
                            <div className={css(s.wrapper)}>
                                <div className={css(s.left)}>
                                    <div className={cn(css(mode(theme).box), 'is-flex gap-1')}>
                                        <a
                                            href="https://hr.workly.uz/auth/recruiter"
                                            target="_blank"
                                            className={cn(css(mode(theme).link),
                                                'is-flex align-center justify-center pointer gap-2')} rel="noreferrer"
                                        >
                                            <div><SearchForCandidate /></div>

                                            <h3 className={css(s.title)}>
                                                <FormattedMessage id="lookingForEmployees" />
                                            </h3>
                                        </a>

                                        <div
                                            onClick={() => navigate('/')}
                                            className={cn(css(s.activeLink), css(mode(theme).link),
                                                'is-flex align-center justify-center pointer gap-2')}
                                        >
                                            <div>
                                                <SearchForJob />
                                            </div>

                                            <h3 className={css(s.title)}><FormattedMessage id="lookingForJob" /></h3>
                                        </div>
                                    </div>
                                </div>

                                {pathname.startsWith('/auth') && (
                                    <SignIn
                                        state={state}
                                        errors={errors}
                                        values={values}
                                        theme={theme}
                                        errorMsg={errorMsg}
                                        handleBlur={handleBlur}
                                        handleChange={handleChange}
                                        loading={postLogin.loading} />
                                )}

                                {pathname.startsWith('/registration') && (
                                    <SignUp
                                        values={values}
                                        theme={theme}
                                        loading={postRegister.loading}
                                        captchaValue={captchaValue}
                                        handleChange={handleChange}
                                        onRecaptchaChange={onRecaptchaChange}
                                        recaptchaRef={recaptchaRef}
                                        requiredFields={requiredSignUpFields} />
                                )}
                            </div>
                        </Form>
                    )
                }}
            </Formik>
        </Layout>
    )
}

const s = StyleSheet.create({
    wrapper: {
        width: 'calc(100% - 32px)',
        display: 'flex',
        justifyContent: 'center',
        paddingTop: 32,
        '@media (max-width: 768px)': {
            padding: 16,
            justifyContent: 'center',
            alignItems: 'center',
        },
    },
    activeLink: {
        background: COLORS.mainColor,
        ':nth-child(1n) > h3': {
            color: COLORS.white,
        },
        ':nth-child(1n) > :first-child': {
            ':nth-child(1n) > :first-child': {
                ':nth-child(1n) > path': {
                    stroke: COLORS.white,
                },
            },
        },
    },
    right: {
        flex: 2.4,
        display: 'flex',
        alignItems: 'left',
        justifyContent: 'left',
        marginBottom: '20%',
        '@media (max-width: 768px)': {
            alignItems: 'center',
            justifyContent: 'center',
        },
    },
    left: {
        flex: 1,
        display: 'flex',
        alignItems: 'baseline',
        justifyContent: 'start',
        paddingLeft: 32,
        '@media (max-width: 768px)': {
            display: 'none',
        },
    },
    title: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
})

const mode = (theme) => StyleSheet.create({
    box: {
        maxWidth: 220,
        padding: 4,
        borderRadius: 4,
        border: theme === 'dark' ? 'none' : '1px solid #EAEAEA',
        background: theme === 'dark' ? COLORS.itemDarkModeBg : COLORS.white,
        boxShadow: '0px 2px 4px 0px rgba(130, 130, 130, 0.05',
    },
    link: {
        width: 32,
        height: 32,
        borderRadius: 2,
        transition: 'width ease 0.3s',
        textDecoration: 'none',
        ':nth-child(1n) > h3': {
            color: COLORS.gray,
            fontSize: 13,
            fontWeight: '500',
        },
        ':nth-child(1n) > :first-child': {
            marginLeft: 6,
            ':nth-child(1n) > :only-child': {
                ':nth-child(1n) > path': {
                    stroke: COLORS.gray,
                },
            },
        },
        ':hover': {
            width: '11.125rem',
            ':nth-child(1n) > :first-child': {
                marginLeft: 0,
            },
        },
    },
})
