import React, { memo, useContext } from 'react'
// eslint-disable-next-line import/no-extraneous-dependencies
import BottomSheet from 'react-draggable-bottom-sheet'
import { BaseContext } from '../../contexts/BaseContextWrapper'

const MemoizedComponent = ({ children, onClose, open }) => {
    const { theme } = useContext(BaseContext)

    return (
        <BottomSheet
            isOpen={open}
            close={onClose}
            classNames={{ draggable: theme === 'dark' ? 'dark_mode-draggable' : '',
                dragIndicator: { indicator: theme === 'dark' ? 'dark_mode-dragInd' : '' } }}
        >
            {children}
        </BottomSheet>
    )
}

export const BottomSheetSlider = memo(MemoizedComponent)
