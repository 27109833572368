import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import React from 'react'
import { range } from 'lodash'
import Pagination from './Pagination'
import { COLORS } from '../../utils/colors'

export default function Table({
    page,
    count,
    items,
    columns,
    setPage,
    pageSize,
    className,
    pageCount,
    isStripped,
    renderItem,
    isHoverable = false,
    setPageSize,
    isScrollable,
    customHeader,
    loading = false,
    loaderItemCount,
    children,
}) {
    // TODO fix table design
    return (
        <React.Fragment>
            <table className={cn(`${isScrollable && ''}`, css(s.table), className)}> {/* TODO fix scrollable  */}
                {customHeader || (
                    <thead className={css(s.table_head)}>
                        <tr>
                            {Object.entries(columns).map(([key, value]) => (
                                <th key={key}>{value}</th>
                            ))}
                        </tr>
                    </thead>
                )}

                <tbody
                    style={{ overflowY: 'auto', height: 'calc(100% - 44px)', overflowX: 'hidden' }}
                    className={`${isStripped ? 'striped' : ''} ${isHoverable ? 'striped-hv pointer' : ''}`}>
                    {
                        loaderItemCount && loading
                            ? range(loaderItemCount).map(renderItem)
                            : items ? items.map(renderItem) : children
                    }
                </tbody>
            </table>

            {pageCount > 1 && (
                <Pagination
                    page={page}
                    count={count}
                    loading={loading}
                    setPage={setPage}
                    pageSize={pageSize}
                    pageCount={pageCount}
                    setPageSize={setPageSize}
                />
            )}
        </React.Fragment>
    )
}

const s = StyleSheet.create({
    space: {
        marginTop: '2rem',
    },
    table: {
        filter: 'drop-shadow(0px 2px 4px rgba(130, 130, 130, 0.05))',
        borderCollapse: 'collapse',
    },
    table_head: {
        color: COLORS.midGray,
        fontSize: 13,
        textTransform: 'uppercase',
        ':nth-child(1n) > :first-child': {
            background: '#EAF4F0',
            ':nth-child(1n) > *': {
                padding: '7px 0',
                fontWeight: '500',
            },
            ':nth-child(1n) > :first-child': {
                borderRadius: '4px 0 0 0',
                paddingLeft: '1.5rem',
            },
            ':nth-child(1n) > :last-child': {
                borderRadius: '0 4px 0 0',
                paddingRight: '1.5rem',
            },
        },
    },
})
