import React, { useState } from 'react'
import cn from 'classnames'
import { css, StyleSheet } from 'aphrodite'
import { isEmpty } from 'lodash'
import { FormattedMessage } from 'react-intl'
import FilterByDate from './FilterByDate'
import FilterByIndustry from './FilterByIndustry'
import FilterBySalary from './FilterBySalary'
// import FilterByAge from './FilterByAge'
import FilterByExperience from './FilterByExperience'
import { Calendar, ChevronDownS, EditSizeS } from '../../svgs/Svgs'
import { COLORS } from '../../../utils/colors'
import Button from '../../common/Button'
import DateFromToSection from './DateFromToSection'
import FilterByEducation from './FilterByEducation'
// import DoubleRangeSlider from '../common/DoubleRangeSlider'

export default function VacanciesFilterSection({
    setFilter,
    section,
    filter,
    setFullSection,
    theme,
}) {
    const [isCollapsed, setIsCollapsed] = useState(false)
    // const [clearRange, setClearRange] = useState(false)
    // const { vacancyAdditional } = useContext(PublicContext)

    const emptyChecker = typeof filter[section.name] === 'number'
        ? filter[section.name] : !isEmpty(filter[section.name])

    const strChecker = filter[section.name] === ':'

    return (
        <div className="is-flex direction-column gap-2">
            <div className="justify-between">
                <h3 className={css(mode(theme).title)}>
                    {section.title}

                    {emptyChecker && !strChecker && !section.noClear && (
                        <span
                            onClick={() => {
                                const {
                                    [section.name]: sectionFilter,
                                    ...clearedFilter
                                } = filter
                                setFilter(clearedFilter)
                            }}
                            className="dis_sl"><FormattedMessage id="clear" />
                        </span>
                    )}

                    {section.hasInput && filter.dateInput && (
                        <span
                            onClick={() => setFilter({ ...filter, dateInput: false, interval: undefined })}
                            className="dis_sl"><FormattedMessage id="clear" />
                        </span>
                    )}
                </h3>

                <ChevronDownS
                    color={theme === 'dark' ? COLORS.white : COLORS.dark}
                    onClick={() => setIsCollapsed(!isCollapsed)}
                    className={cn('pointer dis_sl', css(isCollapsed ? s.chevronDown : s.chevronUp))} />
            </div>

            <div className={collapsableSection(isCollapsed, isCollapsed ? 0 : 176)}>
                {section.isRadio && (
                    <FilterByDate
                        theme={theme}
                        name={section.name}
                        options={section.options}
                        filter={filter}
                        setFilter={setFilter}
                    />
                )}

                {/* {section.haveSearch && ( */}
                {/*    <SearchInput */}
                {/*        className={css(s.searchInp)} */}
                {/*    /> */}
                {/* )} */}

                {section.isCheckbox && (
                    <FilterByIndustry
                        loading={section.loading}
                        name={section.name}
                        options={section.options}
                        filter={filter}
                        theme={theme}
                        setFilter={setFilter}
                    />
                )}

                {/* {section.hasSlider && ( */}
                {/*    <DoubleRangeSlider */}
                {/*        clear={clearRange} */}
                {/*        filter={filter} */}
                {/*        setFilter={setFilter} */}
                {/*        min={vacancyAdditional ? vacancyAdditional.salaryMin : 100} */}
                {/*        max={vacancyAdditional ? vacancyAdditional.salaryMax : 10000000} */}
                {/*    /> */}
                {/* )} */}

                {section.isRange && (
                    <FilterBySalary
                        name={section.name}
                        filter={filter}
                        theme={theme}
                        setFilter={setFilter} />
                )}

                {/* {section.isAgeCheckbox && ( */}
                {/*     <FilterByAge */}
                {/*         name={section.name} */}
                {/*         options={section.options} */}
                {/*         filter={filter} */}
                {/*         theme={theme} */}
                {/*         setFilter={setFilter} */}
                {/*     /> */}
                {/* )} */}

                {section.isExpCheckbox && (
                    <FilterByExperience
                        name={section.name}
                        options={section.options}
                        filter={filter}
                        theme={theme}
                        setFilter={setFilter}
                    />
                )}

                {section.isEduCheckbox && (
                    <FilterByEducation
                        name={section.name}
                        options={section.options}
                        filter={filter}
                        theme={theme}
                        setFilter={setFilter}
                    />
                )}

                {section.hasInput && (
                    <div>
                        {!filter.dateInput ? (
                            <li
                                onClick={() => setFilter({ ...filter, dateInput: true })}
                                className={cn(css(s.editManual), 'is-flex align-center gap-2 pointer')}
                            >
                                <EditSizeS /> <FormattedMessage id="enterManually" />
                            </li>
                        ) : (
                            <DateFromToSection
                                value={filter.interval && filter.interval.split(':')}
                                fieldClassName={css(s.fld)}
                                className={css(s.dateInput)}
                                icon={<Calendar />}
                                onChange={(value) => setFilter({ ...filter, interval: value })}
                            />
                        )}
                    </div>
                )}

                {section.options.length > 5 && (
                    <Button
                        className={css(s.btnMore)}
                        onClick={() => setFullSection({ act: true, section })}>
                        + <FormattedMessage id="more" /> {section.options.length - 5}
                    </Button>
                )}
            </div>
        </div>
    )
}

const s = StyleSheet.create({
    dd: {
        overflow: 'hidden',
        transition: 'height 0.3s ease',
    },
    chevronDown: {
        transform: 'rotateZ(0deg)',
        transition: 'transform 0.3s',
    },
    chevronUp: {
        transform: 'rotateZ(180deg)',
        transition: 'transform 0.3s',
    },
    searchInp: {
        width: '100%',
        height: 24,
        background: '#FAFAFA',
        border: '1px solid rgba(0, 0, 0, 0.06)',
        padding: '0 8px',
        ':nth-child(1n) > input': {
            fontSize: 11,
            paddingTop: 3,
            fontWeight: '400',
            '::placeholder': {
                color: COLORS.midGray,
            },
            color: COLORS.dark,
        },
        ':hover': {
            border: '1px solid rgba(0, 0, 0, 0.06)',
            boxShadow: 'none',
        },
    },
    btnMore: {
        width: '100%',
        background: '#FAFFFC',
        border: '1px solid #D6F0DD',
        borderRadius: 4,
        fontWeight: '400',
        fontSize: 11,
        height: 24,
        color: COLORS.mainColor,
    },
    editManual: {
        color: COLORS.mainColor,
        fontSize: 11,
        fontWeight: '500',
    },
    dateInput: {
        background: COLORS.white,
        fontSize: 14,
        height: 36,
    },
    fld: {
        position: 'relative',
        ':nth-child(1n) > :first-child': {
            ':nth-child(1n) > span': {
                top: 10,
            },
        },
    },
})

const mode = (theme) => StyleSheet.create({
    title: {
        fontWeight: '600',
        fontSize: 14,
        color: theme === 'dark' ? COLORS.white : COLORS.dark,
        whiteSpace: 'nowrap',
        ':nth-child(1n) > span': {
            cursor: 'pointer',
            fontSize: 10,
            marginLeft: 4,
            textTransform: 'uppercase',
            color: COLORS.skyblue,
            ':hover': {
                color: `${COLORS.skyblue}CC`,
            },
        },
    },
})

const collapsableSection = (collapse, maxHeight) => {
    const sy = StyleSheet.create({
        collapsed: {
            maxHeight,
            transition: 'max-height 0.15s linear',
            gap: 8,
        },
        unCollapsed: {
            maxHeight,
            transition: 'max-height 0.25s linear',
            gap: 8,
        },
        main: {
            overflowY: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            height: 'auto',
            gap: 8,
        },
    })

    if (collapse) return css(sy.collapsed, sy.main)
    return css(sy.collapsed, sy.main)
}
