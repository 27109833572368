import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import React, { useContext } from 'react'
import { FormattedMessage } from 'react-intl'
import { Oval } from 'react-loader-spinner'
import { Context } from '../../contexts/GlobalContext'
import { useMessage } from '../../hooks/message'
import { usePostRequest } from '../../hooks/request'
import { CANDIDATE_VERIFY_EMAIL } from '../../urls'
import { COLORS } from '../../utils/colors'
import { CheckSizeS, Repeat } from '../svgs/Svgs'
import Button from './Button'
import { resendVerification } from '../../utils/request'

export default function VerifyEmail({ limit, isChanged, value }) {
    const [showMessage] = useMessage()
    const { getInfo } = useContext(Context)
    const verifyEmail = usePostRequest({ url: CANDIDATE_VERIFY_EMAIL })

    const txt = [
        { text: <FormattedMessage id="weHaveSentAllConfirmation" /> },
        { text: <FormattedMessage id="mailConfirmationIsLimited" /> },
        { text: <FormattedMessage id="mailConfirmationSent" /> },
        { text: <FormattedMessage id="mailUnverified" /> },
    ]

    const sendRequest = async (email) => {
        if (!email || verifyEmail.loading) return

        const { success, error } = await resendVerification(verifyEmail, email)

        if (success) {
            showMessage(<FormattedMessage id="mailConfirmationSent" />, 'success-msg')
            getInfo.setResponse((prev) => ({
                ...getInfo.response,
                data: {
                    ...prev.data,
                    emailConfirmLimit: prev.data.emailConfirmLimit !== 0
                        ? prev.data.emailConfirmLimit - 1
                        : prev.data.emailConfirmLimit,
                },
            }))
            return
        }

        showMessage(error.data.message || error.data.errors[0].message, 'error-msg')
    }

    return (
        <div className={cn(css(s.wrapper), 'is-flex justify-between-start gap-2')}>
            {isChanged && limit > 0 ? <FormattedMessage id="emailChanged" /> : txt[limit].text}

            {limit > 0 && limit < 3 ? (
                <div
                    onClick={() => sendRequest(value)}
                    className={cn(css(s.sndCodeTrig), `align-center justify-center gap-2 pointer 
                    ${verifyEmail.loading ? 'spin' : ''}`)}
                >
                    <Repeat />

                    <p><FormattedMessage id={limit === 2 ? 'sendMailAgain' : 'lastAttempt'} /></p>
                </div>
            ) : limit > 0 ? (
                <Button
                    type="button"
                    onClick={() => sendRequest(value)}
                    className={cn(css(s.confirmBtn), 'flex-cent gap-1')}
                >
                    <h3><FormattedMessage id="verifyMail" /></h3>

                    {verifyEmail.loading ? (
                        <Oval
                            height={16}
                            width={16}
                            color={COLORS.mainColor}
                            visible
                            ariaLabel="oval-loading"
                            secondaryColor={COLORS.mainColor}
                            strokeWidth={7}
                            strokeWidthSecondary={7} />
                    ) : <CheckSizeS />}
                </Button>
            ) : null}
        </div>
    )
}

const s = StyleSheet.create({
    wrapper: {
        color: COLORS.lightBlack,
        fontSize: 12,
        fontWeight: '400',
        ':nth-child(1n) > :last-child': {
            marginLeft: 8,
        },
        '@media (max-width:786px)': {
            flexDirection: 'column',
            ':nth-child(1n) > :last-child': {
                marginLeft: 0,
            },
        },
    },
    sndCodeTrig: {
        ':nth-child(1n) > :first-child': {
            ':nth-child(1n) > path': {
                fill: COLORS.mainColor,
            },
        },
        ':nth-child(1n) > :last-child': {
            color: COLORS.mainColor,
        },
        userSelect: 'none',
        fontWeight: '500',
        fontSize: 12,
        whiteSpace: 'nowrap',
    },
    confirmBtn: {
        borderRadius: 4,
        border: '1px solid #BBE0D3',
        background: '#D6F2E5',
        whiteSpace: 'nowrap',
        padding: '4px 8px',
        ':nth-child(1n) > h3': {
            color: COLORS.mainColor,
            fontSize: 12,
            fontWeight: '500',
        },
    },
})
