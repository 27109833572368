import React, { useContext } from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { useNavigate } from 'react-router-dom'
import { range } from 'lodash'
import { FormattedMessage } from 'react-intl'
import { Layout } from '../common/Layout'
import IndustryItem from './IndustryItem'
import { ChevronPrevSizeXL } from '../svgs/Svgs'
import { COLORS } from '../../utils/colors'
import { Context } from '../../contexts/GlobalContext'
import { LoadingSkeleton } from '../common/LoadingSkeleton'
import { BaseContext } from '../../contexts/BaseContextWrapper'

export default function Industries() {
    const navigate = useNavigate()
    const { matchedIndustries, industryLoading } = useContext(Context)
    const { theme } = useContext(BaseContext)

    return (
        <Layout>
            <div className={css(s.mainWrap)}>
                <div className={cn(css(s.wrapper), 'is-flex direction-column')}>
                    <div
                        onClick={() => navigate(-1)}
                        className={cn(css(mode(theme).chevronWrap), 'is-flex align-center gap-2')}>
                        <ChevronPrevSizeXL color={theme === 'dark' ? COLORS.white : COLORS.dark} />

                        <h3><FormattedMessage id="jobIndustries" /></h3>
                    </div>

                    {!industryLoading ? (
                        <div className={css(s.itemsWrap)}>
                            {matchedIndustries.map((item) => (
                                <IndustryItem theme={theme} key={item.value} item={item} />
                            ))}
                        </div>
                    ) : (
                        <div className={css(s.itemsWrap)}>
                            {range(0, 14).map((i) => (
                                <div key={i} className={cn(css(mode(theme).cardWrap), 'is-flex align-center')}>
                                    <LoadingSkeleton height={40} width={40} />

                                    <div className={cn(css(s.infoWrap), 'is-flex direction-column gap-2')}>
                                        <LoadingSkeleton height={21} />

                                        <LoadingSkeleton height={18} />
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </Layout>
    )
}

const s = StyleSheet.create({
    mainWrap: {
        padding: '24px 32px',
        '@media (max-width: 768px)': {
            padding: 16,
        },
    },
    wrapper: {
        gap: 30,
    },
    itemsWrap: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        gap: 24,
        // margin: '0 24px',
        '@media (max-width: 1400px)': {
            gridTemplateColumns: '1fr 1fr',
            gap: 12,
        },
        '@media (max-width: 900px)': {
            gridTemplateColumns: '1fr',
        },
    },
    infoWrap: {
        ':nth-child(1n) > :first-child': {
            color: COLORS.dark,
            fontSize: 18,
            fontWeight: '600',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            '@media (max-width: 1024px)': {
                fontSize: 14,
            },
        },
        ':nth-child(1n) > :last-child': {
            color: COLORS.midGray,
            fontSize: 15,
            fontWeight: '500',
            '@media (max-width: 1024px)': {
                fontSize: 12,
            },
        },
    },
})

const mode = (theme) => StyleSheet.create({
    chevronWrap: {
        color: theme === 'dark' ? COLORS.white : COLORS.dark,
        fontSize: 24,
        fontWeight: '600',
        cursor: 'pointer',
        '@media (max-width: 768px)': {
            fontSize: 16,
        },
    },
    cardWrap: {
        minWidth: '14rem',
        borderRadius: 4,
        border: theme === 'dark' ? 'none' : `1px solid ${COLORS.smoothGray}`,
        background: theme === 'dark' ? COLORS.itemDarkModeBg : COLORS.white,
        boxShadow: '0px 2px 4px 0px rgba(130, 130, 130, 0.05)',
        padding: '20px 24px',
        gap: 20,
        cursor: 'pointer',
        '@media (max-width: 1024px)': {
            padding: 12,
            gap: 8,
        },
    },
})
