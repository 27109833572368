import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { FormattedMessage } from 'react-intl'
import { COLORS } from '../../utils/colors'

export default function AppliedVacanciesTableHeader({ theme }) {
    return (
        <thead className={cn(css(mode(theme).table_head))}>
            <tr>
                <th><FormattedMessage id="company" /></th>

                <th><FormattedMessage id="vacancy" /></th>

                <th><FormattedMessage id="status" /></th>

                <th><FormattedMessage id="appliedDate" /></th>

                <th><FormattedMessage id="responseDate" /></th>
            </tr>
        </thead>
    )
}

const mode = (theme) => StyleSheet.create({
    table_head: {
        width: '100%',
        color: theme === 'dark' ? COLORS.gray : COLORS.midGray,
        background: theme === 'dark' ? 'rgba(234, 244, 240, 0.10)' : '#EAF4F0',
        fontSize: 13,
        textTransform: 'uppercase',
        textAlign: 'left',
        ':nth-child(1n) > :first-child': {
            ':nth-child(1n) > *': {
                fontWeight: '500',
            },
            ':nth-child(1n) > :first-child': {
                borderRadius: '4px 0 0 0',
                padding: '14.5px 36px',
            },
            ':nth-child(1n) > :last-child': {
                borderRadius: '0 4px 0 0',
            },
        },
    },
})
