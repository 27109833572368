import ProfilePage from '../pages/ProfilePage'
import Auth from '../pages/auth/Auth'
import SignInVerify from '../pages/auth/SignInVerify'
import SignUpVerify from '../pages/auth/SignUpVerify'
import Apply from '../components/company/vacancyApply/Apply'
import HomePage from '../pages/HomePage'
import CompanyPage from '../pages/CompanyPage'
import CompanyVacancyInfo from '../components/company/vacancies/CompanyVacancyInfo'
import Industries from '../components/companies/Industries'
import Companies from '../components/companies/Companies'
import Vacancies from '../components/companies/Vacancies'
import MainForm from '../components/company/vacancyApply/MainForm'

const token = localStorage.getItem('token')

export const routes = [
    { path: '/', component: HomePage },
    { path: '/c/:cSlug', component: CompanyPage },
    { path: '/c/:cSlug/:vSlug', component: CompanyVacancyInfo },
    { path: '/industries', component: Industries },
    { path: '/companies/:id', component: Companies },
    { path: '/companies', component: Companies },
    { path: '/vacancies', component: Vacancies },
]

export const protectedRoutes = [
    { path: '/auth/user', redirectPath: '/', protectedBy: !token, component: Auth },
    { path: '/registration/user', redirectPath: '/', protectedBy: !token, component: Auth },
    { path: '/confirmation/login', redirectPath: '/', protectedBy: !token, component: SignInVerify }, // eslint-disable-line max-len
    { path: '/confirmation/register', redirectPath: '/', protectedBy: !token, component: SignUpVerify }, // eslint-disable-line max-len
    { path: '/account/applies', component: ProfilePage },
    { path: '/account/info', component: ProfilePage },
    // { path: '/account/applies/offer', component: OfferInfo },
    // { path: '/account/applies/reject', component: RejectInfo },
    // { path: '/account/applies/invite', component: InviteInfo },
    // { path: '/account/applies/meetup', component: MeetUpInfo },
    { path: '/c/:cSlug/:vSlug/apply', component: Apply },
    { path: '/c/:cSlug/:vSlug/apply', component: MainForm },
]
