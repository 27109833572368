import React from 'react'
import Popup from 'reactjs-popup'

export default function Modal({
    trigger,
    children,
    modalRef,
    className,
    contentStyle,
    overlayStyle,
    ...args
}) {
    const contStyle = {
        maxHeight: 'calc(100% - 8px)',
        background: 'transparent',
        overflow: 'auto !important',
    }

    return (
        <Popup
            modal
            ref={modalRef}
            closeOnDocumentClick={false}
            closeOnEscape={false}
            className={className}
            contentStyle={{ ...contStyle, ...contentStyle }}
            overlayStyle={{ background: 'rgba(24, 26, 25, 0.6)',
                backdropFilter: 'blur(4px)',
                zIndex: 1,
                ...overlayStyle }}
            trigger={trigger}
            {...args}>
            {children}
        </Popup>
    )
}
