import React, { forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { useLocation, useNavigate } from 'react-router-dom'
import { Oval } from 'react-loader-spinner'
import { FormattedMessage } from 'react-intl'
import Button from '../../components/common/Button'
import { COLORS } from '../../utils/colors'
import { ChevronNextSizeL, EditSizeS, Repeat, SearchForCandidate, SearchForJob } from '../../components/svgs/Svgs'
import { Layout } from '../../components/common/Layout'
import { useMessage } from '../../hooks/message'
import { BaseContext } from '../../contexts/BaseContextWrapper'
import useVerification from '../../hooks/verificationHook'
import { handleSendCodeAgain } from '../../utils/request'
import { deFormatPhone } from '../../utils/number'

const limitSec = 59

export const CountDown = forwardRef(({ submit, theme }, ref) => {
    const [countDown, setCountDown] = useState(limitSec)

    function sendCodeAgain() {
        setCountDown(limitSec)
    }

    useEffect(() => {
        if (countDown > 0) setTimeout(() => setCountDown(countDown - 1), 1000)
    }, [countDown])

    useImperativeHandle(ref, () => ({
        countDown,
        sendCodeAgain,
    }))

    return (
        <div
            onClick={() => countDown === 0 && submit()}
            className={cn(
                `align-center justify-center gap-2 ${countDown === 0 && 'pointer'}`,
                { [css(s.active)]: !countDown },
            )}
        >
            <Repeat />

            <span className={css(s.sendAgain)}>
                <FormattedMessage id="sendCodeAgain" />
            </span>

            {countDown ? (
                <span className={css(mode(theme).countDown)}>
                    00:{countDown < 10 ? `0${countDown}` : countDown}
                </span>
            ) : ''}
        </div>
    )
})

export default function Verification({ onSubmit, phoneNumber, $api, loading, pageLoading }) {
    const navigate = useNavigate()
    const { state } = useLocation()
    const [showMessage] = useMessage()
    const { theme } = useContext(BaseContext)
    const { digits, handleChange, inputRefs, handlePaste, getCode, setDigits } = useVerification()

    const ref = useRef()

    const submitCode = () => {
        const code = getCode()
        if (code.toString().length === 4) {
            onSubmit(code, ref.current.countDown)
        }
    }

    useEffect(() => {
        inputRefs[0].current.focus()
    }, [])

    const handleKeyDown = (e, index) => {
        if (e.key === 'Enter') {
            e.preventDefault()
            submitCode()
        } else if (e.key === 'Backspace' && digits[index] === '' && index > 0) {
            e.preventDefault()
            if (inputRefs[index - 1] && inputRefs[index - 1].current) {
                inputRefs[index - 1].current.focus()
            }
        } else if (e.key.match(/^\d$/) && index < 4) {
            e.preventDefault()

            if (e.key.match(/^\d$/)) {
                const newDigits = [...digits]
                newDigits[index] = e.key
                setDigits(newDigits)
            }
            if (inputRefs[index + 1] && inputRefs[index + 1].current) {
                inputRefs[index + 1].current.focus()
            }
        }
        if (e.key === 'ArrowLeft' && index !== 0) {
            e.preventDefault()

            if (inputRefs[index - 1] && inputRefs[index - 1].current) {
                inputRefs[index - 1].current.focus()
            }
        }
        if (e.key === 'ArrowRight' && index < 3) {
            e.preventDefault()

            if (inputRefs[index + 1] && inputRefs[index + 1].current) {
                inputRefs[index + 1].current.focus()
            }
        }
    }

    return (
        <Layout>
            <div className={css(s.wrapper)}>
                <div className={css(s.left)}>
                    <div className={cn(css(mode(theme).box), 'is-flex gap-1')}>
                        <a
                            href="https://hr.workly.uz/auth/recruiter"
                            target="_blank"
                            className={cn(css(mode(theme).link),
                                'is-flex align-center justify-center pointer gap-2')} rel="noreferrer"
                        >
                            <div><SearchForCandidate /></div>

                            <h3 className={css(s.title)}><FormattedMessage id="lookingForEmployees" /></h3>
                        </a>

                        <div
                            onClick={() => navigate('/')}
                            className={cn(css(mode(theme).link), css(s.activeLink),
                                'is-flex align-center justify-center pointer gap-2')}
                        >
                            <div>
                                <SearchForJob />
                            </div>

                            <h3 className={css(s.title)}><FormattedMessage id="lookingForJob" /></h3>
                        </div>
                    </div>
                </div>

                <div className={css(s.right)}>
                    <div className="is-flex gap-5 full-width">
                        <div className={css(mode(theme).chevronWrap)}
                            onClick={() => navigate(state.from, { state: { ...state } })}>
                            <ChevronNextSizeL />
                        </div>

                        <div className={css(mode(theme).boxWrap)}>
                            <div className={css(mode(theme).boxHeader)}>
                                <ChevronNextSizeL onClick={() => navigate(state.from, { state: { ...state } })} />

                                <h3><FormattedMessage id="enterConfirmationCode" /></h3>
                            </div>
                            <div className={css(s.boxBody)}>
                                <div className={css(mode(theme).cont)}>
                                    <p><FormattedMessage id="confirmationCodeSent" /></p>

                                    <div className={cn('align-center justify-between', css(s.options))}>
                                        <div className={css(mode(theme).editNum)}>
                                            +998 {phoneNumber}

                                            <EditSizeS onClick={() => navigate(state.from, { state: { ...state } })} />
                                        </div>

                                        <CountDown
                                            ref={ref}
                                            theme={theme}
                                            submit={() => handleSendCodeAgain(
                                                $api,
                                                `+998${deFormatPhone(phoneNumber)}`,
                                                showMessage,
                                                ref,
                                                <FormattedMessage id="codeSent" />,
                                            )} />
                                    </div>
                                </div>

                                <div className={css(s.inputsWrap)}>
                                    {digits.map((digit, index) => (
                                        <input
                                            type="text"
                                            name={`inp${index + 1}`}
                                            value={digit.toString()}
                                            onChange={(e) => handleChange(e, index)}
                                            onPaste={(e) => handlePaste(e, index)}
                                            maxLength={1}
                                            inputMode="numeric"
                                            ref={inputRefs[index]}
                                            pattern="[0-9]*"
                                            className={css(mode(theme).input)}
                                            /* eslint-disable-next-line react/no-array-index-key */
                                            key={index}
                                            onKeyDown={(e) => handleKeyDown(e, index)}
                                        />
                                    ))}
                                </div>

                                <Button
                                    type="submit"
                                    onClick={submitCode}
                                    className={cn(css(s.disabledBtn), 'is-flex align-center justify-center gap-2',
                                        { [css(s.enabledBtn)]: getCode().toString().length === 4 })}
                                >
                                    <FormattedMessage id="login" />

                                    {loading || pageLoading ? (
                                        <Oval
                                            height={16}
                                            width={16}
                                            color={COLORS.white}
                                            visible
                                            ariaLabel="oval-loading"
                                            secondaryColor={COLORS.white}
                                            strokeWidth={7}
                                            strokeWidthSecondary={7}

                                        />
                                    ) : null}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

const s = StyleSheet.create({
    wrapper: {
        display: 'flex',
        alignItems: 'baseline',
        justifyContent: 'center',
        padding: 32,
        '@media (max-width: 768px)': {
            padding: 16,
        },
    },
    right: {
        flex: 2.2,
        display: 'flex',
        justifyContent: 'left',
        '@media (max-width: 768px)': {
            justifyContent: 'center',
        },
    },
    left: {
        flex: 1,
        display: 'flex',
        alignItems: 'baseline',
        justifyContent: 'start',
        '@media (max-width: 768px)': {
            display: 'none',
        },
    },
    title: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    activeLink: {
        background: COLORS.mainColor,
        ':nth-child(1n) > h3': {
            color: COLORS.white,
        },
        ':nth-child(1n) > :first-child': {
            ':nth-child(1n) > :first-child': {
                ':nth-child(1n) > path': {
                    stroke: COLORS.white,
                },
            },
        },
    },
    options: {
        flexWrap: 'wrap',
        gap: 12,
    },
    boxBody: {
        padding: 24,
        '@media (max-width: 768px)': {
            padding: 12,
        },
    },
    sendAgain: {
        fontWeight: '500',
        fontSize: 12,
        color: COLORS.gray,
        whiteSpace: 'nowrap',
    },
    active: {
        ':nth-child(1n) > :first-child': {
            ':nth-child(1n) > path': {
                fill: COLORS.mainColor,
            },
        },
        ':nth-child(1n) > span': {
            color: COLORS.mainColor,
        },
        userSelect: 'none',
    },
    fld: {
        position: 'relative',
    },
    inputsWrap: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 32,
        marginTop: 24,
        '@media (max-width: 768px)': {
            gap: 24,
        },
    },
    disabledBtn: {
        background: COLORS.gray,
        width: '100%',
        border: 'none',
        padding: '7.5px 8px',
        // eslint-disable-next-line max-len
        boxShadow: '0px 4px 12px rgba(44, 67, 50, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.25), inset 0px 1px 0px rgba(255, 255, 255, 0.1)',
        borderRadius: '4px',
        cursor: 'pointer',
        marginTop: 24,
        fontWeight: '500',
        fontSize: 14,
        color: COLORS.white,
    },
    enabledBtn: {
        background: `linear-gradient(180deg, ${COLORS.mainColor} 0%, ${COLORS.lightMainColor} 100%)`,
    },
})

const mode = (theme) => StyleSheet.create({
    boxWrap: {
        height: 'fit-content',
        width: '35rem',
        background: theme === 'dark' ? COLORS.darkModeBg : COLORS.white,
        border: theme === 'dark' ? 'none' : `1px solid ${COLORS.smoothGray}`,
        boxShadow: '0px 2px 4px rgba(130, 130, 130, 0.05)',
        borderRadius: 8,
        '@media (max-width: 768px)': {
            width: '100%',
        },
    },
    boxHeader: {
        borderBottom: `1px solid ${theme === 'dark' ? COLORS.midGray : COLORS.smoothGray}`,
        padding: '22px 24px',
        fontWeight: '600',
        fontSize: 24,
        color: theme === 'dark' ? COLORS.white : COLORS.dark,
        display: 'flex',
        alignItems: 'center',
        gap: 4,
        ':nth-child(1n) > :first-child': {
            transform: 'rotate(180deg)',
            cursor: 'pointer',
            ':nth-child(1n) > path': {
                stroke: theme === 'dark' ? COLORS.white : COLORS.dark,
                strokeWidth: 2.5,
            },
            '@media (min-width: 768px)': {
                display: 'none',
            },
        },
        '@media (max-width: 768px)': {
            fontSize: 16,
            padding: 12,
        },
    },
    cont: {
        ':nth-child(1n) > p': {
            fontWeight: 500,
            fontSize: 12,
            color: theme === 'dark' ? COLORS.lightGray : COLORS.lightBlack,
            marginBottom: 8,
        },
        ':nth-child(1n) > :last-child': {
            marginTop: 8,
        },
    },
    chevronWrap: {
        cursor: 'pointer',
        ':nth-child(1n) > :first-child': {
            transform: 'rotate(180deg)',
            ':nth-child(1n) > path': {
                stroke: theme === 'dark' ? COLORS.white : COLORS.dark,
            },
        },
        background: theme === 'dark' ? COLORS.darkModeBg : COLORS.white,
        border: theme === 'dark' ? 'none' : `1px solid ${COLORS.smoothGray}`,
        borderRadius: 64,
        width: 36,
        height: 36,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '@media (max-width: 768px)': {
            display: 'none',
        },
    },
    link: {
        width: 32,
        height: 32,
        borderRadius: 2,
        transition: 'width ease 0.3s',
        textDecoration: 'none',
        ':nth-child(1n) > h3': {
            color: COLORS.gray,
            fontSize: 13,
            fontWeight: '500',
        },
        ':nth-child(1n) > :first-child': {
            marginLeft: 6,
            ':nth-child(1n) > :only-child': {
                ':nth-child(1n) > path': {
                    stroke: COLORS.gray,
                },
            },
        },
        ':hover': {
            width: '11.125rem',
            ':nth-child(1n) > :first-child': {
                marginLeft: 0,
            },
        },
    },
    box: {
        maxWidth: 220,
        padding: 4,
        borderRadius: 4,
        border: theme === 'dark' ? 'none' : '1px solid #EAEAEA',
        background: theme === 'dark' ? COLORS.itemDarkModeBg : COLORS.white,
        boxShadow: '0px 2px 4px 0px rgba(130, 130, 130, 0.05',
    },
    countDown: {
        fontWeight: '500',
        fontSize: 12,
        color: theme === 'dark' ? COLORS.lightGray : COLORS.lightBlack,
    },
    editNum: {
        display: 'flex',
        alignItems: 'center',
        gap: 8,
        background: theme === 'dark' ? COLORS.itemDarkModeBg : COLORS.white,
        border: theme === 'dark' ? 'none' : `1px solid ${COLORS.smoothGray}`,
        boxShadow: '0px 2px 4px rgba(130, 130, 130, 0.05)',
        borderRadius: 4,
        padding: 8,
        ':nth-child(1n) > :last-child': {
            cursor: 'pointer',
        },
        fontWeight: '500',
        fontSize: 14,
        color: theme === 'dark' ? COLORS.white : COLORS.dark,

    },
    input: {
        width: '100%',
        height: '4.5rem !important',
        textAlign: 'center',
        fontSize: 40,
        fontWeight: '600',
        color: theme === 'dark' ? COLORS.white : COLORS.dark,
        background: theme === 'dark' ? COLORS.itemDarkModeBg : COLORS.lotion,
        border: theme === 'dark' ? `1px solid ${COLORS.itemDarkModeBg}` : `1px solid ${COLORS.lightGray}`,
        borderRadius: 4,
        outline: 'none',
        ':focus': {
            border: `1px solid ${COLORS.gray}`,
        },
        '@media (max-width: 768px)': {
            height: '5vh',
            fontSize: 32,
        },
    },
})
