import React, { useContext } from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { useNavigate } from 'react-router-dom'
import Slider from 'react-slick'
import { range } from 'lodash'
import { FormattedMessage } from 'react-intl'
import { COLORS } from '../../utils/colors'
import { ArrowNextSizeM, ChevronNextSizeL } from '../svgs/Svgs'
import { CompaniesItem } from './CompaniesItem'
import noCompanies from '../../static/images/NoCompanies.png'
import { CompanyDefaultLogoSizeL } from '../svgs/SvgPictures'
import { LoadingSkeleton } from '../common/LoadingSkeleton'
import { BaseContext } from '../../contexts/BaseContextWrapper'

export default function TopCompanies({ companies, loading }) {
    const navigate = useNavigate()
    const { theme, lang } = useContext(BaseContext)

    function TopCompNextArrow({ onClick }) {
        return (
            <div style={{ display: onClick === null ? 'none' : 'block' }}>
                <div
                    onClick={onClick}
                    className={css(s.nextIcon)}>
                    <ChevronNextSizeL />
                </div>
            </div>
        )
    }

    function TopCompPrevArrow({ onClick }) {
        return (
            <div style={{ display: onClick === null ? 'none' : 'block' }}>
                <div
                    onClick={onClick}
                    className={css(s.prevIcon)}>
                    <ChevronNextSizeL />
                </div>
            </div>
        )
    }

    const settings = {
        arrows: true,
        dots: false,
        infinite: false,
        slidesToShow: 6,
        slidesToScroll: 1,
        cssEase: 'linear',
        nextArrow: <TopCompNextArrow />,
        prevArrow: <TopCompPrevArrow />,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 1440,
                settings: {
                    slidesToShow: 4,
                },
            },
            {
                breakpoint: 1560,
                settings: {
                    slidesToShow: 5,
                },
            },
        ],
    }

    return (
        <div className={cn(css(s.companiesWrap), 'is-flex direction-column gap-6')}>
            <div className={cn(css(mode(theme).contHeader), 'is-flex align-center justify-between')}>
                <h3>🌟 <FormattedMessage id="topCompanies" /></h3>

                {companies.length ? (
                    <div
                        className="is-flex align-end justify-center gap-2"
                        onClick={() => navigate('/companies')}
                    >
                        <h3><FormattedMessage id="showAll" /></h3>

                        <ArrowNextSizeM />
                    </div>
                ) : null}
            </div>

            {!loading ? (
                <React.Fragment>
                    <div className={css(s.slider)}>
                        <Slider {...settings}>
                            {companies.slice(0, 6).map((item) => (
                                <CompaniesItem theme={theme} key={item.id} item={item} lang={lang} />
                            ))}
                        </Slider>
                    </div>

                    <div className={css(s.mobile)}>
                        {companies.slice(0, 6).map((item) => (
                            <CompaniesItem theme={theme} key={item.id} item={item} lang={lang} />
                        ))}
                    </div>
                </React.Fragment>
            ) : (
                <div className={cn('is-flex align-center gap-7', css(s.skeletonWrap))}>
                    {range(0, 5).map((i) => (
                        <div key={i} className={cn(css(mode(theme).cardWrap), 'is-flex direction-column gap-3')}>
                            <div className={css(s.imgCont)}>
                                <div className={cn(css(s.defaultLogo), 'is-flex align-center justify-center')}>
                                    <CompanyDefaultLogoSizeL />
                                </div>
                            </div>

                            <div className={cn(css(s.infoWrap), 'is-flex direction-column gap-2')}>
                                <LoadingSkeleton height={18} />

                                <LoadingSkeleton height={13} />

                                <LoadingSkeleton height={14} />
                            </div>
                        </div>
                    ))}
                </div>
            )}

            {!companies.length && !loading ? (
                <div className={cn(css(mode(theme).emptyCont), 'is-flex direction-column align-center justify-center')}>
                    <img src={noCompanies} alt="#No Companies" />

                    <h3><FormattedMessage id="noRegisteredCompanies" /></h3>
                </div>
            ) : null}
        </div>
    )
}

const s = StyleSheet.create({
    companiesWrap: {
        width: '100%',
        '@media (max-width: 1024px)': {
            gap: 13,
        },
    },
    contHeader: {
        padding: '0 32px',
        '@media (max-width: 1024px)': {
            padding: '0 16px',
        },
        ':nth-child(1n) > :first-child': {
            color: COLORS.lightBlack,
            fontSize: 24,
            fontWeight: '600',
            '@media (max-width: 1024px)': {
                fontSize: 16,
            },
        },
        ':nth-child(1n) > :last-child': {
            cursor: 'pointer',
            ':nth-child(1n) > :first-child': {
                color: COLORS.lightBlack,
                fontSize: 15,
                fontWeight: '600',
                '@media (max-width: 1024px)': {
                    fontSize: 12,
                    fontWeight: '500',
                },
            },
        },
    },
    prevIcon: {
        left: 5,
        width: 46,
        zIndex: 2,
        top: '40%',
        height: 46,
        transform: 'rotate(-180deg)',
        cursor: 'pointer',
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 64,
        border: '1px solid rgba(139, 139, 139, 0.20)',
        background: 'rgba(255, 255, 255, 0.80)',
        boxShadow: '0px 2px 12px 0px rgba(0, 0, 0, 0.20)',
        backdropFilter: 'blur(6px)',
        ':nth-child(1n) > :only-child': {
            ':nth-child(1n) > path': {
                stroke: COLORS.dark,
                strokeWidth: 3,
            },
        },
        '@media (max-width: 1024px)': {
            display: 'none',
        },
    },
    nextIcon: {
        right: 5,
        left: 'auto',
        width: 46,
        zIndex: 2,
        top: '40%',
        height: 46,
        cursor: 'pointer',
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 64,
        transform: 'rotate(360deg)',
        border: '1px solid rgba(139, 139, 139, 0.20)',
        background: 'rgba(255, 255, 255, 0.80)',
        boxShadow: '0px 2px 12px 0px rgba(0, 0, 0, 0.20)',
        backdropFilter: 'blur(6px)',
        ':nth-child(1n) > :only-child': {
            ':nth-child(1n) > path': {
                stroke: COLORS.dark,
                strokeWidth: 3,
            },
        },
        '@media (max-width: 1024px)': {
            display: 'none',
        },
    },
    slider: {
        '@media (max-width: 1024px)': {
            display: 'none',
        },
    },
    mobile: {
        paddingLeft: 16,
        display: 'none',
        alignItems: 'center',
        gap: 12,
        overflowX: 'scroll',
        scrollbarWidth: 'none', // Firefox
        '-ms-overflow-style': 'none', // Internet Explorer
        '::-webkit-scrollbar': {
            display: 'none', // Safari and Chrome
        },
        '@media (max-width: 1024px)': {
            display: 'flex',
        },
    },
    defaultLogo: {
        height: '14rem',
        borderRadius: 2,
        background: COLORS.mainColor,
    },
    infoWrap: {
        ':nth-child(1n) > :first-child': {
            overflow: 'hidden',
            color: COLORS.dark,
            textOverflow: 'ellipsis',
            fontSize: 18,
            fontWeight: '600',
        },
        ':nth-child(1n) > :last-child': {
            overflow: 'hidden',
            color: COLORS.lightBlack,
            textOverflow: 'ellipsis',
            fontSize: 14,
            fontWeight: '500',
        },
    },
    imgCont: {
        height: '14rem',
        boxSizing: 'border-box',
        ':nth-child(1n) > img': {
            objectFit: 'cover',
            width: '100%',
            height: 'inherit',
        },
    },
    skeletonWrap: {
        margin: '0 32px',
        overflowX: 'scroll',
        scrollbarWidth: 'none', // Firefox
        '-ms-overflow-style': 'none', // Internet Explorer
        '::-webkit-scrollbar': {
            display: 'none', // Safari and Chrome
        },
        '@media (max-width: 1024px)': {
            margin: '0 16px',
            gap: 12,
        },
    },
})

const mode = (theme) => StyleSheet.create({
    contHeader: {
        padding: '0 32px',
        color: theme === 'dark' ? COLORS.white : COLORS.lightBlack,
        '@media (max-width: 1024px)': {
            padding: '0 16px',
        },
        ':nth-child(1n) > :first-child': {
            fontSize: 24,
            fontWeight: '600',
            '@media (max-width: 1024px)': {
                fontSize: 16,
            },
        },
        ':nth-child(1n) > :last-child': {
            cursor: 'pointer',
            ':nth-child(1n) > :first-child': {
                fontSize: 15,
                fontWeight: '600',
                '@media (max-width: 1024px)': {
                    fontSize: 12,
                    fontWeight: '500',
                },
            },
            ':nth-child(1n) > :last-child': {
                ':nth-child(1n) > path': {
                    stroke: theme === 'dark' ? COLORS.white : COLORS.lightBlack,
                },
            },
        },
    },
    cardWrap: {
        minWidth: '17rem',
        maxWidth: '20rem',
        padding: 16,
        boxSizing: 'border-box',
        cursor: 'pointer',
        borderRadius: 4,
        border: `1px solid ${theme === 'dark' ? COLORS.itemDarkModeBg : COLORS.smoothGray}`,
        background: theme === 'dark' ? COLORS.darkModeBg : COLORS.white,
        boxShadow: '0px 2px 4px 0px rgba(130, 130, 130, 0.05)',
        '@media (max-width: 1200px)': {
            padding: 12,
        },
    },
    infoWrap: {
        ':nth-child(1n) > :first-child': {
            overflow: 'hidden',
            color: theme === 'dark' ? COLORS.white : COLORS.dark,
            textOverflow: 'ellipsis',
            fontSize: 18,
            fontWeight: '600',
        },
        ':nth-child(1n) > :last-child': {
            overflow: 'hidden',
            color: theme === 'dark' ? COLORS.gray : COLORS.lightBlack,
            textOverflow: 'ellipsis',
            fontSize: 14,
            fontWeight: '500',
        },
    },
    industry: {
        overflow: 'hidden',
        color: theme === 'dark' ? COLORS.midGray : COLORS.gray,
        textOverflow: 'ellipsis',
        fontSize: 13,
        fontWeight: '400',
    },
    emptyCont: {
        padding: 32,
        ':nth-child(1n) > :first-child': {
            width: 410,
            height: 244,
        },
        ':nth-child(1n) > :last-child': {
            color: COLORS.midGray,
            fontSize: 13,
            fontWeight: '600,',
        },
        '@media (max-width: 768px)': {
            padding: '32px 0 0 0',
            ':nth-child(1n) > :first-child': {
                width: 'inherit',
                height: 244,
            },
        },
    },
})
