import { StyleSheet, css } from 'aphrodite'
import cn from 'classnames'
import React, { useContext, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Oval } from 'react-loader-spinner'
import { BaseContext } from '../../contexts/BaseContextWrapper'
import { useMessage } from '../../hooks/message'
import { usePostRequest } from '../../hooks/request'
import { CANDIDATE_VERIFY_EMAIL } from '../../urls'
import { COLORS } from '../../utils/colors'
import { genderOptions } from '../../utils/options'
import AmazingTooltip from '../common/AmazingTooltip'
import Button from '../common/Button'
import { LoadingSkeleton } from '../common/LoadingSkeleton'
import Modal from '../common/Modal'
import { BriefCaseSizeL,
    CalendarMinusSizeL,
    EditSizeL,
    MailSizeL,
    MapPinSizeL,
    PhoneSizeL,
    Repeat,
    UsersGender,
    VerifiedSizeS,
    WalletSizeL } from '../svgs/Svgs'
import EmailVerificationModal from './EmailVerificationModal'
import UpdateMainInfoModal from './UpdateMainInfoModal'
import { resendVerification } from '../../utils/request'

const Skeleton = ({ theme }) => (
    <div className={css(mode(theme).boxWrap)}>
        <div className="justify-between-start">
            <div className={cn('align-center gap-7', css(s.hero))}>
                <div>
                    <LoadingSkeleton width={64} height={64} borderRadius={50} />
                </div>

                <div className={cn('align-center gap-2', css(s.fullName))}>
                    <LoadingSkeleton />

                    <LoadingSkeleton />
                </div>
            </div>

            <div className={css(s.toolWrap)}>
                <div className={css(s.editIcon)}>
                    <EditSizeL />
                </div>
            </div>
        </div>

        <div className={css(s.infosWrap)}>
            <div className="align-start gap-5 flex-1">
                <div className={css(mode(theme).icon)}>
                    <PhoneSizeL />
                </div>

                <div className={css(mode(theme).info)}>
                    <h3><FormattedMessage id="phoneNumber" /></h3>

                    <LoadingSkeleton height={19} />
                </div>
            </div>

            <div className="align-start gap-5 flex-1">
                <div className={css(mode(theme).icon)}>
                    <UsersGender />
                </div>

                <div className={css(mode(theme).info)}>
                    <h3><FormattedMessage id="gender" /></h3>

                    <LoadingSkeleton height={19} />
                </div>
            </div>

            <div className="align-start gap-5 flex-1">
                <div className={css(mode(theme).icon)}>
                    <MailSizeL />
                </div>

                <div className={css(mode(theme).info)}>
                    <h3><FormattedMessage id="email" /></h3>

                    <LoadingSkeleton height={19} />
                </div>
            </div>
        </div>
    </div>
)

const StatusBtn = ({ api, getInfo, value, limit, setModal }) => {
    const [showMessage] = useMessage()

    const sendRequest = async (email) => {
        if (!email || api.loading) return

        const { success, error } = await resendVerification(api, email)

        if (success) {
            setModal({ active: true, type: 'email' })

            getInfo.setResponse((prev) => ({
                ...getInfo.response,
                data: {
                    ...prev.data,
                    emailConfirmLimit: prev.data.emailConfirmLimit !== 0
                        ? prev.data.emailConfirmLimit - 1 : prev.data.emailConfirmLimit,
                },
            }))
            return
        }

        showMessage(error.data.message || error.data.errors[0].message, 'error-msg')
    }

    return (
        <React.Fragment>
            {limit === 3 ? (
                <Button
                    type="button"
                    onClick={() => sendRequest(value)}
                    className={cn(css(s.confirmBtn), 'flex-cent gap-1')}
                >
                    <h3><FormattedMessage id="verifyMail" /></h3>

                    {api.loading ? (
                        <Oval
                            height={16}
                            width={16}
                            color={COLORS.mainColor}
                            visible
                            ariaLabel="oval-loading"
                            secondaryColor={COLORS.mainColor}
                            strokeWidth={7}
                            strokeWidthSecondary={7} />
                    ) : null}
                </Button>
            ) : (
                <div
                    onClick={() => sendRequest(value)}
                    className={cn(css(s.sndCodeTrig), `is-flex gap-2 pointer ${api.loading ? 'spin' : ''}`)}
                >
                    <Repeat />

                    <p><FormattedMessage id={limit === 2 ? 'sendMailAgain' : 'lastAttempt'} /></p>
                </div>
            )}
        </React.Fragment>
    )
}

export default function MainInfo({ info, putRequest, getInfo, theme }) {
    const [showModal, setShowModal] = useState({ active: false, type: 'form' })
    const { lang } = useContext(BaseContext)
    // eslint-disable-next-line max-len
    const Avatar = info && info.firstName && info.lastName ? `${info.firstName.slice(0, 1)}${info.lastName.slice(0, 1)}` : ''
    const verifyEmail = usePostRequest({ url: CANDIDATE_VERIFY_EMAIL })

    return !getInfo.loading ? (
        <div className={css(mode(theme).boxWrap)}>
            <div className="justify-between-start">
                <div className={cn('align-center gap-7', css(s.hero))}>
                    <div className={css(mode(theme).avatar)}>
                        {info.photo && info.photo.length
                            ? <img src={info.photo[0]} alt="#UserImg" />
                            : <h3>{Avatar}</h3>}
                    </div>

                    <div className={cn('align-center gap-2', css(mode(theme).fullName))}>
                        <h3>{info.firstName}</h3>

                        <h3>{info.lastName}</h3>
                    </div>
                </div>

                <div className={css(s.toolWrap)}>
                    <AmazingTooltip
                        className={css(s.toolTip)}
                        trigger={(
                            <div onClick={() => setShowModal({ active: true, type: 'form' })}
                                className={css(mode(theme).editIcon)}>
                                <EditSizeL />
                            </div>
                        )}
                    >
                        <h3><FormattedMessage id="edit" /></h3>
                    </AmazingTooltip>
                </div>

                <div className={css(s.mobileIcon)}>
                    <div onClick={() => setShowModal({ active: true, type: 'form' })}
                        className={css(mode(theme).editIcon)}>
                        <EditSizeL />
                    </div>
                </div>
            </div>

            <div className={css(s.infosWrap)}>
                {info.email && (
                    <div className="align-start gap-5 flex-1">
                        <div className={css(mode(theme).icon)}>
                            <MailSizeL />
                        </div>

                        <div className={css(mode(theme).info)}>
                            <h3><FormattedMessage id="email" /></h3>

                            <h3>{info.email}</h3>

                            {info.emailConfirmed
                                ? (
                                    <div className={cn(css(s.verifiedMail), 'is-flex gap-1')}>
                                        <VerifiedSizeS color={COLORS.mainColor} />

                                        <h3><FormattedMessage id="emailConfirmed" /></h3>
                                    </div>
                                ) : info.emailConfirmLimit !== 0 ? (
                                    <StatusBtn
                                        getInfo={getInfo}
                                        api={verifyEmail}
                                        value={info.email}
                                        setModal={setShowModal}
                                        limit={info.emailConfirmLimit} />
                                ) : (
                                    <div className="flex-cent gap-1">
                                        <Repeat />

                                        <h3 className={css(s.tryLater)}><FormattedMessage id="tryAfter" /></h3>
                                    </div>
                                )}
                        </div>
                    </div>
                )}

                {info.phoneNumber && (
                    <div className="align-start gap-5 flex-1">
                        <div className={css(mode(theme).icon)}>
                            <PhoneSizeL />
                        </div>

                        <div className={css(mode(theme).info)}>
                            <h3><FormattedMessage id="phoneNumber" /></h3>

                            <h3>{info.phoneNumber}</h3>
                        </div>
                    </div>
                )}

                {info.gender && (
                    <div className="align-start gap-5 flex-1">
                        <div className={css(mode(theme).icon)}>
                            <UsersGender />
                        </div>

                        <div className={css(mode(theme).info)}>
                            <h3><FormattedMessage id="gender" /></h3>

                            <h3>{info.gender && genderOptions.find((e) => e.value === info.gender)[lang]}</h3>
                        </div>
                    </div>
                )}

                {info.birthDate && (
                    <div className="align-start gap-5 flex-1">
                        <div className={css(mode(theme).icon)}>
                            <CalendarMinusSizeL />
                        </div>

                        <div className={css(mode(theme).info)}>
                            <h3><FormattedMessage id="birthDate" /></h3>

                            <h3>{info.birthDate && info.birthDate.split('-').reverse().join().replace(/,/gi, '.')}</h3>
                        </div>
                    </div>
                )}

                {info.desiredSalary && (
                    <div className="align-start gap-5 flex-1">
                        <div className={css(mode(theme).icon)}>
                            <WalletSizeL />
                        </div>

                        <div className={css(mode(theme).info)}>
                            <h3><FormattedMessage id="desiredSalary" /></h3>

                            <h3>
                                {info.desiredSalary && info.desiredSalary.toLocaleString('en-US').replace(/,/gi, ' ')}
                            </h3>
                        </div>
                    </div>
                )}

                {info.location && (
                    <div className="align-start gap-5 flex-1">
                        <div className={cn(css(mode(theme).icon), css(mode(theme).mapPin))}>
                            <MapPinSizeL />
                        </div>

                        <div className={css(mode(theme).info)}>
                            <h3><FormattedMessage id="address" /></h3>

                            <h3>{info.location}</h3>
                        </div>
                    </div>
                )}

                <div className="align-center gap-5 flex-1">
                    <div className={css(mode(theme).icon)}>
                        <BriefCaseSizeL />
                    </div>

                    <div className={css(mode(theme).info)}>
                        <h3><FormattedMessage id="remotePossible" /></h3>

                        <h3>{info.remote ? <FormattedMessage id="yes" /> : <FormattedMessage id="no" />}</h3>
                    </div>
                </div>
            </div>

            <Modal
                closeOnEscape
                open={showModal.active}
                closeOnDocumentClick
                contentStyle={{ zIndex: 10 }}
                overlayStyle={{ top: 52, width: '100%', display: 'flex', alignItems: 'center' }}
                // contentStyle={modalContentStyle}
                onClose={() => setShowModal((prev) => ({ ...prev, active: false }))}
                onOpen={() => setShowModal((prev) => ({ ...prev, active: true }))}
            >
                {showModal.type === 'form' ? (
                    <UpdateMainInfoModal
                        info={info}
                        getInfo={getInfo}
                        putRequest={putRequest}
                        onClose={() => setShowModal((prev) => ({ ...prev, active: false }))}
                    />
                ) : (
                    <EmailVerificationModal
                        email={info.email}
                        onClose={() => setShowModal((prev) => ({ ...prev, active: false }))} />
                )}
            </Modal>
        </div>
    ) : <Skeleton theme={theme} />
}

const s = StyleSheet.create({
    hero: {
        '@media (max-width: 1100px)': {
            gap: 12,
        },
    },
    toolWrap: {
        '@media (max-width: 1100px)': {
            display: 'none',
        },
    },
    mobileIcon: {
        '@media (min-width: 1100px)': {
            display: 'none',
        },
    },
    infosWrap: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        alignItems: 'baseline',
        paddingTop: 24,
        gridRowGap: 25,
        gridColumnGap: 10,
        '@media (max-width: 768px)': {
            gridTemplateColumns: '1fr 1fr',
            gridRowGap: 16,
        },
        '@media (max-width: 412px)': {
            gridTemplateColumns: '1fr',
        },
    },
    toolTip: {
        zIndex: 1,
    },
    confirmBtn: {
        borderRadius: 4,
        border: '1px solid #F0E5C2',
        background: '#FFF4D3',
        whiteSpace: 'nowrap',
        padding: '4px 8px',
        ':nth-child(1n) > h3': {
            color: '#EF9E00',
            fontSize: 12,
            fontWeight: '500',
        },
    },
    verifiedMail: {
        width: 'fit-content',
        borderRadius: 4,
        border: `1px solid ${COLORS.smoothGray}`,
        background: COLORS.white,
        padding: '4px 8px',
        ':nth-child(1n) > h3': {
            color: COLORS.mainColor,
            fontSize: 12,
            fontWeight: '500',
        },
    },
    sndCodeTrig: {
        userSelect: 'none',
        ':nth-child(1n) > :first-child': {
            ':nth-child(1n) > path': {
                fill: COLORS.mainColor,
            },
        },
        ':nth-child(1n) > :last-child': {
            color: COLORS.mainColor,
            fontWeight: '500',
            fontSize: 12,
            whiteSpace: 'nowrap',
        },
    },
    tryLater: {
        fontWeight: '500',
        fontSize: 12,
        whiteSpace: 'nowrap',
        color: COLORS.gray,
    },
})

const mode = (theme) => StyleSheet.create({
    boxWrap: {
        background: theme === 'dark' ? COLORS.darkModeBg : COLORS.white,
        border: theme === 'dark' ? 'none' : `1px solid ${COLORS.smoothGray}`,
        boxShadow: '0px 2px 4px rgba(130, 130, 130, 0.05)',
        borderRadius: 4,
        padding: '24px 16px 24px 24px',
        '@media (max-width: 768px)': {
            padding: '16px 8px 16px 16px',
        },
    },
    avatar: {
        width: 64,
        height: 64,
        background: theme === 'dark' ? 'rgba(255, 255, 255, 0.10)' : COLORS.white,
        border: theme === 'dark' ? 'none' : `1px solid ${COLORS.lightGray}`,
        boxShadow: '0px 2px 4px rgba(130, 130, 130, 0.05)',
        borderRadius: 50,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ':nth-child(1n) > h3': {
            fontWeight: '500',
            fontSize: 24,
            color: theme === 'dark' ? COLORS.white : COLORS.dark,
            '@media (max-width: 768px)': {
                fontSize: 16,
            },
        },
        ':nth-child(1n) > img': {
            width: 'inherit',
            height: 'inherit',
            borderRadius: 50,
        },
        '@media (max-width: 768px)': {
            width: 44,
            height: 44,
        },
    },
    fullName: {
        fontWeight: '600',
        fontSize: 24,
        color: theme === 'dark' ? COLORS.white : COLORS.dark,
        textDecoration: 'none',
        '@media (max-width: 1100px)': {
            fontSize: 20,
        },
        '@media (max-width: 768px)': {
            fontSize: 16,
        },
    },
    editIcon: {
        padding: '8px 8px 4px 8px',
        cursor: 'pointer',
        border: theme === 'dark' ? 'none' : `1px solid ${COLORS.white}`,
        ':hover': {
            border: theme === 'dark' ? 'none' : `1px solid ${COLORS.smoothGray}`,
            background: theme === 'dark' ? 'rgba(255, 255, 255, 0.10)' : 'inherit',
            boxShadow: '0px 2px 4px rgba(130, 130, 130, 0.05)',
            borderRadius: 4,
            ':nth-child(1n) > :only-child': {
                ':nth-child(1n) > path': {
                    stroke: theme === 'dark' ? COLORS.white : COLORS.gray,
                },
            },
        },
    },
    info: {
        display: 'flex',
        flexDirection: 'column',
        gap: 4,
        ':nth-child(1n) > :first-child': {
            fontWeight: 500,
            fontSize: 12,
            color: COLORS.gray,
        },
        ':nth-child(1n) > :last-child': {
            fontWeight: '600',
            fontSize: 16,
            color: theme === 'dark' ? COLORS.white : COLORS.dark,
            '@media (max-width: 768px)': {
                fontSize: 14,
            },
        },
    },
    icon: {
        background: theme === 'dark' ? 'rgba(237, 237, 238, 0.10)' : COLORS.smoothGray,
        borderRadius: 4,
        padding: '6px 6px 4px 6px',
    },
    mapPin: {
        ':nth-child(1n) > :first-child': {
            ':nth-child(1n) > path': {
                stroke: COLORS.gray,
            },
        },
    },
})
