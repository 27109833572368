import React, { useContext } from 'react'
import { css, StyleSheet } from 'aphrodite'
import SkillsForm from './SkillsForm'
import CoverLetterForm from './CoverLetterForm'
import { BaseContext } from '../../../contexts/BaseContextWrapper'

export default function GeneralForm({ setFieldValue, info }) {
    const { theme } = useContext(BaseContext)

    return (
        <div className={css(s.wrapper)}>
            <SkillsForm theme={theme} info={info} setFieldValue={setFieldValue} />

            <CoverLetterForm theme={theme} />
        </div>
    )
}

const s = StyleSheet.create({
    wrapper: {
        flex: 2,
        display: 'flex',
        flexDirection: 'column',
        gap: 25,
        maxWidth: '56.688rem',
    },
})
