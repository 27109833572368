import React, { useContext, useRef, useState } from 'react'
import cn from 'classnames'
import { Field, getIn, useFormikContext } from 'formik'
import { css, StyleSheet } from 'aphrodite'
import { isEmpty } from 'lodash'
import { useIntl } from 'react-intl'
import { Calendar as CalendarIcon } from '../svgs/Svgs'
import { COLORS } from '../../utils/colors'
import CalendarPopup from './CalendarPopup'
import { useOutsideDetector } from '../../hooks/outsideDetector'
import ValidationErrorMessage from './ValidationErrorMessage'
import { BaseContext } from '../../contexts/BaseContextWrapper'

export default function InputDate({
    isMulti,
    name,
    help,
    icon,
    label,
    checkBox,
    loading,
    onInput,
    validate,
    mainName,
    onChange,
    className,
    staticText,
    placeholder,
    noRequiredValidation,
    errorWithMessage = true,
    fieldClassName,
    labelClassName,
    ...attributes
}) {
    const { theme } = useContext(BaseContext)
    const fromRef = useRef()
    const toRef = useRef()
    const contRef = useRef()
    const intl = useIntl()
    const [showCalendar, setShowCalendar] = useState(false)
    const { touched, errors, setFieldValue, values } = useFormikContext()

    useOutsideDetector(contRef, (event, isOutside) => {
        if (isOutside) {
            setShowCalendar(false)
        }
    })
    const isRequired = typeof validate === 'function' && !noRequiredValidation

    const isValid = isMulti
        ? (getIn(errors, name[0]) && getIn(touched, name[0])) || (getIn(errors, name[1]) && getIn(touched, name[1])) // eslint-disable-line max-len
        : getIn(errors, name[0]) && getIn(touched, name[0])

    return (
        <div ref={contRef} className={cn(css(s.formField), fieldClassName)}>
            <div className={cn('control', css(s.field))}>
                {label ? (
                    <label className={cn(css(s.lb), 'align-center align-center justify-between')} htmlFor={name}>
                        <div>
                            {label} {isRequired && (
                                <span style={{ color: isValid ? COLORS.lightRed : COLORS.midGray }}>
                                    *
                                </span>
                            )}
                        </div>

                        <div>{checkBox}</div>
                    </label>
                ) : null}

                <div
                    onClick={() => (
                        isMulti && fromRef.current.value.length > 0 ? toRef.current.focus() : fromRef.current.focus()
                    )}
                    className={cn(
                        css(mode(theme, isValid).cont),
                        'form-inp_pl ',
                        { [css(s.icon)]: icon },
                        className,
                    )}
                >
                    <div className="align-start">
                        <Field
                            min="1900-01-01"
                            max="2099-12-31"
                            type="date"
                            placeholder={intl.formatMessage({ id: 'dateFormat' })}
                            innerRef={fromRef}
                            id={name[0]}
                            name={name[0]}
                            onClick={(e) => e.stopPropagation()}
                            onFocus={() => {
                                setShowCalendar(true)
                            }}
                            validate={validate}
                            className={cn('input', css(s.inp))}
                            {...attributes} />

                        {isMulti && (
                            <>
                                <div>-</div>

                                <Field
                                    min="1001-01-01"
                                    max="2199-12-31"
                                    type="date"
                                    placeholder={intl.formatMessage({ id: 'dateFormat' })}
                                    innerRef={toRef}
                                    id={name[1]}
                                    name={name[1]}
                                    onClick={(e) => e.stopPropagation()}
                                    onFocus={() => {
                                        setShowCalendar(true)
                                    }}
                                    validate={validate}
                                    className={cn('input', css(s.inp))}
                                    {...attributes} />
                            </>
                        )}
                    </div>

                    <CalendarIcon />
                </div>

                {help && (!errors[name] || !touched[name]) ? (
                    <p className={css(s.formHint)}>{help}</p>
                ) : null}

                {errorWithMessage && (
                    <ValidationErrorMessage
                        name={name[0]}
                        errorMsg={help ? <h3 style={{ fontSize: 12 }}>{help}</h3> : ''}
                    />
                )}
            </div>

            {showCalendar && (
                <CalendarPopup
                    name={name}
                    isMulti={isMulti}
                    selectedDay={!isEmpty(values[name])
                        ? !isMulti
                            ? new Date(values[name])
                            : { from: new Date(values[name][0]), to: new Date(values[name][1]) }
                        : null}
                    setFieldValue={setFieldValue}
                    onClose={() => setShowCalendar(false)}
                />
            )}
        </div>
    )
}

const s = StyleSheet.create({
    formField: {
        position: 'relative',
    },
    field: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    lb: {
        fontSize: 12,
        fontWeight: '500',
        color: COLORS.midGray,
        marginBottom: 8,
    },
    inp: {
        background: 'transparent',
        outline: 'none',
        border: 'none',
        width: '100%',
        overflow: 'hidden',
        padding: 0,
        fontSize: 14,
        fontWeight: '500',
        color: 'inherit',
        '::placeholder': {
            color: COLORS.gray,
        },
        fontFamily: 'Roboto, sans-serif',
    },
    formHint: {
        marginTop: 4,
        fontSize: 12,
        fontWeight: '400',
        color: COLORS.lightBlack,
    },
})

const mode = (theme, valid) => StyleSheet.create({
    cont: {
        borderRadius: 4,
        height: 42,
        padding: '0 9px 0 16px',
        display: 'flex',
        alignItems: 'center',
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: valid ? COLORS.lightRed : theme === 'dark' ? 'transparent' : COLORS.lightGray,
        justifyContent: 'space-between',
        ':nth-child(1n) > :first-child': {
            gap: '0.25rem',
            ':nth-child(1n) > *': {
                cursor: 'text',
            },
        },
    },
})
