import React, { useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { COLORS } from '../../utils/colors'
import Modal from '../common/Modal'
import WaitModal from './WaitModal'
import RejectInfo from './RejectInfo'
import MeetUpInfo from './MeetUpInfo'
import InviteInfo from './InviteInfo'
import OfferInfo from './OfferInfo'
import logo from '../../static/temporarily-images/experience_logo.png'
import { statusesInMultiLang } from '../../utils/options'
import { getFullDate } from '../../utils/date'

export default function AppliedVacanciesCard({ item, info, theme, lang }) {
    const navigate = useNavigate()
    const { pathname } = useLocation()
    const [showModal, setShowModal] = useState(false)

    const statuses = [
        {
            id: 1,
            status: statusesInMultiLang[0][lang],
            color: theme === 'dark' ? COLORS.smoothGray : COLORS.dark,
            navigate: () => setShowModal(true),
        },
        {
            id: 2,
            status: statusesInMultiLang[1][lang],
            color: COLORS.lightRed,
            navigate: () => navigate('/account/applies/reject',
                { state: { item, st: statusesInMultiLang[1][lang], info } }),
        },
        {
            id: 3,
            status: statusesInMultiLang[2][lang],
            color: COLORS.mainColor,
            navigate: () => navigate('/account/applies/meetup',
                { state: { item, st: statusesInMultiLang[2][lang] } }),
        },
        {
            id: 4,
            status: statusesInMultiLang[3][lang],
            color: COLORS.mainColor,
            navigate: () => navigate('/account/applies/invite',
                { state: { item, st: statusesInMultiLang[3][lang] } }),
        },
        {
            id: 5,
            status: statusesInMultiLang[4][lang],
            color: COLORS.mainColor,
            navigate: () => navigate('/account/applies/offer',
                { state: {
                    item,
                    st: statusesInMultiLang[4][lang],
                    firstName: info.firstName,
                    lastName: info.lastName,
                } }),
        },
    ]

    return (
        <div className={css(mode(theme).card)}>
            <div className={css(mode(theme).upperSection)}>
                <div className="is-flex align-center gap-2">
                    <div className={css(mode(theme).logo)}>
                        <img src={item.company.logo[1] || logo} alt="#Logo" />
                    </div>

                    <div className={css(mode(theme).info)}>
                        <Link
                            className={css(s.vacancy)} to={`/c/${item.company.slug}/${item.vacancy.slug}`}
                            state={{ from: pathname }}>
                            {item.vacancy.name.length > 30 ? item.vacancy.name.slice(0, 30) : item.vacancy.name}
                        </Link>

                        <Link to={`/c/${item.company.slug}`} className={css(s.companyName)} state={{ from: pathname }}>
                            {item.company.name}
                        </Link>
                    </div>
                </div>

                <div
                    className={css(mode(theme).statusWrap)}
                    onClick={statuses[item.status - 1].navigate}
                    style={{ color: statuses[item.status - 1].color }}
                >
                    <h3><FormattedMessage id="status" />:</h3>

                    <h3>{statuses[item.status - 1].status}</h3>
                </div>
            </div>

            <div className={css(s.lowerSection)}>
                <div className={css(mode(theme).dateInfo)}>
                    <h3><FormattedMessage id="appliedDate" /></h3>

                    <h3>{item.createdAt ? getFullDate(item.createdAt, 'DD MMMM YYYY', lang) : '———'}</h3>
                </div>

                <div className={css(mode(theme).dateInfo)}>
                    <h3><FormattedMessage id="responseDate" /></h3>

                    <h3>{item.updatedAt ? getFullDate(item.updatedAt, 'DD MMMM YYYY', lang) : '———'}</h3>
                </div>
            </div>

            <Modal
                closeOnEscape
                open={showModal}
                closeOnDocumentClick
                overlayStyle={{ top: 52 }}
                onClose={() => setShowModal(false)}
            >
                <WaitModal theme={theme} onClose={() => setShowModal(false)} item={item} />
            </Modal>

            {pathname.endsWith('reject') && (
                <RejectInfo />
            )}

            {pathname.endsWith('meetup') && (
                <MeetUpInfo />
            )}

            {pathname.endsWith('invite') && (
                <InviteInfo />
            )}

            {pathname.endsWith('offer') && (
                <OfferInfo />
            )}

        </div>
    )
}

const s = StyleSheet.create({
    lowerSection: {
        display: 'flex',
        alignItems: 'center',
        paddingTop: 12,
        ':nth-child(1n) > *': {
            flex: 1,
        },
    },
})

const mode = (theme) => StyleSheet.create({
    logo: {
        width: 24,
        height: 24,
        padding: 1,
        borderRadius: 2,
        border: `1px solid ${theme === 'dark' ? COLORS.itemDarkModeBg : COLORS.smoothGray}`,
        ':nth-child(1n) > img': {
            width: 'inherit',
            height: 'inherit',
            objectFit: 'cover',
        },
    },
    card: {
        padding: 12,
        borderRadius: 4,
        border: theme === 'dark' ? 'none' : `1px solid ${COLORS.smoothGray}`,
        background: theme === 'dark' ? COLORS.darkModeBg : COLORS.white,
        boxShadow: '0px 2px 4px 0px rgba(130, 130, 130, 0.05)',
    },
    upperSection: {
        paddingBottom: 12,
        borderBottom: `1px solid ${theme === 'dark' ? COLORS.itemDarkModeBg : COLORS.smoothGray}`,
        display: 'flex',
        alignItems: 'start',
        justifyContent: 'space-between',
    },
    info: {
        display: 'flex',
        alignItems: 'start',
        flexDirection: 'column',
        gap: 4,
        ':nth-child(1n) > :first-child': {
            color: theme === 'dark' ? COLORS.white : COLORS.dark,
            fontSize: 14,
            fontWeight: '700',
            textDecoration: 'none',
        },
        ':nth-child(1n) > :last-child': {
            color: COLORS.gray,
            fontSize: 12,
            fontWeight: '400',
            textDecoration: 'none',
        },
    },
    dateInfo: {
        display: 'flex',
        alignItems: 'start',
        flexDirection: 'column',
        gap: 4,
        ':nth-child(1n) > :first-child': {
            color: COLORS.midGray,
            fontSize: 11,
            fontWeight: '500',
        },
        ':nth-child(1n) > :last-child': {
            color: theme === 'dark' ? COLORS.smoothGray : COLORS.dark,
            fontSize: 13,
            fontWeight: '700',
        },
    },
    statusWrap: {
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        gap: 4,
        padding: '4px 8px',
        borderRadius: 16,
        border: theme === 'dark' ? 'none' : `1px solid ${COLORS.smoothGray}`,
        background: theme === 'dark' ? COLORS.itemDarkModeBg : COLORS.white,
        boxShadow: '0px 2px 4px 0px rgba(130, 130, 130, 0.05)',
        ':nth-child(1n) > :first-child': {
            color: COLORS.gray,
            fontSize: 10,
            fontWeight: '500',
        },
        ':nth-child(1n) > :last-child': {
            whiteSpace: 'nowrap',
            fontSize: 10,
            fontWeight: '500',
        },
    },
})
