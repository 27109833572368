import React from 'react'
import isEmpty from 'lodash/isEmpty'
import axios from 'axios'
import { FormattedMessage } from 'react-intl'
import baseAxios, { domain } from './request'
import { CANDIDATE_PROFILE_INFO, CANDIDATE_REFRESH_TOKEN } from '../urls'

export function auth() {
    if (isEmpty(localStorage.token)) {
        return {
            headers: {
                'accept-language': localStorage.lang,
            },
        }
    }

    return {
        headers: {
            'accept-language': localStorage.lang,
            Authorization: `Bearer ${JSON.parse(localStorage.token)}`,
        },
    }
}

export function getNewTokenByRefresh() {
    const refreshToken = JSON.parse(localStorage.getItem('refresh_token'))
    if (!refreshToken) clearStorage()

    axios.post(domain + CANDIDATE_REFRESH_TOKEN, {
        refresh_token: refreshToken,
        grant_type: 'refresh_token',
        client_id: process.env.REACT_APP_CLIENT_ID || '',
        client_secret: process.env.REACT_APP_CLIENT_SECRET || '',
    }).then(({ data }) => {
        localStorage.setItem('token', JSON.stringify(data.data.access_token))
        localStorage.setItem('refresh_token', JSON.stringify(data.data.refresh_token))
    }).catch(() => clearStorage())
}

export function clearStorage() {
    localStorage.clear()
    // eslint-disable-next-line no-restricted-globals
    location.reload()
}

export async function checkUser(setText, setClassName) {
    if (!localStorage.token) return

    try {
        const res = await baseAxios({ ...auth(), url: CANDIDATE_PROFILE_INFO })
        // eslint-disable-next-line consistent-return
        return { success: true, data: res.data }
    } catch (e) {
        if (e.response === undefined) {
            setText(<FormattedMessage id="checkYourNetwork" />)
            setClassName('error-msg')
        } else if (e.response.status >= 500) {
            setText(<FormattedMessage id="serverError" />)
            setClassName('error-msg')
        } else if (e.response.status >= 400 && e.response.status !== 401) clearStorage()

        else if (e.response.status === 401) getNewTokenByRefresh()
        // eslint-disable-next-line consistent-return
        return { success: false, error: e }
    }
}
