import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import { Link, useLocation } from 'react-router-dom'
import { range } from 'lodash'
import uuid from 'react-uuid'
import { COLORS } from '../../utils/colors'
import Modal from '../common/Modal'
import WaitModal from './WaitModal'
import RejectInfoModal from './RejectInfoModal'
import MeetUpInfoModal from './MeetUpInfoModal'
import InviteInfoModal from './InviteInfoModal'
import OfferInfoModal from './OfferInfoModal'
import logo from '../../static/temporarily-images/experience_logo.png'
import { LoadingSkeleton } from '../common/LoadingSkeleton'
import { statusesInMultiLang } from '../../utils/options'
import { getFullDate } from '../../utils/date'

export default function AppliedVacanciesItem({
    info,
    data,
    lang,
    theme,
    options,
    showModal,
    getApplied,
    setShowModal,
}) {
    const { pathname } = useLocation()

    const statuses = [
        {
            id: 1,
            status: statusesInMultiLang[0][lang],
            color: theme === 'dark' ? COLORS.lightGray : COLORS.dark,
            onClick: (item) => setShowModal({ act: true, type: 'waiting', item }),
        },
        {
            id: 2,
            status: statusesInMultiLang[1][lang],
            color: COLORS.lightRed,
            onClick: (item) => setShowModal({ act: true, type: 'reject', st: item.status, item }),
        },
        {
            id: 3,
            status: statusesInMultiLang[2][lang],
            color: COLORS.mainColor,
            onClick: (item) => setShowModal({ act: true, type: 'meetup', st: item.status, item }),
        },
        {
            id: 4,
            status: statusesInMultiLang[3][lang],
            color: COLORS.mainColor,
            onClick: (item) => setShowModal({ act: true, type: 'invite', st: item.status, item }),
        },
        {
            id: 5,
            status: statusesInMultiLang[4][lang],
            color: COLORS.mainColor,
            onClick: (item) => setShowModal({ act: true, type: 'offer', st: item.status, item }),
        },
    ]

    return !getApplied.loading ? (
        <React.Fragment>
            {data.map((item) => (
                <tr key={uuid()} className={css(mode(theme).row)}>
                    <td className={css(s.compInfo)}>
                        <div className="align-center gap-3">
                            <img src={item.company.logo[1] || logo} alt="#CompanyLogo" className={css(s.logo)} />

                            <Link
                                state={{ from: pathname }}
                                to={`/c/${item.company.slug}`}
                                className={css(mode(theme).companyName)}>
                                {item.company.name}
                            </Link>
                        </div>
                    </td>

                    <td className={css(s.item)}>
                        <Link
                            state={{ from: pathname }}
                            className={css(mode(theme).vacancy)}
                            to={`/c/${item.company.slug}/${item.vacancy.slug}`}>
                            {item.vacancy.name.length > 30 ? item.vacancy.name.slice(0, 30) : item.vacancy.name}
                        </Link>
                    </td>

                    <td
                        style={{ color: statuses[item.status - 1].color }}
                        className={css(s.item)}
                        onClick={() => statuses[item.status - 1].onClick(item)}>
                        {statuses[item.status - 1].status}
                    </td>

                    <td className={css(mode(theme).itemDate)}>
                        {item.createdAt ? getFullDate(item.createdAt, 'DD MMMM YYYY', lang) : '———'}
                    </td>

                    <td className={css(s.itemDate)}>
                        {item.updatedAt ? getFullDate(item.updatedAt, 'DD MMMM YYYY', lang) : '———'}
                    </td>
                </tr>
            ))}

            <Modal
                closeOnEscape
                closeOnDocumentClick
                open={showModal.act}
                overlayStyle={{ top: 52 }}
                onClose={() => setShowModal({ act: false })}
            >
                {showModal.type === 'waiting' && (
                    <WaitModal
                        theme={theme}
                        onClose={() => setShowModal({ act: false })}
                        item={showModal.item}
                    />
                )}

                {showModal.type === 'reject' && (
                    <RejectInfoModal
                        theme={theme}
                        onClose={() => setShowModal({ act: false })}
                        status={statuses}
                        info={info}
                        showModal={showModal}
                    />
                )}

                {showModal.type === 'meetup' && (
                    <MeetUpInfoModal
                        theme={theme}
                        lang={lang}
                        onClose={() => setShowModal({ act: false })}
                        status={statuses}
                        showModal={showModal}
                        getData={getApplied}
                    />
                )}

                {showModal.type === 'invite' && (
                    <InviteInfoModal
                        theme={theme}
                        onClose={() => setShowModal({ act: false })}
                        status={statuses}
                        showModal={showModal}
                        getData={getApplied}
                    />
                )}

                {showModal.type === 'offer' && (
                    <OfferInfoModal
                        theme={theme}
                        onClose={() => setShowModal({ act: false })}
                        status={statuses}
                        info={info}
                        lang={lang}
                        options={options}
                        showModal={showModal}
                        getData={getApplied}
                    />
                )}
            </Modal>
        </React.Fragment>
    ) : (
        range(0, 10).map((i) => (
            <tr key={i}>
                <td className={css(s.compInfo)}>
                    <div className="align-center gap-3">
                        <img src={logo} alt="#CompanyLogo" className={css(s.logo)} />

                        <LoadingSkeleton height={15} width={120} />
                    </div>
                </td>

                <td><LoadingSkeleton height={15} width={120} /></td>

                <td><LoadingSkeleton height={15} width={120} /></td>

                <td><LoadingSkeleton height={15} width={120} /></td>

                <td><LoadingSkeleton height={15} width={120} /></td>
            </tr>
        ))
    )
}

const s = StyleSheet.create({
    compInfo: {
        padding: '18px 36px',
    },
    logo: {
        width: 24,
        height: 24,
    },
    item: {
        cursor: 'pointer',
        fontSize: 13,
        fontWeight: 500,
        textDecoration: 'underline',
    },
})

const mode = (theme) => StyleSheet.create({
    companyName: {
        color: theme === 'dark' ? COLORS.lightGray : COLORS.dark,
        fontSize: 13,
        fontWeight: '500',
        textAlign: 'left',
        ':hover': {
            color: COLORS.skyblue,
        },
    },
    row: {
        color: theme === 'dark' ? COLORS.lightGray : COLORS.dark,
        fontSize: 13,
        fontWeight: '500',
        userSelect: 'none',
        textAlign: 'left',
    },
    vacancy: {
        color: theme === 'dark' ? COLORS.lightGray : COLORS.dark,
        ':hover': {
            color: COLORS.skyblue,
        },
    },
    itemDate: {
        color: theme === 'dark' ? COLORS.lightGray : COLORS.dark,
        fontSize: 13,
        fontWeight: 500,
    },
})
