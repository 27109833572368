import React, { useEffect, useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { useNavigate } from 'react-router-dom'
import ReCAPTCHA from 'react-google-recaptcha'
import { Oval } from 'react-loader-spinner'
import { FormattedMessage, useIntl } from 'react-intl'
import Button from '../../components/common/Button'
import { COLORS } from '../../utils/colors'
import FormInputPhone from '../../components/common/FormInputPhone'
import { email, required } from '../../utils/validators'
import FormInput from '../../components/common/FormInput'
import { ChevronNextSizeL } from '../../components/svgs/Svgs'
import { LoadingSkeleton } from '../../components/common/LoadingSkeleton'

export default function SignUp({
    handleChange,
    requiredFields,
    values,
    loading,
    theme,
    onRecaptchaChange,
    recaptchaRef,
    captchaValue,
}) {
    const [captchaVisible, setCaptchaVisible] = useState(false)
    const [isCaptchaChecked, setIsCaptchaChecked] = useState(true)
    const navigate = useNavigate()
    const intl = useIntl()

    useEffect(() => {
        setTimeout(() => {
            setCaptchaVisible(true)
        }, 2000)
    }, [])

    return (
        <div className={css(s.right)}>
            <div onClick={() => navigate(-1)} className={css(mode(theme).chevronWrap)}>
                <ChevronNextSizeL />
            </div>

            <div className={css(mode(theme).boxWrap)}>
                <div className={css(mode(theme).boxHeader)}>
                    <ChevronNextSizeL onClick={() => navigate(-1)} />

                    <h3><FormattedMessage id="registration" /></h3>
                </div>

                <div className={css(s.boxBody)}>
                    <FormInputPhone
                        id="phoneNumber"
                        inputMode="numeric"
                        label={<FormattedMessage id="phoneNumber" />}
                        name="phoneNumber"
                        labelClassName={css(mode(theme).labelPhoneInp)}
                        className={css(mode(theme).inputPhone)}
                        validate={required}
                        errorWithMessage={false}
                        fieldClassName={cn('is-flex direction-column gap-2', css(mode(theme).field))}
                        placeholder="01 234 56 78"
                        onChange={handleChange}
                    />

                    <FormInput
                        name="firstName"
                        label={<FormattedMessage id="firstName" />}
                        value={values.firstName}
                        labelClassName={css(mode(theme).label)}
                        className={css(mode(theme).input)}
                        placeholder={intl.formatMessage({ id: 'enterYourNamePlaceHolder' })}
                        validate={required}
                        errorWithMessage={false}
                        onChange={handleChange}
                    />

                    <FormInput
                        name="lastName"
                        label={<FormattedMessage id="lastName" />}
                        value={values.lastName}
                        labelClassName={css(mode(theme).label)}
                        className={css(mode(theme).input)}
                        placeholder={intl.formatMessage({ id: 'enterYourLastNamePlaceholder' })}
                        validate={required}
                        errorWithMessage={false}
                        onChange={handleChange}
                    />

                    <FormInput
                        name="email"
                        value={values.email}
                        label={<FormattedMessage id="email" />}
                        labelClassName={css(mode(theme).label)}
                        className={css(mode(theme).input)}
                        validate={email}
                        placeholder={intl.formatMessage({ id: 'exampleEmailPlaceholder' })}
                        onChange={handleChange}
                    />

                    {captchaVisible ? (
                        <div className={cn('recaptcha-container', css(s.reCaptcha), 'is-flex direction-column gap-2')}>
                            <ReCAPTCHA
                                ref={recaptchaRef}
                                size="normal"
                                theme="light"
                                sitekey={process.env.REACT_APP_SITE_KEY}
                                onChange={onRecaptchaChange}
                                badge="bottomleft"
                            />

                            {!isCaptchaChecked && (
                                <h3 style={{ color: 'red', fontSize: 12, fontWeight: '400' }}>
                                    <FormattedMessage id="pleaseConfirmNotRobot" />
                                </h3>
                            )}
                        </div>
                    ) : <LoadingSkeleton width={304} height={78} />}

                    <div>
                        <div className={css(mode(theme).agreement)}>
                            <FormattedMessage id="confirmUserAgreement" />

                            <a href={process.env.REACT_APP_PRIVACY_POLICY} target="_blank" rel="noreferrer">
                                <FormattedMessage id="userAgreement" />
                            </a>
                        </div>

                        <Button
                            type="submit"
                            onClick={() => setIsCaptchaChecked(!!captchaValue)}
                            className={cn(css(s.disabledBtn),
                                'is-flex align-center justify-center gap-2', { [css(s.enabledBtn)]:
                                 Object.values(requiredFields).every((e) => e !== undefined)
                                && captchaValue })}>
                            <FormattedMessage id="next" />

                            {loading ? (
                                <Oval
                                    height={16}
                                    width={16}
                                    color={COLORS.white}
                                    visible
                                    ariaLabel="oval-loading"
                                    secondaryColor={COLORS.white}
                                    strokeWidth={7}
                                    strokeWidthSecondary={7}
                                />
                            ) : null}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

const s = StyleSheet.create({
    right: {
        flex: 2,
        gap: 16,
        display: 'flex',
    },
    boxBody: {
        padding: 24,
        display: 'flex',
        flexDirection: 'column',
        gap: 24,
        '@media (max-width: 768px)': {
            padding: 12,
            gap: 16,
        },
    },
    reCaptcha: {
        width: '100%',
    },
    disabledBtn: {
        background: COLORS.gray,
        width: '100%',
        border: 'none',
        padding: '12px',
        // eslint-disable-next-line max-len
        boxShadow: '0px 4px 12px rgba(44, 67, 50, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.25), inset 0px 1px 0px rgba(255, 255, 255, 0.1)',
        borderRadius: '4px',
        cursor: 'not-allowed',
        marginTop: 12,
        fontWeight: '500',
        fontSize: 14,
        color: COLORS.white,
    },
    enabledBtn: {
        background: `linear-gradient(180deg, ${COLORS.mainColor} 0%, ${COLORS.lightMainColor} 100%)`,
        width: '100%',
        border: 'none',
        padding: '12px',
        // eslint-disable-next-line max-len
        boxShadow: '0px 4px 12px rgba(44, 67, 50, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.25), inset 0px 1px 0px rgba(255, 255, 255, 0.1)',
        borderRadius: '4px',
        cursor: 'pointer',
        marginTop: 12,
        fontWeight: '500',
        fontSize: 14,
        color: COLORS.white,
    },
})

const mode = (theme) => StyleSheet.create({
    boxWrap: {
        height: 'fit-content',
        width: '35rem',
        background: theme === 'dark' ? COLORS.darkModeBg : COLORS.white,
        border: theme === 'dark' ? 'none' : `1px solid ${COLORS.smoothGray}`,
        boxShadow: '0px 2px 4px rgba(130, 130, 130, 0.05)',
        borderRadius: 8,
        '@media (max-width: 768px)': {
            width: '100%',
        },
    },
    boxHeader: {
        borderBottom: `1px solid ${theme === 'dark' ? COLORS.midGray : COLORS.smoothGray}`,
        padding: '22px 24px',
        fontWeight: '600',
        fontSize: 24,
        color: theme === 'dark' ? COLORS.white : COLORS.dark,
        display: 'flex',
        alignItems: 'center',
        gap: 4,
        ':nth-child(1n) > :first-child': {
            transform: 'rotate(180deg)',
            cursor: 'pointer',
            ':nth-child(1n) > path': {
                stroke: theme === 'dark' ? COLORS.white : COLORS.dark,
                strokeWidth: 2.5,
            },
            '@media (min-width: 768px)': {
                display: 'none',
            },
        },
        '@media (max-width: 768px)': {
            fontSize: 16,
            padding: 12,
        },
    },
    labelPhoneInp: {
        fontWeight: 500,
        fontSize: '12px',
        color: theme === 'dark' ? COLORS.lightGray : COLORS.midGray,
    },
    label: {
        fontWeight: 500,
        fontSize: '12px',
        color: theme === 'dark' ? COLORS.lightGray : COLORS.midGray,
        marginBottom: 8,
    },
    inputPhone: {
        fontWeight: 500,
        fontSize: '14px',
        outline: 'none',
        borderRadius: 4,
        color: theme === 'dark' ? COLORS.white : COLORS.dark,
        background: theme === 'dark' ? COLORS.itemDarkModeBg : 'inherit',
        ':placeholder': {
            color: COLORS.gray,
        },
    },
    input: {
        fontWeight: 500,
        fontSize: '14px',
        outline: 'none',
        border: theme === 'dark' ? 'none' : `1px solid ${COLORS.lightGray}`,
        borderRadius: 4,
        color: theme === 'dark' ? COLORS.white : COLORS.dark,
        background: theme === 'dark' ? COLORS.itemDarkModeBg : 'inherit',
        ':placeholder': {
            color: COLORS.gray,
        },
    },
    field: {
        position: 'relative',
        ':nth-child(1n) > :last-child': {
            background: theme === 'dark' ? COLORS.itemDarkModeBg : 'none',
        },
    },
    agreement: {
        fontWeight: 400,
        fontSize: 11,
        color: COLORS.gray,
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        justifyContent: 'center',
        gap: 3,
        ':nth-child(1n) > :last-child': {
            textDecoration: 'none',
            color: COLORS.skyblue,
        },
    },
    chevronWrap: {
        cursor: 'pointer',
        ':nth-child(1n) > :first-child': {
            transform: 'rotate(180deg)',
            ':nth-child(1n) > path': {
                stroke: theme === 'dark' ? COLORS.white : COLORS.dark,
            },
        },
        background: theme === 'dark' ? COLORS.darkModeBg : COLORS.white,
        border: theme === 'dark' ? 'none' : `1px solid ${COLORS.smoothGray}`,
        borderRadius: 64,
        width: 36,
        height: 36,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '@media (max-width: 768px)': {
            display: 'none',
        },
    },
})
