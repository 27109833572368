import { StyleSheet, css } from 'aphrodite'
import cn from 'classnames'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { COLORS } from '../../utils/colors'
import Button from '../common/Button'
import { TickSizeL } from '../svgs/Svgs'

export default function EmailVerificationModal({ email, onClose }) {
    return (
        <div className={cn(css(s.wrapper), 'flex-cent direction-column gap-4')}>
            <span><TickSizeL /></span>

            <div className="flex-cent direction-column gap-2">
                <h3 className={css(s.sentMsg)}><FormattedMessage id="weSentMailLetter" /></h3>

                <div className={css(s.msgDescr)}>
                    <h3><FormattedMessage id="linkSentToMail" /> <span>{email}</span></h3>

                    <h3><FormattedMessage id="checkMailAndSpam" /></h3>
                </div>
            </div>

            <Button type="button" onClick={onClose}><FormattedMessage id="okay" /></Button>
        </div>
    )
}

const s = StyleSheet.create({
    wrapper: {
        padding: '20px 24px',
        background: COLORS.white,
        border: `1px solid ${COLORS.lightGray}`,
        borderRadius: 4,
        boxShadow: '0px 2px 4px 0px rgba(130, 130, 130, 0.05)',
        ':nth-child(1n) > :first-child': {
            padding: 12,
            borderRadius: '50%',
            background: 'rgba(46, 169, 125, 0.10)',
            boxShadow: '0px 2px 4px 0px rgba(42, 136, 104, 0.25)',
        },
        ':nth-child(1n) > :last-child': {
            width: '100%',
            borderRadius: 4,
            padding: '12px 0',
            textAlign: 'center',
            border: `1px solid ${COLORS.lightGray}`,
            background: COLORS.white,
            boxShadow: '0px 2px 4px 0px rgba(130, 130, 130, 0.05)',
        },
    },
    sentMsg: {
        fontSize: 20,
        fontWeight: '700',
        color: COLORS.dark,
    },
    msgDescr: {
        fontSize: 14,
        fontWeight: '400',
        textAlign: 'center',
        color: COLORS.lightBlack,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        ':nth-child(1n) > :first-child': {
            ':nth-child(1n) > span': {
                fontWeight: '700',
            },
        },
    },
})
