import React, { useContext, useEffect, useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { range } from 'lodash'
import uuid from 'react-uuid'
import { FormattedMessage } from 'react-intl'
import AppliedVacanciesItem from './AppliedVacanciesItem'
import Table from '../common/Table'
import AppliedVacanciesTableHeader from './AppliedVacanciesTableHeader'
import { useGetRequest, useInfiniteScroll } from '../../hooks/request'
import { APPLIED_VACANCIES } from '../../urls'
import AppliedVacanciesCard from './AppliedVacanciesCard'
import { NoItemsImageSizeL } from '../svgs/SvgPictures'
import { COLORS } from '../../utils/colors'
import logo from '../../static/temporarily-images/experience_logo.png'
import { LoadingSkeleton } from '../common/LoadingSkeleton'
import { BaseContext } from '../../contexts/BaseContextWrapper'
import { Context } from '../../contexts/GlobalContext'
import { SalaryReceiveOptions } from '../../utils/options'

export default function AppliedVacancies() {
    const { theme, lang } = useContext(BaseContext)
    const { info } = useContext(Context)
    const [showModal, setShowModal] = useState({ act: false })
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(20)
    // eslint-disable-next-line max-len
    const getAppliedVacancies = useGetRequest({ url: APPLIED_VACANCIES, params: { page, 'per-page': pageSize } })
    const appliedVacancies = getAppliedVacancies.response ? getAppliedVacancies.response.data : []
    // eslint-disable-next-line max-len
    const loadInfiniteAppliedVacancies = useInfiniteScroll({ url: APPLIED_VACANCIES }, [])
    const infiniteVacancies = loadInfiniteAppliedVacancies.response ? loadInfiniteAppliedVacancies.response.data : []
    const meta = getAppliedVacancies.response ? getAppliedVacancies.response.meta : []

    useEffect(() => {
        if (document.body.clientWidth > 768) getAppliedVacancies.request()
    }, [page, pageSize])

    return (
        <div className={css(s.wrap)}>
            <div className={css(s.tableWrap)}>
                <Table
                    theme={theme}
                    isStripped
                    isHoverable={theme !== 'dark'}
                    page={meta.currentPage}
                    pageCount={!getAppliedVacancies.loading ? meta.pageCount : 1}
                    count={meta.totalCount}
                    setPage={setPage}
                    loaderItemCount={10}
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                    className={css(s.table)}
                    customHeader={<AppliedVacanciesTableHeader theme={theme} />}>
                    <AppliedVacanciesItem
                        theme={theme}
                        info={info}
                        lang={lang}
                        getApplied={getAppliedVacancies}
                        data={appliedVacancies}
                        options={SalaryReceiveOptions}
                        showModal={showModal}
                        setShowModal={setShowModal}
                    />
                </Table>
            </div>

            <div className={cn(css(s.mobileCards), 'is-flex direction-column gap-5')}>
                {infiniteVacancies.map((item) => (
                    <AppliedVacanciesCard theme={theme} key={uuid()} lang={lang} item={item} info={info} />
                ))}

                {loadInfiniteAppliedVacancies.loading ? range(0, 6).map((i) => (
                    <div key={i} className={css(mode(theme).card)}>
                        <div className={css(mode(theme).upperSection)}>
                            <div className="is-flex align-center gap-2">
                                <div>
                                    <img src={logo} alt="#Logo" />
                                </div>

                                <div>
                                    <LoadingSkeleton height={15} />

                                    <LoadingSkeleton height={12} width={100} />
                                </div>
                            </div>

                            <div className={css(mode(theme).statusWrap)}>
                                <h3><FormattedMessage id="status" />:</h3>

                                <LoadingSkeleton height={12} width={70} />
                            </div>
                        </div>

                        <div className={css(s.lowerSection)}>
                            <div className={css(mode(theme).dateInfo)}>
                                <h3><FormattedMessage id="appliedDate" /></h3>

                                <LoadingSkeleton height={15} />
                            </div>

                            <div className={css(mode(theme).dateInfo)}>
                                <h3><FormattedMessage id="responseDate" /></h3>

                                <LoadingSkeleton height={15} />
                            </div>
                        </div>
                    </div>
                )) : null}

                {!loadInfiniteAppliedVacancies.loading
                    && loadInfiniteAppliedVacancies.hasMore
                    && <div ref={loadInfiniteAppliedVacancies.ref} />}
            </div>

            {!appliedVacancies.length && !infiniteVacancies.length ? (
                <div className={cn('is-flex direction-column align-center justify-center', css(s.emptyWrap))}>
                    <NoItemsImageSizeL />

                    <h3 className={css(s.txt)}><FormattedMessage id="youHaveNoApplies" /> 🤓</h3>
                </div>
            ) : null}
        </div>
    )
}

const s = StyleSheet.create({
    table: {
        width: '100%',
        textAlign: 'center',
    },
    tableWrap: {
        '@media (max-width: 768px)': {
            display: 'none',
        },
    },
    wrap: {
        overflowX: 'hidden',
        height: '100%',
        padding: '24px 32px 0 0',
        '@media (max-width: 1100px)': {
            padding: 16,
        },
    },
    txt: {
        color: COLORS.midGray,
        fontSize: 16,
        fontWeight: 600,
    },
    emptyWrap: {
        marginTop: '5.419rem',
    },
    mobileCards: {
        display: 'none',
        height: '100%',
        '@media (max-width: 768px)': {
            display: 'flex',
        },
    },
    lowerSection: {
        display: 'flex',
        alignItems: 'center',
        paddingTop: 12,
        ':nth-child(1n) > *': {
            flex: 1,
        },
    },
})

const mode = (theme) => StyleSheet.create({
    card: {
        padding: 12,
        borderRadius: 4,
        border: theme === 'dark' ? 'none' : `1px solid ${COLORS.smoothGray}`,
        background: theme === 'dark' ? COLORS.darkModeBg : COLORS.white,
        boxShadow: '0px 2px 4px 0px rgba(130, 130, 130, 0.05)',
    },
    upperSection: {
        paddingBottom: 12,
        borderBottom: `1px solid ${theme === 'dark' ? COLORS.itemDarkModeBg : COLORS.smoothGray}`,
        display: 'flex',
        alignItems: 'start',
        justifyContent: 'space-between',
    },
    statusWrap: {
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        gap: 4,
        padding: '4px 8px',
        borderRadius: 16,
        border: theme === 'dark' ? 'none' : `1px solid ${COLORS.smoothGray}`,
        background: theme === 'dark' ? COLORS.itemDarkModeBg : COLORS.white,
        boxShadow: '0px 2px 4px 0px rgba(130, 130, 130, 0.05)',
        ':nth-child(1n) > :first-child': {
            color: COLORS.gray,
            fontSize: 10,
            fontWeight: '500',
        },
    },
    dateInfo: {
        display: 'flex',
        alignItems: 'start',
        flexDirection: 'column',
        gap: 4,
        ':nth-child(1n) > :first-child': {
            color: COLORS.midGray,
            fontSize: 11,
            fontWeight: '500',
        },
    },
})
