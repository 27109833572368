import React, { useCallback, useContext, useEffect, useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { useNavigate } from 'react-router-dom'
import debounce from 'lodash/debounce'
import { range } from 'lodash'
import { FormattedMessage, useIntl } from 'react-intl'
import { Layout } from '../common/Layout'
import VacanciesFilter from './vacancyFilter/VacanciesFilter'
import { VacancyCardItem } from './VacancyCardItem'
import { Context } from '../../contexts/GlobalContext'
import { COLORS } from '../../utils/colors'
import { ChevronPrevSizeXL, DefaultLocation, FilterRows, MapPin, WalletSizeM } from '../svgs/Svgs'
import SearchInput from '../common/SearchInput'
import { NoItemsImageSizeM } from '../svgs/SvgPictures'
import Button from '../common/Button'
import { useInfiniteScroll } from '../../hooks/request'
import { PUBLIC_VACANCIES_LIST } from '../../urls'
import { useQueryParams } from '../../hooks/queryString'
import { LoadingSkeleton } from '../common/LoadingSkeleton'
import { BaseContext } from '../../contexts/BaseContextWrapper'
import { BottomSheetSlider } from '../common/BottomSheetSlider'

export default function Vacancies() {
    const { theme } = useContext(BaseContext)
    const intl = useIntl()
    const { search } = useQueryParams()
    const navigate = useNavigate()
    const { vacanciesFilter, setVacanciesFilter } = useContext(Context)
    const [openDrawer, setOpenDrawer] = useState(false)
    const [searchKey, setSearchKey] = useState(search || '')
    const { edu, age, salary, experience, industry, interval, addedDate, sortBy } = vacanciesFilter
    const params = {
        experience: experience ? experience.join(',') : undefined,
        age: age ? age.join(',') : undefined,
        salary: salary || undefined,
        educationLevel: edu ? edu.map((e) => e).join(',') : undefined,
        industry: industry ? industry.join(',') : undefined,
        date: addedDate,
        interval: interval || undefined,
        sort: sortBy || undefined,
        search,
    }
    const getVacancies = useInfiniteScroll({ url: PUBLIC_VACANCIES_LIST, params }, [search])
    const vacancies = getVacancies.response ? getVacancies.response.data : []
    const meta = getVacancies.response ? getVacancies.response.meta : {}

    useEffect(() => {
        if (Object.keys(vacanciesFilter).length > 1) {
            getVacancies.request({ params: { ...params, page: 1 } })
        } else {
            getVacancies.reload()
        }
    }, [vacanciesFilter])

    const sendRequest = useCallback(
        debounce((val) => {
            navigate(`?search=${val}`)
            getVacancies.reset()
        }, 500),
        [],
    )

    const onChangeInput = useCallback((value) => {
        setSearchKey(value)
        sendRequest(value)
    }, [])

    return (
        <Layout>
            <div className={cn('is-flex', css(s.cont))}>
                <VacanciesFilter
                    width="22%"
                    height="100%"
                    filter={vacanciesFilter}
                    setFilter={setVacanciesFilter}
                    vacancyAdditional={getVacancies.response ? getVacancies.response.additional : []}
                />

                <div className={cn(css(s.infoCont), 'is-flex direction-column')}>
                    <div className={cn('is-flex align-center justify-between', css(mode(theme).contHeader))}>
                        <div className="is-flex align-center justify-between full-width">
                            <div className={cn(css(mode(theme).chevronWrap), 'is-flex align-center gap-2')}>
                                <ChevronPrevSizeXL onClick={() => navigate('/')} className="pointer" />

                                <h3><FormattedMessage id="vacancies" /> <span>•</span> {meta ? meta.totalCount : 0}</h3>
                            </div>

                            <Button
                                onClick={() => setOpenDrawer(true)}
                                className={cn(css(s.filterBtn), 'is-flex align-center gap-2')}>
                                <h3><FormattedMessage id="filter" /></h3>

                                <FilterRows />
                            </Button>
                        </div>

                        <SearchInput
                            value={searchKey}
                            onChange={(value) => onChangeInput(value)}
                            placeholder={intl.formatMessage({ id: 'vacancySearchInputPlaceholder' })}
                            className={css(mode(theme).searchInp)}
                            magnifierColor={theme === 'dark' ? COLORS.gray : COLORS.lightBlack} />
                    </div>

                    <div className={css(s.wrap)}>
                        {vacancies.map((item) => (
                            <VacancyCardItem theme={theme} key={item.id} item={item} keyword={searchKey} />
                        ))}

                        {getVacancies.loading ? range(0, 20).map((i) => (
                            <div key={i} className={css(mode(theme).cardWrap)}>
                                <div className={css(s.cardBodyWrap)}>
                                    <div>
                                        <div className={css(s.compInfo)}>
                                            <div className={css(mode(theme).defaultLogo)}>
                                                <DefaultLocation color={COLORS.white} />
                                            </div>

                                            <div className={css(s.comp_titles)}>
                                                <LoadingSkeleton height={16} />

                                                <LoadingSkeleton width={100} height={13} />
                                            </div>
                                        </div>

                                        <div className={css(s.detailsWrap)}>
                                            <div className={css(mode(theme).detailWrap)}>
                                                <WalletSizeM />

                                                <LoadingSkeleton height={13} />
                                            </div>

                                            <div className={css(mode(theme).detailWrap)}>
                                                <MapPin />

                                                <LoadingSkeleton height={13} />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <LoadingSkeleton height={13} />
                            </div>
                        )) : null}

                        {!getVacancies.loading && getVacancies.hasMore && <div ref={getVacancies.ref} />}
                    </div>

                    {!vacancies.length && !getVacancies.loading ? (
                        <div className={cn(css(s.emptyCont),
                            'is-flex direction-column align-center justify-center')}>
                            <NoItemsImageSizeM />

                            <h3>{search === ''
                                ? <FormattedMessage id="byFilterNoItems" />
                                : <FormattedMessage id="bySearchNoItems" />}
                            </h3>
                        </div>
                    ) : null}
                </div>

                <BottomSheetSlider
                    open={openDrawer}
                    onClose={() => setOpenDrawer(false)}>
                    <VacanciesFilter
                        width="100%"
                        height="90%"
                        filter={vacanciesFilter}
                        setFilter={setVacanciesFilter}
                        vacancyAdditional={getVacancies.response ? getVacancies.response.additional : []}
                    />
                </BottomSheetSlider>
            </div>
        </Layout>
    )
}

const s = StyleSheet.create({
    ly: {
        width: '100%',
        scrollbarGutter: 'stable',
    },
    cont: {
        height: '100%',
        ':nth-child(1n) > :first-child': {
            flex: 1,
            minWidth: '18rem',
            '@media (max-width: 768px)': {
                display: 'none',
            },
        },
    },
    infoCont: {
        flex: 4,
        padding: '24px 32px 0 32px',
        '@media (max-width: 1024px)': {
            padding: '16px 16px 0 16px',
        },
        position: 'relative',
    },
    wrap: {
        padding: '24px 0 24px 0',
        overflowY: 'auto',
        display: 'grid',
        justifyContent: 'center',
        gridTemplateColumns: 'repeat(auto-fill, minmax(21rem, 1fr))',
        gap: 24,
        '@media (max-width: 1024px)': {
            gap: 12,
            padding: '16px 0 16px 0',
        },
        scrollbarWidth: 'none', // Firefox
        '-ms-overflow-style': 'none', // Internet Explorer
        '::-webkit-scrollbar': {
            display: 'none', // Safari and Chrome
        },
    },
    emptyCont: {
        ':nth-child(1n) > :last-child': {
            color: COLORS.midGray,
            fontSize: 13,
            fontWeight: '600,',
        },
        '@media (max-width: 768px)': {
            marginTop: 12,
        },
    },
    filterBtn: {
        padding: '4px 8px',
        color: COLORS.midGray,
        fontSize: 13,
        fontWeight: 600,
        borderRadius: 4,
        border: `1px solid ${COLORS.gray}`,
        background: COLORS.smoothGray,
        '@media (min-width: 768px)': {
            display: 'none',
        },
    },
    cardBodyWrap: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    compInfo: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        gap: '12px',
        marginBottom: '16px',
        ':nth-child(1n) > img': {
            width: 40,
            height: 40,
        },
    },
    comp_titles: {
        display: 'flex',
        flexDirection: 'column',
        gap: '4px',
        '@media (max-width: 768px)': {
            gap: 2,
            fontSize: 13,
        },
    },
    detailsWrap: {
        display: 'flex',
        flexDirection: 'column',
        gap: 8,
    },
})

const mode = (theme) => StyleSheet.create({
    contHeader: {
        backgroundColor: theme === 'dark' ? '#242424' : '#f6f6f6',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        '@media (max-width: 1024px)': {
            flexDirection: 'column',
            alignItems: 'start',
            gap: 8,
        },
    },
    chevronWrap: {
        color: theme === 'dark' ? COLORS.white : COLORS.dark,
        fontSize: 24,
        fontWeight: '600',
        cursor: 'pointer',
        '@media (max-width: 1024px)': {
            fontSize: 16,
        },
        ':nth-child(1n) > :first-child': {
            ':nth-child(1n) > path': {
                stroke: theme === 'dark' ? COLORS.white : COLORS.dark,
            },
        },
    },
    searchInp: {
        height: 40,
        width: '20.15rem',
        background: theme === 'dark' ? COLORS.darkModeBg : 'unset',
        border: `1px solid ${theme === 'dark' ? COLORS.itemDarkModeBg : COLORS.lightGray}`,
        '@media (max-width: 1024px)': {
            width: '100%',
        },
        ':nth-child(1n) > input': {
            color: theme === 'dark' ? COLORS.lightGray : COLORS.midGray,
            fontSize: 13,
            fontWeight: '400',
        },
        ':nth-child(1n) > :last-child': {
            ':nth-child(1n) > path': {
                fill: theme === 'dark' ? COLORS.gray : COLORS.lightBlack,
            },
        },
    },
    cardWrap: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        minHeight: 155,
        minWidth: '20rem',
        cursor: 'pointer',
        backgroundColor: theme === 'dark' ? COLORS.darkModeBg : COLORS.white,
        border: `1px solid ${theme === 'dark' ? COLORS.itemDarkModeBg : COLORS.smoothGray}`,
        boxShadow: '0px 2px 4px rgba(130, 130, 130, 0.05)',
        borderRadius: '4px',
        padding: 16,
        ':hover': theme === 'dark' ? 'none' : {
            border: `1px solid ${COLORS.lightGray}`,
            boxShadow: '0px 2px 8px rgba(136, 136, 136, 0.25)',
        },
        '@media (max-width: 768px)': {
            padding: 12,
            gap: 8,
        },
    },
    name: {
        fontWeight: 700,
        fontSize: 16,
        color: theme === 'dark' ? COLORS.white : COLORS.dark,
        '@media (max-width: 768px)': {
            fontSize: 13,
        },
    },
    detailWrap: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 8,
        ':nth-child(1n) > svg': {
            ':nth-child(1n) > :first-child': {
                stroke: COLORS.mainColor,
            },
            ':nth-child(1n) > :last-child': {
                stroke: COLORS.mainColor,
            },
        },
        background: theme === 'dark' ? COLORS.itemDarkModeBg : 'inherit',
        border: theme === 'dark' ? 'none' : `1px solid ${COLORS.smoothGray}`,
        boxShadow: '0px 2px 4px rgba(130, 130, 130, 0.05)',
        borderRadius: '16px',
        padding: '4px 12px',
        fontWeight: 500,
        fontSize: '13px',
        color: theme === 'dark' ? COLORS.gray : COLORS.lightBlack,
        width: 'fit-content',
        '@media (max-width: 768px)': {
            padding: '6px 8px',
            fontSize: 10,
            gap: 4,
        },
        whiteSpace: 'nowrap',
    },
    defaultLogo: {
        width: 40,
        height: 40,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: COLORS.mainColor,
        border: theme === 'dark' ? 'none' : `1px solid ${COLORS.white}`,
        borderRadius: 4,
        boxSizing: 'border-box',
    },
    createdDate: {
        color: theme === 'dark' ? COLORS.gray : COLORS.midGray,
        fontSize: 13,
        fontWeight: '400',
    },
    logoCont: {
        width: 40,
        height: 40,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxSizing: 'border-box',
        ':nth-child(1n) > img': {
            width: 'inherit',
            border: theme === 'dark' ? 'none' : `1px solid ${COLORS.white}`,
            borderRadius: 4,
        },
    },
})
