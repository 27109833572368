import React, { useContext } from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
// import { useNavigate } from 'react-router-dom'
import { useLocation, useNavigate } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { ChevronPrevSizeXL, DefaultCompanyLogoSizeL } from '../svgs/Svgs'
import { COLORS } from '../../utils/colors'
import CompanyImages from './CompanyImages'
import CompanyInfo from './CompanyInfo'
import defaultBg from '../../static/images/noBannerImg.png'
import { BaseContext } from '../../contexts/BaseContextWrapper'
import { Map, MapWrapper } from '../common/Map'
import SocialNetworkTags from './SocialNetworkTags'

export default function CompanyBanner({ info, loading }) {
    const navigate = useNavigate()
    const { theme, lang } = useContext(BaseContext)
    const { state } = useLocation()

    const validLocations = info.locations && info.locations.filter((l) => l.latitude !== '' && l.longitude !== '')

    const markers = validLocations && validLocations.map((l) => (
        {
            id: l.id,
            name: l.name,
            location: {
                lat: Number(l.latitude),
                lng: Number(l.longitude),
            },
            icon: info.logo[1],
        }
    ))

    const handleNavigate = () => {
        if (state && (state.prev || state.from)) {
            navigate(state.prev || state.from)
        } else {
            navigate('/')
        }
    }

    return (
        <div id="banner" className={cn(css(mode(theme).cont))}>
            <div>
                {info.banner && info.banner.length ? (
                    <div className={bgStyle(info.banner && info.banner[0])}>
                        <div
                            onClick={() => handleNavigate()}
                            className={cn(css(s.chevronBack), 'is-flex align-center justify-center pointer')}
                        >
                            <ChevronPrevSizeXL />
                        </div>
                    </div>
                ) : (
                    <div className={css(s.defaultBanner)}>
                        <img src={defaultBg} alt="#DefaultBanner" />

                        <div
                            onClick={() => handleNavigate()}
                            className={cn(css(s.chevronBack), 'is-flex align-center justify-center pointer')}
                        >
                            <ChevronPrevSizeXL />
                        </div>
                    </div>
                )}

                <div className="justify-center is-relative">
                    {!loading && info.logo && info.logo.length ? (
                        <div className={css(s.logoCont)}>
                            <img src={info.logo[1]} alt="#CompanyLogo" />
                        </div>
                    ) : (
                        <div className={css(s.defaultLogo)}>
                            <DefaultCompanyLogoSizeL color={COLORS.white} />
                        </div>
                    )}
                </div>
            </div>

            <div className={css(s.infoWrap)}>
                <CompanyInfo loading={loading} theme={theme} info={info} lang={lang} />

                <CompanyImages loading={loading} theme={theme} photos={info.photos} />

                <SocialNetworkTags tags={info.socialLinks} loading={loading} />

                {validLocations && validLocations.length ? (
                    <div className={cn('is-flex direction-column align-start', css(mode(theme).cLocation))}>
                        <h3><FormattedMessage id="companyLocations" /></h3>

                        <MapWrapper>
                            <Map markers={markers} mapContainerClassName={css(s.mapCnt)} />
                        </MapWrapper>
                    </div>
                ) : null}
            </div>
        </div>
    )
}

const s = StyleSheet.create({
    mapCnt: {
        background: '#FFFF',
        opacity: 1,
        // zIndex: 2000,
    },
    infoWrap: {
        padding: '24px 32px 32px 32px',
        display: 'flex',
        flexDirection: 'column',
        gap: 24,
        '@media (max-width: 768px)': {
            padding: 16,
            gap: 16,
        },
    },
    chevronBack: {
        width: 48,
        height: 48,
        ':nth-child(1n) > :only-child': {
            ':nth-child(1n) > path': {
                stroke: COLORS.white,
            },
        },
        borderRadius: 64,
        background: 'rgba(0, 0, 0, 0.20)',
        boxShadow: '0px 2px 4px 0px rgba(130, 130, 130, 0.05)',
    },
    logoCont: {
        width: 64,
        height: 64,
        borderRadius: 4,
        background: COLORS.white,
        boxSizing: 'border-box',
        position: 'absolute',
        top: -32,
        ':nth-child(1n) > img': {
            width: 'inherit',
            borderRadius: 4,
        },
    },
    defaultLogo: {
        width: 64,
        height: 64,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: COLORS.mainColor,
        border: `1px solid ${COLORS.white}`,
        borderRadius: 4,
        boxSizing: 'border-box',
        position: 'absolute',
        top: -32,
    },
    defaultBanner: {
        height: '12rem',
        width: '100%',
        position: 'relative',
        boxSizing: 'border-box',
        background: `linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), ${COLORS.mainColor}`,
        borderRadius: 5,
        ':nth-child(1n) > img': {
            objectFit: 'cover',
            objectPosition: 'top',
            height: 'inherit',
            width: 'inherit',
            borderRadius: 5,
            '@media (max-width: 768px)': {
                borderRadius: '0 0 5px 5px',
            },
        },
        ':nth-child(1n) > :last-child': {
            position: 'absolute',
            top: 20,
            left: 20,
        },
        '@media (max-width: 768px)': {
            borderRadius: '0 0 5px 5px',
        },
    },
})

const mode = (theme) => StyleSheet.create({
    cont: {
        margin: '22px 32px 0 32px',
        background: theme === 'dark' ? COLORS.darkModeBg : COLORS.white,
        boxShadow: '0px 2px 4px rgba(130, 130, 130, 0.05)',
        borderRadius: 4,
        '@media (max-width: 1024px)': {
            margin: '16px 16px 0 16px',
        },
        '@media (max-width: 768px)': {
            margin: 0,
            borderRadius: 0,
        },
    },
    cLocation: {
        marginTop: 6,
        gap: 22,
        ':nth-child(1n) > :first-child': {
            color: theme === 'dark' ? COLORS.white : COLORS.lightBlack,
            fontSize: 24,
            fontWeight: '700',
            '@media (max-width: 768px)': {
                fontSize: 16,
                gap: 16,
            },
        },
        '@media (max-width: 768px)': {
            gap: 10,
        },
    },
})

const bgStyle = (banner) => css(StyleSheet.create({
    bg: {
        padding: 20,
        boxSizing: 'border-box',
        display: 'flex',
        alignItems: 'flex-start',
        width: '100%',
        height: '12rem',
        // eslint-disable-next-line max-len
        background: banner ? `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${banner})` : 'none',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        color: '#FFFFFF',
        fontSize: 12,
        fontWeight: '500',
        borderRadius: 4,
        '@media (max-width: 768px)': {
            borderRadius: '0 0 5px 5px',
        },
    },
}).bg)
