import React, { memo } from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { useNavigate } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { COLORS } from '../../utils/colors'
import { CompanyDefaultLogoSizeL } from '../svgs/SvgPictures'
import { VerifiedSizeL } from '../svgs/Svgs'

function MemoizedComponent({ item, theme, lang }) {
    const navigate = useNavigate()

    return (
        <div
            onClick={() => navigate(`/c/${item.slug}`, { state: { from: '/' } })}
            className={cn(css(mode(theme).cardWrap), 'is-flex direction-column gap-3')}
        >
            <div className={css(s.imgCont)}>
                {item.logo && item.logo.length
                    ? <img src={item.logo[0]} alt="#CompanyLogo" />
                    : (
                        <div className={cn(css(s.defaultLogo), 'is-flex align-center justify-center')}>
                            <CompanyDefaultLogoSizeL />
                        </div>
                    )}
            </div>

            <div className={cn(css(mode(theme).infoWrap), 'is-flex direction-column gap-2')}>
                <h3 className="is-flex align-center gap-1">{item.name} {item.verified ? <VerifiedSizeL /> : null}</h3>

                <h3 className={css(mode(theme).industry)}>{item.industry[lang]}</h3>

                <div className={cn(css(s.vCount), 'is-flex align-center gap-1')}>
                    <span>{item.vacancyCount}</span>

                    <h3>
                        {item.vacancyCount === 1
                            ? <FormattedMessage id="vacancy" />
                            : <FormattedMessage id="vacancies" />}
                    </h3>
                </div>
            </div>
        </div>
    )
}

export const CompaniesItem = memo(MemoizedComponent)

const s = StyleSheet.create({
    defaultLogo: {
        height: '14rem',
        borderRadius: 2,
        background: COLORS.mainColor,
    },
    vCount: {
        textTransform: 'lowercase',
    },
    imgCont: {
        height: '14rem',
        boxSizing: 'border-box',
        ':nth-child(1n) > img': {
            objectFit: 'cover',
            width: '100%',
            height: 'inherit',
        },
    },
})

const mode = (theme) => StyleSheet.create({
    cardWrap: {
        flex: 1,
        minWidth: '16rem',
        padding: 16,
        boxSizing: 'border-box',
        cursor: 'pointer',
        borderRadius: 4,
        border: `1px solid ${theme === 'dark' ? COLORS.itemDarkModeBg : COLORS.smoothGray}`,
        background: theme === 'dark' ? COLORS.darkModeBg : COLORS.white,
        boxShadow: '0px 2px 4px 0px rgba(130, 130, 130, 0.05)',
        '@media (max-width: 1200px)': {
            padding: 12,
        },
    },
    infoWrap: {
        ':nth-child(1n) > :first-child': {
            overflow: 'hidden',
            color: theme === 'dark' ? COLORS.white : COLORS.dark,
            textOverflow: 'ellipsis',
            fontSize: 18,
            fontWeight: '600',
        },
        ':nth-child(1n) > :last-child': {
            overflow: 'hidden',
            color: theme === 'dark' ? COLORS.gray : COLORS.lightBlack,
            textOverflow: 'ellipsis',
            fontSize: 14,
            fontWeight: '500',
        },
    },
    industry: {
        overflow: 'hidden',
        color: theme === 'dark' ? COLORS.midGray : COLORS.gray,
        textOverflow: 'ellipsis',
        fontSize: 13,
        fontWeight: '400',
    },
})
