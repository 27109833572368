import React, { useEffect, useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import Select, { createFilter } from 'react-select'
import { Field } from 'formik'
import cn from 'classnames'
import { FormattedMessage } from 'react-intl'
import { RocketLayout, TablerX } from '../../svgs/Svgs'
import { COLORS } from '../../../utils/colors'
import { useLoad } from '../../../hooks/request'
import { PUBLIC_SKILLS_LIST } from '../../../urls'

export default function SkillsForm({ setFieldValue, info, theme }) {
    const [addSkills, setAddSkills] = useState(false)
    // const [addProgrammes, setAddProgrammes] = useState(false)
    const [addedSkills, setAddedSkills] = useState(info.skills)
    // const [addedProgrammes, setAddedProgrammes] = useState([])
    const loadSkills = useLoad({ url: PUBLIC_SKILLS_LIST })
    const skills = loadSkills.response ? loadSkills.response.data : []

    useEffect(() => {
        setAddedSkills(info.skills)
    }, [info])

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            margin: 0,
            padding: 0,
            minHeight: 20,
            maxHeight: 25,
            border: 'none',
            background: 'inherit',
            boxShadow: state.isFocused ? 'none' : 'none',
        }),
        dropdownIndicator: (provided) => ({
            ...provided,
            display: 'none',
        }),
        indicatorSeparator: (provided) => ({
            ...provided,
            display: 'none',
        }),
        menu: (base) => ({
            ...base,
            margin: 0,
            paddingTop: 6,
            borderRadius: 4,
            width: 'max-content',
            minWidth: '100%',
            background: theme === 'dark' ? COLORS.itemDarkModeBg : COLORS.white,
            border: theme === 'dark' ? 'none' : `1px solid ${COLORS.smoothGray}`,
            boxShadow: '0px 4px 16px rgba(62, 62, 62, 0.15)',
        }),
        menuList: (base) => ({
            ...base,
            paddingTop: 0,
            background: theme === 'dark' ? COLORS.itemDarkModeBg : COLORS.white,
            paddingBottom: 0,
        }),
        option: (provided) => ({
            ...provided,
            margin: 0,
            fontSize: 14,
            cursor: 'pointer',
            fontWeight: '500',
            color: COLORS.gray,
            padding: '10px 16px',
            background: theme === 'dark' ? COLORS.itemDarkModeBg : COLORS.white,
            ':hover': {
                background: theme === 'dark' ? COLORS.darkModeBg : COLORS.smoothGray,
            },
        }),
        group: (provided) => ({
            ...provided,
            padding: 0,
        }),
        groupHeading: () => ({
            margin: 0,
            fontSize: 11,
            fontWeight: '500',
            color: COLORS.dark,
            textTransform: 'uppercase',
            padding: '10px 16px 10px 16px',
            ':hover': {
                background: 'none',
            },
        }),
    }

    const handleChange = (option, field, form) => {
        const selectedOptions = [...field.value, option]
        form.setFieldValue(field.name, selectedOptions)
        setAddedSkills((prev) => [...prev, option])
        setTimeout(() => {
            const items = document.getElementById('skills')
            const cont = document.getElementById('skillCont')
            items.scrollTop = cont.scrollHeight
        }, 0)
    }

    const NoOptionsMessage = ({ inputValue }) => (inputValue !== '' ? 'Нет доступных вариантов' : '')

    // eslint-disable-next-line max-len
    const availableOptions = skills.filter((item) => addedSkills && !addedSkills.some((selected) => selected.value === item.value))

    const onDeleteSkills = (value) => {
        const res = addedSkills.filter((val) => val.value !== value)
        setAddedSkills(res)
        setFieldValue('skills', res)
    }

    return (
        <div className={css(mode(theme).formWrap)}>
            <div className={css(mode(theme).formHeader)}>
                <div className={cn('align-center gap-2', css(s.headTitle))}>
                    <RocketLayout />

                    <h3><FormattedMessage id="proSkills" /></h3>
                </div>
            </div>

            <div className={css(s.formBody)}>
                <div className={css(mode(theme).cont)}>
                    <h3><FormattedMessage id="whatSkillsDoYouOwn" /></h3>

                    <div id="skillCont" className={css(s.skillsWrap)}>
                        <div id="skills" className="is-flex align-center is-flex-wrap gap-5">
                            {addedSkills && addedSkills.map((item) => (
                                <div key={item.value} className={css(mode(theme).skillWrap)}>
                                    <h3>
                                        {item.label.length > 27
                                            ? `${item.label.slice(0, 27)}...` : item.label}
                                    </h3>

                                    <span onClick={() => onDeleteSkills(item.value)}>
                                        <TablerX color={theme === 'dark' ? COLORS.smoothGray : COLORS.lightBlack} />
                                    </span>
                                </div>
                            ))}
                        </div>

                        {addSkills ? (
                            <Field name="skills">
                                {({ field, form }) => (
                                    <Select
                                        value=""
                                        autoFocus
                                        id="skills"
                                        isSearchable
                                        placeholder=""
                                        options={availableOptions}
                                        menuPosition="fixed"
                                        className={css(s.selectInp)}
                                        noOptionsMessage={NoOptionsMessage}
                                        styles={customStyles}
                                        defaultMenuIsOpen
                                        menuIsOpen
                                        hideSelectedOptions
                                        onBlur={() => setAddSkills(false)}
                                        filterOption={createFilter({ ignoreAccents: false })}
                                        onChange={(option) => handleChange(option, field, form)}
                                    />
                                )}
                            </Field>
                        ) : (
                            <h3 onClick={() => setAddSkills(true)} className={css(s.addTrigger)}>
                                + <FormattedMessage id="addSkills" />
                            </h3>
                        )}
                    </div>
                </div>

                {/* <div className={css(s.cont)}> */}
                {/*     <h3>Какими программами вы владеете?</h3> */}

                {/*     <div className={css(s.skillsWrap)}> */}
                {/*         {addedSkills && addedSkills.map((item) => ( */}
                {/*             <div key={item.value} className={css(s.skillWrap)}> */}
                {/*                 <h3>{item.label}</h3> */}

                {/*                 <span onClick={() => onDeleteSkills(item.label)}><TablerX /></span> */}
                {/*             </div> */}
                {/*         ))} */}

                {/*         {addSkills ? ( */}
                {/*             <Field name="skills"> */}
                {/*                 {({ field, form, values }) => ( */}
                {/*                     <Select */}
                {/*                         autoFocus */}
                {/*                         id="skills" */}
                {/*                         placeholder="" */}
                {/*                         value={field.value} */}
                {/*                         styles={customStyles} */}
                {/*                         options={availableSkills} */}
                {/* eslint-disable-next-line max-len */}
                {/*                         onChange={(option) => handleChange(values, option, field, form, 'skills')} */}
                {/*                         onBlur={() => setAddSkills(false)} */}
                {/*                     /> */}
                {/*                 )} */}
                {/*             </Field> */}
                {/*         ) : ( */}
                {/*             <h3 onClick={() => setAddSkills(true)} className={css(s.addTrigger)}> */}
                {/*                 + Добавить навык */}
                {/*             </h3> */}
                {/*         )} */}
                {/*     </div> */}
                {/* </div> */}
            </div>
        </div>
    )
}

const s = StyleSheet.create({
    formBody: {
        padding: '16px 24px 28px 24px',
        display: 'flex',
        flexDirection: 'column',
        gap: 24,
        '@media (max-width: 768px)': {
            padding: 16,
            gap: 16,
        },
    },
    skillsWrap: {
        margin: '12px 0 12px 0',
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        gap: 16,
        '@media (max-width: 768px)': {
            gap: 10,
            margin: '12px 0 0px 0',
        },
        ':nth-child(1n) > :first-child': {
            maxHeight: '10rem',
            overflowY: 'auto',
            '@media (max-width: 768px)': {
                gap: 10,
            },
        },
    },
    addTrigger: {
        fontWeight: '500',
        fontSize: 14,
        color: COLORS.mainColor,
        cursor: 'pointer',
    },
    headTitle: {
        '@media (max-width: 768px)': {
            gap: 4,
        },
    },
    fld: {
        border: 'none',
    },
    selectInp: {
        width: '100%',
    },
})

const mode = (theme) => StyleSheet.create({
    formWrap: {
        border: theme === 'dark' ? 'none' : `1px solid ${COLORS.smoothGray}`,
        background: theme === 'dark' ? COLORS.darkModeBg : COLORS.white,
        boxShadow: '0px 2px 4px rgba(130, 130, 130, 0.05)',
        borderRadius: 4,
    },
    formHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '14px 24px',
        fontWeight: '600',
        fontSize: 13,
        textTransform: 'uppercase',
        color: theme === 'dark' ? COLORS.white : COLORS.dark,
        borderBottom: `1px solid ${theme === 'dark' ? COLORS.lightBlack : COLORS.lightGray}`,
        borderRadius: '4px 4px 0px 0px',
        ':nth-child(1n) > :last-child': {
            cursor: 'pointer',
        },
        ':nth-child(1n) > :first-child': {
            ':nth-child(1n) > :first-child': {
                ':nth-child(1n) > path': {
                    stroke: theme === 'dark' ? COLORS.gray : '#333333',
                },
            },
        },
        '@media (max-width: 768px)': {
            padding: '14px 16px',
        },
    },
    cont: {
        ':nth-child(1n) > h3': {
            fontWeight: '500',
            fontSize: 14,
            color: theme === 'dark' ? COLORS.gray : COLORS.midGray,
        },
    },
    skillWrap: {
        display: 'flex',
        border: theme === 'dark' ? 'none' : `1px solid ${COLORS.gray}`,
        borderRadius: 4,
        ':nth-child(1n) > h3': {
            padding: '6.5px 8px',
            background: theme === 'dark' ? 'rgba(255, 255, 255, 0.10)' : COLORS.white,
            borderBottomLeftRadius: 4,
            borderTopLeftRadius: 4,
            fontWeight: '500',
            fontSize: 13,
            color: theme === 'dark' ? COLORS.white : COLORS.lightBlack,
        },
        ':nth-child(1n) > span': {
            background: theme === 'dark' ? 'rgba(237, 237, 238, 0.10)' : COLORS.smoothGray,
            borderLeft: theme === 'dark' ? 'none' : `1px solid ${COLORS.gray}`,
            borderBottomRightRadius: 4,
            borderTopRightRadius: 4,
            padding: '6px 6px 3px 6px',
            cursor: 'pointer',
        },
    },
    // menuWrap: {
    //     top: 34,
    //     position: 'absolute',
    //     zIndex: '200',
    //     background: COLORS.white,
    //     border: `1px solid ${COLORS.smoothGray}`,
    //     boxShadow: '0px 4px 16px rgba(62, 62, 62, 0.15)',
    //     borderRadius: 4,
    //     padding: '10px 0 10px 0',
    //     width: '21.563rem',
    //     minWidth: '12.5rem',
    //     height: '20rem',
    //     overflowY: 'scroll',
    // },
    // menuSkills: {
    //     ':nth-child(1n) > h3': {
    //         fontWeight: '500',
    //         fontSize: 11,
    //         textTransform: 'uppercase',
    //         color: COLORS.dark,
    //         padding: '0px 0px 8px 16px',
    //     },
    //     ':nth-child(1n) > p': {
    //         fontWeight: '500',
    //         fontSize: 14,
    //         color: COLORS.gray,
    //         padding: '10px 16px',
    //     },
    // },
})
