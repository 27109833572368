import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { Link, useNavigate } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { Oval } from 'react-loader-spinner'
import { FormattedMessage } from 'react-intl'
import FormInputPhone from '../../components/common/FormInputPhone'
import { COLORS } from '../../utils/colors'
import Button from '../../components/common/Button'
import { phone } from '../../utils/validators'
import { ChevronNextSizeL } from '../../components/svgs/Svgs'

export default function SignIn({ values, handleChange, handleBlur, errors, errorMsg, state, loading, theme }) {
    const navigate = useNavigate()

    return (
        <div className={css(s.right)}>
            <div className={css(mode(theme).boxWrap)}>
                <div className={css(mode(theme).boxHeader)}>
                    <ChevronNextSizeL onClick={() => navigate(-1)} />

                    <h3><FormattedMessage id="enterPhoneNumber" /></h3>
                </div>

                <div className={css(s.boxBody)}>
                    <FormInputPhone
                        id="phoneNumber"
                        label={<FormattedMessage id="phoneNumber" />}
                        name="phoneNumber"
                        type="tel"
                        inputMode="numeric"
                        labelClassName={css(mode(theme).label)}
                        className={css(mode(theme).inputPhone)}
                        validate={phone}
                        errorWithMessage={false}
                        fieldClassName={cn('is-flex direction-column gap-2', css(mode(theme).field))}
                        placeholder="01 234 56 78"
                        value={values.phoneNumber}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />

                    {errorMsg && (
                        <div className={css(mode(theme).error)}>
                            {errorMsg}
                            <Link state={{ phone: values.phoneNumber, ...state }} to="/registration/user">
                                <FormattedMessage id="register" />
                            </Link>
                        </div>
                    )}

                    <Button type="submit" className={cn(css(s.disabledBtn), 'is-flex align-center justify-center gap-2',
                        { [css(s.enabledBtn)]:
                            isEmpty(errors) && values.phoneNumber && Object.values(values.phoneNumber).length })}
                    >
                        <FormattedMessage id="next" />

                        {loading ? (
                            <Oval
                                height={16}
                                width={16}
                                color={COLORS.white}
                                visible
                                ariaLabel="oval-loading"
                                secondaryColor={COLORS.white}
                                strokeWidth={7}
                                strokeWidthSecondary={7}

                            />
                        ) : null}
                    </Button>
                </div>
            </div>
        </div>
    )
}

const s = StyleSheet.create({
    right: {
        flex: 2,
        display: 'flex',
        alignItems: 'left',
        justifyContent: 'left',
        '@media (max-width: 768px)': {
            alignItems: 'center',
            justifyContent: 'center',
        },
    },
    boxBody: {
        padding: 24,
        display: 'flex',
        flexDirection: 'column',
        gap: 16,
        '@media (max-width: 768px)': {
            padding: 12,
            gap: 16,
        },
    },
    disabledBtn: {
        background: COLORS.gray,
        width: '100%',
        border: 'none',
        padding: '7.5px 8px',
        // eslint-disable-next-line max-len
        boxShadow: '0px 4px 12px rgba(44, 67, 50, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.25), inset 0px 1px 0px rgba(255, 255, 255, 0.1)',
        borderRadius: '4px',
        cursor: 'not-allowed',
        fontWeight: '500',
        fontSize: 14,
        color: COLORS.white,
    },
    enabledBtn: {
        background: `linear-gradient(180deg, ${COLORS.mainColor} 0%, ${COLORS.lightMainColor} 100%)`,
        cursor: 'pointer',
    },
})

const mode = (theme) => StyleSheet.create({
    boxWrap: {
        height: 'fit-content',
        width: '35rem',
        background: theme === 'dark' ? COLORS.darkModeBg : COLORS.white,
        border: theme === 'dark' ? 'none' : `1px solid ${COLORS.smoothGray}`,
        boxShadow: '0px 2px 4px rgba(130, 130, 130, 0.05)',
        borderRadius: 8,
        '@media (max-width: 768px)': {
            width: '90vw',
        },
    },
    boxHeader: {
        borderBottom: `1px solid ${theme === 'dark' ? COLORS.midGray : COLORS.smoothGray}`,
        padding: '22px 24px',
        fontWeight: '600',
        fontSize: 24,
        color: theme === 'dark' ? COLORS.white : COLORS.dark,
        display: 'flex',
        alignItems: 'center',
        gap: 4,
        ':nth-child(1n) > :first-child': {
            transform: 'rotate(180deg)',
            cursor: 'pointer',
            ':nth-child(1n) > path': {
                stroke: theme === 'dark' ? COLORS.white : COLORS.dark,
                strokeWidth: 2.5,
            },
            '@media (min-width: 768px)': {
                display: 'none',
            },
        },
        '@media (max-width: 768px)': {
            fontSize: 16,
            padding: 12,
        },
    },
    label: {
        fontWeight: 500,
        fontSize: '12px',
        color: theme === 'dark' ? COLORS.lightGray : COLORS.midGray,
    },
    inputPhone: {
        width: '100%',
        fontWeight: 500,
        fontSize: '14px',
        color: theme === 'dark' ? COLORS.white : COLORS.dark,
        outline: 'none',
        backgroundColor: 'inherit',
        ':placeholder': {
            color: COLORS.gray,
        },
    },
    field: {
        position: 'relative',
        ':nth-child(1n) > :last-child': {
            background: theme === 'dark' ? COLORS.itemDarkModeBg : COLORS.white,
            border: theme === 'dark' ? 'none' : `1px solid ${COLORS.lightGray}`,
        },
    },
    error: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 8,
        fontSize: 11,
        fontWeight: '500',
        color: theme === 'dark' ? COLORS.white : COLORS.dark,
        ':nth-child(1n) > :last-child': {
            textDecoration: 'none',
            color: theme === 'dark' ? COLORS.skyblue : 'none',
        },
    },
})
