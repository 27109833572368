import React, { useContext } from 'react'
import { FormattedMessage } from 'react-intl'
import VacanciesFilterBoard from './VacanciesFilterBoard'
import { dateRangeFilter, sortFilter } from '../../../utils/filter'
import { Context } from '../../../contexts/GlobalContext'
import { educationLvl } from '../../../utils/options'

export default function VacanciesFilter({ filter, setFilter, width, rounded, height, vacancyAdditional }) {
    const { industryLoading, matchedIndustries } = useContext(Context)

    const filterData = [
        {
            id: 1,
            title: <FormattedMessage id="sortBy" />,
            name: 'sortBy',
            isRadio: true,
            noClear: true,
            options: sortFilter,
        },
        {
            id: 2,
            title: <FormattedMessage id="addedDate" />,
            name: 'addedDate',
            hasInput: true,
            isRadio: true,
            options: dateRangeFilter,
        },
        {
            id: 3,
            title: <FormattedMessage id="industry" />,
            name: 'industry',
            isCheckbox: true,
            haveSearch: true,
            options: matchedIndustries,
            loading: industryLoading,
        },
        {
            id: 4,
            title: <FormattedMessage id="salary" />,
            name: 'salary',
            isRange: true,
            hasSlider: true,
            options: [],
        },
        {
            id: 6,
            title: <FormattedMessage id="experience" />,
            name: 'experience',
            isExpCheckbox: true,
            options: [
                { value: 0, label: <FormattedMessage id="noExperience" /> },
                { value: 1, label: <FormattedMessage id="fromOneToThreeYear" /> },
                { value: 2, label: <FormattedMessage id="fromThreeToSixYear" /> },
                { value: 3, label: <FormattedMessage id="overSixYear" /> },
            ],
        },
        {
            id: 7,
            title: <FormattedMessage id="education" />,
            name: 'edu',
            isEduCheckbox: true,
            options: educationLvl,
        },
    ]

    return (
        <VacanciesFilterBoard
            data={filterData}
            height={height}
            filter={filter}
            width={width}
            rounded={rounded}
            setFilter={setFilter}
            vacancyAdditional={vacancyAdditional} />
    )
}
