import React, { useContext } from 'react'
import cn from 'classnames'
import { Field, getIn, useFormikContext } from 'formik'
import { css, StyleSheet } from 'aphrodite'
import { Oval } from 'react-loader-spinner'
import { COLORS } from '../../utils/colors'
import { BaseContext } from '../../contexts/BaseContextWrapper'
import { ChevronNextSizeL } from '../svgs/Svgs'
import Button from './Button'

export default function VerifyCodeInput({
    name,
    help,
    icon,
    type,
    btnText,
    loading,
    validate,
    className,
    onClick,
    isLoading,
    onBlur = () => {},
    btnClassName,
    fieldClassName,
    labelClassName,
    noRequiredValidation,
    component = 'input',
    ...attributes
}) {
    const { theme } = useContext(BaseContext)
    const { errors, touched, handleBlur } = useFormikContext()
    const isValid = getIn(errors, name) && getIn(touched, name)

    return (
        <div className={cn('field', fieldClassName)}>
            <div className={cn('control flex-cent justify-between', css(mode(theme, isValid).field))}>
                <Field
                    id={name}
                    name={name}
                    validate={validate}
                    pattern="[0-9]*"
                    inputMode="numeric"
                    maxLength={4}
                    type={type || 'text'}
                    component={component}
                    className={cn(
                        css(mode(theme, isValid).inp), 'form-inp_pl', { [css(s.icon)]: icon }, className,
                    )}
                    onBlur={(e) => { handleBlur(e); onBlur(e) }}
                    {...attributes} />

                <Button
                    type="button"
                    onClick={onClick}
                    className={cn(css(s.btn, btnClassName), 'flex-cent')}>
                    {!isLoading ? <span className="flex-cent gap-2">{btnText}  <ChevronNextSizeL /></span> : null}

                    {isLoading ? (
                        <Oval
                            height={16}
                            width={16}
                            color={COLORS.white}
                            visible
                            ariaLabel="oval-loading"
                            secondaryColor={COLORS.white}
                            strokeWidth={7}
                            strokeWidthSecondary={7} />
                    ) : null}
                </Button>
            </div>
        </div>
    )
}

const s = StyleSheet.create({
    btn: {
        minHeight: 32,
        borderRadius: 4,
        fontWeight: '500',
        fontSize: 14,
        whiteSpace: 'nowrap',
        padding: '5px 14px !important',
        '@media (max-width: 786px)': {
            fontSize: 12,
            padding: '4px 7px !important',
        },
    },
    dng: {
        border: `1px solid ${COLORS.lightRed}`,
    },
})

const mode = (theme, valid) => StyleSheet.create({
    inp: {
        width: 'calc(100% - 155px)',
        borderRadius: 4,
        height: '42px',
        outline: 'none',
        paddingLeft: 16,
        background: 'inherit',
        color: theme === 'dark' ? COLORS.white : COLORS.dark,
        position: 'relative',
        fontSize: 14,
        '-webkit-appearance': 'none',
        boxSizing: 'border-box',
        fontWeight: '500',
        border: 'none',
        ':disabled': {
            backgroundColor: '#f2f2f2',
        },
    },
    field: {
        paddingRight: 4,
        background: theme === 'dark' ? COLORS.itemDarkModeBg : COLORS.white,
        border: `1px solid ${valid ? COLORS.lightRed : theme === 'dark' ? 'transparent' : COLORS.lightGray}`,
    },
})
