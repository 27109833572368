import React, { useContext } from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { FormattedMessage } from 'react-intl'
import { COLORS } from '../../../utils/colors'
import { DefaultLocation, MapPin, WalletSizeM } from '../../svgs/Svgs'
import Button from '../../common/Button'
import { LoadingSkeleton } from '../../common/LoadingSkeleton'
import { getFullDate } from '../../../utils/date'
import { BaseContext } from '../../../contexts/BaseContextWrapper'
import { fromToStr } from '../../../utils/string'

export default function RowScrollViewVacancyItem({ item, info, handleNavigate, loading, theme }) {
    const { lang } = useContext(BaseContext)

    return (
        <div className={cn(css(mode(theme).cardWrap), 'is-flex direction-column gap-5')}>
            {/* <div className={css(s.cardBodyWrap)}> */}
            <div className="is-flex direction-column gap-5"
                onClick={() => handleNavigate('forward', item.slug)}>
                <div className={css(s.compInfo)}>
                    {!loading && info.logo && info.logo.length ? (
                        <div className={css(s.logoCont)}>
                            <img src={info.logo[1]} alt="#CompanyLogo" />
                        </div>
                    ) : (
                        <div className={css(s.defaultLogo)}>
                            <DefaultLocation color={COLORS.white} />
                        </div>
                    )}

                    {!loading ? (
                        <div className="is-flex direction-column">
                            <h3 className={css(mode(theme).positionName)}>
                                {item.name && item.name.length > 27
                                    ? `${item.name.slice(0, 27)}...` : item.name}
                            </h3>

                            <h3 className={css(s.date)}>
                                {item.createdAt && getFullDate(item.createdAt, 'DD MMMM YYYY', lang)}
                            </h3>
                        </div>
                    ) : (
                        <div className="is-flex direction-column">
                            <LoadingSkeleton height={16} />

                            <LoadingSkeleton height={13} />
                        </div>
                    )}
                </div>

                {!loading ? (
                    <div className={css(s.detailsWrap)}>
                        <div className={css(mode(theme).detailWrap)}>
                            <WalletSizeM />

                            <h3>
                                {fromToStr(
                                    item.salaryMin,
                                    item.salaryMax,
                                    item.currency,
                                    <FormattedMessage id="basedOnInterview" />,
                                    lang,
                                )}
                            </h3>
                        </div>

                        <div className={css(mode(theme).detailWrap)}>
                            <MapPin />

                            {item.location ? (
                                <h3>
                                    {item.location && item.location.length > 27
                                        ? `${item.location.slice(0, 27)}...` : item.location}
                                </h3>
                            ) : <h3 style={{ color: COLORS.gray }}><FormattedMessage id="notSpecified" /></h3>}
                        </div>
                    </div>
                ) : (
                    <div className={css(s.detailsWrap)}>
                        <div className={css(mode(theme).detailWrap)}>
                            <WalletSizeM />

                            <LoadingSkeleton height={13} />
                        </div>

                        <div className={css(mode(theme).detailWrap)}>
                            <MapPin />

                            <LoadingSkeleton height={13} />
                        </div>
                    </div>
                )}
            </div>

            {!loading ? (
                <Button
                    onClick={!item.isApplied ? (e) => {
                        e.stopPropagation()
                        handleNavigate('apply', item.slug)
                    } : null}
                    className={css(item.isApplied ? s.disabledBtn : s.applyBtn)}
                >
                    {item.isApplied ? <FormattedMessage id="alreadyApplied" /> : <FormattedMessage id="apply" />}
                </Button>
            ) : <div className={css(s.skeletonBtn)}><LoadingSkeleton width="100%" /></div> }
        </div>
    )
}

const s = StyleSheet.create({
    infoWrap: {
        '@media (max-width: 768px)': {
            gap: 8,
        },
    },
    compInfo: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        gap: '12px',
        ':nth-child(1n) > img': {
            width: 40,
            height: 40,
        },
    },
    date: {
        color: COLORS.gray,
        fontSize: 13,
        fontWeight: '400',
        marginTop: '4px',
    },
    detailsWrap: {
        minHeight: 68,
        ':nth-child(1n) > :first-child': {
            marginBottom: '8px',
        },
    },
    applyBtn: {
        background: `linear-gradient(180deg, ${COLORS.mainColor} 0%, ${COLORS.lightMainColor} 100%)`,
        boxShadow: 'inset 0px -1px 0px rgba(0, 0, 0, 0.25), inset 0px 1px 0px rgba(255, 255, 255, 0.1)',
        borderRadius: '4px',
        fontWeight: 500,
        fontSize: '13px',
        color: COLORS.white,
        padding: '8.5px 0',
        width: '100%',
    },
    disabledBtn: {
        background: COLORS.gray,
        borderRadius: 4,
        border: 'none',
        // eslint-disable-next-line max-len
        boxShadow: '0px 4px 12px rgba(44, 67, 50, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.25), inset 0px 1px 0px rgba(255, 255, 255, 0.1)',
        cursor: 'not-allowed',
        padding: '8.5px 0',
        fontWeight: '500',
        fontSize: 13,
        width: '100%',
        color: COLORS.white,
    },
    logoCont: {
        width: 40,
        height: 40,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 4,
        boxSizing: 'border-box',
        ':nth-child(1n) > img': {
            width: 'inherit',
            borderRadius: 4,
        },
    },
    defaultLogo: {
        width: 40,
        height: 40,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: COLORS.mainColor,
        border: `1px solid ${COLORS.white}`,
        borderRadius: 4,
        boxSizing: 'border-box',
    },
})

const mode = (theme) => StyleSheet.create({
    cardWrap: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        minHeight: 155,
        minWidth: '21rem',
        cursor: 'pointer',
        backgroundColor: theme === 'dark' ? COLORS.darkModeBg : COLORS.white,
        border: `1px solid ${theme === 'dark' ? COLORS.itemDarkModeBg : COLORS.smoothGray}`,
        boxShadow: '0px 2px 4px rgba(130, 130, 130, 0.05)',
        borderRadius: '4px',
        padding: 16,
        ':hover': theme === 'dark' ? 'none' : {
            border: `1px solid ${COLORS.lightGray}`,
            boxShadow: '0px 2px 8px rgba(136, 136, 136, 0.25)',
        },
        '@media (max-width: 768px)': {
            padding: 12,
            gap: 8,
        },
    },
    positionName: {
        fontWeight: 700,
        fontSize: 16,
        color: theme === 'dark' ? COLORS.white : COLORS.dark,
        '@media (max-width: 768px)': {
            fontSize: 13,
        },
    },
    detailWrap: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 8,
        ':nth-child(1n) > svg': {
            ':nth-child(1n) > :first-child': {
                stroke: COLORS.mainColor,
            },
            ':nth-child(1n) > :last-child': {
                stroke: COLORS.mainColor,
            },
        },
        background: theme === 'dark' ? COLORS.itemDarkModeBg : 'inherit',
        border: `1px solid ${theme === 'dark' ? COLORS.itemDarkModeBg : COLORS.smoothGray}`,
        boxShadow: '0px 2px 4px rgba(130, 130, 130, 0.05)',
        borderRadius: '16px',
        padding: '4px 12px',
        fontWeight: 500,
        fontSize: '13px',
        color: theme === 'dark' ? COLORS.smoothGray : COLORS.lightBlack,
        width: 'fit-content',
        '@media (max-width: 768px)': {
            padding: '6px 8px',
            fontSize: 10,
            gap: 4,
        },
        whiteSpace: 'nowrap',
    },
})
