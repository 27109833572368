import React from 'react'
import cn from 'classnames'
import { Field, getIn, useFormikContext } from 'formik'
import { css, StyleSheet } from 'aphrodite'
import Select, { components } from 'react-select'
import ValidationErrorMessage from './ValidationErrorMessage'
import { format, integersOnly } from '../../utils/number'
import { CheckMark, ChevronDown } from '../svgs/Svgs'
import { COLORS } from '../../utils/colors'

export default function FormInputCurrency({
    name,
    help,
    label,
    loading,
    onInput,
    options,
    validate,
    onChange,
    className,
    staticText,
    placeholder,
    fieldClassName,
    labelClassName,
    theme = '',
    errorWithMessage = true,
    isSelectable = false,
    ...attributes
}) {
    const { touched, errors, setFieldValue } = useFormikContext()

    const selectStyle = {
        control: (base) => ({
            ...base,
            boxShadow: 'none',
            outline: 'none',
            height: 24,
            minHeight: 'auto',
            borderColor: 'transparent',
            backgroundColor: theme === 'dark' ? COLORS.itemDarkModeBg : 'inherit',
            cursor: 'pointer',
            '&:hover': {
                outline: 'none',
                boxShadow: 'none',
            },
        }),
        option: (optStyles, { isSelected, isFocused }) => ({
            ...optStyles,
            backgroundColor: (isSelected || isFocused) && theme === 'dark' ? COLORS.darkModeBg : (isSelected || isFocused) && theme !== 'dark' ? COLORS.mintCream : ' transparent', // eslint-disable-line max-len
            color: isSelected && theme === 'dark'
                ? COLORS.white : isSelected && theme !== 'dark' ? COLORS.dark : COLORS.midGray,
            cursor: 'pointer',
            padding: '14px 8px 14px 16px',
            borderBottom: `1px solid ${theme === 'dark' ? '#595959' : COLORS.smoothGray}`,
            '&:hover': {
                backgroundColor: (isSelected || isFocused) && theme === 'dark' ? COLORS.darkModeBg : (isSelected || isFocused) && theme !== 'dark' ? COLORS.mintCream : ' transparent', // eslint-disable-line max-len
            },
            ':first-of-type': {
                borderRadius: '4px 4px 0 0',
            },
            ':last-of-type': {
                borderBottom: 'none',
                borderRadius: '0 0 4px 4px',
            },
        }),
        menu: (base) => ({
            ...base,
            right: 0,
            backgroundColor: theme === 'dark' ? COLORS.itemDarkModeBg : 'inherit',
            color: theme === 'dark' ? COLORS.white : COLORS.dark,
            width: '8.5rem',
            ':nth-of-type(1n) > :first-of-type': {
                padding: 0,
            },
        }),
        valueContainer: (optStyles) => ({
            ...optStyles,
            padding: 0,
        }),
        dropdownIndicator: (optStyles) => ({
            ...optStyles,
            padding: 0,
        }),
        singleValue: (optStyles) => ({
            ...optStyles,
            fontSize: 16,
            fontWeight: '500',
            color: COLORS.mainColor,
        }),
    }

    const Menu = (props) => <components.Menu className={css(s.opt)} {...props} />

    const Option = (props) => {
        const { children, isSelected } = props

        return (
            <components.Option {...props}>
                <span className="justify-between" style={{ height: 16 }}>
                    <h3 className={cn(css(s.optLab))}>{children}</h3>

                    {isSelected ? (<CheckMark />) : ''}
                </span>
            </components.Option>
        )
    }

    const DropdownIndicator = (props) => (
        <components.DropdownIndicator {...props}>
            <span className={cn('expand expand-more is-flex')}>
                <ChevronDown />
            </span>
        </components.DropdownIndicator>
    )

    const isValid = !errorWithMessage && getIn(errors, name) && getIn(touched, name)
    const isRequired = typeof validate === 'function'

    return (
        <div className={cn('field', fieldClassName)}>
            <div className={cn('control', css(s.field))}>
                {label ? (
                    <label className={labelClassName} htmlFor={name}>
                        {label} {isRequired && (
                            <span style={{ color: isValid ? COLORS.lightRed : COLORS.midGray }}>
                                *
                            </span>
                        )}
                    </label>
                ) : null}

                <div className={cn(css(mode(theme).cont, isValid && s.dng), className)}>
                    <Field
                        id={name}
                        className={cn('form-inp_pl', css(s.inp))}
                        name={name}
                        validate={validate}
                        placeholder={placeholder}
                        type="text"
                        onChange={(event) => {
                            const value = format(integersOnly(event.target.value))
                            setFieldValue(name, value)
                        }}
                        {...attributes} />

                    {isSelectable ? (
                        <Select
                            {...attributes}
                            options={options}
                            autoFocus={false}
                            isSearchable={false}
                            styles={selectStyle}
                            defaultValue={options[0]}
                            onChange={(event) => {
                                setFieldValue('currency', event.label)
                                if (typeof onChange === 'function') {
                                    onChange(event)
                                }
                            }}
                            components={{ Option, Menu, DropdownIndicator, IndicatorSeparator: () => null }}
                        />
                    ) : (
                        <h3
                            style={{
                                paddingRight: 7,
                                fontSize: 16,
                                fontWeight: '500',
                                color: COLORS.mainColor,
                            }}>UZS
                        </h3>
                    )}

                    {help && (!errors[name] || !touched[name]) ? (
                        <p className={css(s.formHint)}>{help}</p>
                    ) : null}
                </div>

                {errorWithMessage && (
                    <ValidationErrorMessage
                        name={name}
                        errorMsg={help ? <h3 style={{ fontSize: 12 }}>{help}</h3> : ''}
                    />
                )}
            </div>
        </div>
    )
}

const s = StyleSheet.create({
    field: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    input_wrap: {
        position: 'relative',
        minWidth: 1,
        maxWidth: 100,
        height: 16,
        ':nth-child(1n) > input': {
            position: 'absolute',
            left: 0,
        },
    },
    inp: {
        outline: 'none',
        border: 'none',
        overflow: 'hidden',
        padding: 0,
        fontSize: 14,
        fontWeight: '500',
        color: COLORS.dark,
    },
    dng: {
        borderColor: COLORS.lightRed,
    },
    formHint: {
        marginTop: 4,
        fontSize: 12,
        fontWeight: '400',
        color: COLORS.lightBlack,
    },
    text: {
        color: COLORS.gray,
        fontSize: 14,
        fontWeight: '400',
    },
})

const mode = (theme) => StyleSheet.create({
    cont: {
        border: theme === 'dark' ? 'none' : `1px solid ${COLORS.lightGray}`,
        borderRadius: 4,
        height: 42,
        padding: '0 9px 0 16px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        ':nth-child(1n) > :first-child': {
            gap: '0.25rem',
            ':nth-child(1n) > *': {
                cursor: 'text',
            },
        },
    },
})
