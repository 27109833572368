import React, { useContext } from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { useNavigate } from 'react-router-dom'
import { Oval } from 'react-loader-spinner'
import { FormattedMessage } from 'react-intl'
import { ArrowNextSizeL, ChevronNextSizeL } from '../../svgs/Svgs'
import Button from '../../common/Button'
import { COLORS } from '../../../utils/colors'
import { BaseContext } from '../../../contexts/BaseContextWrapper'

export default function Tabs({
    step,
    state,
    goBack,
    loading,
    vacancy,
    pathname,
    handleBack,
    applyTrigger,
    applyLoading,
}) {
    const { theme } = useContext(BaseContext)
    const navigate = useNavigate()

    return (
        <div className={css(mode(theme).wrapper)}>
            <div className={css(mode(theme).header)}>
                <div className={css(mode(theme).headerLeft)} onClick={() => handleBack()}>
                    <ChevronNextSizeL className={css(mode(theme).chevron)} />

                    <div className={cn('is-flex align-center gap-2', css(s.applyingPos))}>
                        <FormattedMessage id="applyForPos" />

                        <h3>
                            {vacancy.name && vacancy.name.length > 70
                                ? vacancy.name.slice(0, 70) : vacancy.name}
                        </h3>
                    </div>
                </div>

                <div className="align-center gap-5">
                    <Button
                        type="button"
                        onClick={() => navigate(goBack, { state: { from: pathname } })}
                        className={css(s.cancelBtn)}>
                        <FormattedMessage id="cancel" />
                    </Button>

                    {step === 'second' ? (
                        <div onClick={applyTrigger}
                            className={cn('align-center gap-2', css(s.submitBtn))}>
                            {!applyLoading ? <FormattedMessage id="send" /> : <FormattedMessage id="sending" />}

                            {!applyLoading ? <ChevronNextSizeL /> : (
                                <Oval
                                    height={16}
                                    width={16}
                                    color={COLORS.white}
                                    visible
                                    ariaLabel="oval-loading"
                                    secondaryColor={COLORS.white}
                                    strokeWidth={7}
                                    strokeWidthSecondary={7}
                                />
                            )}
                        </div>
                    ) : (
                        <Button
                            type="submit"
                            className={cn('align-center gap-2', css(s.submitBtn))}>
                            {!loading ? <FormattedMessage id="next" /> : <FormattedMessage id="saving" />}

                            {!loading ? <ChevronNextSizeL /> : (
                                <Oval
                                    height={16}
                                    width={16}
                                    color={COLORS.white}
                                    visible
                                    ariaLabel="oval-loading"
                                    secondaryColor={COLORS.white}
                                    strokeWidth={7}
                                    strokeWidthSecondary={7}
                                />
                            )}
                        </Button>
                    )}
                </div>
            </div>
            <div className={css(mode(theme).cont)}>
                <div onClick={() => navigate('?step=first', { state: { ...state } })}
                    className={cn(css(mode(theme, step).first), 'align-center gap-3 pointer')}>
                    <h3>1</h3>

                    <h3><FormattedMessage id="mainInfos" /></h3>
                </div>

                <ArrowNextSizeL />

                <div onClick={() => navigate('?step=second', { state: { ...state } })}
                    className={cn(css(mode(theme, step).second), 'align-center gap-3 pointer')}>
                    <h3>2</h3>

                    <h3><FormattedMessage id="skillsAndCoverLetter" /></h3>
                </div>
            </div>
        </div>
    )
}

const s = StyleSheet.create({
    cancelBtn: {
        color: COLORS.gray,
        border: `1px solid ${COLORS.gray}`,
        background: 'transparent',
        padding: '0 16px',
        boxShadow: '0px 2px 4px rgba(130, 130, 130, 0.05)',
        borderRadius: '4px',
        cursor: 'pointer',
        height: 40,
        ':hover': {
            background: COLORS.smoothGray,
        },
        '@media (max-width: 768px)': {
            display: 'none',
        },
    },
    submitBtn: {
        fontSize: 14,
        fontWeight: '500',
        color: COLORS.white,
        border: 'none',
        padding: '0 16px',
        background: `linear-gradient(180deg, ${COLORS.mainColor} 0%, ${COLORS.lightMainColor} 100%)`,
        // eslint-disable-next-line max-len
        boxShadow: '0px 4px 12px rgba(44, 67, 50, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.25), inset 0px 1px 0px rgba(255, 255, 255, 0.1)',
        borderRadius: '4px',
        cursor: 'pointer',
        height: 40,
        ':hover': {
            background: `linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
            linear-gradient(180deg, ${COLORS.mainColor} 0%, ${COLORS.lightMainColor} 100%)`,
            boxShadow: `0px 4px 12px rgba(44, 67, 50, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.25), '
            inset 0px 1px 0px rgba(255, 255, 255, 0.1)`,
        },
        '@media (max-width: 768px)': {
            padding: '0 8px',
        },
    },
    applyingPos: {
        '@media (max-width: 576px)': {
            flexDirection: 'column',
            alignItems: 'start',
            gap: 0,
            fontSize: 14,
        },
    },
})

const mode = (theme, step) => StyleSheet.create({
    wrapper: {
        position: 'sticky',
        top: 0,
        zIndex: 2,
        background: theme === 'dark' ? COLORS.darkModeBg : COLORS.white,
    },
    header: {
        background: 'inherit',
        borderBottom: `1px solid ${theme === 'dark' ? COLORS.lightBlack : COLORS.smoothGray}`,
        padding: '14px 32px',
        display: 'flex',
        gap: 20,
        alignItems: 'center',
        justifyContent: 'space-between',
        '@media (max-width: 768px)': {
            padding: 16,
        },
    },
    chevron: {
        transform: 'rotate(180deg)',
        ':nth-child(1n) > path': {
            stroke: theme === 'dark' ? COLORS.white : COLORS.dark,
            strokeWidth: 3,
        },
    },
    headerLeft: {
        display: 'flex',
        alignItems: 'center',
        gap: 8,
        cursor: 'pointer',
        fontWeight: '600',
        fontSize: 24,
        color: theme === 'dark' ? COLORS.white : COLORS.dark,
        '@media (max-width: 1200px)': {
            fontSize: 16,
            gap: 4,
        },
    },
    cont: {
        background: 'inherit',
        borderBottom: `1px solid ${theme === 'dark' ? COLORS.lightBlack : COLORS.smoothGray}`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '11px 0',
        gap: 14,
        '@media (max-width: 768px)': {
            gap: 8,
        },
    },
    first: {
        fontWeight: '500',
        fontSize: 16,
        color: step === 'first' ? COLORS.white : theme === 'dark' ? COLORS.gray : COLORS.dark,
        ':nth-child(1n) > :first-child': {
            borderRadius: 64,
            padding: '6.5px 13px',
            background: step === 'first' ? COLORS.mainColor : theme === 'dark' ? COLORS.lightBlack : COLORS.smoothGray,
        },
        ':nth-child(1n) > :last-child': {
            color: step === 'first' ? COLORS.mainColor : COLORS.gray,
        },
        '@media (max-width: 768px)': {
            padding: '4px 10px',
            fontSize: 12,
        },
    },
    second: {
        fontWeight: '500',
        fontSize: 16,
        color: step === 'second' ? COLORS.white : theme === 'dark' ? COLORS.gray : COLORS.dark,
        ':nth-child(1n) > :first-child': {
            borderRadius: 64,
            padding: '6.5px 12px',
            background: step === 'second' ? COLORS.mainColor : theme === 'dark' ? COLORS.lightBlack : COLORS.smoothGray,
        },
        ':nth-child(1n) > :last-child': {
            color: step === 'second' ? COLORS.mainColor : COLORS.gray,
        },
        '@media (max-width: 768px)': {
            padding: '4px 10px',
            fontSize: 12,
        },
    },
})
