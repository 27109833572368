import React, { useEffect, useRef } from 'react'
// eslint-disable-next-line import/no-extraneous-dependencies
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
// import { getGeocode, getLatLng } from 'use-places-autocomplete'
import { isEmpty } from 'lodash'
import { COLORS } from '../../utils/colors'
// import { DefaultLocationT } from '../svgs/Svgs'
// import DefaultMapPin from '../../static/svgs/defaultMapPin.svg'

const defaultCenter = { // center Tashkent
    lat: 41.292705726422646,
    lng: 69.24321688978108,
}

const libraries = ['places']

export function MapWrapper({ children }) {
    return (
        <LoadScript
            loadingElement={null}
            libraries={libraries}
            googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
        >
            {children}
        </LoadScript>
    )
}

export const Map = ({ markers = [], mapContainerClassName, userLocation, center }) =>
// const handleMapClick = async (e) => {
// const latLng = {
//     lat: e.latLng.lat(),
//     lng: e.latLng.lng(),
// }
// const geoCode = await getGeocode({ location: latLng })

// if (typeof onMapClick === 'function') onMapClick({ ...latLng, address: geoCode[0].formatted_address })
// }

// const customMarkerIcon = {
//     url: DefaultMapPin,
//     // scaledSize: new window.google.maps.Size(30, 30), // Adjust the size as needed
//     // origin: new window.google.maps.Point(0, 0),
//     // anchor: new window.google.maps.Point(15, 15), // Half of the width and height for centering
// }

// const CustomOverlay = ({ lat, lng }) => (
//     <OverlayView
//         position={{ lat, lng }}
//         mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
//     >
//         <DefaultLocationT />
//         {/* <div className={cn('flex-cent', css(s.pin))}> */}
//         {/* <DefaultLocation color={COLORS.white} /> */}
//         {/* </div> */}
//     </OverlayView>
// )
    // eslint-disable-next-line implicit-arrow-linebreak
    (
        <GoogleMap
            zoom={(userLocation || center) ? 16 : 11}
            // onClick={handleMapClick}
            options={{
                gestureHandling: 'cooperative',
            }}
            center={center || userLocation || defaultCenter}
            mapContainerClassName={cn(css(s.map), mapContainerClassName)}>
            {markers.map(({ location, id }) => (
                // <CustomOverlay key={`${lat}-${lng}`} lat={lat} lng={lng} />
                <Marker key={id} position={location} />
            ))}
        </GoogleMap>
    )

export function MapSearcher({ data, clearSuggestions, onClear }) {
    const divRef = useRef()

    // const handleSelect = async (address) => {
    //     clearSuggestions()
    //
    //     try {
    //         const results = await getGeocode({ address })
    //         const { lat, lng } = await getLatLng(results[0])
    //         onSelect({ address, lat, lng })
    //     } catch (error) {
    //         console.error('Error:', error)
    //     }
    // }

    const handleClickOutside = () => {
        clearSuggestions()
        onClear()
    }

    // eslint-disable-next-line consistent-return
    useEffect(() => {
        if (!isEmpty(data)) {
            document.addEventListener('click', handleClickOutside)

            return () => {
                document.removeEventListener('click', handleClickOutside)
            }
        }
    }, [data])

    return !isEmpty(data) && (
        <div className={css(s.cont)}>
            <ul ref={divRef} className="no_dot_list">
                {data.map((suggestion) => (
                    <li
                        className={css(s.listItem)}
                        key={suggestion.id}
                        // onClick={(e) => {
                        //     e.stopPropagation()
                        //     handleSelect(suggestion.description)
                        // }}
                    >
                        {suggestion.description}
                    </li>
                ))}
            </ul>
        </div>
    )
}

const s = StyleSheet.create({
    map: {
        width: '100%',
        height: 400,
    },
    input: {
        width: '100%',
        height: 42,
        border: `1px solid ${COLORS.lightGray}`,
    },
    cont: {
        marginTop: 10,
        borderRadius: 4,
        background: COLORS.white,
        position: 'absolute',
        zIndex: 1,
        width: '100%',
        boxShadow: '0px 4px 16px 0px rgba(62, 62, 62, 0.15)',
        border: `1px solid ${COLORS.smoothGray}`,
    },
    listItem: {
        padding: '14px 16px',
        borderBottom: `1px solid ${COLORS.smoothGray}`,
        color: COLORS.gray,
        fontSize: 14,
        fontWeight: 500,
        cursor: 'pointer',
        ':last-of-type': {
            borderBottom: 'none',
        },
        ':hover': {
            color: COLORS.dark,
        },
    },
    pin: {
        width: 36,
        height: 36,
        background: COLORS.mainColor,
        borderRadius: 4,
        transform: 'translateY(90deg)',
        boxShadow: '0px 2px 4px 0px rgba(130, 130, 130, 0.05)',
        ':before': {
            content: '',
            position: 'absolute',
            height: 12,
            width: 12,
            left: '50%',
            bottom: -7,
            transform: 'translateX(-50%) rotate(45deg)',
            transition: 'all 0.5s cubic-bezier(0.68,-0.55,0.265,1.55)',
        },
    },
})
