import React, { memo, useContext, useEffect, useRef, useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import cn from 'classnames'
import { FormattedMessage } from 'react-intl'
import { Tick,
    LogoM,
    LoginIcon,
    ExitSizeM,
    UserCircle,
    ChevronDownS,
    VerifiedSizeL,
    DefaultLocation } from '../svgs/Svgs'
import { COLORS } from '../../utils/colors'
import Button from './Button'
import { Popup } from '../Popup'
import { LoadingSkeleton } from './LoadingSkeleton'
import { ExitModal } from '../profile/ExitModal'
import { BaseContext } from '../../contexts/BaseContextWrapper'
import { Context } from '../../contexts/GlobalContext'
import { useGetRequest } from '../../hooks/request'
import { PUBLIC_COMPANY_INFO } from '../../urls'
import ToggleSwitch from './ToggleSwitch'
import Ninja from '../../static/images/ninja.svg'
import { updateEducationLvl } from '../../utils/options'

function ProfilePopup({
    onClose,
    email,
    photo,
    firstName,
    lastName,
    exitRef,
    Avatar,
    navigate,
}) {
    return (
        <div className={cn('is-flex direction-column gap-4', css(s.profile))}>
            <div className="align-center gap-3">
                <div className={css(s.avatarPop)}>
                    {photo ? <img src={photo} alt="#UserImg" /> : <h3>{Avatar}</h3>}
                </div>

                <div className={cn('is-flex direction-column', css(s.name))}>
                    <h3>{firstName} {lastName}</h3>

                    <h3>{email}</h3>
                </div>
            </div>

            <ul className={cn('is-flex direction-column gap-3', css(s.lst))}>
                <li onClick={() => navigate('/account/info')}>
                    <UserCircle /> <FormattedMessage id="personalAccount" />
                </li>

                <li onClick={() => {
                    onClose()
                    exitRef.current.onOpenModal()
                }}>
                    <ExitSizeM /> <FormattedMessage id="logout" />
                </li>
            </ul>
        </div>
    )
}
function MemoizedComponent() {
    const { theme, toggleTheme, lang, setLang } = useContext(BaseContext)
    const { info, userLoading, getInfo } = useContext(Context)
    const navigate = useNavigate()
    const { pathname, search } = useLocation()
    const { cSlug } = useParams()
    const getCompanyInfo = useGetRequest({ url: PUBLIC_COMPANY_INFO.replace('{slug}', cSlug) })
    const cInfo = getCompanyInfo.response ? getCompanyInfo.response.data : {}
    const [, setOpenList] = useState(false)
    const exitRef = useRef()
    const popupRef = useRef()
    const token = localStorage.getItem('token')
    // eslint-disable-next-line max-len
    const Avatar = (info && Object.values(info).length) ? `${info.firstName.slice(0, 1)}${info.lastName.slice(0, 1)}` : ''

    useEffect(() => {
        if (cSlug) getCompanyInfo.request()
    }, [])

    const languageOptions = [
        { value: 'uz', label: 'O\'zbekcha' },
        { value: 'en', label: 'English' },
        { value: 'ru', label: 'Русский' },
    ]

    return (
        <div className={css(mode(theme, cSlug).headerWrap)}>
            <span
                onClick={() => navigate('/')}
                className={css(s.logoBar)}
            >
                { Object.values(cInfo).length && cSlug ? (
                    <div className="is-flex align-center gap-3">
                        {cInfo.logo && cInfo.logo.length
                            ? <img src={cInfo.logo[1]} className={css(s.cLogo)} alt="#CompanyLogo" />
                            : <DefaultLocation color={theme === 'dark' ? COLORS.white : COLORS.mainColor} />}

                        <h3 className={css(mode(theme, cSlug).cName)}>
                            {cInfo.name} {cInfo.verified ? <VerifiedSizeL /> : null}
                        </h3>
                    </div>
                ) : <LogoM color={theme === 'dark' || !cSlug ? COLORS.white : COLORS.dark} />}
            </span>

            <div className={css(s.right)}>
                <div className={cn(css(mode(theme, cSlug).themeToggleCont), 'is-flex align-center gap-2')}>
                    <img src={Ninja} alt="#NinjaIcon" />

                    <h3><FormattedMessage id="ninjaMode" /></h3>

                    <ToggleSwitch onClick={toggleTheme} isChecked={theme === 'dark'} />
                </div>

                {(!token && !getInfo.response) && !userLoading && pathname !== '/auth/user' ? (
                    <Button
                        className={cn(css(mode(theme, cSlug).loginBtn),
                            'is-flex align-center justify-center')}
                        onClick={() => navigate('/auth/user',
                            { state: { redirect: search ? pathname.concat(search) : pathname } })}
                    >
                        <h3><FormattedMessage id="loginToMyAccount" /></h3>

                        <LoginIcon
                            color={
                                cSlug && theme !== 'dark'
                                    ? COLORS.dark : theme === 'dark'
                                            && cSlug ? COLORS.white : COLORS.white
                            } />
                    </Button>
                ) : null}

                {!userLoading && Object.values(info).length ? (
                    <div className="is-flex align-center gap-5">
                        <Popup
                            ref={popupRef}
                            popupClassName={css(s.popup)}
                            position="right"
                            trigger={(
                                <div className={css(s.userInfoWrap)}>
                                    {info.firstName || info.lastName
                                        ? (
                                            <div className={cn(css(s.userCont), 'direction-column align-end')}>
                                                <h3 className={css(mode(theme, cSlug).status)}>
                                                    <FormattedMessage id="candidate" />
                                                </h3>

                                                <div className={css(mode(theme, cSlug).userName)}>
                                                    <h3>{info.firstName}</h3>

                                                    <h3>{info.lastName}</h3>
                                                </div>
                                            </div>
                                        ) : ''}

                                    <div className={css(mode(theme, cSlug).avatar)}>
                                        {info.photo && info.photo.length
                                            ? <img src={info.photo[0]} alt="#UserImg" />
                                            : <h3>{Avatar}</h3>}
                                    </div>
                                </div>
                            )}>
                            <ProfilePopup
                                firstName={info.firstName}
                                lastName={info.lastName}
                                email={info.email}
                                exitRef={exitRef}
                                Avatar={Avatar}
                                navigate={navigate}
                                pathname={pathname}
                                photo={info.photo[0]}
                                onClose={() => setOpenList(false)} />
                        </Popup>
                    </div>
                ) : null}

                {userLoading ? (
                    <div className={css(s.userInfoWrap)}>
                        <div className="direction-column align-end">
                            <h3 className={css(mode(theme, cSlug).status)}>
                                <FormattedMessage id="candidate" />
                            </h3>

                            <div className={css(s.userName)}>
                                <LoadingSkeleton height={12} />
                            </div>
                        </div>

                        <LoadingSkeleton borderRadius={50} width={32} height={32} />
                    </div>
                ) : null}

                <Popup
                    trigger={(
                        <Button className={cn(css(mode(theme, cSlug).langBtn), 'align-center justify-between')}>
                            <h3>{lang}</h3>

                            <ChevronDownS
                                color={theme === 'dark' ? COLORS.smoothGray : cSlug ? COLORS.dark : COLORS.white} />
                        </Button>
                    )}
                >
                    <div className={css(s.popUp)}>
                        {languageOptions.map((item) => (
                            <div
                                key={item.value}
                                className={cn(css(s.popUpItem), 'align-center justify-between')}
                                onClick={() => {
                                    setLang(item.value)
                                    updateEducationLvl(item.value)
                                }}>
                                {item.label}
                                {lang === item.value ? <Tick /> : null}
                            </div>
                        ))}
                    </div>
                </Popup>
            </div>

            <ExitModal ref={exitRef} onlyModal />
        </div>
    )
}

export const Header = memo(MemoizedComponent)

const s = StyleSheet.create({
    logoBar: {
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        color: '#FFF',
        fontSize: 14,
        fontWeight: '700',
        ':nth-child(1n) > :first-child': {
            marginRight: 2,
        },
    },
    cLogo: {
        width: 32,
        height: 32,
    },
    right: {
        display: 'flex',
        alignItems: 'center',
        gap: 16,
        '@media (max-width: 768px)': {
            gap: 8,
        },
    },
    popUp: {
        border: `1px solid ${COLORS.smoothGray}`,
        filter: 'drop-shadow(0px 4px 16px rgba(62, 62, 62, 0.15))',
        borderRadius: 4,
        width: '10.5rem',
        ':nth-child(1n) > :first-child': {
            borderTopLeftRadius: 4,
            borderTopRightRadius: 4,
        },
        ':nth-child(1n) > div': {
            borderBottom: `1px solid ${COLORS.smoothGray}`,
        },
        ':nth-child(1n) > :last-child': {
            borderBottom: 'none',
            borderBottomLeftRadius: 4,
            borderBottomRightRadius: 4,
        },
    },
    popUpItem: {
        userSelect: 'none',
        background: COLORS.white,
        padding: '0 10px',
        height: 36,
        fontWeight: '500',
        fontSize: 12,
        color: COLORS.dark,
        cursor: 'pointer',
        ':hover': {
            background: '#E3FAEF',
        },
        ':nth-child(1n) > :last-child': {
            ':nth-child(1n) > path': {
                fill: COLORS.mainColor,
            },
        },
    },
    userInfoWrap: {
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        gap: 8,
        position: 'relative',
    },
    profileBar: {
        ':nth-child(1n) > :first-child': {
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'end',
            ':nth-child(1n) > :first-child': {
                fontWeight: '500',
                fontSize: 11,
                color: '#FFFFFFBF',
            },
            ':nth-child(1n) > :last-child': {
                fontWeight: '700',
                fontSize: 12,
                color: '#FFF',
            },
        },
    },
    userCont: {
        '@media (max-width: 500px)': {
            display: 'none',
        },
    },
    avatarPop: {
        width: 32,
        height: 32,
        background: COLORS.white,
        borderRadius: 50,
        border: `1px solid ${COLORS.lightGray}`,
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        justifyContent: 'center',
        ':nth-child(1n) > h3': {
            fontWeight: '500',
            fontSize: 12,
            color: COLORS.dark,
            lineHeight: '14px',
        },
        ':nth-child(1n) > img': {
            width: 'inherit',
            height: 'inherit',
            borderRadius: 50,
        },
    },
    registerBtn: {
        fontWeight: '500',
        fontSize: 13,
        color: COLORS.white,
        background: 'rgba(255, 255, 255, 0.2)',
        borderRadius: 4,
        padding: '8.5px 16px',
    },
    profile: {
        marginTop: '0.5rem',
        width: '13rem',
        background: COLORS.white,
        border: `1px solid ${COLORS.lightGray}`,
        boxShadow: '0px 2px 8px rgba(136, 136, 136, 0.25)',
        borderRadius: 4,
        padding: 16,
    },
    name: {
        ':nth-child(1n) > :first-child': {
            color: COLORS.dark,
            fontWeight: '700',
            fontSize: 12,
        },
        ':nth-child(1n) > :last-child': {
            color: COLORS.gray,
            fontWeight: '500',
            fontSize: 10,
            marginTop: 4,
        },
    },
    lst: {
        fontWeight: '400',
        fontSize: 12,
        color: COLORS.lightBlack,
        listStyle: 'none',
        ':nth-child(1n) > *': {
            display: 'flex',
            alignItems: 'center',
            gap: 8,
            cursor: 'pointer',
        },
    },
    icon: {
        ':nth-child(1n) > *': {
            stroke: COLORS.spanishGray,
        },
    },
    popup: {
        top: 20,
    },
})

const mode = (theme, slug) => StyleSheet.create({
    headerWrap: {
        zIndex: 3,
        position: 'relative',
        background: theme === 'dark' ? COLORS.dark
            : slug ? COLORS.white : COLORS.mainColor,
        boxShadow: '0px 1px 16px rgba(72, 109, 96, 0.06)',
        padding: '10px 25px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        '@media (max-width: 768px)': {
            padding: '12px 16px',
        },
    },
    themeToggleCont: {
        borderRadius: 4,
        color: theme === 'dark' ? COLORS.smoothGray : slug ? COLORS.dark : COLORS.white,
        padding: '6px 8px',
        border: `1px solid ${theme === 'dark' ? '#343836'
            : slug ? COLORS.smoothGray : COLORS.white}`,
        boxShadow: '0px 2px 4px 0px rgba(130, 130, 130, 0.05)',
        background: 'transparent',
        fontSize: 12,
        fontWeight: '500',
        '@media (max-width: 500px)': {
            border: 'none',
            padding: 0,
            ':nth-child(1n) > :first-child': {
                display: 'none',
            },
            ':nth-child(1n) > h3': {
                display: 'none',
            },
        },
    },
    status: {
        fontWeight: '500',
        fontSize: 11,
        color: theme === 'dark' ? 'rgba(255, 255, 255, 0.75)'
            : slug ? COLORS.gray : 'rgba(255, 255, 255, 0.75)',
    },
    userName: {
        display: 'flex',
        alignItems: 'center',
        gap: 4,
        ':nth-child(1n) > h3': {
            fontWeight: 700,
            fontSize: 12,
            color: theme === 'dark' ? COLORS.white : slug ? COLORS.dark : COLORS.white,
            marginTop: 2,
        },
    },
    avatar: {
        width: 32,
        height: 32,
        background: theme === 'dark' ? 'transparent' : COLORS.white,
        borderRadius: 50,
        display: 'flex',
        alignItems: 'center',
        border: `1px solid ${theme === 'dark' ? COLORS.lightBlack : COLORS.smoothGray}`,
        cursor: 'pointer',
        justifyContent: 'center',
        ':nth-child(1n) > h3': {
            fontWeight: '500',
            fontSize: 12,
            color: theme === 'dark' ? COLORS.white : COLORS.dark,
            lineHeight: '14px',
        },
        ':nth-child(1n) > img': {
            width: 'inherit',
            height: 'inherit',
            borderRadius: 50,
        },
    },
    loginBtn: {
        height: 32,
        borderRadius: 4,
        border: theme === 'dark' ? '1px solid #343836' : slug ? `1px solid ${COLORS.smoothGray}` : 'none',
        background: theme === 'dark' || slug ? 'transparent' : 'rgba(255, 255, 255, 0.20)',
        boxShadow: theme === 'dark' ? 'none' : '0px 2px 4px 0px rgba(130, 130, 130, 0.05)',
        padding: '9px 16px',
        fontWeight: 500,
        fontSize: 13,
        color: theme === 'dark' ? COLORS.smoothGray : slug ? COLORS.dark : COLORS.white,
        '@media (min-width: 501px)': {
            ':nth-child(1n) > :last-child': {
                display: 'none',
            },
        },
        '@media (max-width: 500px)': {
            ':nth-child(1n) > :first-child': {
                display: 'none',
            },
            background: theme === 'dark' ? COLORS.darkModeBg : 'rgba(255, 255, 255, 0.20)',
            padding: 0,
            height: 28,
            width: 36,
        },
    },
    cName: {
        color: theme === 'dark' ? COLORS.white : COLORS.dark,
        fontSize: 18,
        fontWeight: '700',
        display: 'flex',
        alignItems: 'center',
        gap: 4,
    },
    langBtn: {
        height: 28,
        boxSizing: 'border-box',
        width: '3.674rem',
        border: `1px solid ${theme === 'dark' ? '#343836' : slug && theme !== 'dark' ? COLORS.smoothGray : 'none'}`,
        background: theme === 'dark' || slug ? 'inherit' : 'rgba(255, 255, 255, 0.2)',
        borderRadius: 4,
        padding: '0 8px',
        fontWeight: 500,
        fontSize: 13,
        textTransform: 'uppercase',
        ':nth-child(1n) > h3': {
            color: theme === 'dark' ? COLORS.smoothGray : slug ? COLORS.dark : COLORS.white,
        },
    },
})
