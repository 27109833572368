import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import Select, { components } from 'react-select'
import { Field, getIn, useFormikContext } from 'formik'
import Highlighter from 'react-highlight-words'
import { FormattedMessage } from 'react-intl'
import ValidationErrorMessage from './ValidationErrorMessage'
import { CheckMark, ChevronDown, XCircle } from '../svgs/Svgs'
import { COLORS } from '../../utils/colors'

export default function SelectForm({
    icon,
    name,
    help,
    label,
    options,
    optStyle,
    validate,
    optional,
    onChange,
    className,
    customOption,
    dropdownColor,
    contClassname,
    controlStyles,
    indicatorStyle,
    secondaryLabel,
    labelClassName,
    customComponents,
    placeholderStyles,
    labelContClassName,
    optionLabelClassName,
    styles = {},
    theme = '',
    valueContainerStyles,
    isMulti = false,
    errorWithMessage = true,
    ...attributes
}) {
    const { errors, touched } = useFormikContext()
    const isValid = !errorWithMessage && getIn(errors, name) && getIn(touched, name)

    const customStyles = {
        option: (optStyles, { isSelected, isFocused }) => ({
            ...optStyles,
            backgroundColor: (isSelected || isFocused) && theme === 'dark' ? COLORS.darkModeBg : (isSelected || isFocused) && theme !== 'dark' ? COLORS.mintCream : ' transparent', // eslint-disable-line
            color: isSelected ? COLORS.dark : COLORS.midGray,
            cursor: 'pointer',
            padding: '14px 16px',
            fontWeight: '500',
            fontSize: 14,
            borderBottom: `1px solid ${theme === 'dark' ? '#595959' : COLORS.smoothGray}`,
            '&:hover': {
                backgroundColor: (isSelected || isFocused) && theme === 'dark' ? COLORS.darkModeBg : (isSelected || isFocused) && theme !== 'dark' ? COLORS.mintCream : ' transparent', // eslint-disable-line
            },
            ...optStyle,
        }),
        valueContainer: (optStyles) => ({
            ...optStyles,
            padding: '12.75px 16px',
            gap: 8,
            ...valueContainerStyles,
        }),
        input: (optStyles) => ({
            ...optStyles,
            outline: 'none !important',
            padding: 0,
            margin: 0,
        }),
        placeholder: (optStyles) => ({
            ...optStyles,
            fontSize: 14,
            fontWeight: '500',
            color: theme === 'dark' ? COLORS.gray : COLORS.dark,
            ...placeholderStyles,
        }),
        singleValue: (optStyles) => ({
            ...optStyles,
            fontSize: 14,
            fontWeight: '500',
            color: COLORS.dark,
        }),
        control: (optStyles) => ({
            ...optStyles,
            borderColor: isValid ? COLORS.lightRed : theme === 'dark' ? 'transparent' : COLORS.lightGray,
            boxShadow: 'none',
            '&:hover': {
                borderColor: `${isValid ? COLORS.lightRed : COLORS.lightGray} !important`,
            },
            ...controlStyles,
        }),
        dropdownIndicator: (optStyles) => ({
            ...optStyles,
            ...indicatorStyle,
        }),
        multiValue: (optStyles) => ({
            ...optStyles,
            height: 24,
            padding: '0 8px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 4,
            margin: 0,
            borderRadius: 4,
        }),
        multiValueLabel: (optStyles) => ({
            ...optStyles,
            color: COLORS.midGray,
            fontSize: 11,
            fontWeight: '400',
            padding: 0,
            paddingLeft: 0,

        }),
        multiValueRemove: (optStyles) => ({
            ...optStyles,
            cursor: 'pointer',
            ':nth-of-type(1n) > :first-of-type': {
                ':nth-of-type(1n) > :first-of-type': {
                    fill: COLORS.midGray,
                },
            },
            ':hover': {
                ':nth-of-type(1n) > :first-of-type': {
                    ':nth-of-type(1n) > :first-of-type': {
                        fill: COLORS.lightBlack,
                    },
                },
            },
        }),
        ...styles,
    }

    const Menu = (props) => <components.Menu className={css(s.opt)} {...props} />

    const Option = (props) => {
        const { children, isSelected, selectProps } = props

        return (
            <components.Option {...props}>
                <span className="justify-between" style={{ height: 16 }}>
                    {/* <h3 className={cn(css(s.optLab), optionLabelClassName)}> */}
                    {/*     {children} */}
                    {/* </h3> */}

                    <Highlighter
                        textToHighlight={children}
                        activeClassName="highlight"
                        searchWords={[selectProps.inputValue.trim()]}
                        highlightClassName={cn(css(s.optLab), optionLabelClassName)}
                    />

                    {isSelected ? (<CheckMark />) : ''}
                </span>
            </components.Option>
        )
    }

    const DropdownIndicator = (props) => (
        <components.DropdownIndicator {...props}>
            <span className={cn('expand expand-more is-flex')}>
                <ChevronDown />
            </span>
        </components.DropdownIndicator>
    )

    const MultiValueRemove = (props) => (
        <components.MultiValueRemove {...props}>
            <XCircle />
        </components.MultiValueRemove>
    )

    const IndicatorsContainer = (props) => (!isMulti ? (
        <components.IndicatorsContainer {...props} />
    ) : null)

    const isRequired = typeof validate === 'function'

    return (
        <div className={cn(contClassname)}>
            <div className="control">
                {label ? (
                    <div className={cn('justify-between', labelContClassName)}>
                        <span className={css(s.lab)}>
                            <label className={cn(css(mode(theme).label), labelClassName)} htmlFor={name}>
                                {label} {isRequired && (
                                    <span style={{ color: isValid ? COLORS.lightRed : COLORS.midGray }}>
                                        *
                                    </span>
                                )}
                            </label>
                        </span>

                        {secondaryLabel || null}
                    </div>
                ) : null}

                <Field name={name} validate={validate}>
                    {({ form }) => (
                        <Select
                            isMulti={isMulti}
                            components={{
                                Option: customOption || Option,
                                Menu,
                                DropdownIndicator,
                                MultiValueRemove,
                                IndicatorsContainer,
                                IndicatorSeparator: () => null,
                                ...customComponents,
                            }}
                            className={className}
                            styles={customStyles}
                            onBlur={() => {
                                form.setFieldTouched()
                            }}
                            noOptionsMessage={() => <FormattedMessage id="notFound" />}
                            autoFocus={false}
                            options={options}
                            {...attributes}
                            onChange={(values) => {
                                if (typeof onChange === 'function') {
                                    onChange(values)
                                    return
                                }
                                form.setFieldValue(name, values)
                            }} />
                    )}
                </Field>

                {errorWithMessage && <ValidationErrorMessage name={name} />}
            </div>
        </div>
    )
}

const s = StyleSheet.create({
    help: {
        marginTop: '-2px',
    },
    lab: {
        display: 'inline-flex',
        paddingBottom: 8,
    },
    opt: {
        ':nth-child(1n) > :first-child': {
            padding: 0,
            ':nth-child(1n) > :first-child': {
                borderTopRightRadius: 4,
                borderTopLeftRadius: 4,
            },
            ':nth-child(1n) > :last-child': {
                borderBottomWidth: 0,
                borderBottomRightRadius: 4,
                borderBottomLeftRadius: 4,
            },
        },
    },
    optLab: {
        backgroundColor: 'transparent',
    },
})

const mode = (theme) => StyleSheet.create({
    label: {
        fontSize: 12,
        color: theme === 'dark' ? COLORS.lightGray : COLORS.midGray,
        fontWeight: '500',
    },
})
