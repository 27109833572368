import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import { COLORS } from '../../../utils/colors'
import Checkbox from '../../common/Checkbox'

export default function FilterByExperience({
    name,
    options,
    setFilter,
    filter,
    theme,
}) {
    function onCheck(id) {
        if (filter[name]) {
            if (filter[name].includes(id)) {
                if (filter[name].length > 1) {
                    setFilter({
                        ...filter,
                        [name]: filter[name].filter((fil) => fil !== id),
                    })
                } else {
                    const {
                        [name]: sectionFilter,
                        ...clearedFilter
                    } = filter
                    setFilter(clearedFilter)
                }
            } else {
                setFilter({ ...filter, [name]: [...filter[name], id] })
            }
        } else setFilter({ ...filter, [name]: [id] })
    }

    const isChecked = (id) => filter[name] && filter[name].includes(id)

    return (
        <ul className="no_dot_list is-flex direction-column gap-2">
            {options.map((option) => (
                <li onClick={() => onCheck(option.value)} key={option.value} className="justify-between pointer">
                    <div className="align-center gap-2">
                        <Checkbox
                            className={css(mode(theme).checkBox)}
                            checked={isChecked(option.value)}
                            onClick={() => {}}
                        />

                        <h3 className={css(mode(theme).label,
                            !isChecked(option.value) && mode(theme).labelColor)}>
                            {option.label}
                        </h3>
                    </div>

                    {/* <span className={cn( */}
                    {/*    'flex-cent', css(s.count, !isChecked(option.value) && s.countColor), */}
                    {/* )}> */}
                    {/*    {0} */}
                    {/* </span> */}
                </li>
            ))}
        </ul>
    )
}

// const s = StyleSheet.create({
//     count: {
//         border: '1px solid #2EA97D33',
//         borderRadius: 4,
//         background: '#FAFFFC',
//         color: COLORS.mainColor,
//         fontSize: 10,
//         fontWeight: '500',
//         height: 16,
//         padding: '0 4px',
//     },
//     countColor: {
//         color: COLORS.midGray,
//         background: COLORS.smoothGray,
//         border: `1px solid ${COLORS.smoothGray}`,
//     },
// })

const mode = (theme) => StyleSheet.create({
    label: {
        fontSize: 11,
        fontWeight: '500',
        color: theme === 'dark' ? COLORS.lightGray : COLORS.lightBlack,
    },
    labelColor: {
        color: COLORS.gray,
    },
    checkBox: {
        borderColor: theme === 'dark' ? COLORS.gray : '#E8E8E8',
    },
})
