export const COLORS = {
    mainColor: '#2EA97D',
    white: '#FFFFFF',
    dark: '#181A19',
    lightBlack: '#343836',
    mainTitleColor: '#414644',
    gray: '#969F9B',
    lightGray: '#C9D2CE',
    smoothGray: '#EDEDEE',
    midGray: '#6D7471',
    lightRed: '#F33B5C',
    lightMainColor: '#138753',
    skyblue: '#0095F2',
    mintCream: '#FAFFFC',
    blue: '#1B93C0',
    green: '#386E56',
    chineseWhite: '#E0E0E0',
    lotion: '#FAFAFA',
    spanishGray: '#96A09B',
    saladColor: '#F2FAF6',
    darkModeBg: '#313131',
    itemDarkModeBg: '#464646',
    borderBottom: '#EBEBEB',
    darkModeBody: '#242424',
    mainIconColor: '#A2A2A2',
    mainIconDarkMode: '#A1A1A1',
}
