import React, { memo, useContext, useEffect, useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import uuid from 'react-uuid'
import { FormattedMessage, useIntl } from 'react-intl'
import { EditSizeL, Language, PlusRoundedSizeL, XCircleSizeL } from '../svgs/Svgs'
import SelectForm from './SelectForm'
import { AvailableLanguages, LanguageLevels } from '../../utils/options'
import { COLORS } from '../../utils/colors'
import { BaseContext } from '../../contexts/BaseContextWrapper'
import AmazingTooltip from './AmazingTooltip'

function LanguageBlock({ setFieldValue, index, removeMode, langs, onRemove, availableLanguages, theme, lang }) {
    const blockValue = langs[index] ? langs[index].lang : ''
    const intl = useIntl()

    return (
        <div className={css(mode(theme).wrapper)}>
            <div
                style={{ borderColor: 'transparent' }}
                className={css(s.langForm, removeMode && index !== 1 && s.removable_field)}>
                {removeMode && index !== 1 && (
                    <XCircleSizeL className="dis_sl" onClick={() => onRemove(index)} />
                )}

                <SelectForm
                    theme={theme}
                    labelContClassName={css(s.lab)}
                    valueContainerStyles={{ padding: '11.5px 16px' }}
                    placeholderStyles={{ color: COLORS.gray, fontFamily: 'Roboto, sans-serif' }}
                    labelClassName={css(s.lb)}
                    indicatorStyle={{ paddingRight: 10 }}
                    label={<FormattedMessage id="candidateLanguages" />}
                    placeholder={intl.formatMessage({ id: 'chooseLanguage' })}
                    value={AvailableLanguages.find((e) => e.short === blockValue)}
                    getOptionLabel={(option) => option[lang]}
                    className={css(mode(theme).selectInp)}
                    name={`languageLevel[${index}]`}
                    onChange={(value) => {
                        setFieldValue(
                            'languageLevel',
                            { ...langs, [index]: { lang: value.short, level: 'A1' } },
                        )
                    }}
                    options={availableLanguages}
                />

                <div className={cn('is-flex direction-column gap-2', css(mode(theme).wrap))}>
                    <h3><FormattedMessage id="langLvl" /></h3>

                    <div className="is-flex">
                        {LanguageLevels.map((e) => (
                            <AmazingTooltip
                                key={uuid()}
                                className={css(s.tooltip)}
                                trigger={(
                                    <span
                                        key={uuid()}
                                        onMouseDown={() => {
                                            setFieldValue(
                                                'languageLevel',
                                                { ...langs, [index]: { ...langs[index], level: e.level } },
                                            )
                                        }}
                                        className={
                                            cn(css(mode(theme).lvl),
                                                { [css(s.selected)]: langs[index] && langs[index].level === e.level })
                                        }>
                                        {e.level}
                                    </span>
                                )}
                            >
                                {e[lang]}
                            </AmazingTooltip>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

function MemoizedComponent({ setFieldValue, values }) {
    const { theme, lang } = useContext(BaseContext)
    const [availableLanguages, setAvailableLanguages] = useState(AvailableLanguages)
    const [removeMode, setRemoveMode] = useState(false)
    const langs = values.languageLevel
    const langCount = Object.values(langs).length

    const onRemove = async (index) => {
        const { [index]: removedLang, ...clearedLangs } = langs
        await setFieldValue('languageLevel', clearedLangs)

        if (Object.keys(clearedLangs).length <= 1) setRemoveMode(false)
    }

    useEffect(() => {
        const langCodesSet = new Set(Object.values(langs).map((l) => l.lang))
        setAvailableLanguages(AvailableLanguages.filter((e) => !langCodesSet.has(e.short)))
    }, [langs])

    return (
        <div className={css(mode(theme).formWrap)}>
            <div className={css(mode(theme).formHeader)}>
                <div className={cn('align-center gap-2', css(s.headTitle))}>
                    <Language />

                    <h3><FormattedMessage id="languageKnowledge" /></h3>
                </div>

                <div className="align-center gap-6">
                    {langCount > 1 && (
                        <EditSizeL onClick={() => setRemoveMode(!removeMode)} className="pointer dis_sl" />
                    )}

                    <PlusRoundedSizeL
                        className="pointer dis_sl"
                        onClick={() => setFieldValue(
                            'languageLevel',
                            { ...langs, [langCount + 1]: { lang: null, level: null } },
                        )} />
                </div>
            </div>

            <div className={css(mode(theme, langCount).formBody)}>
                {Object.keys(langs).map((langKey) => (
                    <LanguageBlock
                        key={+langKey}
                        index={+langKey}
                        langs={langs}
                        theme={theme}
                        lang={lang}
                        count={langCount}
                        onRemove={onRemove}
                        removeMode={removeMode}
                        setFieldValue={setFieldValue}
                        availableLanguages={availableLanguages}
                    />
                ))}
            </div>
        </div>
    )
}

export const LanguagesForm = memo(MemoizedComponent)

const s = StyleSheet.create({
    headTitle: {
        '@media (max-width: 768px)': {
            gap: 4,
        },
    },
    removable_field: {
        borderColor: COLORS.lightRed,
        ':nth-child(1n) > :first-child': {
            cursor: 'pointer',
            position: 'absolute',
            right: -12,
            top: -12,
        },
    },
    lab: {
        ':nth-child(1n) > span': {
            display: 'inline-flex',
        },
    },
    langForm: {
        padding: '16px 24px',
        display: 'flex',
        flexDirection: 'column',
        borderWidth: 1,
        borderStyle: 'solid',
        borderRadius: 5,
        position: 'relative',
        gap: 24,
        '@media (max-width: 768px)': {
            padding: 16,
            gap: 16,
        },
    },
    selected: {
        width: '100%',
        background: `linear-gradient(180deg, ${COLORS.mainColor} 0%, ${COLORS.lightMainColor} 100%)`,
        borderRight: '1px solid rgba(0, 0, 0, 0.15)',
        pointerEvents: 'none',
        color: COLORS.white,
        backgroundClip: 'padding-box',
    },
    tooltip: {
        width: '100%',
        whiteSpace: 'nowrap',
        zIndex: 0,
    },
})

const mode = (theme, count) => StyleSheet.create({
    formWrap: {
        border: theme === 'dark' ? 'none' : `1px solid ${COLORS.smoothGray}`,
        background: COLORS.white,
        boxShadow: '0px 2px 4px rgba(130, 130, 130, 0.05)',
        borderRadius: 4,
        width: '100%',
    },
    formHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '14px 24px',
        fontWeight: '600',
        fontSize: 13,
        textTransform: 'uppercase',
        color: theme === 'dark' ? COLORS.white : COLORS.dark,
        borderBottom: `1px solid ${theme === 'dark' ? COLORS.lightBlack : COLORS.lightGray}`,
        borderRadius: '4px 4px 0px 0px',
        ':nth-child(1n) > :last-child': {
            cursor: 'pointer',
        },
        ':nth-child(1n) > :first-child': {
            ':nth-child(1n) > :first-child': {
                ':nth-child(1n) > path': {
                    stroke: theme === 'dark' ? COLORS.gray : '#333333',
                },
            },
        },
        '@media (max-width: 768px)': {
            padding: '14px 16px',
        },
    },
    formBody: {
        display: 'grid',
        gridTemplateColumns: count > 1 ? '50% 50%' : '1fr',
        '@media (max-width: 768px)': {
            gridTemplateColumns: '1fr',
        },
    },
    wrap: {
        ':nth-child(1n) > h3': {
            fontWeight: '500',
            fontSize: 12,
            color: theme === 'dark' ? COLORS.lightGray : COLORS.midGray,
        },
    },
    wrapper: {
        width: '100%',
        borderBottom: `1px solid ${theme === 'dark' ? COLORS.lightBlack : COLORS.lightGray}`,
    },
    box: {
        background: COLORS.white,
        border: `1px solid ${COLORS.smoothGray}`,
        boxShadow: '0px 2px 4px rgba(130, 130, 130, 0.05)',
        borderRadius: 4,
    },
    lb: {
        fontSize: 12,
        fontWeight: '500',
        color: COLORS.midGray,
    },
    selectInp: {
        ':nth-child(1n) > div': {
            // styles for div inside select component
            background: theme === 'dark' ? COLORS.itemDarkModeBg : COLORS.white,
            border: theme === 'dark' ? 'none' : `1px solid ${COLORS.lightGray}`,
            color: theme === 'dark' ? COLORS.white : COLORS.dark,
            ':nth-child(1n) *': {
                color: theme === 'dark' ? COLORS.white : COLORS.dark,
            },
        },
    },
    lvl: {
        flex: 1,
        textAlign: 'center',
        background: theme === 'dark' ? 'rgba(255, 255, 255, 0.10)' : COLORS.white,
        border: 'solid rgba(0, 0, 0, 0.15)',
        borderTopWidth: 1,
        borderBottomWidth: 1,
        borderLeftWidth: 0.5,
        borderRightWidth: 0.5,
        padding: '13px 0',
        fontWeight: '500',
        fontSize: 14,
        color: COLORS.lightGray,
        cursor: 'pointer',
        ':hover': {
            background: COLORS.smoothGray,
            color: theme === 'dark' ? COLORS.lightBlack : COLORS.gray,
        },
        ':first-of-type': {
            borderLeftWidth: 1,
            borderRadius: '3px 0 0 3px',
        },
        ':last-of-type': {
            borderRightWidth: 1,
            borderRadius: '0 3px 3px 0',
        },
    },
})
