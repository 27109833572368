import React, { useContext, useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import uuid from 'react-uuid'
import DOMPurify from 'isomorphic-dompurify'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { BachelorHat,
    Share,
    MapPin,
    EyeOpen,
    Briefcase,
    DeviceLaptop,
    EyeOpenSizeS,
    ChevronNextSizeL,
    // HourGlass,
    FileSymlink,
    VerifiedSizeL,
    DefaultLocationSizeS,
    WalletSizeM } from '../../svgs/Svgs'
import ScrollAnimation from '../../common/ScrollAnimation'
import Modal from '../../common/Modal'
import ShareModal from '../ShareModal'
import Button from '../../common/Button'
import { AvailableLanguages, educationLvl, jobFormats, LanguageLevels, workSchedule } from '../../../utils/options'
import { useLoad } from '../../../hooks/request'
import { PUBLIC_COMPANY_VACANCIES, PUBLIC_COMPANY_VACANCY } from '../../../urls'
import { COLORS } from '../../../utils/colors'
import OtherVacancies from './OtherVacancies'
import VacancyInfoSkeleton from './VacancyInfoSkeleton'
import { BaseContext } from '../../../contexts/BaseContextWrapper'
import NotFound from '../../errors/NotFound'
// import { getFullDate } from '../../../utils/date'

export default function VacancyInfo() {
    const { theme, lang } = useContext(BaseContext)
    const navigate = useNavigate()
    const { state, pathname } = useLocation()
    const params = useParams()
    const [showModal, setShowModal] = useState(false)
    const loadVacancyInfo = useLoad(
        { url: PUBLIC_COMPANY_VACANCY
            .replace('{slug}/{vSlug}', `${params.cSlug}/${params.vSlug}`) }, [params],
    )

    const loadVacancies = useLoad({ url: PUBLIC_COMPANY_VACANCIES.replace('{slug}', params.cSlug || '') })
    const cVacancies = loadVacancies.response ? loadVacancies.response.data : []
    const vacancyInfo = loadVacancyInfo.response ? loadVacancyInfo.response.data : []
    const token = localStorage.getItem('token')
    const goTo = token ? `/c/${params.cSlug}/${params.vSlug}/apply?step=first` : '/auth/user'

    const handleNav = (type) => {
        if (type === 'apply') {
            navigate(goTo, { state: {
                from: state ? state.from : '/',
                prev: `/c/${params.cSlug}/${params.vSlug}`,
                redirect: `/c/${params.cSlug}/${params.vSlug}/apply?step=first`,
            } })
        } else if (type === 'back') {
            if (state && (state.from || state.prev)) {
                navigate(state.prev || state.from,
                    { state: { from: state.from || '/' } })
            } else {
                navigate('/')
            }
        }
    }

    return (
        <>
            {loadVacancyInfo.loading && <VacancyInfoSkeleton theme={theme} path={pathname} navigate={navigate} />}
            {!loadVacancyInfo.loading && loadVacancyInfo.response ? (
                <div className={css(s.mainWrap)}>
                    {cVacancies.length > 1
                        ? (
                            <OtherVacancies
                                state={state}
                                theme={theme}
                                handleNavigate={handleNav}
                                params={params}
                                vacancies={cVacancies}
                                loading={loadVacancies.loading}
                                companyLogo={Object.values(vacancyInfo).length ? vacancyInfo.company.logo[1] : ''} />
                        )
                        : null}

                    <ScrollAnimation
                        contClassName={css(mode(theme).infoWrap)}
                        /* eslint-disable-next-line max-len */
                        bodyClassName={css(s.vacDescBody)}
                        headerClassName={css(mode(theme).contHeader)}
                        headerChildren={(
                            <>
                                <div className={cn(css(s.flxBtw), 'is-flex align-start justify-between')}>
                                    <div className={css(s.companyBrief)}>
                                        <div onClick={() => handleNav('back')} className="is-flex gap-2">
                                            <span className={chevron(cVacancies)}>
                                                <ChevronNextSizeL
                                                    color={theme === 'dark' ? COLORS.white : COLORS.dark} />
                                            </span>

                                            <h3 className={css(mode(theme).vacancyName)} title={vacancyInfo.name}>

                                                {vacancyInfo.name.length > 70
                                                    ? vacancyInfo.name.slice(0, 70) : vacancyInfo.name}
                                            </h3>
                                        </div>

                                        <div onClick={() => navigate(`/c/${params.cSlug}`)}
                                            className={cn('align-center pointer', css(mode(theme).companyName))}>
                                            {vacancyInfo.company && vacancyInfo.company.logo.length ? (
                                                <div className={css(mode(theme).logoCont)}>
                                                    <img
                                                        alt="#CompanyLogo"
                                                        src={vacancyInfo.company && vacancyInfo.company.logo[1]}
                                                    />
                                                </div>
                                            ) : (
                                                <div className={css(mode(theme).defaultLogo)}>
                                                    <DefaultLocationSizeS color={COLORS.white} />
                                                </div>
                                            )}

                                            <p className="is-flex align-center gap-1">
                                                {vacancyInfo.company && vacancyInfo.company.name}

                                                {vacancyInfo.company.verified ? <VerifiedSizeL /> : null}
                                            </p>
                                        </div>
                                    </div>

                                    <div className={cn('align-start gap-5', css(s.btnGrp))}>
                                        <div className={cn(css(mode(theme).viewCount),
                                            'is-flex align-center justify-center gap-1')}>
                                            <EyeOpen color={theme === 'dark'
                                                ? COLORS.mainIconDarkMode : COLORS.mainIconColor} />

                                            <span className="is-flex align-center justify-center">
                                                <EyeOpenSizeS color={theme === 'dark'
                                                    ? COLORS.mainIconDarkMode : COLORS.mainIconColor} />
                                            </span>

                                            <h3>{vacancyInfo.view}</h3>
                                        </div>

                                        <div className="is-flex align-center gap-5">
                                            <Modal
                                                trigger={(
                                                    <button className={css(mode(theme).shareBtn)}><Share /></button>
                                                )}
                                                open={showModal}
                                                closeOnEscape
                                                overlayStyle={{ top: 52 }}
                                                closeOnDocumentClick
                                                onClose={() => setShowModal(false)}
                                                onOpen={() => setShowModal(true)}
                                            >
                                                <ShareModal
                                                    theme={theme}
                                                    shareLink={
                                                    // eslint-disable-next-line max-len
                                                        `${window.location.protocol}//${window.location.hostname}/c/${params.cSlug}/${params.vSlug}`
                                                    }
                                                    onCLose={() => setShowModal(false)}
                                                />
                                            </Modal>

                                            <Button
                                                onClick={
                                                    !vacancyInfo.isApplied
                                                        ? () => handleNav('apply') : null
                                                }
                                                className={cn(css(vacancyInfo.isApplied ? s.disabledBtn : s.applyBtn),
                                                    'is-flex align-center justify-center gap-2')}
                                            >
                                                <FileSymlink />

                                                {vacancyInfo.isApplied
                                                    ? <FormattedMessage id="alreadyApplied" />
                                                    : <FormattedMessage id="apply" />}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                        bodyChildren={(
                            <React.Fragment>
                                <div className={cn('align-center', css(s.items))}>
                                    <div className={css(mode(theme).itemWrap)}>
                                        <Briefcase />

                                        <div className={css(mode(theme).info)}>
                                            <h3><FormattedMessage id="experience" /></h3>

                                            {vacancyInfo.experienceMin && vacancyInfo.experienceMax ? (
                                                <div className={cn(css(s.experience), 'is-flex align-center gap-1')}>
                                                    <h3>
                                                        {vacancyInfo.experienceMin}

                                                        <span>-</span>

                                                        {vacancyInfo.experienceMax}
                                                    </h3>

                                                    <h3><FormattedMessage id="year" /></h3>
                                                </div>
                                            ) : <h3><FormattedMessage id="noMatter" /></h3>}
                                        </div>
                                    </div>

                                    {vacancyInfo.salaryMin && vacancyInfo.salaryMax ? (
                                        <div className={css(mode(theme).itemWrap)}>
                                            <WalletSizeM />

                                            <div className={css(mode(theme).info)}>
                                                <h3><FormattedMessage id="salary" /></h3>

                                                <p>
                                                    {Number(vacancyInfo.salaryMin)
                                                        .toLocaleString('en-US').replace(/,/gi, ' ')}
                                                    <span> - </span>
                                                    {Number(vacancyInfo.salaryMax)
                                                        .toLocaleString('en-US').replace(/,/gi, ' ')}
                                                    <span> {vacancyInfo.currency}</span>
                                                </p>
                                            </div>
                                        </div>
                                    ) : null}

                                    {vacancyInfo.location ? (
                                        <div className={css(mode(theme).itemWrap)}>
                                            <MapPin />

                                            <div className={css(mode(theme).info)}>
                                                <h3><FormattedMessage id="jobLocation" /></h3>

                                                <p>{vacancyInfo.location}</p>
                                            </div>
                                        </div>
                                    ) : null}

                                    {vacancyInfo.educationType ? (
                                        <div className={css(mode(theme).itemWrap)}>
                                            <BachelorHat />

                                            <div className={css(mode(theme).info)}>
                                                <h3><FormattedMessage id="education" /></h3>

                                                <p>{educationLvl[vacancyInfo.educationType - 1].label}</p>
                                            </div>
                                        </div>
                                    ) : null}

                                    {vacancyInfo.format ? (
                                        <div className={css(mode(theme).itemWrap)}>
                                            <DeviceLaptop />

                                            <div className={css(mode(theme).info)}>
                                                <h3><FormattedMessage id="workingFormat" /></h3>

                                                <p>
                                                    {vacancyInfo.format && jobFormats[vacancyInfo.format][lang]}
                                                </p>
                                            </div>
                                        </div>
                                    ) : null}

                                    {vacancyInfo.schedule ? (
                                        <div className={css(mode(theme).itemWrap)}>
                                            <DeviceLaptop />

                                            <div className={css(mode(theme).info)}>
                                                <h3><FormattedMessage id="workingSchedule" /></h3>

                                                <p>
                                                    {vacancyInfo.format && workSchedule[vacancyInfo.schedule][lang]}
                                                </p>
                                            </div>
                                        </div>
                                    ) : null}

                                    {/* {vacancyInfo.deadline ? ( */}
                                    {/*     <div className={css(mode(theme).itemWrap)}> */}
                                    {/*         <HourGlass /> */}

                                    {/*         <div className={css(mode(theme).info)}> */}
                                    {/*             <h3><FormattedMessage id="deadline" /></h3> */}

                                    {/*             <p> */}
                                    {/*                 {vacancyInfo.deadline */}
                                    {/*             && getFullDate(vacancyInfo.deadline, 'DD MMMM YYYY', lang)} */}
                                    {/*             </p> */}
                                    {/*         </div> */}
                                    {/*     </div> */}
                                    {/* ) : null} */}
                                </div>

                                {(vacancyInfo.languages && vacancyInfo.languages.length) ? (
                                    <div className={css(mode(theme).langInfo)}>
                                        <h3><FormattedMessage id="languageKnowledge" />:</h3>

                                        {vacancyInfo.languages && vacancyInfo.languages.map((l) => (
                                            <div key={uuid()} className={css(mode(theme).listWrap)}>
                                                <li className={css(mode(theme).listItem, s.listItemLang)}>
                                                    <h3>
                                                        {AvailableLanguages.find((e) => e.short === l.language)[lang]}
                                                    </h3>

                                                    <h3>
                                                        {l.level}

                                                        ({LanguageLevels.find((e) => e.level === l.level)[lang]})
                                                    </h3>
                                                </li>
                                            </div>
                                        ))}
                                    </div>
                                ) : ''}

                                {(vacancyInfo.skills && vacancyInfo.skills.length) ? (
                                    <div className={css(mode(theme).skillsInfo)}>
                                        <h3><FormattedMessage id="keySkills" /></h3>

                                        <div className={css(s.skillsWrap)}>
                                            {/* {vacancyInfo.skills.map((skill) => ( */}
                                            <div className={css(mode(theme).cont)}>
                                                {/* <p>{skill.name}</p> */}
                                                <div className={css(s.skillsCont)}>
                                                    {vacancyInfo.skills.map((val) => (
                                                        <div key={val.id} className={css(mode(theme).skill)}>
                                                            {val.name}
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            {/* ))} */}
                                        </div>
                                    </div>
                                ) : null}

                                {vacancyInfo.description ? (
                                    <div className={css(mode(theme).descr)}>
                                        <h3><FormattedMessage id="vacancyDescription" />:</h3>

                                        {/* eslint-disable-next-line react/no-danger */}
                                        <p dangerouslySetInnerHTML={{
                                            __html: DOMPurify.sanitize(vacancyInfo.description),
                                        }} />
                                    </div>
                                ) : null}

                                <div className={css(s.mobileBtn)}>
                                    <Button
                                        onClick={
                                            !vacancyInfo.isApplied
                                                ? () => handleNav('apply') : null
                                        }
                                        className={cn(css(
                                            vacancyInfo.isApplied ? s.disabledBtn : s.applyBtn,
                                        ), 'is-flex align-center justify-center gap-2')}>
                                        <FileSymlink />

                                        {vacancyInfo.isApplied
                                            ? <FormattedMessage id="alreadyApplied" />
                                            : <FormattedMessage id="apply" />}
                                    </Button>
                                </div>
                            </React.Fragment>
                        )}
                    />
                </div>
            ) : null}

            {(!loadVacancyInfo.loading && !vacancyInfo) && <NotFound />}
        </>
    )
}

const s = StyleSheet.create({
    mainWrap: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        margin: '24px 32px 32px 32px',
        '@media (max-width: 1200px)': {
            flexDirection: 'column-reverse',
            gap: 16,
            margin: '16px 24px 24px 24px',
        },
        '@media (max-width: 768px)': {
            margin: '16px 0',
        },
    },
    experience: {
        textTransform: 'lowercase',
    },
    chevronWrap: {
        ':nth-child(1n) > :first-child': {
            transform: 'rotate(180deg)',
            ':nth-child(1n) > path': {
                stroke: COLORS.dark,
            },
        },
        background: COLORS.white,
        border: `1px solid ${COLORS.smoothGray}`,
        borderRadius: 64,
        width: 36,
        height: 36,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: 16,
        cursor: 'pointer',
        '@media (max-width: 1200px)': {
            display: 'none',
        },
    },
    vNameCont: {
        ':nth-child(1n) > :first-child': {
            cursor: 'pointer',
            width: 18,
            transform: 'rotate(180deg)',
        },
    },
    vacDescBody: {
        maxHeight: 'calc(100vh - 255px)',
        overflowY: 'auto',
        transition: 'all 0.2s linear',
        scrollbarGutter: 'stable',
        padding: 24,
        display: 'flex',
        flexDirection: 'column',
        gap: 32,
        '-webkit-scrollbar': {
            display: 'none',
        },
        '@media (max-width: 768px)': {
            padding: 16,
            gap: 16,
        },
    },
    btnGrp: {
        '@media (max-width: 768px)': {
            padding: '12px 16px 0 0',
            ':nth-child(1n) > :last-child': {
                display: 'none',
            },
        },
    },
    mobileBtn: {
        '@media (min-width: 769px)': {
            display: 'none',
        },
        ':nth-child(1n)> *': {
            width: '100%',
        },
    },
    applyBtn: {
        height: 32,
        minWidth: '12rem',
        padding: '0 16px',
        background: `linear-gradient(180deg, ${COLORS.mainColor} 0%, ${COLORS.lightMainColor} 100%)`,
        // eslint-disable-next-line max-len
        boxShadow: '0px 4px 12px rgba(44, 67, 50, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.25), inset 0px 1px 0px rgba(255, 255, 255, 0.1)',
        borderRadius: 4,
        fontWeight: '500',
        fontSize: 13,
        color: COLORS.white,
        ':nth-child(1n) > :first-child': {
            ':nth-child(1n) > path': {
                stroke: COLORS.white,
            },
        },
        ':hover': {
            background: `linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
            linear-gradient(180deg, ${COLORS.mainColor} 0%, ${COLORS.lightMainColor} 100%)`,
            boxShadow: `0px 4px 12px rgba(44, 67, 50, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.25), '
            inset 0px 1px 0px rgba(255, 255, 255, 0.1)`,
            borderRadius: 4,
        },
    },
    disabledBtn: {
        height: 32,
        minWidth: '12rem',
        padding: '0 16px',
        background: COLORS.gray,
        borderRadius: 4,
        border: 'none',
        // eslint-disable-next-line max-len
        boxShadow: '0px 4px 12px rgba(44, 67, 50, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.25), inset 0px 1px 0px rgba(255, 255, 255, 0.1)',
        cursor: 'not-allowed',
        fontWeight: '500',
        fontSize: 13,
        color: COLORS.white,
        ':nth-child(1n) > :first-child': {
            ':nth-child(1n) > path': {
                stroke: COLORS.white,
            },
        },
    },
    companyBrief: {
        '@media (max-width: 768px)': {
            padding: '12px 4px 7px 16px',
            width: '100%',
        },
    },
    flxBtw: {
        width: '100%',
        '@media (max-width: 768px)': {
            justifyContent: 'center',
            borderBottom: `1px solid ${COLORS.lightGray}`,
        },
    },
    items: {
        flexWrap: 'wrap',
        gap: 16,
        '@media (max-width: 768px)': {
            gap: 8,
        },
    },
    listItemLang: {
        textIndent: 15,
        marginLeft: 5,
        display: 'flex',
        alignItems: 'center',
    },
    skillsWrap: {
        marginTop: 16,
        display: 'flex',
        flexDirection: 'column',
        gap: 12,
        '@media (max-width: 768px)': {
            marginTop: 12,
        },
    },
    skillsCont: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        gap: 8,
    },
})

const chevron = (data) => css(StyleSheet.create({
    chevronBack: {
        ':nth-child(1n) > :first-child': {
            cursor: 'pointer',
            width: 18,
            transform: 'rotate(180deg)',
        },
        '@media (min-width: 1201px)': {
            display: data && data.length > 1 ? 'none' : 'block',
        },
    },
}).chevronBack)

const mode = (theme) => StyleSheet.create({
    companyName: {
        ':nth-child(1n) > p': {
            fontWeight: '500',
            fontSize: 14,
            color: theme === 'dark' ? COLORS.gray : COLORS.midGray,
        },
        gap: 6,
        marginTop: 8,
    },
    viewCount: {
        borderRadius: 4,
        padding: '4px 8px',
        border: theme === 'dark' ? 'none' : `1px solid ${COLORS.smoothGray}`,
        background: theme === 'dark' ? COLORS.itemDarkModeBg : COLORS.white,
        boxShadow: '0px 2px 4px 0px rgba(130, 130, 130, 0.05)',
        ':nth-child(1n) > h3': {
            fontSize: 14,
            fontWeight: '600',
            color: theme === 'dark' ? COLORS.smoothGray : COLORS.lightBlack,
            '@media (max-width: 768px)': {
                fontSize: 13,
            },
        },
        ':nth-child(1n) > :first-child': {
            '@media (max-width: 767px)': {
                display: 'none',
            },
        },
        ':nth-child(1n) > span': {
            '@media (min-width: 768px)': {
                display: 'none',
            },
        },
        '@media (max-width: 768px)': {
            padding: '4px 6px',
        },
    },
    defaultLogo: {
        width: 16,
        height: 16,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: theme === 'dark' ? 'none' : `1px solid ${COLORS.white}`,
        background: COLORS.mainColor,
        borderRadius: 4,
        boxSizing: 'border-box',
    },
    infoWrap: {
        flex: 2.5,
        background: theme === 'dark' ? COLORS.darkModeBg : COLORS.white,
        border: `1px solid ${theme === 'dark' ? COLORS.itemDarkModeBg : COLORS.smoothGray}`,
        boxShadow: '0px 2px 4px rgba(130, 130, 130, 0.05)',
        borderRadius: 4,
        '@media (max-width: 1200px)': {
            borderRadius: 0,
            width: 'calc(100% - 2px)',
        },
        '@media (max-width: 768px)': {
            borderRadius: 0,
        },
    },
    logoCont: {
        width: 16,
        height: 16,
        border: theme === 'dark' ? 'none' : `1px solid ${COLORS.white}`,
        borderRadius: 2,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxSizing: 'border-box',
        ':nth-child(1n) > img': {
            width: 'inherit',
            borderRadius: 4,
        },
    },
    contHeader: {
        borderBottom: `1px solid ${theme === 'dark' ? COLORS.itemDarkModeBg : COLORS.lightGray}`,
        background: 'inherit',
        padding: 24,
        '@media (max-width: 768px)': {
            padding: 0,
            borderBottom: 'none',
        },
    },
    vacancyName: {
        fontWeight: '600',
        fontSize: 24,
        width: 'fit-content',
        color: theme === 'dark' ? COLORS.white : COLORS.dark,
        textOverflow: 'ellipsis',
        '@media (max-width: 1440px)': {
            fontSize: 20,
        },
    },
    mobileCont: {
        ':nth-child(1n) > :first-child': {
            '@media (min-width: 1200px)': {
                display: 'none',
            },
        },
    },
    shareBtn: {
        height: 32,
        background: COLORS.white,
        border: `1px solid ${COLORS.smoothGray}`,
        borderRadius: 4,
        padding: '0 6px',
        display: 'flex',
        alignItems: 'center',
        ':hover': {
            background: COLORS.smoothGray,
        },
    },
    itemWrap: {
        display: 'flex',
        alignItems: 'center',
        padding: '6px 10px',
        gap: 10,
        ':nth-child(1n) > :first-child': {
            ':nth-child(1n) > path': {
                stroke: COLORS.mainColor,
            },
            '@media (max-width: 768px)': {
                width: 24,
            },
        },
        background: theme === 'dark' ? COLORS.itemDarkModeBg : COLORS.white,
        border: `1px solid ${theme === 'dark' ? COLORS.itemDarkModeBg : COLORS.smoothGray}`,
        boxShadow: '0px 2px 4px rgba(130, 130, 130, 0.05)',
        borderRadius: 4,
        '@media (max-width: 768px)': {
            padding: '4px 8px',
            gap: 4,
        },
    },
    info: {
        display: 'flex',
        gap: 4,
        flexDirection: 'column',
        ':nth-child(1n) > :first-child': {
            fontWeight: '500',
            fontSize: 12,
            color: COLORS.gray,
        },
        ':nth-child(1n) > :last-child': {
            fontWeight: '600',
            fontSize: 13,
            color: theme === 'dark' ? COLORS.white : COLORS.dark,
            '@media (max-width: 768px)': {
                fontSize: 12,
            },
        },
    },
    descr: {
        display: 'flex',
        flexDirection: 'column',
        gap: 16,
        fontWeight: '500',
        fontSize: 14,
        whiteSpace: 'break-spaces',
        wordBreak: 'break-word',
        color: theme === 'dark' ? COLORS.gray : COLORS.lightBlack,
        ':nth-child(1n) > h3': {
            fontWeight: '600',
            fontSize: 20,
            color: theme === 'dark' ? COLORS.white : COLORS.dark,
            '@media (max-width: 768px)': {
                fontSize: 16,
            },
        },
        ':nth-child(1n) > :last-child': {
            ':nth-child(1n) > ol': {
                padding: '15px !important',
            },
            ':nth-child(1n) > ul': {
                listStyleType: 'none',
                ':nth-child(1n) > *': {
                    '::before': {
                        content: '"\\2022"',
                        color: COLORS.midGray,
                        display: 'inline-block',
                        width: '1em',
                        // fontSize: '1.5em',
                        marginLeft: 0,
                    },
                },
            },
        },
        '@media (max-width: 768px)': {
            fontSize: 12,
            gap: 12,
        },
    },
    langInfo: {
        display: 'flex',
        flexDirection: 'column',
        ':nth-child(1n) > h3': {
            fontWeight: '600',
            fontSize: 20,
            color: theme === 'dark' ? COLORS.white : COLORS.dark,
            marginBottom: 16,
            '@media (max-width: 768px)': {
                fontSize: 16,
                marginBottom: 12,
            },
        },
    },
    listWrap: {
        paddingLeft: 2,
        fontWeight: '500',
        fontSize: 14,
        color: theme === 'dark' ? COLORS.gray : COLORS.lightBlack,
        '@media (max-width: 768px)': {
            fontSize: 12,
        },
    },
    listItem: {
        position: 'relative',
        listStyleType: 'none',
        textIndent: 10,
        '::before': {
            position: 'absolute',
            content: '""',
            borderRadius: '50%',
            width: '4px',
            height: '4px',
            backgroundColor: theme === 'dark' ? COLORS.gray : COLORS.lightBlack,
            top: 6,
            left: 0,
        },
    },
    skillsInfo: {
        ':nth-child(1n) > h3': {
            fontWeight: '600',
            fontSize: 20,
            color: theme === 'dark' ? COLORS.white : COLORS.dark,
        },
    },
    cont: {
        ':nth-child(1n) > :first-child': {
            fontWeight: '500',
            fontSize: 12,
            color: theme === 'dark' ? COLORS.smoothGray : COLORS.midGray,
            marginBottom: 8,
        },
    },
    skill: {
        background: theme === 'dark' ? 'rgba(46, 169, 125, 0.03)' : 'rgba(46, 169, 125, 0.03)',
        border: `1px solid ${theme === 'dark' ? 'rgba(46, 169, 125, 0.20)' : 'rgba(46, 169, 125, 0.2)'}`,
        borderRadius: 4,
        fontWeight: '500',
        fontSize: 11,
        color: COLORS.mainColor,
        padding: '5.5px 8px',
    },
})
