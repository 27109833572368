import React, { useContext, useEffect, useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { Field, Form, Formik } from 'formik'
import Select, { createFilter } from 'react-select'
import { Oval } from 'react-loader-spinner'
import { FormattedMessage } from 'react-intl'
import { COLORS } from '../../utils/colors'
import { CloseSidebar, RocketLayout, TablerX } from '../svgs/Svgs'
import Button from '../common/Button'
import { useLoad } from '../../hooks/request'
import { PUBLIC_SKILLS_LIST } from '../../urls'
import { useMessage } from '../../hooks/message'
import { hasChangesArr } from '../../utils/compateChanges'
import { BaseContext } from '../../contexts/BaseContextWrapper'

export default function SkillsModal({ onClose, showModal, info, getInfo, putInfo }) {
    const [showMessage] = useMessage()
    const { theme } = useContext(BaseContext)
    const [addSkills, setAddSkills] = useState(false)
    // const [addProgrammes, setAddProgrammes] = useState(false)
    const [addedSkills, setAddedSkills] = useState(info.skills)
    // const [addedProgrammes, setAddedProgrammes] = useState([])
    const loadSkills = useLoad({ url: PUBLIC_SKILLS_LIST })
    const skills = loadSkills.response ? loadSkills.response.data : []

    useEffect(() => {
        setAddedSkills(info && info.skills)
    }, [info])

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            margin: 0,
            padding: 0,
            minHeight: 20,
            maxHeight: 25,
            border: 'none',
            background: 'inherit',
            boxShadow: state.isFocused ? 'none' : 'none',
            ':nth-of-type(1n) > :first-of-type': {
                ':nth-of-type(1n) > :last-of-type': {
                    color: theme === 'dark' ? COLORS.white : COLORS.dark,
                },
            },
        }),
        dropdownIndicator: (provided) => ({
            ...provided,
            display: 'none',
        }),
        indicatorSeparator: (provided) => ({
            ...provided,
            display: 'none',
        }),
        menu: (base) => ({
            ...base,
            margin: 0,
            zIndex: 200,
            paddingTop: 6,
            borderRadius: 4,
            width: 'max-content',
            minWidth: '100%',
            background: theme === 'dark' ? COLORS.itemDarkModeBg : COLORS.white,
            border: theme === 'dark' ? 'none' : `1px solid ${COLORS.smoothGray}`,
            boxShadow: '0px 4px 16px rgba(62, 62, 62, 0.15)',
        }),
        menuList: (base) => ({
            ...base,
            paddingTop: 0,
            background: theme === 'dark' ? COLORS.itemDarkModeBg : COLORS.white,
            paddingBottom: 0,
        }),
        option: (provided) => ({
            ...provided,
            margin: 0,
            fontSize: 14,
            cursor: 'pointer',
            fontWeight: '500',
            color: COLORS.gray,
            padding: '10px 16px',
            background: theme === 'dark' ? COLORS.itemDarkModeBg : COLORS.white,
            ':hover': {
                background: theme === 'dark' ? COLORS.darkModeBg : COLORS.smoothGray,
            },
        }),
        group: (provided) => ({
            ...provided,
            padding: 0,
        }),
        groupHeading: () => ({
            margin: 0,
            fontSize: 11,
            fontWeight: '500',
            color: COLORS.dark,
            textTransform: 'uppercase',
            padding: '10px 16px 10px 16px',
            ':hover': {
                background: 'none',
            },
        }),
    }

    const handleChange = (option, field, form) => {
        const selectedOptions = [...field.value, option.value]
        form.setFieldValue(field.name, selectedOptions)
        setAddedSkills((prev) => [...prev, option])
        setTimeout(() => {
            const items = document.getElementById('skills')
            const cont = document.getElementById('skillCont')
            items.scrollTop = cont.scrollHeight
        }, 0)
    }

    const NoOptionsMessage = ({ inputValue }) => (inputValue !== '' ? <FormattedMessage id="noOptionsAvailable" /> : '')

    // eslint-disable-next-line max-len
    const availableOptions = skills.filter((item) => addedSkills && !addedSkills.some((selected) => selected.value === item.value))

    const onDeleteSkills = (value, setFieldValue) => {
        const res = addedSkills.filter((val) => val.value !== value)
        setAddedSkills(res)
        setFieldValue('skills', res)
    }

    const initialValues = {
        skills: info.skills,
    }

    const onSubmit = async (values) => {
        if (hasChangesArr(info.skills, values.skills)) {
            onClose()
            return
        }

        if (putInfo.loading) return

        const data = { skills: values.skills.map((skill) => (skill.value ? skill.value : skill)) }
        const { success, error } = await putInfo.request({ data })
        if (success) {
            getInfo.request()
            onClose()
            showMessage('SUCCESS', 'success-msg')
        } else {
            showMessage(error.data.errors[0].message || 'ERROR', 'error-msg')
        }
    }

    return (
        <Formik
            enableReinitialize
            initialValues={{
                skills: [],
                ...initialValues,
            }} onSubmit={onSubmit}>
            {({ setFieldValue }) => (
                <Form>
                    <div>
                        <div className={css(s.modalWrap)}>
                            <div className={css(mode(theme).modalHeader)}>
                                <h3>
                                    {showModal.type === 'add'
                                        ? <FormattedMessage id="addExperience" />
                                        : <FormattedMessage id="updateExperience" />}
                                </h3>

                                <CloseSidebar color={theme === 'dark' ? COLORS.white : COLORS.dark} onClick={onClose} />
                            </div>

                            <div className={css(mode(theme).modalBody)}>
                                <div className={css(mode(theme).formWrap)}>
                                    <div className={css(mode(theme).formHeader)}>
                                        <div className={cn('align-center gap-2', css(s.headTitle))}>
                                            <RocketLayout />

                                            <h3><FormattedMessage id="proSkills" /></h3>
                                        </div>
                                    </div>

                                    <div className={css(s.formBody)}>
                                        <div className={css(mode(theme).cont)}>
                                            <h3><FormattedMessage id="whatSkillsDoYouOwn" /></h3>

                                            <div id="skillCont" className={css(s.skillsWrap)}>
                                                <div id="skills" className="is-flex align-center is-flex-wrap gap-5">
                                                    {addedSkills && addedSkills.map((item) => (
                                                        <div key={item.value} className={css(mode(theme).skillWrap)}>
                                                            <h3>
                                                                {item.label.length > 27
                                                                    ? `${item.label.slice(0, 27)}...` : item.label}
                                                            </h3>

                                                            {/* eslint-disable-next-line max-len */}
                                                            <span onClick={() => onDeleteSkills(item.value, setFieldValue)}>
                                                                <TablerX
                                                                    color={theme === 'dark'
                                                                        ? COLORS.white
                                                                        : COLORS.dark}
                                                                />
                                                            </span>
                                                        </div>
                                                    ))}
                                                </div>

                                                {addSkills ? (
                                                    <Field name="skills">
                                                        {({ field, form }) => (
                                                            <Select
                                                                value=""
                                                                autoFocus
                                                                id="skills"
                                                                isSearchable
                                                                placeholder=""
                                                                openMenuOnFocus
                                                                options={availableOptions}
                                                                menuPosition="fixed"
                                                                className={css(s.selectInp)}
                                                                noOptionsMessage={NoOptionsMessage}
                                                                styles={customStyles}
                                                                menuIsOpen
                                                                defaultMenuIsOpen
                                                                hideSelectedOptions
                                                                onBlur={() => setAddSkills(false)}
                                                                filterOption={createFilter({ ignoreAccents: false })}
                                                                onChange={(option) => handleChange(option, field, form)}
                                                            />
                                                        )}
                                                    </Field>
                                                ) : (
                                                    <h3
                                                        onClick={() => setAddSkills(true)}
                                                        className={css(s.addTrigger)}>
                                                        + <FormattedMessage id="addSkills" />
                                                    </h3>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className={css(mode(theme).modalFooter)}>
                            <Button
                                type="button"
                                onClick={onClose}
                                className={cn(css(mode(theme).cancelBtn), css(s.btn))}>
                                <FormattedMessage id="cancel" />
                            </Button>

                            <Button type="submit" className={cn(css(s.saveBtn), css(s.btn))}>
                                {!putInfo.loading ? <FormattedMessage id="save" /> : <FormattedMessage id="saving" />}

                                {putInfo.loading ? (
                                    <Oval
                                        height={16}
                                        width={16}
                                        color={COLORS.white}
                                        visible
                                        ariaLabel="oval-loading"
                                        secondaryColor={COLORS.white}
                                        strokeWidth={7}
                                        strokeWidthSecondary={7}
                                    />
                                ) : null}
                            </Button>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    )
}

const s = StyleSheet.create({
    modalWrap: {
        height: '100%',
        width: '37.25rem',
        borderRadius: '4px 4px 0px 0px',
        '@media (max-width: 768px)': {
            width: '90vw',
        },
    },
    btn: {
        borderRadius: 4,
        padding: '12px 16px',
        fontWeight: '500',
        fontSize: 14,
        display: 'flex',
        alignItems: 'center',
        gap: 8,
    },
    saveBtn: {
        color: COLORS.white,
        background: `linear-gradient(180deg, ${COLORS.mainColor} 0%, ${COLORS.lightMainColor} 100%)`,
        // eslint-disable-next-line max-len
        boxShadow: '0px 4px 12px rgba(44, 67, 50, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.25), inset 0px 1px 0px rgba(255, 255, 255, 0.1)',
    },
    formBody: {
        height: '100%',
        padding: '16px 24px 16px 24px',
        display: 'flex',
        flexDirection: 'column',
        gap: 24,
        '@media (max-width: 768px)': {
            padding: 16,
            gap: 16,
        },
    },
    skillsWrap: {
        margin: '12px 0 12px 0',
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        gap: 16,
        '@media (max-width: 768px)': {
            gap: 10,
            margin: '12px 0 0px 0',
        },
        ':nth-child(1n) > :first-child': {
            maxHeight: '10rem',
            overflowY: 'auto',
            '@media (max-width: 768px)': {
                gap: 10,
            },
        },
    },
    // menuSkills: {
    //     ':nth-child(1n) > h3': {
    //         fontWeight: '500',
    //         fontSize: 11,
    //         textTransform: 'uppercase',
    //         color: COLORS.dark,
    //         padding: '0px 0px 8px 16px',
    //     },
    //     ':nth-child(1n) > p': {
    //         fontWeight: '500',
    //         fontSize: 14,
    //         color: COLORS.gray,
    //         padding: '10px 16px',
    //     },
    // },
    addTrigger: {
        fontWeight: '500',
        fontSize: 14,
        color: COLORS.mainColor,
        cursor: 'pointer',
    },
    headTitle: {
        '@media (max-width: 768px)': {
            gap: 4,
        },
    },
    fld: {
        border: 'none',
    },
    selectInp: {
        width: '100%',
        ':nth-child(1n) > :last-child': {
            marginTop: '5%',
            position: 'unset',
        },
    },
})

const mode = (theme) => StyleSheet.create({
    modalHeader: {
        padding: '20px 24px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        background: theme === 'dark' ? COLORS.itemDarkModeBg : COLORS.white,
        borderBottom: `1px solid ${theme === 'dark' ? COLORS.lightBlack : COLORS.lightGray}`,
        borderRadius: '4px 4px 0px 0px',
        ':nth-child(1n) > h3': {
            fontWeight: '700',
            fontSize: 20,
            color: theme === 'dark' ? COLORS.white : COLORS.dark,
            '@media (max-width: 768px)': {
                fontSize: 16,
            },
        },
        ':nth-child(1n) > :last-child': {
            cursor: 'pointer',
        },
        '@media (max-width: 768px)': {
            padding: '14px 16px',
        },
    },
    modalBody: {
        padding: 24,
        background: theme === 'dark' ? COLORS.darkModeBg : COLORS.smoothGray,
        '@media (max-width: 768px)': {
            height: '60vh',
            padding: 16,
        },
    },
    cancelBtn: {
        color: COLORS.gray,
        border: `1px solid ${COLORS.gray}`,
        background: 'inherit',
    },
    modalFooter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'end',
        gap: 16,
        background: theme === 'dark' ? COLORS.itemDarkModeBg : COLORS.white,
        borderTop: `1px solid ${theme === 'dark' ? COLORS.lightBlack : COLORS.lightGray}`,
        borderRadius: '0px 0px 4px 4px',
        padding: '16px 24px',
        '@media (max-width: 768px)': {
            padding: '8px 16px',
        },
    },
    cont: {
        ':nth-child(1n) > h3': {
            fontWeight: '500',
            fontSize: 14,
            color: theme === 'dark' ? COLORS.gray : COLORS.midGray,
        },
    },
    skillWrap: {
        display: 'flex',
        border: theme === 'dark' ? 'none' : `1px solid ${COLORS.gray}`,
        borderRadius: 4,
        ':nth-child(1n) > h3': {
            padding: '6.5px 8px',
            background: theme === 'dark' ? 'rgba(255, 255, 255, 0.10)' : COLORS.white,
            borderBottomLeftRadius: 4,
            borderTopLeftRadius: 4,
            fontWeight: '500',
            fontSize: 13,
            color: theme === 'dark' ? COLORS.white : COLORS.lightBlack,
        },
        ':nth-child(1n) > span': {
            background: theme === 'dark' ? 'rgba(237, 237, 238, 0.10)' : COLORS.smoothGray,
            borderLeft: theme === 'dark' ? 'none' : `1px solid ${COLORS.gray}`,
            borderBottomRightRadius: 4,
            borderTopRightRadius: 4,
            padding: '6px 6px 3px 6px',
            cursor: 'pointer',
        },
    },
    formWrap: {
        border: theme === 'dark' ? 'none' : `1px solid ${COLORS.smoothGray}`,
        background: theme === 'dark' ? COLORS.darkModeBg : COLORS.white,
        boxShadow: '0px 2px 4px rgba(130, 130, 130, 0.05)',
        borderRadius: 4,
    },
    formHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '14px 24px',
        fontWeight: '600',
        fontSize: 13,
        textTransform: 'uppercase',
        color: theme === 'dark' ? COLORS.white : COLORS.dark,
        borderBottom: `1px solid ${theme === 'dark' ? COLORS.lightBlack : COLORS.lightGray}`,
        borderRadius: '4px 4px 0px 0px',
        ':nth-child(1n) > :last-child': {
            cursor: 'pointer',
        },
        ':nth-child(1n) > :first-child': {
            ':nth-child(1n) > :first-child': {
                ':nth-child(1n) > path': {
                    stroke: theme === 'dark' ? COLORS.gray : '#333333',
                },
            },
        },
        '@media (max-width: 768px)': {
            padding: '14px 16px',
        },
    },
})
