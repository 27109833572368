import React, { useContext } from 'react'
import cn from 'classnames'
import { css, StyleSheet } from 'aphrodite'
import { FormattedMessage } from 'react-intl'
import { useLocation } from 'react-router-dom'
import { Layout } from '../components/common/Layout'
import bannerImg from '../static/images/public-banner.png'
import { SearchContainer } from '../components/companies/SearchContainer'
import IndustryGrid from '../components/companies/IndustryGrid'
import TopCompanies from '../components/companies/TopCompanies'
import HotVacancies from '../components/companies/HotVacancies'
import HowItWorks from '../components/companies/HowItWorks'
import { COLORS } from '../utils/colors'
import { useLoad } from '../hooks/request'
import { PUBLIC_COMPANIES_LIST } from '../urls'
import { Context } from '../contexts/GlobalContext'
import OfferToNotRegistered from '../components/profile/OfferToNotRegistered'
import { useQueryParams } from '../hooks/queryString'

export default function HomePage() {
    const { hash } = useLocation()
    const { ct } = useQueryParams() // candidate token
    const loadCompanies = useLoad({ url: PUBLIC_COMPANIES_LIST })
    const companies = loadCompanies.response ? loadCompanies.response.data : []
    const { matchedIndustries, industryLoading } = useContext(Context)

    const title = [
        <FormattedMessage id="bannerSlogan2" />,
        <FormattedMessage id="bannerSlogan" />,
    ]

    return (
        <Layout isFooter>
            <div>
                <div className={cn(bgStyle(bannerImg), 'is-flex direction-column align-center justify-end')}>
                    <div className={cn(css(s.titleWrap), 'is-flex direction-column align-center justify-end')}>
                        <h3 className={css(s.title)}>{title[Math.floor(Math.random() * title.length)]}</h3>
                    </div>

                    <SearchContainer />
                </div>

                <div className={cn(css(s.contentsWrapper), 'is-flex direction-column')}>
                    <IndustryGrid industryOptions={matchedIndustries} loading={industryLoading} />

                    <TopCompanies companies={companies} loading={loadCompanies.loading} />

                    <HotVacancies />

                    <HowItWorks />
                </div>
            </div>

            {(hash === '#apply' && ct) && <OfferToNotRegistered token={ct} />}
        </Layout>
    )
}

const s = StyleSheet.create({
    mainSection: {
        width: '100%',
    },
    contentsWrapper: {
        gap: 34,
        padding: '58px 0 34px 0',
        '@media (max-width: 1024px)': {
            padding: '40px 0 17px 0',
            gap: 24,
        },
    },
    titleWrap: {
        height: '10rem',
        color: COLORS.white,
        fontSize: 'clamp(14px, 5vw, 40px)',
        fontWeight: '700',
        textWrap: 'balance',
    },
    title: {
        textAlign: 'center',
        paddingBottom: 30,
        animationName: {
            from: {
                opacity: 0,
                transform: 'translate3d(0, -100%, 0)',
            },
            to: {
                opacity: 1,
                transform: 'translate3d(0, 0, 0)',
            },
        },
        animationDuration: '.8s',
        animationIterationCount: '1',
    },
    '@keyframes fadeInTopToBottom': {
        from: {
            opacity: 0,
            color: 'red',
        },
        to: {
            opacity: 1,
            color: '#fff',
        },
    },
})

const bgStyle = (banner) => css(StyleSheet.create({
    cont: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        width: '100%',
        height: '50vh',
        maxHeight: 255,
        // eslint-disable-next-line max-len
        background: `linear-gradient(0deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.50) 100%), url(${banner}), lightgray 50% / cover no-repeat`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        '@media (max-width: 1024px)': {
            height: '30vh',
            borderRadius: '0 0 5px 5px',
        },
    },
}).cont)
