import React, { useEffect, useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import uuid from 'react-uuid'
import { useParams } from 'react-router-dom'
import Table from '../../common/Table'
import { VacanciesTableItem } from './VacanciesTableItem'
import { VacanciesTableHeader } from './VacanciesTableHeader'
import { useGetRequest } from '../../../hooks/request'
import { PUBLIC_COMPANY_VACANCIES } from '../../../urls'

export function VacanciesTable({ info, theme, handleNavigate }) {
    const { cSlug } = useParams()
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(20)
    const getVacancies = useGetRequest({
        url: PUBLIC_COMPANY_VACANCIES.replace('{slug}', cSlug),
        params: { page, 'per-page': pageSize },
    })
    const vacancies = getVacancies.response ? getVacancies.response.data : []
    const meta = getVacancies.response ? getVacancies.response.meta : []

    useEffect(() => {
        getVacancies.request()
    }, []) // eslint-disable-line

    return (
        <div className={css(s.tableWrap)}>
            <Table
                isStripped
                isHoverable={theme !== 'dark'}
                page={meta.currentPage}
                pageCount={meta.pageCount}
                count={meta.totalCount}
                setPage={setPage}
                pageSize={pageSize}
                setPageSize={setPageSize}
                className={css(s.vacancyTable)}
                items={vacancies}
                loaderItemCount={20}
                loading={getVacancies.loading}
                customHeader={<VacanciesTableHeader theme={theme} />}
                renderItem={(item) => (
                    <VacanciesTableItem
                        info={info}
                        key={uuid()}
                        theme={theme}
                        item={item}
                        handleNavigate={handleNavigate}
                        getVacancies={getVacancies}
                    />
                )}
            />
        </div>
    )
}

const s = StyleSheet.create({
    vacancyTable: {
        width: '100%',
        textAlign: 'center',
    },
    tableWrap: {
        padding: '0 32px',
        '@media (max-width: 1024px)': {
            display: 'none',
        },
    },
})
