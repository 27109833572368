import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { FormattedMessage } from 'react-intl'
import { DisplayByColumnSizeL, DisplayByGrid, DisplayByScroll } from '../../svgs/Svgs'
import { COLORS } from '../../../utils/colors'

export function VacanciesHeader({ setSwitchView, switchView, loadData, data, companyName, theme }) {
    const totalVacancies = loadData.response && loadData.response.meta.totalCount

    return (
        <div className={css(s.companyVacHeader)}>
            <div className="align-center gap-2">
                <h3 className={css(mode(theme).title)}>
                    <FormattedMessage id="companyVacancies" /> {companyName} <span>•</span> {totalVacancies}
                </h3>
            </div>

            {data.length && !loadData.loading ? (
                <div className="align-center gap-5">
                    <div className={css(s.dpl)}>
                        <span
                            onClick={() => setSwitchView('column')}
                            className={cn(switchView === 'column' ? css(s.actCol) : css(mode(theme).col))}>
                            <DisplayByScroll />
                        </span>

                        <span
                            onClick={() => setSwitchView('grid')}
                            className={cn(switchView === 'grid' ? css(s.actCol) : css(mode(theme).col))}>
                            <DisplayByGrid />
                        </span>

                        <span
                            onClick={() => setSwitchView('table')}
                            className={cn(switchView === 'table' ? css(s.actCol) : css(mode(theme).col))}>
                            <DisplayByColumnSizeL />
                        </span>
                    </div>
                </div>
            ) : null}
        </div>
    )
}

const s = StyleSheet.create({
    companyVacHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0 32px',
        '@media (max-width: 1024px)': {
            padding: '0 16px',
            gap: 12,
        },
    },
    dpl: {
        display: 'flex',
        alignItems: 'center',
        ':nth-child(1n) > *': {
            padding: 4,
            display: 'inline-flex',
            cursor: 'pointer',
        },
        ':nth-child(1n) > :first-child': {
            borderTopLeftRadius: 4,
            borderBottomLeftRadius: 4,
        },
        ':nth-child(1n) > :last-child': {
            borderTopRightRadius: 4,
            borderBottomRightRadius: 4,
            '@media (max-width: 1024px)': {
                display: 'none',
            },
        },
    },
    actCol: {
        background: COLORS.mainColor,
        ':not(:last-of-type)': {
            ':nth-child(1n) > :first-child': {
                ':nth-child(1n) > *': {
                    stroke: '#FFF',
                },
            },
        },
        ':last-of-type': {
            ':nth-child(1n) > :first-child': {
                ':nth-child(1n) > *': {
                    fill: '#FFF',
                },
            },
        },
    },
})

const mode = (theme) => StyleSheet.create({
    title: {
        gap: 8,
        fontWeight: '700',
        fontSize: 20,
        color: theme === 'dark' ? COLORS.white : COLORS.lightBlack,
        ':nth-child(1n) > :first-child': { color: COLORS.green },
        ':nth-child(1n) > :last-child': {
            color: COLORS.gray,
            fontWeight: '500',
        },
        '@media (max-width: 768px)': {
            fontSize: 16,
            gap: 4,
        },
    },
    col: {
        background: theme === 'dark' ? COLORS.darkModeBg : '#fff',
        ':not(:last-of-type)': {
            ':nth-child(1n) > :first-child': {
                ':nth-child(1n) > *': {
                    stroke: theme === 'dark' ? COLORS.spanishGray : '#92929D',
                },
            },
            ':last-of-type': {
                ':nth-child(1n) > :first-child': {
                    ':nth-child(1n) > *': {
                        fill: theme === 'dark' ? COLORS.spanishGray : '#92929D',
                    },
                },
            },
        },
    },
})
