import React, { createContext, useEffect, useRef, useState } from 'react'
import { IntlProvider } from 'react-intl'
import moment from 'moment'
import { usePersistState } from '../hooks/state'
import { translationsMessage } from '../translate'
import Message from '../components/common/Message'
import { SidebarWithTrigger } from '../components/common/SidebarWithTrigger'
import { COLORS } from '../utils/colors'

export const BaseContext = createContext()

export default function BaseContextWrapper({ children }) {
    const [text, setText] = useState('')
    const [lang, setLang] = usePersistState('lang', 'uz')
    const [theme, setTheme] = usePersistState('theme', 'light')
    const [className, setClassName] = useState('')
    const [sidebar, setSidebar] = useState({})
    const childRef = useRef()
    // const currentVersion = '1.0.3'

    useEffect(() => {
        moment.locale(lang === 'uz' ? 'uz-latn' : lang)
    }, [lang])

    // useEffect(() => {
    //     const version = localStorage.getItem('version')
    //     if (!version || version !== currentVersion) {
    //         localStorage.clear()
    //         localStorage.setItem('version', currentVersion)
    //         window.location.reload()
    //     }
    // }, [])

    const toggleTheme = () => {
        setTheme((prev) => (prev === 'light' ? 'dark' : 'light'))
        document.getElementById('theme-color')
            .setAttribute('content', theme === 'light' ? COLORS.dark : COLORS.mainColor)
    }
    const closeSidebar = () => childRef.current.onClose()

    return (
        <BaseContext.Provider value={{
            lang,
            setLang,
            setText,
            setSidebar,
            setClassName,
            closeSidebar,
            toggleTheme,
            theme,
        }}>
            <IntlProvider locale={lang} messages={translationsMessage(lang)}>
                {children}

                {text ? (
                    <Message
                        text={text}
                        delay={5}
                        className={className}
                        closeMessage={() => setText(null)} />
                ) : null}

                {sidebar && sidebar.content ? (
                    <SidebarWithTrigger
                        isActive
                        ref={childRef}
                        onClose={() => {
                            if (typeof sidebar.onClose === 'function') {
                                sidebar.onClose()
                            }
                            setSidebar(null)
                        }}>
                        {sidebar.content}
                    </SidebarWithTrigger>
                ) : null}
            </IntlProvider>
        </BaseContext.Provider>
    )
}
