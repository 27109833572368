import React from 'react'
import { Form, Formik } from 'formik'
import { EditorState } from 'draft-js'
import { css, StyleSheet } from 'aphrodite'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import Tabs from './Tabs'
import MainForm from './MainForm'
import GeneralForm from './GeneralForm'
import { Layout } from '../../common/Layout'
import { DELETE_CANDIDATE_PHOTO } from '../../../urls'
import { useQueryParams } from '../../../hooks/queryString'
import { useDeleteRequest } from '../../../hooks/request'

export default function ApplyForms({
    info,
    setFile,
    loading,
    vacancy,
    onSubmit,
    applyTrigger,
    applyLoading,
    initialValues,
}) {
    const { step } = useQueryParams()
    const navigate = useNavigate()
    const { state, pathname } = useLocation()
    const params = useParams()
    const goBack = state ? state.from : `/c/${params.cSlug}/${params.vSlug}`
    const deletePhoto = useDeleteRequest({ url: DELETE_CANDIDATE_PHOTO })

    const handleBack = () => {
        if (state && (state.prev || state.from)) {
            navigate(state.prev || state.from, { state: { from: state.from || '/' } })
        } else {
            navigate('/')
        }
    }

    return (
        <Layout>
            <Formik
                enableReinitialize
                initialValues={{
                    birth_date: '',
                    gender: '',
                    address: '',
                    desiredSalary: '',
                    currency: 'UZS',
                    education: [{ id: 1, level: '', start_date: '', end_date: '', institution: '', additional: '' }],
                    experience: [{ id: 1, job: '', position: '', start_date: '', end_date: '', achievements: '' }],
                    skills: [],
                    languageLevel: { 1: { lang: null, level: null } },
                    remote: false,
                    coverLetter: EditorState.createEmpty(),
                    ...initialValues,
                }}
                initialTouched={{ remote: true }}
                onSubmit={onSubmit}>
                {({ values, setFieldValue, setValues, errors }) => (
                    <Form>
                        <div className={css(s.mainWrap)}>
                            <Tabs
                                state={state}
                                step={step}
                                goBack={goBack}
                                vacancy={vacancy}
                                loading={loading}
                                pathname={pathname}
                                handleBack={handleBack}
                                applyLoading={applyLoading}
                                applyTrigger={() => applyTrigger(values)}
                            />

                            <div className={css(s.formsWrap)}>
                                {(step === 'first' || (pathname.endsWith('apply') && step !== 'second')) && (
                                    <MainForm
                                        info={info}
                                        errors={errors}
                                        values={values}
                                        setFile={setFile}
                                        setValues={setValues}
                                        initialValues={initialValues}
                                        deletePhoto={deletePhoto}
                                        setFieldValue={setFieldValue}
                                    />
                                )}

                                {step === 'second' && <GeneralForm info={info} setFieldValue={setFieldValue} />}
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </Layout>
    )
}

const s = StyleSheet.create({
    mainWrap: {
        userSelect: 'none',
        paddingBottom: '6%',
    },
    formsWrap: {
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 27,
        '@media (max-width: 768px)': {
            marginTop: 16,
        },
    },
})
