import React, { useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import Slider from 'react-slick'
import { useLocation, useNavigate } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { VacanciesHeader } from './VacanciesHeader'
import { VacanciesTable } from './VacanciesTable'
import RowScrollViewVacancyItem from './RowScrollViewVacancyItem'
import GridViewVacancies from './GridViewVacancies'
import { COLORS, COLORS as COLORS_PALETTE } from '../../../utils/colors'
import { ChevronNextSizeL } from '../../svgs/Svgs'
import { AddReport } from '../../svgs/SvgPictures'

export default function CompanyVacancies({ info, vacancies, slug, loadVacancies, theme }) {
    const [switchView, setSwitchView] = useState('grid')
    const navigate = useNavigate()
    const { state, pathname } = useLocation()

    function SampleArrows({ onClick, nextIcon }) {
        return (
            <div style={{ display: onClick === null ? 'none' : 'block' }}>
                <div
                    onClick={onClick}
                    className={cn(css(s.icon), { [css((s.nextIcon))]: nextIcon })}>
                    <ChevronNextSizeL />
                </div>
            </div>
        )
    }

    const settings = {
        arrows: true,
        dots: false,
        infinite: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        centerPadding: false,
        cssEase: 'linear',
        nextArrow: <SampleArrows nextIcon />,
        prevArrow: <SampleArrows />,
        responsive: [
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                },
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 1440,
                settings: {
                    slidesToShow: 3,
                },
            },
        ],
    }

    const token = localStorage.getItem('token')
    const handleNavigate = (type, vSlug) => {
        switch (type) {
        case 'forward':
            navigate(`/c/${slug}/${vSlug}`,
                { state: { from: state ? state.from : '/', prev: pathname } })
            break
        case 'apply':
            if (token) {
                navigate(`/c/${slug}/${vSlug}/apply?step=first`,
                    { state: { from: state ? state.from : '/', prev: pathname } })
            } else {
                navigate('/auth/user')
            }
            break
        default:
            if (state && (state.from || state.prev)) {
                navigate(state.from || state.prev,
                    { state: { from: state.from } })
            } else {
                navigate('/')
            }
        }
    }

    return (
        <div className={cn(css(s.vacanciesWrap), 'is-flex direction-column gap-5')}>
            {info.vacancy || vacancies.length ? (
                <VacanciesHeader
                    theme={theme}
                    data={vacancies}
                    companyName={info.name}
                    switchView={switchView}
                    loadData={loadVacancies}
                    setSwitchView={setSwitchView}
                />
            ) : null}

            <React.Fragment>
                {switchView === 'grid' ? (
                    <GridViewVacancies
                        info={info}
                        theme={theme}
                        vacancies={vacancies}
                        loadVacancies={loadVacancies}
                        handleNavigate={handleNavigate}
                    />
                ) : switchView === 'table' ? (
                    <VacanciesTable
                        info={info}
                        theme={theme}
                        handleNavigate={handleNavigate}
                    />
                ) : (
                    <React.Fragment>
                        <div className={css(s.slider)}>
                            <Slider {...settings}>
                                {vacancies.map((item) => (
                                    <RowScrollViewVacancyItem
                                        info={info}
                                        item={item}
                                        key={item.id}
                                        theme={theme}
                                        loading={loadVacancies.loading}
                                        handleNavigate={handleNavigate}
                                    />
                                ))}

                                {!loadVacancies.loading && loadVacancies.hasMore && <div ref={loadVacancies.ref} />}
                            </Slider>
                        </div>

                        <div className={css(s.mobile)}>
                            {vacancies.map((item) => (
                                <RowScrollViewVacancyItem
                                    info={info}
                                    item={item}
                                    key={item.id}
                                    theme={theme}
                                    loading={loadVacancies.loading}
                                    handleNavigate={handleNavigate}
                                />
                            ))}

                            {!loadVacancies.loading && loadVacancies.hasMore && <div ref={loadVacancies.ref} />}
                        </div>
                    </React.Fragment>
                )}
            </React.Fragment>

            {info.vacancy && !vacancies.length ? (
                <div className={css(s.noCardWrap)}>
                    <div>
                        <AddReport />

                        <h3 className={css(s.noCard)}>
                            <FormattedMessage id="noPublishedVacancies" />
                        </h3>
                    </div>
                </div>
            ) : null}
        </div>
    )
}

const s = StyleSheet.create({
    vacanciesWrap: {
        marginTop: 32,
        '@media (max-width: 768px)': {
            marginTop: 20,
        },
    },
    icon: {
        left: 25,
        width: 46,
        zIndex: 2,
        top: 60,
        height: 46,
        transform: 'rotate(-180deg)',
        cursor: 'pointer',
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 64,
        border: '1px solid rgba(139, 139, 139, 0.20)',
        background: 'rgba(255, 255, 255, 0.80)',
        boxShadow: '0px 2px 12px 0px rgba(0, 0, 0, 0.20)',
        backdropFilter: 'blur(6px)',
        ':nth-child(1n) > :only-child': {
            ':nth-child(1n) > path': {
                stroke: COLORS.dark,
                strokeWidth: 3,
            },
        },
        '@media (max-width: 768px)': {
            display: 'none',
        },
    },
    nextIcon: {
        transform: 'rotate(360deg)',
        right: 20,
        left: 'auto',
    },
    noCardWrap: {
        ':nth-child(1n) > :first-child': {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        },
    },
    noCard: {
        lineHeight: 1.2,
        fontSize: 13,
        fontWeight: '600',
        color: COLORS_PALETTE.midGray,
        textAlign: 'center',
        marginTop: '1.5rem',
    },
    spinner: {
        animation: 'spin 1s linear infinite',
        animationName: {
            '0%': { transform: 'rotate(0deg)' },
            '100%': { transform: 'rotate(360deg)' },
        },
    },
    slider: {
        '@media (max-width: 1024px)': {
            display: 'none',
        },
    },
    mobile: {
        paddingLeft: 16,
        display: 'flex',
        alignItems: 'center',
        gap: 12,
        overflowY: 'scroll',
        scrollbarWidth: 'none', // Firefox
        '-ms-overflow-style': 'none', // Internet Explorer
        '::-webkit-scrollbar': {
            display: 'none', // Safari and Chrome
        },
        '@media (min-width: 1024px)': {
            display: 'none',
        },
    },
    vacancies: {
        display: 'grid',
        position: 'relative',
        gridTemplateColumns: 'repeat( auto-fill, minmax(25rem,  1fr))',
        gap: 24,
        '@media (max-width: 768px)': {
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
        },
        padding: '0 32px',
        '@media (max-width: 1024px)': {
            padding: '0 16px',
            gap: 12,
        },
    },
    flexCont: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    applyBtn: {
        background: `linear-gradient(180deg, ${COLORS.mainColor} 0%, ${COLORS.lightMainColor} 100%)`,
        boxShadow: 'inset 0px -1px 0px rgba(0, 0, 0, 0.25), inset 0px 1px 0px rgba(255, 255, 255, 0.1)',
        borderRadius: '4px',
        fontWeight: '500',
        fontSize: 13,
        color: COLORS.white,
        padding: '8.5px 0',
        width: '100%',
        marginTop: 8,
        '@media (min-width: 768px)': {
            marginTop: 16,
        },
    },
    disabledBtn: {
        background: COLORS.gray,
        borderRadius: 4,
        border: 'none',
        // eslint-disable-next-line max-len
        boxShadow: '0px 4px 12px rgba(44, 67, 50, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.25), inset 0px 1px 0px rgba(255, 255, 255, 0.1)',
        cursor: 'not-allowed',
        padding: '8.5px 0',
        fontWeight: '500',
        fontSize: 13,
        width: '100%',
        marginTop: 8,
        color: COLORS.white,
        '@media (min-width: 768px)': {
            marginTop: 16,
        },
    },
    skeletonBtn: {
        marginTop: 8,
        '@media (min-width: 768px)': {
            marginTop: 16,
        },
    },
    logoCont: {
        width: 40,
        height: 40,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 4,
        boxSizing: 'border-box',
        ':nth-child(1n) > img': {
            width: 'inherit',
            borderRadius: 4,
        },
    },
    bodyWrap: {
        width: '100%',
    },
    companyInfo: {
        display: 'flex',
        alignItems: 'center',
        gap: '12px',
        ':nth-child(1n) > img': {
            width: 40,
            height: 40,
        },
    },
    detailsWrap: {
        marginTop: 17,
        display: 'flex',
        flexDirection: 'column',
        gap: 8,
        '@media (max-width: 1024px)': {
            marginTop: 15,
        },
    },
})

// const mode = (theme) => StyleSheet.create({
//     mainWrap: {
//         minWidth: '100%',
//         cursor: 'pointer',
//         background: theme === 'dark' ? COLORS.darkModeBg : COLORS.white,
//         padding: '16px 16px 16px 16px',
//         boxSizing: 'border-box',
//         border: `1px solid ${theme === 'dark' ? COLORS.itemDarkModeBg : COLORS.smoothGray}`,
//         boxShadow: '0px 2px 4px rgba(130, 130, 130, 0.05)',
//         borderRadius: 4,
//         display: 'flex',
//         flexDirection: 'column',
//         justifyContent: 'space-between',
//         ':hover': theme === 'dark' ? 'none' : {
//             border: `1px solid ${COLORS.lightGray}`,
//             boxShadow: '0px 2px 8px rgba(136, 136, 136, 0.25)',
//         },
//         '@media (max-width: 768px)': {
//             padding: 12,
//         },
//     },
//     detailWrap: {
//         display: 'flex',
//         alignItems: 'center',
//         justifyContent: 'center',
//         gap: 8,
//         ':nth-child(1n) > svg': {
//             ':nth-child(1n) > :first-child': {
//                 stroke: COLORS.mainColor,
//             },
//             ':nth-child(1n) > :last-child': {
//                 stroke: COLORS.mainColor,
//             },
//         },
//         background: theme === 'dark' ? COLORS.itemDarkModeBg : 'inherit',
//         border: `1px solid ${theme === 'dark' ? COLORS.itemDarkModeBg : COLORS.smoothGray}`,
//         boxShadow: '0px 2px 4px rgba(130, 130, 130, 0.05)',
//         borderRadius: '16px',
//         padding: '4px 12px',
//         fontWeight: 500,
//         fontSize: '13px',
//         color: theme === 'dark' ? COLORS.smoothGray : COLORS.lightBlack,
//         width: 'fit-content',
//         '@media (max-width: 768px)': {
//             padding: '6px 8px',
//             fontSize: 10,
//             gap: 4,
//         },
//         whiteSpace: 'nowrap',
//     },
//     defaultLogo: {
//         width: 40,
//         height: 40,
//         display: 'flex',
//         alignItems: 'center',
//         justifyContent: 'center',
//         background: COLORS.mainColor,
//         border: theme === 'dark' ? 'none' : `1px solid ${COLORS.white}`,
//         borderRadius: 4,
//         boxSizing: 'border-box',
//     },
// })
