import React, { memo, useContext } from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
// import { Formik } from 'formik'
import { Link, useNavigate } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { InstagramSizeL, InstagramSizeM,
    LinkedInSizeL, LinkedInSizeM,
    LinkIcon,
    LogoL, LogoM,
    Phone, TelegramSizeL, TelegramSizeM, YouTubeSizeL, YouTubeSizeM } from '../svgs/Svgs'
import { COLORS } from '../../utils/colors'
import { BaseContext } from '../../contexts/BaseContextWrapper'
import { Header } from './Header'

// import { Context } from '../../contexts/GlobalContext'

function MemoizedComponent({
    children,
    padded = false,
    className,
    candidate,
    cInfo = {},
    isFooter = false,
    ...args
}) {
    // const { slug } = useParams()
    const navigate = useNavigate()
    // const [selectedLanguage, setSelectedLanguage] = useState(null)
    const { theme } = useContext(BaseContext)
    // const { lang, setLang } = useContext(Context)

    // useEffect(() => {
    //     if (token) getInfo.request()
    // }, [])

    // const languageOptions = [
    //     { value: 'ru', label: 'RU' },
    //     { value: 'uz', label: 'UZ' },
    //     { value: 'en', label: 'EN' },
    // ]

    const footerItems = [
        [
            { id: 1, title: <FormattedMessage id="postVacancy" />, link: 'https://hr.workly.uz/auth/recruiter' },
            { id: 2, title: <FormattedMessage id="aboutSystem" />, link: 'https://hr.workly.io/' },
            { id: 3, title: <FormattedMessage id="advantages" />, link: 'https://hr.workly.io/service-1.html' },
            { id: 4, title: <FormattedMessage id="requestForDemo" />, link: 'https://hr.workly.io/contact.html' },
        ],

        [
            { id: 1, title: <FormattedMessage id="findJob" />, link: '/' },
            { id: 2, title: <FormattedMessage id="loginToAccount" />, link: '/account/info' },
        ],

        [
            { id: 1, icon: TelegramSizeL, iconMob: TelegramSizeM, link: 'https://t.me/worklyhr' },
            { id: 2, icon: InstagramSizeL, iconMob: InstagramSizeM, link: 'https://www.instagram.com/workly.io' },
            { id: 3, icon: LinkedInSizeL, iconMob: LinkedInSizeM, link: 'https://www.linkedin.com/company/workly' },
            { id: 4, icon: YouTubeSizeL, iconMob: YouTubeSizeM, link: 'https://www.youtube.com/@worklyio' },
        ],
    ]

    return (
        <div {...args} className={css(s.layoutWrap)}>
            <Header cInfo={cInfo} />

            <section>
                <div
                    id="mn-cont"
                    className={cn(
                        `${theme === 'dark' ? 'dark' : 'light'}`,
                        css(s.child),
                        { [css(s.p)]: padded }, className,
                    )}>
                    {children}

                    {isFooter ? (
                        <div className={css(mode(theme).footer)}>
                            <div className={cn(css(mode(theme).upperCont), 'is-flex')}>
                                <div
                                    className={cn(css(mode(theme).departments),
                                        'flex-1 is-flex direction-column gap-5')}>
                                    <h3><FormattedMessage id="forCompanies" /></h3>

                                    <div className={css(mode(theme).linksWrap)}>
                                        {footerItems[0].map((item) => (
                                            <a key={item.id} href={item.link}>{item.title}</a>
                                        ))}
                                    </div>
                                </div>

                                <div
                                    className={cn(css(mode(theme).departments),
                                        'flex-1 is-flex direction-column gap-5')}>
                                    <h3><FormattedMessage id="forApplicants" /></h3>

                                    <div className={cn(css(s.innerLinks), 'is-flex align-center full-width')}>
                                        {footerItems[1].map((item) => (
                                            <Link key={item.id} to={item.link}>{item.title}</Link>
                                        ))}
                                    </div>
                                </div>
                            </div>

                            <div className={cn(css(s.lowerCont), 'is-flex justify-between align-end')}>
                                <div className="full-width is-flex align-end justify-between flex-1 gap-5">
                                    <div className="is-flex direction-column gap-3">
                                        <span>powered by</span>

                                        <span
                                            onClick={() => navigate('/')}
                                            className={css(s.logoBar)}
                                        >
                                            <LogoL />

                                            <LogoM color={COLORS.mainColor} />
                                        </span>
                                    </div>

                                    <div className="is-flex align-end gap-3">
                                        {footerItems[2].map((item) => (
                                            <a
                                                key={item.id}
                                                href={item.link}
                                                target="_blank"
                                                rel="noreferrer"
                                                className={cn(css(mode(theme).socialIcon),
                                                    'is-flex align-center justify-center')}>
                                                <item.icon
                                                    color={theme === 'dark' ? COLORS.smoothGray : COLORS.lightBlack}
                                                />

                                                <item.iconMob
                                                    color={theme === 'dark' ? COLORS.smoothGray : COLORS.lightBlack}
                                                />
                                            </a>
                                        ))}
                                    </div>
                                </div>

                                <div className={cn(css(s.tagWrap), 'is-flex align-end gap-7')}>
                                    <div className={cn('align-center gap-2', css(mode(theme).tag))}>
                                        <Phone color={COLORS.mainColor} />

                                        <a className={css(mode(theme).tagTxt)} href="tel:+998 78 113 19 89">
                                            +998 78 113 19 89
                                        </a>
                                    </div>

                                    <div className={cn('align-center gap-2', css(mode(theme).tag))}>
                                        <LinkIcon color={COLORS.mainColor} />

                                        <a
                                            className={css(mode(theme).tagTxt)}
                                            href="https://hr.workly.io"
                                            target="_blank" rel="noreferrer"
                                        >
                                            hr.workly.io
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : null}
                </div>
            </section>
        </div>
    )
}

export const Layout = memo(MemoizedComponent)

const s = StyleSheet.create({
    layoutWrap: {
        height: '100%',
        ':nth-child(1n) > section': {
            height: 'calc(100% - 52px)',
        },
    },
    child: {
        height: '100%',
        overflowY: 'auto',
        overflowX: 'hidden',
        flex: 1,
        transition: 'all 0.2s linear',
        scrollbarGutter: 'stable',
    },
    p: {
        minWidth: '79.6%',
        padding: '0 2.2% 2.2% 2.2%',
        '@media (max-width: 768px)': {
            padding: 0,
        },
    },
    lowerCont: {
        paddingTop: 24,
        gap: 24,
        ':nth-child(1n) > :first-child': {
            ':nth-child(1n) > :first-child': {
                color: COLORS.midGray,
                fontSize: 14,
                fontWeight: '600',
                textTransform: 'uppercase',
                '@media (max-width: 1024px)': {
                    fontSize: 12,
                },
            },
        },
        '@media (max-width: 1024px)': {
            gap: 16,
            paddingTop: 16,
            flexDirection: 'column',
            alignItems: 'start',
        },
    },
    tagWrap: {
        '@media (max-width: 1024px)': {
            gap: 12,
        },
    },
    logoBar: {
        '@media (max-width: 768px)': {
            ':nth-child(1n) > :first-child': {
                display: 'none',
            },
        },
        '@media (min-width: 769px)': {
            ':nth-child(1n) > :last-child': {
                display: 'none',
            },
        },
    },
    innerLinks: {
        // flex: 1,
        gap: 32,
        '@media (max-width: 1440px)': {
            gap: 16,
        },
        '@media (max-width: 480px)': {
            ':nth-child(1n) > :last-child': {
                flex: 1,
                paddingLeft: 31,
                textAlign: 'center',
            },
        },
    },
})

const mode = (theme) => StyleSheet.create({
    footer: {
        zIndex: 1,
        width: 'calc(100% - 64px)',
        padding: 32,
        borderTop: `1px solid ${theme === 'dark' ? COLORS.lightBlack : COLORS.lightGray}`,
        backgroundColor: theme === 'dark' ? COLORS.dark : COLORS.white,
        '@media (max-width: 1024px)': {
            padding: 16,
            width: 'calc(100% - 32px)',
        },
    },
    upperCont: {
        borderBottom: `1px solid ${theme === 'dark' ? COLORS.lightBlack : COLORS.lightGray}`,
        paddingBottom: 24,
        gap: 24,
        '@media (max-width: 1024px)': {
            flexDirection: 'column',
            alignItems: 'start',
            gap: 16,
            paddingBottom: 16,
        },
    },
    departments: {
        width: '100%',
        ':nth-child(1n) > :last-child': {
            ':nth-child(1n) > a': {
                textOverflow: 'ellipsis',
                color: COLORS.midGray,
                fontSize: 16,
                fontWeight: '500',
                textDecoration: 'none',
                whiteSpace: 'nowrap',
                '@media (max-width: 768px)': {
                    fontSize: 13,
                },
            },
        },
        ':nth-child(1n) > h3': {
            color: theme === 'dark' ? COLORS.white : COLORS.dark,
            fontSize: 24,
            fontWeight: '600',
            '@media (max-width: 768px)': {
                fontSize: 16,
            },
        },
    },
    linksWrap: {
        display: 'flex',
        alignItems: 'center',
        gap: 32,
        '@media (max-width: 1440px)': {
            gap: 16,
        },
        '@media (max-width: 768px)': {
            display: 'grid',
            gridTemplateColumns: 'auto auto',
        },
    },
    tag: {
        padding: '8px 24px',
        border: `1px solid ${theme === 'dark' ? '#525252' : COLORS.lightGray}`,
        boxShadow: '0px 2px 4px rgba(130, 130, 130, 0.05)',
        borderRadius: 32,
        '@media (max-width: 1024px)': {
            padding: '6px 12px',
        },
    },

    tagTxt: {
        fontSize: 20,
        fontWeight: '600',
        textDecoration: 'none',
        color: theme === 'dark' ? COLORS.smoothGray : COLORS.dark,
        display: 'flex',
        alignItems: 'center',
        gap: 4,
        '@media (max-width: 1024px)': {
            fontSize: 14,
        },
    },
    socialIcon: {
        width: 44,
        height: 44,
        borderRadius: 32,
        border: theme === 'dark' ? 'none' : `1px solid ${COLORS.lightGray}`,
        background: theme === 'dark' ? COLORS.darkModeBg : COLORS.white,
        boxShadow: '0px 2px 4px 0px rgba(130, 130, 130, 0.05)',
        '@media (max-width: 768px)': {
            width: 34,
            height: 34,
            ':nth-child(1n) > :first-child': {
                display: 'none',
            },
        },
        '@media (min-width: 769px)': {
            ':nth-child(1n) > :last-child': {
                display: 'none',
            },
        },
    },
})
