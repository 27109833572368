import React, { useContext } from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import cableLeft from '../../static/images/cableLeft.png'
import cableRight from '../../static/images/cableRight.png'
import { COLORS } from '../../utils/colors'
import { NotFoundStatusCode, NotFoundStatusCodeSizeS } from '../svgs/SvgPictures'
import { BaseContext } from '../../contexts/BaseContextWrapper'

export default function NotFound() {
    const { theme } = useContext(BaseContext)
    return (
        // <Layout>
        <div className={css(s.cont)}>
            <div className="justify-between direction-column">
                <div className={css(s.leftCable)}>
                    <img src={cableLeft} alt="#error" />
                </div>

                <div className={cn(css(s.contentWrap), 'align-center justify-center full-height direction-column')}>
                    <div className={css(s.statusWrap)}>
                        <NotFoundStatusCode color={theme === 'dark' ? COLORS.gray : COLORS.dark} />

                        <NotFoundStatusCodeSizeS color={theme === 'dark' ? COLORS.gray : COLORS.dark} />
                    </div>

                    <h3 className={css(mode(theme).title)}>Ууууупс! Страница не найдена</h3>

                    <h3 className={css(s.desc)}>Страница, которую вы искали была перемещена или удалена</h3>
                </div>

                <div className={css(s.rightCable)}>
                    <img src={cableRight} alt="#error" />
                </div>
            </div>
        </div>
        // </Layout>
    )
}

const s = StyleSheet.create({
    ly: {
        width: '88.4%',
        scrollbarGutter: 'auto',
    },
    leftCable: {
        position: 'absolute',
        left: '-9rem',
        ':nth-child(1n) > img': {
            '@media (max-width: 768px)': {
                maxWidth: '14.5rem',
                left: 0,
            },
        },
        '@media (max-width: 768px)': {
            left: '-4rem',
            top: '5%',
        },
    },
    rightCable: {
        bottom: '5%',
        right: 0,
        position: 'absolute',
        ':nth-child(1n) > img': {
            '@media (max-width: 768px)': {
                maxWidth: '12.5rem',
            },
        },
    },
    statusWrap: {
        marginTop: 23,
        ':nth-child(1n) > :first-child': {
            '@media (max-width: 768px)': {
                display: 'none',
            },
        },
        ':nth-child(1n) > :last-child': {
            '@media (min-width: 769px)': {
                display: 'none',
            },
        },
    },
    contentWrap: {
        margin: '0 32px',
        width: 'calc(100% - 64px)',
        textAlign: 'center',
    },
    cont: {
        position: 'relative',
        height: 'calc(100% - 3rem)',
        width: '100%',
        // marginTop: '3.75rem',
        ':nth-child(1n) > :first-child': {
            height: '100%',
        },
    },
    desc: {
        marginTop: 12,
        fontSize: 20,
        color: COLORS.gray,
        fontWeight: '400',
        '@media (max-height: 768px)': {
            fontSize: 14,
        },
    },
})

const mode = (theme) => StyleSheet.create({
    title: {
        marginTop: 25,
        color: theme === 'dark' ? COLORS.smoothGray : COLORS.dark,
        fontWeight: '600',
        fontSize: 24,
        '@media (max-height: 768px)': {
            marginTop: 12,
            fontSize: 16,
        },
    },
})
