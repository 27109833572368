import React, { useContext, useState } from 'react'
import axios from 'axios'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { convertToHTML } from 'draft-convert'
import { css, StyleSheet } from 'aphrodite'
import { FormattedMessage } from 'react-intl'
import { format, integersOnly } from '../../../utils/number'
import ApplyForms from './ApplyForms'
import { useLoad, usePostRequest, usePutRequest } from '../../../hooks/request'
import { CANDIDATE_INFO_UPDATE,
    CANDIDATE_PHOTO,
    PUBLIC_COMPANY_VACANCY,
    VACANCY_APPLY } from '../../../urls'
import { domain } from '../../../utils/request'
import { useMessage } from '../../../hooks/message'
import { PartyPopper } from '../../svgs/Svgs'
import { COLORS } from '../../../utils/colors'
import { auth } from '../../../utils/auth'
import { Context } from '../../../contexts/GlobalContext'

export default function Apply() {
    const [showMessage] = useMessage()
    const { state } = useLocation()
    const { cSlug, vSlug } = useParams()
    const navigate = useNavigate()
    const putInfo = usePutRequest({ url: CANDIDATE_INFO_UPDATE })
    const applyRequest = usePostRequest({ url: VACANCY_APPLY })
    const [imageUploadLoading, setImageUploadLoading] = useState(false)
    const [file, setFile] = useState()
    const { info, getInfo } = useContext(Context)
    const loadVacancyInfoPublic = useLoad(
        { url: PUBLIC_COMPANY_VACANCY.replace('{slug}/{vSlug}',
            `${cSlug}/${vSlug}`) }, [],
    )
    const vacancy = loadVacancyInfoPublic.response ? loadVacancyInfoPublic.response.data : []

    const initialValues = {
        // ...info,
        address: info.location ? info.location : '',
        birth_date: info.birthDate || '',
        gender: info.gender || '',
        skills: info.skills || [],
        remote: info.remote,
        languageLevel: info.languages || [],
        desiredSalary: format(integersOnly(info.desiredSalary)) || '',
        experience: info.experience && info.experience.length ? info.experience.map((exp) => ({
            id: exp.id,
            job: exp.job,
            end_date: exp.endDate,
            start_date: exp.startDate,
            achievements: exp.achievements,
            position: exp.position,
        })) : [],
        education: info.education && info.education.length ? info.education.map((edu) => ({
            id: edu.id,
            level: edu.level,
            start_date: edu.startDate,
            end_date: edu.endDate,
            institution: edu.institution,
            additional: edu.additional,
        })) : [],
        location: info.location && Object.values(info.location).every((e) => e) ? { value: info.location.id, label: info.location.name } : null, // eslint-disable-line max-len
    }

    const CongratsMsg = () => (
        // eslint-disable-next-line no-undef
        <div className={css(s.congratsMsg)}>
            <PartyPopper />
            <FormattedMessage id="youHaveAppliedFor" /> <span>{vacancy.name}</span>
        </div>
    )

    const onSubmitApply = async (values) => {
        // const skills = [...values.skills, ...values.programmes]
        const html = convertToHTML(values.coverLetter.getCurrentContent())
        const tempElement = document.createElement('div')
        tempElement.innerHTML = html
        const isEmptyTag = tempElement.textContent.trim() === ''

        const { success, error } = await applyRequest.request({
            data: {
                slug: vSlug,
                skills: values.skills.map((skill) => skill.value),
                cover_letter: isEmptyTag ? '' : html,
            },
        })

        if (success) {
            showMessage(<CongratsMsg />, css(s.congratsMsg))
            navigate('/vacancies')
        } else if (error.data.errors) {
            error.data.errors.map((val) => (
                showMessage(val.message, 'error-msg')
            ))
        } else {
            showMessage(error.data.message, 'error-msg')
        }
    }

    const onSubmit = async (data) => {
        if (putInfo.loading || imageUploadLoading) return
        // let imageUploadSuccess = false
        // let infoUpdateSuccess = false
        let imageUploadError = null
        let infoUpdateError = null

        if (typeof file === 'object' && file !== '') {
            setImageUploadLoading(true)
            const formData = new FormData()
            formData.append('file', file)
            await axios.post(domain + CANDIDATE_PHOTO, formData, auth())
                .then((res) => {
                    if (res.data.success) {
                        showMessage('SUCCESS', 'success-msg')
                        // imageUploadSuccess = true
                        setFile('')
                    }
                }).catch(({ response }) => {
                    if (response.data.errors.length) {
                        imageUploadError = response.data.errors[0].message
                    } else {
                        imageUploadError = response.data.message
                    }
                })
            setImageUploadLoading(false)
        }

        const newData = {
            location: data.address,
            birthday: data.birth_date,
            gender: data.gender || 'not_set',
            desired_salary: data.desiredSalary ? data.desiredSalary.replace(/\s/g, '') : null,
            currency: data.currency,
            education: data.education.map((edu) => ({
                level: edu.level || '',
                start_date: edu.start_date || '',
                end_date: edu.end_date || '',
                institution: edu.institution || '',
                additional: edu.additional || '',
            })),
            experience: data.experience.filter((val) => val !== undefined).map((exp) => ({
                job: exp.job || '',
                start_date: exp.start_date || '',
                end_date: exp.end_date || '',
                position: exp.position || '',
                achievements: exp.achievements || '',
            })),
            remote: data.remote,
            languages: Object.values(data.languageLevel).filter((v) => !(v.lang === null && v.level === null)),
        }

        const { error } = await putInfo.request({ data: newData })
        if (error) {
            // infoUpdateSuccess = true
            infoUpdateError = error.data.errors[0].message || error.data.message
        }

        if (!imageUploadError && !infoUpdateError) {
            getInfo.request()
            navigate('?step=second', { state: { ...state } })
        } else {
            if (imageUploadError) {
                showMessage(imageUploadError, 'error-msg')
            }
            if (infoUpdateError) {
                showMessage(infoUpdateError, 'error-msg')
            }
        }
    }

    return (
        <ApplyForms
            info={info}
            vacancy={vacancy}
            onSubmit={onSubmit}
            setFile={setFile}
            applyLoading={applyRequest.loading}
            loading={file === '' ? putInfo.loading : imageUploadLoading}
            applyTrigger={onSubmitApply}
            initialValues={initialValues}
        />
    )
}

const s = StyleSheet.create({
    congratsMsg: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 8,
        fontWeight: '500',
        fontSize: 12,
        backgroundColor: COLORS.white,
        color: COLORS.midGray,
        ':nth-child(1n) > span': {
            color: COLORS.lightBlack,
        },
        ':nth-child(1n) > :last-child': {
            ':nth-child(1n) > path': {
                fill: COLORS.lightGray,
            },
        },
    },
})
