import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { useLocation, useNavigate } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { COLORS } from '../../utils/colors'

export default function IndustryItem({ item, theme }) {
    const navigate = useNavigate()
    const { pathname } = useLocation()
    const { Icon } = item
    const intl = useIntl()

    return (
        <div
            className={cn(css(mode(theme).cardWrap), 'is-flex align-center')}
            onClick={() => navigate(`/companies/${item.value}`, { state: { from: pathname } })}
        >
            <span><Icon /></span>

            <div className={cn(css(mode(theme).infoWrap), 'is-flex direction-column gap-2')}>
                <h3>{item.label}</h3>

                <div className={cn('is-flex align-center gap-1', css(mode(theme).cCount))}>
                    <span>{item.companyCount === 0 ? '0' : item.companyCount}</span>

                    <h3>
                        {item.companyCount === 1
                            ? intl.formatMessage({ id: 'company' })
                            : intl.formatMessage({ id: 'companies' })}
                    </h3>
                </div>
            </div>
        </div>
    )
}

const mode = (theme) => StyleSheet.create({
    cardWrap: {
        minWidth: '14rem',
        borderRadius: 4,
        border: `1px solid ${theme === 'dark' ? COLORS.itemDarkModeBg : COLORS.smoothGray}`,
        background: theme === 'dark' ? COLORS.darkModeBg : COLORS.white,
        boxShadow: '0px 2px 4px 0px rgba(130, 130, 130, 0.05)',
        padding: '20px 24px',
        gap: 20,
        cursor: 'pointer',
        '@media (max-width: 1024px)': {
            padding: 12,
            gap: 8,
        },
    },
    cCount: {
        textTransform: 'lowercase',
    },
    infoWrap: {
        overflowX: 'hidden',
        ':nth-child(1n) > :first-child': {
            color: theme === 'dark' ? COLORS.white : COLORS.dark,
            fontSize: 18,
            fontWeight: '600',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            textWrap: 'balance',
            '@media (max-width: 1024px)': {
                fontSize: 14,
            },
        },
        ':nth-child(1n) > :last-child': {
            color: theme === 'dark' ? COLORS.gray : COLORS.midGray,
            fontSize: 15,
            fontWeight: '500',
            '@media (max-width: 1024px)': {
                fontSize: 12,
            },
        },
        '@media (max-width: 768px)': {
            gap: 4,
        },
    },
})
