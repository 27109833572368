import React, { useContext } from 'react'
import cn from 'classnames'
import { css, StyleSheet } from 'aphrodite'
import { FormattedMessage } from 'react-intl'
import MainInfo from './MainInfo'
import EducationInfo from './EducationInfo'
import ExperienceInfo from './ExperienceInfo'
import LanguageInfo from './LanguageInfo'
import SkillsInfo from './SkillsInfo'
import { usePutRequest } from '../../hooks/request'
import { CANDIDATE_INFO_UPDATE } from '../../urls'
import { EditPencil, Trash } from '../svgs/Svgs'
import { BaseContext } from '../../contexts/BaseContextWrapper'
import { Context } from '../../contexts/GlobalContext'

export default function AccountInfo() {
    const putInfo = usePutRequest({ url: CANDIDATE_INFO_UPDATE })
    const { theme, lang } = useContext(BaseContext)
    const { info, getInfo } = useContext(Context)

    const options = [
        {
            id: 1,
            value: <FormattedMessage id="change" />,
            type: 'update',
            Icon: EditPencil,
            navigate: true,
            onClick: (setShowModal, act, type, ind, id) => setShowModal({ act, type, ind, id }),
        },
        {
            id: 2,
            value: <FormattedMessage id="delete" />,
            type: 'delete',
            Icon: Trash,
            onClick: (onDelete, id, close) => onDelete(id, close),
        },
    ]

    return (
        <div className={cn(css(s.wrapper), 'direction-column is-flex gap-7')}>
            <MainInfo
                info={info}
                theme={theme}
                getInfo={getInfo}
                putRequest={putInfo}
            />

            <EducationInfo
                info={info}
                theme={theme}
                options={options}
                getInfo={getInfo}
                putInfo={putInfo}
            />

            <ExperienceInfo
                info={info}
                theme={theme}
                options={options}
                getInfo={getInfo}
            />

            <LanguageInfo
                lang={lang}
                info={info}
                theme={theme}
                options={options}
                getInfo={getInfo}
            />

            <SkillsInfo
                info={info}
                theme={theme}
                getInfo={getInfo}
                putInfo={putInfo}
            />
        </div>
    )
}

const s = StyleSheet.create({
    wrapper: {
        overflowX: 'hidden',
        height: '100%',
        padding: '24px 32px 32px 0',
        '@media (max-width: 768px)': {
            gap: 16,
            padding: 0,
        },
        '@media (max-width: 1100px)': {
            padding: '16px 0 16px 0',
        },
    },
})
