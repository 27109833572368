import React from 'react'
import { FormattedMessage } from 'react-intl'

export const dateRangeFilter = [
    { id: 1, name: <FormattedMessage id="today" />, key: 'today' },
    { id: 2, name: <FormattedMessage id="thisWeek" />, key: 'week' },
    { id: 3, name: <FormattedMessage id="thisMonth" />, key: 'month' },
    { id: 4, name: <FormattedMessage id="lastMonth" />, key: 'lastMonth' },
]

export const sortFilter = [
    { id: '-v_created_at', name: <FormattedMessage id="newFirst" />, key: 'newFirst' },
    { id: 'v_created_at', name: <FormattedMessage id="oldFirst" />, key: 'oldFirst' },
    { id: 'salary_min', name: <FormattedMessage id="salaryAsc" />, key: 'salaryAsc' },
    { id: '-salary_max', name: <FormattedMessage id="salaryDesc" />, key: 'salaryDesc' },
]

export function fetchDataByFilter($api, filter, additionalParams = {}) {
    const {
        edu,
        age,
        salary,
        experience,
        industry,
        interval,
        addedDate,
        sortBy,
    } = filter

    const params = {
        experience: experience ? experience.join(',') : undefined,
        age: age ? age.join(',') : undefined,
        salary: salary || undefined,
        educationLevel: edu ? edu.map((e) => e.value).join(',') : undefined,
        industry: industry ? industry.join(',') : undefined,
        date: addedDate,
        interval: interval || undefined,
        sort: sortBy || undefined,
    }

    const defaultFilter = { sortBy: '-v_created_at' }
    function isEmptyFilter(obj1, obj2) {
        if (Object.keys(obj1).length !== Object.keys(obj2).length) return false

        for (const key of Object.keys(obj1)) {
            if (obj1[key] !== obj2[key]) {
                return false
            }
        }

        return true
    }

    if (isEmptyFilter(filter, defaultFilter)) {
        $api.reset()
    } else {
        $api.request({ params: { ...params, ...additionalParams } })
    }
}
