import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { FormattedMessage } from 'react-intl'
import { Briefcase, LinkIcon, Phone, VerifiedSizeL } from '../svgs/Svgs'
import { COLORS } from '../../utils/colors'
import { LoadingSkeleton } from '../common/LoadingSkeleton'

export default function CompanyInfo({ info, theme, loading, lang }) {
    return (
        <div className={css(s.info)}>
            <div className="is-flex direction-column gap-5">
                <div className={cn(css(s.titleWrap), 'align-center gap-2')}>
                    <div className={cn(css(mode(theme).title), 'is-flex align-center gap-2')}>
                        <FormattedMessage id="aboutCompany" />

                        {!loading
                            ? (
                                <h3 className="is-flex align-center gap-1">
                                    {info.name} {info.verified ? <VerifiedSizeL /> : null}
                                </h3>
                            ) : <LoadingSkeleton height={20} />}
                    </div>
                </div>

                <div className={css(s.aboutComp)}>
                    <div className={cn(css(s.details), 'align-center gap-5')}>
                        {info.industryName ? (
                            <div className={cn('align-center gap-2', css(mode(theme).tag))}>
                                <Briefcase color={COLORS.mainColor} />

                                <h3 className={css(mode(theme).tagTxt)}>
                                    <span><FormattedMessage id="industry" />:</span>

                                    <span>{!loading ? info.industryName[lang] : <LoadingSkeleton height={13} />}</span>
                                </h3>
                            </div>
                        ) : null}

                        {info.phoneNumber ? (
                            <div className={cn('align-center gap-2', css(mode(theme).tag))}>
                                <Phone color={COLORS.mainColor} />

                                <h3 className={css(mode(theme).tagTxt)}>
                                    <span><FormattedMessage id="phoneNumber" />:</span>

                                    {!loading ? (
                                        <a href={`tel:${info.phoneNumber}`}>
                                            {info.phoneNumber}
                                        </a>
                                    ) : <LoadingSkeleton height={13} />}
                                </h3>
                            </div>
                        ) : null}

                        {info.link ? (
                            <div className={cn('align-center gap-2', css(mode(theme).tag))}>
                                <LinkIcon color={COLORS.mainColor} />

                                <h3 className={css(mode(theme).tagTxt)}>
                                    <span><FormattedMessage id="website" />:</span>

                                    {!loading ? (
                                        <a href={`${info.link}`}
                                            target="_blank" rel="noreferrer">
                                            {info.link}
                                        </a>
                                    ) : <LoadingSkeleton height={13} />}
                                </h3>
                            </div>
                        ) : null}
                    </div>

                    {info.description ? (
                        <div className={css(mode(theme).descr)}>
                            <p>{!loading ? info.description : <LoadingSkeleton height={16} />}</p>
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    )
}

const s = StyleSheet.create({
    titleWrap: {
        height: '28px',
        ':nth-child(1n) > :last-child': {
            cursor: 'pointer',
            ':nth-child(1n) > path': {
                stroke: COLORS.mainColor,
            },
        },
    },
    info: {
        marginTop: 24,
    },
    aboutComp: {
        display: 'flex',
        flexDirection: 'column',
        gap: 16,
    },
    details: {
        flexWrap: 'wrap',
    },
})

const mode = (theme) => StyleSheet.create({
    descr: {
        whiteSpace: 'break-spaces',
        wordBreak: 'break-word',
        fontWeight: '600',
        fontSize: 16,
        color: theme === 'dark' ? '#BABABA' : COLORS.lightBlack,
        '@media (max-width: 768px)': {
            fontSize: 13,
            lineHeight: '15px',
        },
    },
    tagTxt: {
        fontSize: 13,
        fontWeight: '600',
        ':nth-child(1n) > :first-child': {
            fontWeight: '400',
            color: COLORS.gray,
        },
        ':nth-child(2n) > :last-child': {
            textDecoration: 'none',
            color: theme === 'dark' ? COLORS.smoothGray : COLORS.dark,
        },
        display: 'flex',
        alignItems: 'center',
        gap: 4,
        '@media (max-width: 768px)': {
            fontSize: 12,
        },
    },
    title: {
        fontSize: 20,
        fontWeight: '700',
        color: theme === 'dark' ? COLORS.white : COLORS.lightBlack,
        '@media (max-width: 768px)': {
            fontSize: 16,
        },
    },
    tag: {
        padding: '6px 12px',
        border: `1px solid ${theme === 'dark' ? '#525252' : COLORS.lightGray}`,
        boxShadow: '0px 2px 4px rgba(130, 130, 130, 0.05)',
        borderRadius: 16,
        '@media (max-width: 768px)': {
            padding: '4px 10px',
        },
    },
})
