import React, { memo, useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { useNavigate } from 'react-router-dom'
import { useIntl } from 'react-intl'
import Button from '../common/Button'
import { MagnifierSizeL } from '../svgs/Svgs'
import { COLORS } from '../../utils/colors'

function MemoizedComponent() {
    const [searchValue, setSearchValue] = useState('')
    const navigate = useNavigate()
    const intl = useIntl()
    const handleSubmit = () => {
        if (!searchValue) return

        navigate(`/vacancies?search=${searchValue}`)
    }

    return (
        <form onSubmit={handleSubmit} className={cn(css(s.contWrap), 'is-flex align-center justify-between')}>
            <div className={cn(css(s.wrap), 'is-flex align-center justify-start gap-2 flex-1')}>
                <div className="is-flex align-center justify-center">
                    <MagnifierSizeL />
                </div>

                <input
                    name="searchKey"
                    placeholder={intl.formatMessage({ id: 'mainSearchContainerPlaceHolder' })}
                    className={css(s.searchInput)}
                    onChange={(e) => setSearchValue(e.target.value)}
                />
            </div>

            <Button type="submit" onClick={handleSubmit} className={css(s.btn)}>
                {intl.formatMessage({ id: 'search' })}
            </Button>
        </form>
    )
}

export const SearchContainer = memo(MemoizedComponent)

const s = StyleSheet.create({
    contWrap: {
        width: '50.75rem',
        position: 'absolute',
        margin: '0 16px',
        bottom: -20,
        '@media (max-width: 1024px)': {
            width: '90%',
        },
    },
    wrap: {
        height: 46,
        padding: '0 16px',
        borderRadius: '4px 0px 0px 4px',
        border: `1px solid ${COLORS.gray}`,
        background: 'rgba(255, 255, 255, 0.80)',
        backdropFilter: 'blur(12px)',
        ':has(input:focus)': {
            background: COLORS.white,
            backdropFilter: 'blur(8px)',
        },
        '@media (max-width: 768px)': {
            borderRadius: 4,
        },
    },
    searchInput: {
        outline: 'none',
        border: 'none',
        background: 'transparent',
        color: COLORS.midGray,
        fontSize: 16,
        width: '100%',
        height: 'inherit',
        fontWeight: '500',
        '@media (max-width: 768px)': {
            fontSize: 14,
        },
    },
    btn: {
        width: 136,
        background: `linear-gradient(180deg, ${COLORS.mainColor} 0%, ${COLORS.lightMainColor} 100%)`,
        color: COLORS.white,
        fontSize: 18,
        fontWeight: '500',
        height: 48,
        padding: '0 16px',
        borderRadius: '0px 4px 4px 0px',
        ':hover': {
            // eslint-disable-next-line max-len
            background: `linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(180deg, ${COLORS.mainColor} 0%, ${COLORS.lightMainColor} 100%)`,
        },
        '@media (max-width: 768px)': {
            display: 'none',
        },
    },
})
