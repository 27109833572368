import React, { useEffect, useRef } from 'react'
import { css, StyleSheet } from 'aphrodite'
import { Editor } from 'react-draft-wysiwyg'
import { Field } from 'formik'
import cn from 'classnames'
import { FormattedMessage, useIntl } from 'react-intl'
import { COLORS } from '../../../utils/colors'
import boldIconBlack from '../../../static/images/bold-black.svg'
import boldIconWhite from '../../../static/images/bold-white.svg'
import italic from '../../../static/images/italic-black.svg'
import underline from '../../../static/images/underline-black.svg'
import unordered from '../../../static/images/unordered-black.svg'
import ordered from '../../../static/images/ordered-black.svg'
import link from '../../../static/images/link-black.svg'
import unlink from '../../../static/images/unlink-black.svg'
import alignLeft from '../../../static/images/align-left-black.svg'
import alignCenter from '../../../static/images/align-center-black.svg'
import alignRight from '../../../static/images/align-right-black.svg'
import italicWhite from '../../../static/images/italic-white.svg'
import underlineWhite from '../../../static/images/underline-white.svg'
import unorderedWhite from '../../../static/images/unordered-white.svg'
import orderedWhite from '../../../static/images/ordered-white.svg'
import linkWhite from '../../../static/images/link-white.svg'
import unlinkWhite from '../../../static/images/unlink-white.svg'
import alignLeftWhite from '../../../static/images/align-left-white.svg'
import alignCenterWhite from '../../../static/images/align-center-white.svg'
import alignRightWhite from '../../../static/images/align-right-white.svg'
import { MailSizeM } from '../../svgs/Svgs'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import '../../../static/css/editorStyles.css'

export default function CoverLetterForm({ theme }) {
    const editorRef = useRef(null)
    const width = useRef()
    const intl = useIntl()

    useEffect(() => {
        if (editorRef.current) {
            width.current = editorRef.current.offsetWidth
        }
    }, [])

    window.addEventListener('resize', () => {
        if (editorRef.current) {
            width.current = editorRef.current.offsetWidth
        }
    })

    const toolbarOptions = {
        options: ['fontSize', 'inline', 'list', 'link', 'textAlign'],
        fontSize: {
            options: [8, 10, 12, 14, 16, 18, 20, 24],
            title: 'Размер Шрифта',
        },
        inline: {
            options: ['bold', 'italic', 'underline'],
            bold: { icon: theme === 'dark' ? boldIconWhite : boldIconBlack, title: 'Жирный' },
            italic: { icon: theme === 'dark' ? italicWhite : italic, title: 'Курсив' },
            underline: { icon: theme === 'dark' ? underlineWhite : underline, title: 'Подчеркнутый' },
        },
        list: {
            options: ['unordered', 'ordered'],
            unordered: { icon: theme === 'dark' ? unorderedWhite : unordered, title: 'Неупорядоченный Список' },
            ordered: { icon: theme === 'dark' ? orderedWhite : ordered, title: 'Упорядоченный Список' },
        },
        link: {
            options: ['link', 'unlink'],
            link: { icon: theme === 'dark' ? linkWhite : link, title: 'Ссылка' },
            unlink: { icon: theme === 'dark' ? unlinkWhite : unlink, title: '' },
        },
        textAlign: {
            options: ['left', 'center', 'right'],
            left: { icon: theme === 'dark' ? alignLeftWhite : alignLeft },
            center: { icon: theme === 'dark' ? alignCenterWhite : alignCenter },
            right: { icon: theme === 'dark' ? alignRightWhite : alignRight },
        },
    }

    const editorStyle = {
        maxWidth: `calc(${width.current}px - 49.67px)`,
        padding: '0 16px',
        boxSizing: 'border-box',
        overflow: 'auto',
        maxHeight: '200px',
        minHeight: '7.938rem',
        resize: 'none',
        zIndex: -1,
    }

    return (
        <div className={css(mode(theme).formWrap)}>
            <div className={css(mode(theme).formHeader)}>
                <div className={cn('align-center gap-2', css(s.headTitle))}>
                    <MailSizeM />

                    <h3><FormattedMessage id="coverLetter" /></h3>
                </div>
            </div>

            <div ref={editorRef} className={css(s.formBody)}>
                <Field name="coverLetter">
                    {({ form, field }) => (
                        <Editor
                            id="formBody"
                            name="coverLetter"
                            placeholder={intl.formatMessage({ id: 'enter' })}
                            toolbar={toolbarOptions}
                            editorState={field.value}
                            editorStyle={editorStyle}
                            wrapperClassName={css(mode(theme).cont)}
                            toolbarClassName={css(mode(theme).toolbar)}
                            onEditorStateChange={(state) => form.setFieldValue(field.name, state)}
                        />
                    )}
                </Field>
            </div>
        </div>
    )
}

const s = StyleSheet.create({
    formBody: {
        padding: '16px 24px 24px 24px',
        display: 'flex',
        flexDirection: 'column',
        gap: 24,
        '@media (max-width: 768px)': {
            padding: 16,
            gap: 16,
        },
    },
    headTitle: {
        '@media (max-width: 768px)': {
            gap: 4,
        },
    },
})

const mode = (theme) => StyleSheet.create({
    cont: {
        border: theme === 'dark' ? 'none' : `1px solid ${COLORS.lightGray}`,
        background: theme === 'dark' ? 'rgba(255, 255, 255, 0.10)' : COLORS.white,
        borderRadius: 4,
        margin: 0,
        padding: 0,
    },
    toolbar: {
        backgroundColor: theme === 'dark' ? COLORS.itemDarkModeBg : COLORS.smoothGray,
        borderBottom: theme === 'dark' ? `1px solid ${COLORS.midGray}` : 'none',
        borderLeft: 'none',
        borderTop: 'none',
        borderRight: 'none',
        marginBottom: 0,
        padding: '6px 16px 0 16px',
        fontSize: 'unset',
        borderRadius: 'none',
        gap: 16,
        '@media (max-width: 768px)': {
            gap: 4,
            padding: '6px 8px',
        },
    },
    formWrap: {
        border: theme === 'dark' ? 'none' : `1px solid ${COLORS.smoothGray}`,
        background: theme === 'dark' ? COLORS.darkModeBg : COLORS.white,
        boxShadow: '0px 2px 4px rgba(130, 130, 130, 0.05)',
        borderRadius: 4,
    },
    formHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '14px 24px',
        fontWeight: '600',
        fontSize: 13,
        textTransform: 'uppercase',
        color: theme === 'dark' ? COLORS.white : COLORS.dark,
        borderBottom: `1px solid ${theme === 'dark' ? COLORS.lightBlack : COLORS.lightGray}`,
        borderRadius: '4px 4px 0px 0px',
        ':nth-child(1n) > :last-child': {
            cursor: 'pointer',
        },
        ':nth-child(1n) > :first-child': {
            ':nth-child(1n) > :first-child': {
                ':nth-child(1n) > path': {
                    stroke: theme === 'dark' ? COLORS.gray : '#333333',
                },
            },
        },
        '@media (max-width: 768px)': {
            padding: '14px 16px',
        },
    },
})
