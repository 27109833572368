import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { useLocation, useNavigate } from 'react-router-dom'
import Highlighter from 'react-highlight-words'
import { FormattedMessage } from 'react-intl'
import { DefaultLocation, VerifiedSizeL } from '../svgs/Svgs'
import { COLORS } from '../../utils/colors'

export default function CompaniesCardItem({ item, keyword, theme, lang }) {
    const navigate = useNavigate()
    const { pathname } = useLocation()

    return (
        <div
            id="card"
            className={css(mode(theme).cardWrap)}
            onClick={() => navigate(`/c/${item.slug}`, { state: { from: pathname } })}
        >
            <div className={cn(css(mode(theme).cardBodyWrap), 'is-flex direction-column gap-2')}>
                <div className={css(s.compInfo)}>
                    {item.logo && item.logo.length ? (
                        <div className={css(mode(theme).logoCont)}>
                            <img src={item.logo[1]} alt="#CompanyLogo" />
                        </div>
                    ) : (
                        <div className={css(mode(theme).defaultLogo)}>
                            <DefaultLocation color={COLORS.white} />
                        </div>
                    )}

                    <div className={css(s.comp_titles)}>
                        <div className="is-flex align-center gap-1">
                            <Highlighter
                            /* eslint-disable-next-line max-len */
                                textToHighlight={`${item.name.length > 18 ? `${item.name.substring(0, 18)}...` : item.name}` || ''}
                                searchWords={[keyword]}
                                className={css(mode(theme).name)}
                                highlightClassName="highlight-bg"
                            />

                            {item.verified ? <VerifiedSizeL /> : null}
                        </div>

                        <Highlighter
                            textToHighlight={`${item.industry[lang]}` || ''}
                            searchWords={[keyword]}
                            className={css(mode(theme).industry)}
                            highlightClassName="highlight-bg"
                        />
                    </div>
                </div>

                <div className={cn(css(s.vCount), 'is-flex align-center gap-1')}>
                    <span>{item.vacancyCount}</span>

                    <h3>
                        {item.vacancyCount === 1
                            ? <FormattedMessage id="vacancy" />
                            : <FormattedMessage id="vacancies" />}
                    </h3>
                </div>
            </div>
        </div>
    )
}

const s = StyleSheet.create({
    compInfo: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        gap: '12px',
        ':nth-child(1n) > img': {
            width: 40,
            height: 40,
        },
    },
    vCount: {
        textTransform: 'lowercase',
    },
    comp_titles: {
        display: 'flex',
        flexDirection: 'column',
        gap: '4px',
        '@media (max-width: 768px)': {
            gap: 2,
            fontSize: 13,
        },
    },
})

const mode = (theme) => StyleSheet.create({
    cardWrap: {
        maxHeight: '6rem',
        boxSizing: 'border-box',
        backgroundColor: theme === 'dark' ? COLORS.darkModeBg : COLORS.white,
        border: `1px solid ${theme === 'dark' ? COLORS.itemDarkModeBg : COLORS.smoothGray}`,
        boxShadow: '0px 2px 4px rgba(130, 130, 130, 0.05)',
        borderRadius: '4px',
        padding: 16,
        cursor: 'pointer',
        ':hover': theme === 'dark' ? 'none' : {
            border: `1px solid ${COLORS.lightGray}`,
            boxShadow: '0px 2px 8px rgba(136, 136, 136, 0.25)',
        },
        '@media (max-width: 768px)': {
            padding: 12,
        },
    },
    cardBodyWrap: {
        ':nth-child(1) > :last-child': {
            color: theme === 'dark' ? COLORS.gray : COLORS.lightBlack,
            fontSize: 14,
            fontWeight: '500',
        },
    },
    name: {
        fontWeight: 700,
        fontSize: 16,
        color: theme === 'dark' ? COLORS.white : COLORS.dark,
        '@media (max-width: 768px)': {
            fontSize: 13,
        },
    },
    industry: {
        fontWeight: 400,
        fontSize: 13,
        color: theme === 'dark' ? COLORS.lightGray : COLORS.gray,
    },
    defaultLogo: {
        width: 40,
        height: 40,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: COLORS.mainColor,
        border: theme === 'dark' ? 'none' : `1px solid ${COLORS.white}`,
        borderRadius: 4,
        boxSizing: 'border-box',
    },
    logoCont: {
        width: 40,
        height: 40,
        ':nth-child(1n) > img': {
            width: 40,
            height: 40,
            border: theme === 'dark' ? 'none' : `1px solid ${COLORS.white}`,
            borderRadius: 4,
        },
    },
})
