import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { useLocation } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { Briefcase, ChevronNextSizeL, DefaultLocationSizeS, WalletSizeM } from '../../svgs/Svgs'
import { COLORS } from '../../../utils/colors'
import { LoadingSkeleton } from '../../common/LoadingSkeleton'

export function OtherVacanciesSkeleton({ navigate, theme }) {
    const { state } = useLocation()

    return (
        <div className={css(s.leftWrap)}>
            <div className={css(mode(theme).chevronWrap)}>
                <ChevronNextSizeL onClick={() => navigate(-1)} />
            </div>

            <div className={css(mode(theme).contWrap)}>
                <h3>
                    {state && state.public
                        ? <FormattedMessage id="similarVacancies" />
                        : <FormattedMessage id="otherVacancies" />}
                </h3>

                <div className={css(s.cardsWrap)}>
                    <div className={css(mode(theme).card)}>
                        <div className="align-center gap-3">
                            <LoadingSkeleton width={32} height={32} />

                            <div>
                                <LoadingSkeleton height={15} />

                                <LoadingSkeleton height={13} />
                            </div>
                        </div>

                        <span><LoadingSkeleton height={10} width={93} /></span>
                    </div>

                    <div className={css(mode(theme).card)}>
                        <div className="align-center gap-3">
                            <LoadingSkeleton width={32} height={32} />

                            <div>
                                <LoadingSkeleton height={15} />

                                <LoadingSkeleton height={13} />
                            </div>
                        </div>

                        <span><LoadingSkeleton height={10} width={93} /></span>
                    </div>

                    <div className={css(mode(theme).card)}>
                        <div className="align-center gap-3">
                            <LoadingSkeleton width={32} height={32} />

                            <div>
                                <LoadingSkeleton height={15} />

                                <LoadingSkeleton height={13} />
                            </div>
                        </div>

                        <span><LoadingSkeleton height={10} width={93} /></span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default function VacancyInfoSkeleton({ path, navigate, theme }) {
    return (
        <div className={css(s.mainWrap)}>
            {!path.includes('shared') ? (
                <OtherVacanciesSkeleton theme={theme} navigate={navigate} />
            ) : (
                <div className={css(s.chevron)}>
                    <ChevronNextSizeL onClick={() => navigate(-1)} />
                </div>
            ) }
            <div className={css(mode(theme).infoWrap)}>
                <div className={css(mode(theme).contHeader)}>
                    <div className={css(s.flxBtw)}>
                        <div className={css(mode(theme).companyBrief)}>
                            <h3 className={css(mode(theme).vacancyName)}>
                                <ChevronNextSizeL onClick={() => navigate(-1)} />

                                <LoadingSkeleton height={28} />
                            </h3>

                            <div className={cn('align-center', css(s.companyName))}>
                                <div className={css(mode(theme).defaultLogo)}>
                                    <DefaultLocationSizeS color={COLORS.white} />
                                </div>

                                <LoadingSkeleton height={14} />
                            </div>
                        </div>

                        <div className={cn('align-center gap-5', css(s.btnGrp))}>
                            <LoadingSkeleton width={32} height={32} />

                            <LoadingSkeleton height={32} width={193.3} />
                        </div>
                    </div>
                </div>

                <div className={css(s.vacDescBody)}>
                    <div className={cn('align-center', css(s.items))}>
                        <div className={css(mode(theme).itemWrap)}>
                            <Briefcase />

                            <div className={css(mode(theme).info)}>
                                <h3><FormattedMessage id="experience" /></h3>

                                <LoadingSkeleton height={15} />
                            </div>
                        </div>

                        <div className={css(mode(theme).itemWrap)}>
                            <WalletSizeM />

                            <div className={css(mode(theme).info)}>
                                <h3><FormattedMessage id="salary" /></h3>

                                <LoadingSkeleton height={15} />
                            </div>
                        </div>
                    </div>

                    <div className={css(s.mobileBtn)}>
                        <LoadingSkeleton />
                    </div>
                </div>
            </div>
        </div>
    )
}

const s = StyleSheet.create({
    mainWrap: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        margin: '24px 32px 32px 32px',
        '@media (max-width: 1200px)': {
            flexDirection: 'column-reverse',
            gap: 16,
            margin: '16px 24px 24px 24px',
        },
        '@media (max-width: 768px)': {
            margin: '16px 0',
        },
    },
    companyName: {
        gap: 6,
        marginTop: 8,
    },
    vacDescBody: {
        maxHeight: 'calc(100vh - 255px)',
        overflowY: 'auto',
        transition: 'all 0.2s linear',
        scrollbarGutter: 'stable',
        padding: 24,
        display: 'flex',
        flexDirection: 'column',
        gap: 32,
        '-webkit-scrollbar': {
            display: 'none',
        },
        '@media (max-width: 768px)': {
            padding: 16,
            gap: 16,
        },
    },
    btnGrp: {
        '@media (max-width: 768px)': {
            display: 'none',
        },
    },
    shareBtn: {
        height: 32,
        background: COLORS.white,
        border: `1px solid ${COLORS.smoothGray}`,
        borderRadius: 4,
        padding: '0 6px',
        display: 'flex',
        alignItems: 'center',
        ':hover': {
            background: COLORS.smoothGray,
        },
    },
    mobileBtn: {
        width: '100%',
        '@media (min-width: 768px)': {
            display: 'none',
        },
        ':nth-child(1n)> *': {
            width: '100%',
        },
    },
    applyBtn: {
        height: 32,
        padding: '0 16px',
        background: `linear-gradient(180deg, ${COLORS.mainColor} 0%, ${COLORS.lightMainColor} 100%)`,
        // eslint-disable-next-line max-len
        boxShadow: '0px 4px 12px rgba(44, 67, 50, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.25), inset 0px 1px 0px rgba(255, 255, 255, 0.1)',
        borderRadius: 4,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 8,
        fontWeight: '500',
        fontSize: 13,
        color: COLORS.white,
        ':nth-child(1n) > :first-child': {
            ':nth-child(1n) > path': {
                stroke: COLORS.white,
            },
        },
        ':hover': {
            background: `linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
            linear-gradient(180deg, ${COLORS.mainColor} 0%, ${COLORS.lightMainColor} 100%)`,
            boxShadow: `0px 4px 12px rgba(44, 67, 50, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.25), '
            inset 0px 1px 0px rgba(255, 255, 255, 0.1)`,
            borderRadius: 4,
        },
    },
    flxBtw: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
    },
    items: {
        flexWrap: 'wrap',
        gap: 16,
        '@media (max-width: 768px)': {
            gap: 8,
        },
    },
    info: {
        display: 'flex',
        gap: 4,
    },
    contWrap: {
        flex: 1,
        background: COLORS.white,
        border: `1px solid ${COLORS.smoothGray}`,
        boxShadow: '0px 2px 4px rgba(130, 130, 130, 0.05)',
        borderRadius: 4,
        padding: 24,
        ':nth-child(1n) > :first-child': {
            fontWeight: '600',
            fontSize: 20,
            color: COLORS.dark,
        },
        '@media (max-width: 768px)': {
            padding: 12,
            borderRadius: 0,
        },
    },
    leftWrap: {
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        gap: 16,
        marginRight: 24,
        '@media (max-width: 1200px)': {
            width: '100%',
        },
    },
    img: {
        width: 40,
        height: 40,
    },
    cardsWrap: {
        marginTop: 16,
        display: 'flex',
        flexDirection: 'column',
        gap: 16,
        maxHeight: '22rem',
        overflowY: 'scroll',
        scrollbarWidth: 'none',
        '::-webkit-scrollbar': {
            display: 'none',
        },
        '-ms-overflow-style': {
            display: 'none',
        },
        '@media (max-width: 768px)': {
            marginTop: 12,
            gap: 12,
        },
    },
})

const mode = (theme) => StyleSheet.create({
    defaultLogo: {
        width: 16,
        height: 16,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: theme === 'dark' ? 'none' : `1px solid ${COLORS.white}`,
        background: COLORS.mainColor,
        borderRadius: 4,
        boxSizing: 'border-box',
    },
    infoWrap: {
        flex: 2.5,
        background: theme === 'dark' ? COLORS.darkModeBg : COLORS.white,
        border: `1px solid ${theme === 'dark' ? COLORS.itemDarkModeBg : COLORS.smoothGray}`,
        boxShadow: '0px 2px 4px rgba(130, 130, 130, 0.05)',
        borderRadius: 4,
        '@media (max-width: 1200px)': {
            borderRadius: 0,
            width: 'calc(100% - 2px)',
        },
        '@media (max-width: 768px)': {
            borderRadius: 0,
        },
    },
    logoCont: {
        width: 16,
        height: 16,
        border: theme === 'dark' ? 'none' : `1px solid ${COLORS.white}`,
        borderRadius: 2,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxSizing: 'border-box',
        ':nth-child(1n) > img': {
            width: 'inherit',
            borderRadius: 4,
        },
    },
    contHeader: {
        borderBottom: `1px solid ${theme === 'dark' ? COLORS.itemDarkModeBg : COLORS.lightGray}`,
        background: theme === 'dark' ? COLORS.itemDarkModeBg : 'inherit',
        padding: 24,
        '@media (max-width: 768px)': {
            padding: 0,
            borderBottom: 'none',
        },
    },
    vacancyName: {
        display: 'flex',
        alignItems: 'center',
        fontWeight: '600',
        fontSize: 24,
        color: theme === 'dark' ? COLORS.white : COLORS.dark,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        gap: 4,
        ':nth-child(1n) > :first-child': {
            cursor: 'pointer',
            width: 18,
            '@media (min-width: 1200px)': {
                display: 'none',
            },
            transform: 'rotate(180deg)',
            ':nth-child(1n) > path': {
                stroke: theme === 'dark' ? COLORS.white : COLORS.dark,
            },
        },
        '@media (max-width: 1200px)': {
            fontSize: 20,
        },
    },
    companyBrief: {
        '@media (max-width: 768px)': {
            borderBottom: `1px solid ${theme === 'dark' ? COLORS.itemDarkModeBg : COLORS.lightGray}`,
            padding: '12px 16px 7px 16px',
            width: '100%',
        },
    },
    shareBtn: {
        height: 32,
        background: COLORS.white,
        border: `1px solid ${COLORS.smoothGray}`,
        borderRadius: 4,
        padding: '0 6px',
        display: 'flex',
        alignItems: 'center',
        ':hover': {
            background: COLORS.smoothGray,
        },
    },
    itemWrap: {
        display: 'flex',
        alignItems: 'center',
        padding: '6px 10px',
        gap: 10,
        ':nth-child(1n) > :first-child': {
            ':nth-child(1n) > path': {
                stroke: COLORS.mainColor,
            },
            '@media (max-width: 768px)': {
                width: 24,
            },
        },
        background: theme === 'dark' ? COLORS.itemDarkModeBg : COLORS.white,
        border: `1px solid ${theme === 'dark' ? COLORS.itemDarkModeBg : COLORS.smoothGray}`,
        boxShadow: '0px 2px 4px rgba(130, 130, 130, 0.05)',
        borderRadius: 4,
        '@media (max-width: 768px)': {
            padding: '4px 8px',
            gap: 4,
        },
    },
    info: {
        display: 'flex',
        gap: 4,
        flexDirection: 'column',
        ':nth-child(1n) > h3': {
            fontWeight: '500',
            fontSize: 12,
            color: COLORS.gray,
        },
        ':nth-child(1n) > p': {
            fontWeight: '600',
            fontSize: 13,
            color: theme === 'dark' ? COLORS.white : COLORS.dark,
            '@media (max-width: 768px)': {
                fontSize: 12,
            },
        },
    },
    contWrap: {
        flex: 1,
        background: theme === 'dark' ? COLORS.darkModeBg : COLORS.white,
        border: `1px solid ${theme === 'dark' ? COLORS.itemDarkModeBg : COLORS.smoothGray}`,
        boxShadow: '0px 2px 4px rgba(130, 130, 130, 0.05)',
        borderRadius: 4,
        padding: 24,
        ':nth-child(1n) > h3': {
            fontWeight: '600',
            fontSize: 20,
            color: theme === 'dark' ? COLORS.white : COLORS.dark,
        },
        '@media (max-width: 768px)': {
            padding: 12,
            borderRadius: 0,
        },
    },
    card: {
        background: theme === 'dark' ? COLORS.itemDarkModeBg : COLORS.white,
        border: `1px solid ${theme === 'dark' ? COLORS.itemDarkModeBg : COLORS.smoothGray}`,
        boxShadow: '0px 2px 4px rgba(130, 130, 130, 0.05)',
        borderRadius: 4,
        padding: '16px 12px',
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'space-between',
        gap: 16,
    },
    chevronWrap: {
        ':nth-child(1n) > :first-child': {
            transform: 'rotate(180deg)',
            ':nth-child(1n) > path': {
                stroke: theme === 'dark' ? COLORS.white : COLORS.dark,
            },
        },
        background: theme === 'dark' ? 'rgba(0, 0, 0, 0.10)' : COLORS.white,
        border: `1px solid ${theme === 'dark' ? 'rgba(255, 255, 255, 0.20)' : COLORS.smoothGray}`,
        boxShadow: theme === 'dark' ? '0px 2px 12px 0px rgba(0, 0, 0, 0.20)' : 'none',
        backdropFilter: theme === 'dark' ? 'blur(6px)' : 'none',
        borderRadius: 64,
        width: 36,
        height: 36,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        '@media (max-width: 1200px)': {
            display: 'none',
        },
    },
})
