import React from 'react'
import cn from 'classnames'
import { css, StyleSheet } from 'aphrodite'
import { isEmpty } from 'lodash'
import { FormattedMessage } from 'react-intl'
import { ChevronPrev } from '../../svgs/Svgs'
import { COLORS } from '../../../utils/colors'
// import SearchInput from '../common/SearchInput'
import Checkbox from '../../common/Checkbox'

export default function FullSection({
    setFullSection,
    section,
    setFilter,
    filter,
    name,
    theme,
}) {
    function onCheck(id) {
        if (filter[section.name]) {
            if (filter[section.name].includes(id)) {
                if (filter[name].length > 1) {
                    setFilter({
                        ...filter,
                        [name]: filter[name].filter((fil) => fil !== id),
                    })
                } else {
                    const {
                        [name]: sectionFilter,
                        ...clearedFilter
                    } = filter
                    setFilter(clearedFilter)
                }
            } else {
                setFilter({ ...filter, [name]: [...filter[name], id] })
            }
        } else setFilter({ ...filter, [name]: [id] })
    }

    const isChecked = (id) => filter[name] && filter[name].includes(id)

    const emptyChecker = typeof filter[section.name] === 'number'
        ? filter[section.name] : !isEmpty(filter[section.name])

    const strChecker = filter[section.name] === ':'

    return (
        <div className="is-flex direction-column full-height full-width">
            <div className={cn('is-flex direction-column gap-3', css(mode(theme).topic))}>
                <div className="align-center gap-1">
                    <ChevronPrev
                        pointer
                        color={theme === 'dark' ? COLORS.white : COLORS.dark}
                        onClick={() => setFullSection(false)} />

                    <h3 className={css(mode(theme).title)}>
                        {section.title}

                        {emptyChecker && !strChecker && (
                            <span
                                onClick={() => {
                                    const {
                                        [section.name]: sectionFilter,
                                        ...clearedFilter
                                    } = filter
                                    setFilter(clearedFilter)
                                }}
                                className="dis_sl"><FormattedMessage id="clear" />
                            </span>
                        )}
                    </h3>
                </div>

                {/* <SearchInput */}
                {/*     className={css(s.searchInp)} */}
                {/*     placeholder="Найти отдел" */}
                {/*     // onChange={(e) => onChange(e.target.value)} */}
                {/* /> */}
            </div>

            <div className={css(s.cont)}>
                <ul className="no_dot_list is-flex direction-column gap-2">
                    {section.options.map((option) => (
                        <li
                            key={option.value}
                            className="justify-between pointer"
                            onClick={() => onCheck(option.value)}>
                            <div className="align-center gap-2">
                                <Checkbox
                                    className={css(mode(theme).checkBox)}
                                    checked={isChecked(option.value)}
                                    onClick={() => {}}
                                />

                                <h3 className={css(mode(theme).label,
                                    !isChecked(option.value) && mode(theme).labelColor)}>
                                    {option.label}
                                </h3>
                            </div>

                            {/* <span className={cn( */}
                            {/*     'flex-cent', css(s.count, !isChecked(option.id) && s.countColor), */}
                            {/* )}> */}
                            {/*     {option.companyCount} */}
                            {/* </span> */}
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    )
}

const s = StyleSheet.create({
    cont: {
        padding: '12px 10px 12px 16px',
        height: 'calc(100% - 41px)',
        boxSizing: 'border-box',
        overflowY: 'auto',
    },
    searchInp: {
        width: '100%',
        height: 24,
        background: '#FAFAFA',
        border: '1px solid rgba(0, 0, 0, 0.06)',
        padding: '0 8px',
        ':nth-child(1n) > input': {
            fontSize: 11,
            paddingTop: 3,
            fontWeight: '400',
            '::placeholder': {
                color: COLORS.midGray,
            },
            color: COLORS.dark,
        },
        ':hover': {
            border: '1px solid rgba(0, 0, 0, 0.06)',
            boxShadow: 'none',
        },
    },
    count: {
        border: '1px solid #2EA97D33',
        borderRadius: 4,
        background: '#FAFFFC',
        color: COLORS.mainColor,
        fontSize: 10,
        fontWeight: '500',
        height: 16,
        padding: '0 4px',
    },
    countColor: {
        color: COLORS.midGray,
        background: COLORS.smoothGray,
        border: `1px solid ${COLORS.smoothGray}`,
    },
})

const mode = (theme) => StyleSheet.create({
    title: {
        fontWeight: '600',
        fontSize: 14,
        whiteSpace: 'nowrap',
        color: theme === 'dark' ? COLORS.white : COLORS.dark,
        ':nth-child(1n) > span': {
            cursor: 'pointer',
            fontSize: 10,
            marginLeft: 4,
            textTransform: 'uppercase',
            color: COLORS.skyblue,
            ':hover': {
                color: `${COLORS.skyblue}CC`,
            },
        },
    },
    label: {
        fontSize: 11,
        fontWeight: '500',
        color: theme === 'dark' ? COLORS.lightGray : COLORS.lightBlack,
    },
    labelColor: {
        color: COLORS.gray,
    },
    checkBox: {
        borderColor: theme === 'dark' ? COLORS.gray : '#E8E8E8',
    },
    topic: {
        padding: '16px 16px 12px',
        borderBottom: `1px solid ${theme === 'dark' ? COLORS.gray : COLORS.smoothGray}`,
    },
})
