import React, { useContext } from 'react'
import Skeleton from 'react-loading-skeleton'
import { BaseContext } from '../../contexts/BaseContextWrapper'

export function LoadingSkeleton({ width = 120, height = 23, borderRadius = 4, inline }) {
    const { theme } = useContext(BaseContext)

    return (
        <Skeleton
            inline={inline}
            duration={1}
            baseColor="#DBF0E9"
            className="align-center"
            containerClassName="align-center 11"
            highlightColor="#FFFFFF"
            width={width}
            height={height}
            style={{ opacity: theme === 'dark' ? 0.3 : 1 }}
            borderRadius={borderRadius} />
    )
}
