import React, { useCallback, useContext, useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { useNavigate, useParams } from 'react-router-dom'
import { debounce, range } from 'lodash'
import { FormattedMessage, useIntl } from 'react-intl'
import { Layout } from '../common/Layout'
import { ChevronPrevSizeXL, DefaultLocation } from '../svgs/Svgs'
import { COLORS } from '../../utils/colors'
import CompaniesCardItem from './CompaniesCardItem'
import SearchInput from '../common/SearchInput'
import { Context } from '../../contexts/GlobalContext'
import { useInfiniteScroll } from '../../hooks/request'
import { PUBLIC_COMPANIES_LIST } from '../../urls'
import noCompanies from '../../static/images/NoCompanies.png'
import { useQueryParams } from '../../hooks/queryString'
import { LoadingSkeleton } from '../common/LoadingSkeleton'
import { BaseContext } from '../../contexts/BaseContextWrapper'

export default function Companies() {
    const { search } = useQueryParams()
    const [searchKey, setSearchKey] = useState(search || '')
    const navigate = useNavigate()
    const { id } = useParams()
    const intl = useIntl()
    const { matchedIndustries } = useContext(Context)
    const { theme, lang } = useContext(BaseContext)
    const getCompanies = useInfiniteScroll({ url: PUBLIC_COMPANIES_LIST, params: { search, industry: id } }, [search])
    const companies = getCompanies.response ? getCompanies.response.data : []
    const title = id && matchedIndustries.length
        ? matchedIndustries.filter((item) => item.value === Number(id))[0].label
        : <FormattedMessage id="companiesList" />
    const emptyTitle = id ? 'В этой отрасле работы пока нет компаний' : 'Компании ещё не зарегестрировались в системе'

    const sendRequest = useCallback(
        debounce((val) => {
            if (!getCompanies.loading || companies.length) {
                navigate(`?search=${val}`)
                getCompanies.reset()
            }
        }, 500),
        [],
    )

    const onChangeInput = useCallback((value) => {
        setSearchKey(value)
        sendRequest(value)
    }, [])

    return (
        <Layout>
            <div className={css(s.mainWrap)}>
                <div className={cn(css(s.wrapper), 'is-flex direction-column')}>
                    <div className={cn(css(mode(theme).contHeader), 'is-flex align-center justify-between')}>
                        <div
                            onClick={() => navigate('/')}
                            className={cn(css(mode(theme).chevronWrap), 'is-flex align-center gap-2')}
                        >
                            <ChevronPrevSizeXL />

                            <h3>{title} <span>•</span> {companies.length}</h3>
                        </div>

                        <SearchInput
                            value={searchKey}
                            onChange={(value) => onChangeInput(value)}
                            placeholder={intl.formatMessage({ id: 'vacancySearchInputPlaceholder' })}
                            className={css(mode(theme).searchInp)}
                            magnifierColor={theme === 'dark' ? COLORS.gray : COLORS.lightBlack}
                        />
                    </div>

                    {!getCompanies.loading ? (
                        <div className={css(s.itemsWrap)}>
                            {companies.map((item) => (
                                <CompaniesCardItem
                                    lang={lang}
                                    item={item}
                                    theme={theme}
                                    key={item.id}
                                    keyword={searchKey}
                                />
                            ))}

                            {!getCompanies.loading && getCompanies.hasMore && <div ref={getCompanies.ref} />}
                        </div>
                    ) : (
                        <div className={css(s.itemsWrap)}>
                            {range(0, 5).map((i) => (
                                <div key={i} className={css(mode(theme).cardWrap)}>
                                    <div className={cn(css(s.cardBodyWrap), 'is-flex direction-column gap-2')}>
                                        <div className={css(s.compInfo)}>
                                            <div className={css(mode(theme).defaultLogo)}>
                                                <DefaultLocation color={COLORS.white} />
                                            </div>

                                            <div className={css(s.comp_titles)}>
                                                <LoadingSkeleton height={21} />

                                                <LoadingSkeleton height={15} />
                                            </div>
                                        </div>

                                        <LoadingSkeleton height={16} />
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}

                    {!companies.length && !getCompanies.loading ? (
                        <div className={cn(css(s.emptyCont), 'is-flex direction-column align-center justify-center')}>
                            <img src={noCompanies} alt="#No Companies" />

                            <h3>{emptyTitle}</h3>
                        </div>
                    ) : null}
                </div>
            </div>
        </Layout>
    )
}

const s = StyleSheet.create({
    mainWrap: {
        padding: '0 32px',
        '@media (max-width: 1024px)': {
            padding: '0 16px',
        },
    },
    wrapper: {
        gap: 30,
        '@media (max-width: 1024px)': {
            gap: 12,
        },
    },
    itemsWrap: {
        overflowY: 'scroll',
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(16rem, 1fr))',
        gap: 24,
        '@media (max-width: 1024px)': {
            gap: 12,
        },
        scrollbarWidth: 'none', // Firefox
        '-ms-overflow-style': 'none', // Internet Explorer
        '::-webkit-scrollbar': {
            display: 'none', // Safari and Chrome
        },
    },
    emptyCont: {
        width: '100%',
        padding: 32,
        ':nth-child(1n) > :first-child': {
            width: 410,
            height: 244,
        },
        ':nth-child(1n) > :last-child': {
            color: COLORS.midGray,
            fontSize: 13,
            fontWeight: '600,',
        },
        '@media (max-width: 768px)': {
            padding: '32px 0 0 0',
            ':nth-child(1n) > :first-child': {
                width: 'inherit',
                height: 244,
            },
        },
    },
    cardBodyWrap: {
        ':nth-child(1) > :last-child': {
            color: COLORS.lightBlack,
            fontSize: 14,
            fontWeight: '500',
        },
    },
    compInfo: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        gap: '12px',
        ':nth-child(1n) > img': {
            width: 40,
            height: 40,
        },
    },
    comp_titles: {
        display: 'flex',
        flexDirection: 'column',
        gap: '4px',
        '@media (max-width: 768px)': {
            gap: 2,
            fontSize: 13,
        },
    },
    name: {
        fontWeight: 700,
        fontSize: 16,
        color: COLORS.dark,
        '@media (max-width: 768px)': {
            fontSize: 13,
        },
    },
    industry: {
        fontWeight: 400,
        fontSize: 13,
        color: COLORS.gray,
    },
})

const mode = (theme) => StyleSheet.create({
    contHeader: {
        position: 'sticky',
        top: 0,
        backgroundColor: theme === 'dark' ? '#242424' : '#f6f6f6',
        zIndex: 1,
        padding: '24px 0 0 0',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        '@media (max-width: 1024px)': {
            flexDirection: 'column',
            alignItems: 'start',
            gap: 12,
            padding: '16px 0 0 0',
        },
    },
    chevronWrap: {
        color: theme === 'dark' ? COLORS.white : COLORS.dark,
        fontSize: 24,
        fontWeight: '600',
        cursor: 'pointer',
        '@media (max-width: 1024px)': {
            fontSize: 16,
        },
        ':nth-child(1n) > :first-child': {
            ':nth-child(1n) > path': {
                stroke: theme === 'dark' ? COLORS.white : COLORS.dark,
            },
        },
    },
    searchInp: {
        height: 40,
        width: '20.15rem',
        background: theme === 'dark' ? COLORS.darkModeBg : 'unset',
        border: `1px solid ${theme === 'dark' ? COLORS.itemDarkModeBg : COLORS.lightGray}`,
        '@media (max-width: 1024px)': {
            width: '100%',
        },
        ':nth-child(1n) > input': {
            color: theme === 'dark' ? COLORS.lightGray : COLORS.midGray,
            fontSize: 13,
            fontWeight: '400',
        },
        ':nth-child(1n) > :last-child': {
            ':nth-child(1n) > path': {
                fill: theme === 'dark' ? COLORS.gray : COLORS.lightBlack,
            },
        },
    },
    cardWrap: {
        maxHeight: '6rem',
        boxSizing: 'border-box',
        backgroundColor: theme === 'dark' ? COLORS.itemDarkModeBg : COLORS.white,
        border: theme === 'dark' ? 'none' : `1px solid ${COLORS.smoothGray}`,
        boxShadow: '0px 2px 4px rgba(130, 130, 130, 0.05)',
        borderRadius: '4px',
        padding: 16,
        cursor: 'pointer',
        ':hover': {
            border: `1px solid ${COLORS.lightGray}`,
            boxShadow: '0px 2px 8px rgba(136, 136, 136, 0.25)',
        },
        '@media (max-width: 768px)': {
            padding: 12,
        },
    },
    defaultLogo: {
        width: 40,
        height: 40,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: COLORS.mainColor,
        border: theme === 'dark' ? 'none' : `1px solid ${COLORS.white}`,
        borderRadius: 4,
        boxSizing: 'border-box',
    },
})
