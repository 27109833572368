import React, { useEffect, useState } from 'react'
import { Formik } from 'formik'
import axios from 'axios'
import { FormattedMessage } from 'react-intl'
import { useMessage } from '../../hooks/message'
import { useDeleteRequest, usePostRequest } from '../../hooks/request'
import { CANDIDATE_PHOTO,
    CANDIDATE_UPDATE_PHONE,
    CANDIDATE_UPDATE_PHONE_CONFIRM,
    DELETE_CANDIDATE_PHOTO } from '../../urls'
import { domain } from '../../utils/request'
import { deFormatPhone, format, formatPhone, integersOnly } from '../../utils/number'
import { auth } from '../../utils/auth'
import { MainInfoModalForm } from './MainInfoModalForm'

export default function UpdateMainInfoModal({
    onClose,
    putRequest,
    info,
    getInfo,
}) {
    const [showMessage] = useMessage()
    const MAX_FILE_SIZE = 3145728
    const [file, setFile] = useState()
    const [isSuccess, setIsSuccess] = useState({ phone: false, code: false })
    const [img, setImg] = useState('')
    const allowedFormats = ['jpg', 'jpeg', 'png']
    const deletePhoto = useDeleteRequest({ url: DELETE_CANDIDATE_PHOTO })
    const updatePhone = usePostRequest({ url: CANDIDATE_UPDATE_PHONE })
    const verifyPhone = usePostRequest({ url: CANDIDATE_UPDATE_PHONE_CONFIRM })
    const [imageUploadLoading, setImageUploadLoading] = useState(false)

    useEffect(() => {
        if (info.photo) setImg(info.photo[2])
    }, [info])

    const initialValues = {
        firstName: info.firstName || '',
        lastName: info.lastName || '',
        email: info.email || '',
        phone: info.phoneNumber.includes('+998')
            ? formatPhone(info.phoneNumber.slice(4, info.phoneNumber.length), true)
            : formatPhone(info.phoneNumber, true),
        address: info.location ? info.location : '',
        birth_date: info.birthDate || '',
        gender: info.gender,
        remote: info.remote,
        desiredSalary: format(integersOnly(info.desiredSalary)),
    }

    const onChange = async (e) => {
        if (e.target.files[0]) {
            const currentFile = e.target.files[0].name.split('.')
            const fileExtension = currentFile[currentFile.length - 1]
            if (allowedFormats.includes(fileExtension)) {
                if (e.target.files[0].size <= MAX_FILE_SIZE) {
                    setImg(URL.createObjectURL(e.target.files[0]))
                    setFile(e.target.files[0])
                } else {
                    showMessage(<FormattedMessage id="largeFile" />, 'error-msg')
                }
            } else {
                showMessage(<FormattedMessage id="currentTypeIsNotSupported" />, 'error-msg')
            }
        }
    }

    const onSubmit = async (values) => {
        if (putRequest.loading || imageUploadLoading || deletePhoto.loading) return
        let imageUploadSuccess = false
        let infoUpdateSuccess = false
        let imageUploadError = null
        let infoUpdateError = null

        if (img === '') deletePhoto.request()

        if (typeof file === 'object' && file) {
            setImageUploadLoading(true)
            const formData = new FormData()
            formData.append('file', file)
            try {
                const response = await axios.post(domain + CANDIDATE_PHOTO, formData, auth())
                if (response.data.success) {
                    imageUploadSuccess = true
                }
            } catch (error) {
                imageUploadError = error.response
                    ? error.response.data.errors[0].message
                    || error.response.data.message : 'Image upload failed'
            }
            setImageUploadLoading(false)
        }

        if (values) {
            const newData = {
                first_name: values.firstName,
                last_name: values.lastName,
                email: values.email,
                location: values.address,
                birthday: values.birth_date,
                gender: values.gender || 'not_set',
                remote: values.remote,
                desired_salary: values.desiredSalary ? values.desiredSalary.replace(/\s/g, '') : null,
                // currency: values.currency,
            }

            const { success, error } = await putRequest.request({ data: newData })

            if (success) {
                getInfo.setResponse((prevState) => ({
                    ...getInfo.response,
                    data: {
                        ...prevState.data,
                        ...newData,
                        emailConfirmed: values.email === info.email,
                    },
                }))
                infoUpdateSuccess = true
            } else {
                infoUpdateError = error.data.errors[0].message || error.data.message
            }
        }

        if (imageUploadSuccess && infoUpdateSuccess) {
            showMessage(<FormattedMessage id="successFullyUpdated" />, 'success-msg')
        } else {
            if (imageUploadError) {
                showMessage(imageUploadError, 'error-msg')
            }
            if (infoUpdateError) {
                showMessage(infoUpdateError, 'error-msg')
            }
        }

        onClose()
    }

    const updatePhoneNumber = async (value) => {
        if (updatePhone.loading || !value) return

        const { success, error } = await updatePhone.request({ data: {
            phone: `+998${deFormatPhone(value)}`,
            client_id: process.env.REACT_APP_CLIENT_ID || '',
            client_secret: process.env.REACT_APP_CLIENT_SECRET || '',
        } })

        if (success) {
            setIsSuccess((prev) => ({ ...prev, phone: true }))
            showMessage(<FormattedMessage id="confirmationCodeSent" />, 'success-msg')
            return
        }

        showMessage(error.data.message || error.data.errors[0].message, 'error-msg')
    }

    const verifyPhoneNumber = async (value, code) => {
        if (verifyPhone.loading || !value || !code) return

        const { error, success } = await verifyPhone.request({ data: {
            phone: `+998${deFormatPhone(value)}`,
            code,
        } })

        if (success) {
            getInfo.setResponse((prevState) => ({
                ...getInfo.response,
                data: {
                    ...prevState.data,
                    phoneNumber: `+998${deFormatPhone(value)}`,
                },
            }))
            showMessage(<FormattedMessage id="successFullyUpdated" />, 'success-msg')
            setIsSuccess((prev) => ({ ...prev, code: true }))
            return
        }

        showMessage(error.data.message || error.data.errors[0].message, 'error-msg')
    }

    return (
        <Formik
            enableReinitialize
            initialValues={{
                firstName: '',
                lastName: '',
                phone: '',
                email: '',
                verify_code: '',
                birth_date: '',
                gender: info.gender,
                address: '',
                desiredSalary: '',
                currency: 'UZS',
                remote: false,
                ...initialValues,
            }} onSubmit={onSubmit}>
            {({
                values,
                setFieldValue,
                errors,
                handleChange,
            }) => (
                <MainInfoModalForm
                    img={img}
                    info={info}
                    errors={errors}
                    values={values}
                    setImg={setImg}
                    onClose={onClose}
                    onChange={onChange}
                    isSuccess={isSuccess}
                    putRequest={putRequest}
                    updatePhone={updatePhone}
                    deletePhoto={deletePhoto}
                    verifyPhone={verifyPhone}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                    updatePhoneNumber={updatePhoneNumber}
                    verifyPhoneNumber={verifyPhoneNumber}
                    imageUploadLoading={imageUploadLoading}
                />
            )}
        </Formik>
    )
}
