import React, { useContext, useEffect, useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import { useParams } from 'react-router-dom'
import { useInfiniteScroll, useLoad } from '../hooks/request'
import { PUBLIC_COMPANY_INFO, PUBLIC_COMPANY_VACANCIES } from '../urls'
import { Layout } from '../components/common/Layout'
import CompanyNotFound from '../components/company/CompanyNotFound'
import CompanyBanner from '../components/company/CompanyBanner'
import CompanyVacancies from '../components/company/vacancies/CompanyVacancies'
import { BaseContext } from '../contexts/BaseContextWrapper'

export default function CompanyPage() {
    const { cSlug } = useParams()
    const [loading, setLoading] = useState(true)
    const loadInfo = useLoad({ url: PUBLIC_COMPANY_INFO.replace('{slug}', cSlug) })
    const info = loadInfo.response ? loadInfo.response.data : {}
    const loadVacancies = useInfiniteScroll({ url: PUBLIC_COMPANY_VACANCIES.replace('{slug}', cSlug),
        params: { 'per-page': 20 } }, [])
    const vacancies = loadVacancies.response ? loadVacancies.response.data : []
    const { theme } = useContext(BaseContext)

    useEffect(() => {
        if (!loadInfo.loading && !loadVacancies.loading) {
            setLoading(false)
        }
    }, [loadInfo.loading, loadVacancies.loading])

    return (
        <Layout>
            <div className={css(s.cont)}>
                {loadInfo.loading || Object.values(info).length ? (
                    <div id="section" className={css(s.section)}>
                        <CompanyBanner loading={loading} info={info} />
                    </div>
                ) : null}

                <CompanyVacancies
                    slug={cSlug}
                    info={info}
                    theme={theme}
                    vacancies={vacancies}
                    loadVacancies={loadVacancies}
                />

                {!Object.values(info).length && !vacancies.length && !loading ? (
                    <CompanyNotFound theme={theme} />
                ) : null}
            </div>
        </Layout>
    )
}

const s = StyleSheet.create({
    cont: {
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: 32,
        '@media (max-width: 768px)': {
            paddingBottom: 16,
        },
    },
    section: {
        display: 'flex',
        flexDirection: 'column',
    },
    companyHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: '22px 32px 0 32px',
        '@media (max-width: 1024px)': {
            margin: '16px 16px 0 16px',
        },
        '@media (max-width: 768px)': {
            margin: 0,
        },
    },
    title: {
        fontWeight: '600',
        fontSize: 24,
        color: '#181A19',
        '@media (max-width: 768px)': {
            display: 'none',
        },
    },
})
