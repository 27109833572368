import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import { FormattedMessage } from 'react-intl'
import { COLORS } from '../../utils/colors'
import { CloseSidebar } from '../svgs/Svgs'

export default function RejectInfoModal({ onClose, status, showModal, theme }) {
    return (
        <div>
            <div className={css(s.modalWrap)}>
                <div className={css(mode(theme).modalHeader)}>
                    <div className="is-flex align-center gap-2">
                        <h3>{status[showModal.st - 1].status}</h3>

                        <h3>{showModal.item && showModal.item.company.name}</h3>
                    </div>

                    <CloseSidebar color={theme === 'dark' ? COLORS.white : COLORS.dark} onClick={onClose} />
                </div>

                <div className={css(mode(theme).modalBody)}>
                    {showModal.item.rejectReason
                        ? <p>{showModal.item.rejectReason}</p>
                        : (
                            <div className="is-flex direction-column gap-7">
                                <p className={css(s.statusTxt)}>
                                    <FormattedMessage id="rejectStatusText" />
                                </p>

                                {/* <p> */}
                                {/*     Добрый день, {info.firstName} {info.lastName}! */}
                                {/*     Готовы вернуться к вам с обратной связью.
                                 К сожалению, мы не готовы пригласить вас */}
                                {/*     на вакансию {showModal.item.vacancy.name} в нашей компании. */}
                                {/*     Будем рады оставаться на связи и предлагать
                                вам в будущем другие возможные вакансии. */}
                                {/*     В любом случае я благодарю вас за уделенное время, */}
                                {/*     нам было приятно с вами познакомиться! */}
                                {/*     Желаю удачи и успехов в поиске нового места работы 🙂 */}
                                {/*     С уважением, компания {showModal.item.company.name}, */}
                                {/*     отдел подбора и адаптации персонала */}
                                {/* </p> */}
                            </div>
                        )}
                </div>
            </div>
        </div>
    )
}

const s = StyleSheet.create({
    modalWrap: {
        width: '37.25rem',
        borderRadius: '4px 4px 0px 0px',
        '@media (max-width: 768px)': {
            width: '90vw',
        },
    },
    statusTxt: {
        color: COLORS.gray,
        fontSize: 14,
        fontStyle: 'italic',
        fontWeight: '500',
    },
})

const mode = (theme) => StyleSheet.create({
    modalHeader: {
        padding: '20px 24px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        background: theme === 'dark' ? COLORS.itemDarkModeBg : COLORS.white,
        borderBottom: `1px solid ${theme === 'dark' ? COLORS.lightBlack : COLORS.lightGray}`,
        borderRadius: '4px 4px 0px 0px',
        ':nth-child(1n) > :first-child': {
            fontWeight: '600',
            fontSize: 20,
            color: theme === 'dark' ? COLORS.smoothGray : COLORS.dark,
        },
        ':nth-child(1n) > :last-child': {
            cursor: 'pointer',
        },
        '@media (max-width: 768px)': {
            padding: '14px 16px',
        },
    },
    modalBody: {
        padding: 24,
        background: theme === 'dark' ? COLORS.darkModeBg : COLORS.white,
        '@media (max-width: 768px)': {
            padding: 16,
        },
        color: theme === 'dark' ? COLORS.smoothGray : COLORS.dark,
        fontSize: 14,
        fontWeight: '400',
    },
})
