import React, { forwardRef, useImperativeHandle, useState } from 'react'
import cn from 'classnames'
import { css, StyleSheet } from 'aphrodite'
import { Oval } from 'react-loader-spinner'
import { FormattedMessage } from 'react-intl'
import { Exit } from '../svgs/Svgs'
import Modal from '../common/Modal'
import { COLORS } from '../../utils/colors'
import { useMessage } from '../../hooks/message'
import { usePostRequest } from '../../hooks/request'
import { PROFILE_LOGOUT } from '../../urls'

export const ExitModal = forwardRef(({ onlyModal = false }, ref) => {
    const [showModal, setShowModal] = useState(false)
    const [loading, setLoading] = useState(false)
    const [showMessage] = useMessage()
    const logout = usePostRequest({ url: PROFILE_LOGOUT })

    useImperativeHandle(ref, () => ({
        onOpenModal() { setShowModal(true) },
    }), [])

    async function onLogout() {
        const { success } = await logout.request()

        if (success) showMessage(<FormattedMessage id="youLoggedOut" />, 'success-msg')
        setLoading(true)
        localStorage.clear()
        // eslint-disable-next-line no-restricted-globals
        window.location.pathname = '/'
    }

    return (
        <React.Fragment>
            {!onlyModal && (
                <button
                    onClick={() => setShowModal(true)}
                    className={cn('flex-cent', css(s.exitBtn))}>
                    <Exit /> <FormattedMessage id="logout" />
                </button>
            )}

            <Modal
                open={showModal}
                onClose={() => setShowModal(false)}
                closeOnDocumentClick={!logout.loading}
                closeOnEscape>
                <div className={cn('is-flex direction-column', css(s.exitModal))}>
                    <React.Fragment>
                        {!logout.loading && !loading ? (
                            <div>
                                <div className={cn(css(s.modalTxt), css(s.extModal))}>
                                    <h3><FormattedMessage id="areYouSureToLogOut" /></h3>
                                </div>

                                <div className={cn('justify-end', css(s.btnGr))}>
                                    <button
                                        className={css(s.cancelBtn)}
                                        onClick={() => setShowModal(false)}>
                                        <FormattedMessage id="cancel" />
                                    </button>

                                    <button
                                        onClick={onLogout}
                                        className={cn('flex-cent', css(s.exitBtn))}>
                                        <Exit /> <FormattedMessage id="logout" />
                                    </button>
                                </div>
                            </div>
                        ) : (
                            <div className="is-flex align-center gap-7">
                                <h3><FormattedMessage id="loggingOut" /></h3>

                                <Oval
                                    height={24}
                                    width={24}
                                    color={COLORS.mainColor}
                                    visible
                                    ariaLabel="oval-loading"
                                    secondaryColor={COLORS.lightMainColor}
                                    strokeWidth={10}
                                    strokeWidthSecondary={10}

                                />
                            </div>
                        )}
                    </React.Fragment>
                </div>
            </Modal>
        </React.Fragment>
    )
})

const s = StyleSheet.create({
    exitBtn: {
        cursor: 'pointer',
        background: '#FFE1E7',
        border: '1px solid #FFD1DA',
        borderRadius: 4,
        color: COLORS.lightRed,
        padding: '8px 0',
        width: '8.5rem',
        gap: 8,
        outline: 'none',
        ':hover': {
            border: `1px solid ${COLORS.lightRed}`,
            boxShadow: '0px 2px 4px rgba(168, 168, 168, 0.15)',
        },
        ':first-of-type': {
            width: '11.75rem',
        },
    },
    exitModal: {
        background: COLORS.white,
        padding: '20px 24px',
        borderRadius: 4,
        '@media (max-width: 768px)': {
            margin: 16,
            padding: 16,
        },
    },
    modalTxt: {
        color: COLORS.lightBlack,
        fontSize: 20,
        fontWeight: '500',
        paddingBottom: 20,
        marginBottom: 20,
        borderBottom: `1px solid ${COLORS.lightGray}`,
    },
    btnGr: {
        gap: 16,
    },
    cancelBtn: {
        fontWeight: '500',
        fontSize: 14,
        color: COLORS.gray,
        padding: '11px 16px',
        border: `1px solid ${COLORS.gray}`,
        borderRadius: 4,
        background: 'transparent',
        cursor: 'pointer',
        outline: 'none',
    },
    extModal: {
        paddingRight: '8.25rem',
        '@media (max-width: 768px)': {
            paddingRight: 0,
        },
    },
})
