/* eslint-disable import/no-extraneous-dependencies */
import moment from 'moment/moment'
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import cn from 'classnames'
import { css, StyleSheet } from 'aphrodite'
import { DayPicker, useNavigation as useCalendarNavigation } from 'react-day-picker'
import { FormattedMessage } from 'react-intl'
import { COLORS } from '../../utils/colors'
import Button from './Button'
import { CalendarChevron, DoubleChevronSizeM } from '../svgs/Svgs'
import { getDayOfWeek } from '../../utils/date'
// import { BaseContext } from '../../contexts/BaseContextWrapper'

const weekEnd = [0, 6]
const today = moment()

// const options = [
//     { id: 1, name: 'Сегодня', type: 'today', days: { from: today.toDate(), to: today.toDate() } },
//     {
//         id: 2,
//         name: 'Вчера',
//         type: 'yesterday',
//         days: {
//             from: today.clone().subtract(1, 'days').toDate(),
//             to: today.clone().subtract(1, 'days').toDate(),
//         },
//     },
//     {
//         id: 3,
//         name: 'Последние 7 дней',
//         type: '7days',
//         days: {
//             from: today.clone().subtract(6, 'days').toDate(),
//             to: today.toDate(),
//         },
//     },
//     {
//         id: 4,
//         name: 'Последние 30 дней',
//         type: '30days',
//         days: {
//             from: today.clone().subtract(29, 'days').toDate(),
//             to: today.toDate(),
//         },
//     },
//     {
//         id: 5,
//         name: 'Текущий месяц',
//         type: 'month',
//         days: {
//             from: today.clone().startOf('month').toDate(),
//             to: today.clone().endOf('month').toDate(),
//         },
//     },
//     {
//         id: 6,
//         name: 'Предыдущий месяц',
//         type: 'lastMonth',
//         days: {
//             from: today.clone().subtract(1, 'month').startOf('month').toDate(),
//             to: today.clone().subtract(1, 'month').endOf('month').toDate(),
//         },
//     },
//     { id: 7, name: 'Произвольная дата', type: 'custom', days: null },
// ]

const Caption = forwardRef((_, ref) => {
    const calendarNavigation = useCalendarNavigation()
    // const { lang } = useContext(BaseContext)
    const date = moment(calendarNavigation ? calendarNavigation.currentMonth : new Date())

    useImperativeHandle(ref, () => ({
        navigateTo(to) {
            calendarNavigation.goToMonth(to)
        },
    }))

    return (
        <div className={cn('justify-between', css(s.cap))}>
            <div className="align-center">
                <DoubleChevronSizeM
                    className="pointer dis_sl"
                    onClick={() => calendarNavigation.goToMonth(date.subtract(1, 'year').toDate())}
                />

                <CalendarChevron
                    color={COLORS.gray}
                    className="pointer dis_sl"
                    onClick={() => calendarNavigation.goToMonth(date.subtract(1, 'month').toDate())}
                />
            </div>

            <h3 className={css(s.capLabel)}>{moment(date).format('MMMM YYYY')}</h3>

            <div className="align-center">
                <CalendarChevron
                    rotate={180}
                    color={COLORS.gray}
                    className="pointer dis_sl"
                    onClick={() => calendarNavigation.goToMonth(date.add(1, 'month').toDate())}
                />

                <DoubleChevronSizeM
                    rotate={180}
                    className="pointer dis_sl"
                    onClick={() => calendarNavigation.goToMonth(date.add(1, 'year').toDate())}
                />
            </div>
        </div>
    )
})

function Footer({ isMulti, setSelected, handleClear, onClose }) {
    const calendarNavigation = useCalendarNavigation()

    function onClear() {
        setSelected(undefined)
        handleClear()
    }

    function onSelectToday() {
        setSelected(today.toDate())
        calendarNavigation.goToMonth(today.toDate())
    }

    return (
        <tfoot>
            <tr>
                <td colSpan={7}>
                    <div className={cn('justify-between', css(s.actions))}>
                        <div className="align-center gap-2">
                            <h3 className={css(s.clearAct)} onClick={onClear}><FormattedMessage id="clear" /></h3>

                            {!isMulti && (
                                <h3
                                    onClick={onSelectToday}
                                    className={css(s.todayAct)}>
                                    <FormattedMessage id="today" />
                                </h3>
                            )}
                        </div>

                        <Button onClick={onClose} className={css(s.saveBtn)}>ОK</Button>
                    </div>
                </td>
            </tr>
        </tfoot>
    )
}

export default function CalendarPopup({ isMulti, setFieldValue, name, onClose, selectedDay }) {
    const captionRef = useRef()
    const [, setSelectedOption] = useState('today')
    const [selected, setSelected] = useState(selectedDay)
    // console.log(selectedOption)

    // function handleOptionClick(type, days) {
    //     if (days) {
    //         setSelected(days)
    //         captionRef.current.navigateTo(days.from)
    //     }
    //
    //     setSelectedOption(type)
    // }

    useEffect(() => {
        if (!selected) return

        if (!isMulti) {
            setFieldValue(name[0], selected ? moment(selected).format('YYYY-MM-DD') : '')
        } else {
            setFieldValue(name[0], selected && selected.from ? moment(selected.from).format('YYYY-MM-DD') : '')
            setFieldValue(name[1], selected && selected.to ? moment(selected.to).format('YYYY-MM-DD') : '')
        }
    }, [selected])

    const from = selected && selected.from ? selected.from : null
    const to = selected && selected.to ? selected.to : null

    const last = getDayOfWeek(from || new Date(), true, false)
    const first = getDayOfWeek(to || new Date(), false, false)

    const bkdLast = []
    const bkdFirst = []

    const newLastDate = new Date(last.getTime())
    const newFirstDate = new Date(first.getTime())

    for (let i = 0; newLastDate.getTime() < (to ? to.getTime() : 0); i += 1) {
        bkdLast.push(new Date(newLastDate.getTime()))
        newLastDate.setDate(newLastDate.getDate() + 7)
    }

    for (
        let i = 0;
        newFirstDate.getTime() > (from ? from.getTime() : 0)
        && newFirstDate.getTime() <= (to ? to.getTime() : 0);
        i += 1
    ) {
        bkdFirst.push(new Date(newFirstDate.getTime()))
        newFirstDate.setDate(newFirstDate.getDate() - 7)
    }

    const styles = `
      .rdp-day {
          width: 100%;
          height: 100%;
          font-size: 12px !important;
          font-weight: 400 !important;
      }
      .rdp-cell {
          width: 32px;
          height: 28px;
      }
      .rdp-head_cell {
          width: 32px;
          height: 28px;
      }
    `
    /* isMulti && s.multiPicker */
    return (
        <div className={cn('is-flex', css(s.dayPickerCont))}>
            <style>{styles}</style>

            {/* {isMulti && ( */}
            {/*     <div className={css(s.dayOptions)}> */}
            {/*         <ul className="no_dot_list"> */}
            {/*             {options.map((e) => ( */}
            {/*                 <li */}
            {/*                     key={e.id} */}
            {/*                     className={css(s.item, selectedOption === e.type && s.activeItem)} */}
            {/*                     onClick={() => handleOptionClick(e.type, e.days)}> */}
            {/*                     <h3>{e.name}</h3> */}

            {/*                     {selectedOption === e.type && <CheckSizeM />} */}
            {/*                 </li> */}
            {/*             ))} */}
            {/*         </ul> */}
            {/*     </div> */}
            {/* )} */}

            <div>
                <DayPicker
                    toYear={2099}
                    fromYear={1900}
                    mode={!isMulti ? 'single' : 'range'}
                    onDayClick={() => setSelectedOption('custom')}
                    components={{
                        Caption: () => <Caption ref={captionRef} />,
                        Footer: () => (
                            <Footer
                                isMulti={isMulti}
                                setSelected={setSelected}
                                onClose={onClose}
                                handleClear={() => {
                                    setSelectedOption(null)
                                    if (isMulti) {
                                        name.forEach((n) => setFieldValue(n, ''))
                                    } else {
                                        setFieldValue(name[0], '')
                                    }
                                }}
                            />
                        ),
                    }}
                    modifiers={{
                        weekEnd: (day) => weekEnd.includes(day.getDay()),
                        days: (day) => day,
                        bkdLast,
                        bkdFirst,
                    }}
                    showOutsideDays
                    modifiersClassNames={{
                        weekEnd: css(s.weekEnd),
                        selected: css(s.select),
                        today: css(s.td),
                        outside: css(s.ou),
                        // days: css(s.day),
                        range_start: css(s.rs),
                        range_middle: css(s.rm),
                        range_end: css(s.re),
                        bkdLast: css(s.aaa),
                        bkdFirst: css(s.aa),
                    }}
                    selected={selected}
                    onSelect={(day) => setSelected(day)}
                />
            </div>
        </div>
    )
}

const s = StyleSheet.create({
    dayOptions: {
        borderRight: `1px solid ${COLORS.smoothGray}`,
        width: '43%',
    },
    dayPickerCont: {
        position: 'absolute',
        zIndex: 1,
        right: 0,
        background: COLORS.white,
        borderRadius: 4,
        border: `1px solid ${COLORS.lightGray}`,
        boxShadow: '0px 4px 16px 0px rgba(62, 89, 78, 0.15)',
        ':nth-child(1n) > :last-child': {
            padding: 12,
            boxSizing: 'border-box',
            flex: 1,
        },
    },
    multiPicker: {
        width: 462,
    },
    item: {
        height: 'calc(100% / 7)',
        padding: '0 12px 0 16px',
        boxSizing: 'border-box',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        fontSize: 12,
        fontWeight: '500',
        color: COLORS.gray,
        cursor: 'pointer',
        borderBottom: `1px solid ${COLORS.smoothGray}`,
        ':last-of-type': {
            borderBottom: 'none',
        },
        ':hover': {
            backgroundColor: COLORS.smoothGray,
        },
    },
    activeItem: {
        color: COLORS.dark,
    },
    select: {
        border: `1px solid ${COLORS.skyblue}`,
        borderRadius: 2,
        color: COLORS.skyblue,
        fontSize: 16,
        fontWeight: '500',
    },
    ou: {
        color: '#BBC6C1',
    },
    td: {
        color: COLORS.skyblue,
        fontSize: 16,
        fontWeight: '500',
    },
    weekEnd: {
        color: COLORS.lightRed,
    },
    actions: {
        borderTop: `1px solid ${COLORS.smoothGray}`,
        paddingTop: 8,
        marginTop: 8,

    },
    saveBtn: {
        color: COLORS.white,
        fontSize: 13,
        fontWeight: '500',
        height: 24,
        padding: '0 8px',
        borderRadius: 4,
        background: COLORS.mainColor,
        ':hover': {
            boxShadow: '0px 2px 8px 0px rgba(62, 89, 78, 0.15)',
        },
    },
    clearAct: {
        cursor: 'pointer',
        color: COLORS.midGray,
        fontSize: 12,
        fontWeight: 500,
    },
    cap: {
        borderBottom: `1px solid ${COLORS.smoothGray}`,
        paddingBottom: 8,
        marginBottom: 8,
    },
    capLabel: {
        color: COLORS.lightBlack,
        textTransform: 'capitalize',
        fontSize: 12,
        fontWeight: '500',
    },
    todayAct: {
        cursor: 'pointer',
        color: COLORS.skyblue,
        fontSize: 12,
        fontWeight: 500,
    },
    rm: {
        borderWidth: 0,
        borderTop: '1px solid #0095F2',
        borderBottom: '1px solid #0095F2',
        borderRadius: 0,
        color: '#0095F2',
        // fontSize: 12,
        // fontWeight: '500',
        backgroundColor: 'rgba(0, 149, 242, 0.05)',
    },
    re: {
        backgroundColor: '#0095F2',
        borderRadius: '0 2px 2px 0',
        color: COLORS.white,
    },
    rs: {
        backgroundColor: '#0095F2',
        borderRadius: '2px 0 0 2px',
        color: COLORS.white,
    },
    aaa: {
        borderRight: '1px solid #0095F2',
        borderBottomRightRadius: 2,
        borderTopRightRadius: 2,
    },
    aa: {
        borderLeft: '1px solid #0095F2',
        borderBottomLeftRadius: 2,
        borderTopLeftRadius: 2,
    },
})
