import React, { useRef, useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import axios from 'axios'
import uuid from 'react-uuid'
import { FormattedMessage } from 'react-intl'
import { COLORS } from '../../utils/colors'
import { ChevronNext, LightingSizeL, PlusRoundedSizeL,
    ThreeDotsMenuHorizontalSizeL } from '../svgs/Svgs'
import logo from '../../static/temporarily-images/experience_logo.png'
import { Popup } from '../Popup'
import Modal from '../common/Modal'
import { ExperienceModal } from './ExperienceModal'
import { useMessage } from '../../hooks/message'
import AmazingTooltip from '../common/AmazingTooltip'
import { usePostRequest } from '../../hooks/request'
import { CANDIDATE_EXPERIENCE_CREATE,
    CANDIDATE_EXPERIENCE_DELETE,
    CANDIDATE_EXPERIENCE_UPDATE } from '../../urls'
import { domain } from '../../utils/request'
import { LoadingSkeleton } from '../common/LoadingSkeleton'
import { auth } from '../../utils/auth'

const Skeleton = ({ theme }) => (
    <div className={css(mode(theme).boxWrap)}>
        <div className={css(s.boxHeader)}>
            <div className="is-flex align-center gap-3">
                <LightingSizeL color={theme === 'dark' ? COLORS.white : COLORS.dark} />

                <h3 className={css(mode(theme).headTitle)}><FormattedMessage id="workExperience" /></h3>
            </div>
        </div>

        <div className={css(mode(theme).experiencesWrap)}>
            <div className="is-flex align-center justify-between">
                <div className={cn('is-flex align-center gap-7', css(s.right))}>
                    <img className={css(s.logo)} src={logo} alt="#ExpLogo" />

                    <div className="is-flex direction-column gap-2">
                        <LoadingSkeleton height={21} width={200} />

                        <LoadingSkeleton height={19} width={146} />
                    </div>
                </div>

                <div className={cn('is-flex align-center gap-7', css(s.left), css(s.mobSkeleton))}>
                    <LoadingSkeleton
                        height={19}
                        width={135}
                    />
                </div>
            </div>
        </div>
    </div>
)

export default function ExperienceInfo({
    info,
    getInfo,
    options,
    theme,
}) {
    const popUpRef = useRef()
    const [showMessage] = useMessage()
    const [showModal, setShowModal] = useState({
        act: false,
        type: '',
    })
    // eslint-disable-next-line max-len
    const expCreate = usePostRequest({ url: CANDIDATE_EXPERIENCE_CREATE })
    // eslint-disable-next-line max-len

    const initialValues = {
        ...info,
        experience: info.experience ? info.experience.map((exp) => ({
            job: exp.job,
            end_date: exp.endDate,
            start_date: exp.startDate,
            achievements: exp.achievements,
            position: exp.position,
        })) : [],
    }

    const onCreate = async (values, ind) => {
        const required = Object.values(values.experience[ind]).some((val) => val !== '')
        if (!required || expCreate.loading) return

        const { success, error } = await expCreate.request({
            data: {
                job: values.experience[ind].job,
                start_date: values.experience[ind].start_date,
                end_date: values.experience[ind].end_date,
                position: values.experience[ind].position,
                achievements: values.experience[ind].achievements,
            },
        })

        if (success) {
            getInfo.request()
            setShowModal({ act: false, type: '' })
            showMessage(<FormattedMessage id="successFullyAdded" />, 'success-msg')
        } else {
            showMessage(error.data.errors[0].message || error.data.message, 'error-msg')
        }
    }
    const onUpdate = async (values, ind, id) => {
        if (Object.values(values.experience[ind]).every((value) => value === '' || value === null)) return

        const data = {
            job: values.experience[ind].job,
            startDate: values.experience[ind].start_date,
            endDate: values.experience[ind].end_date,
            position: values.experience[ind].position,
            achievements: values.experience[ind].achievements,
        }

        const existingData = info.experience.find((exp) => exp.id === id)

        const hasChanges = (
            data.job !== existingData.job
            || data.startDate !== existingData.startDate
            || data.endDate !== existingData.endDate
            || data.position !== existingData.position
            || data.achievements !== existingData.achievements
        )

        if (!hasChanges) {
            setShowModal({ act: false, type: '' })
            return
        }

        axios.put(
            domain + CANDIDATE_EXPERIENCE_UPDATE.replace('{id}', id), data, auth(),
        ).then((res) => {
            if (res.data.success) {
                getInfo.setResponse((prevState) => ({
                    ...getInfo.response,
                    data: {
                        ...prevState.data,
                        experience: info.experience.map((exp) => (exp.id === id ? { ...exp, ...data } : exp)),
                    },
                }))
                setShowModal({ act: false, type: '' })
                showMessage(<FormattedMessage id="successFullyUpdated" />, 'success-msg')
            }
        }).catch(({ response }) => showMessage(response.data.errors[0].message || response.data.message, 'error-msg'))
    }

    const onDelete = async (id) => {
        axios.delete(domain + CANDIDATE_EXPERIENCE_DELETE.replace('{id}', id), auth())
            .then(({ data }) => {
                if (data.success) {
                    getInfo.setResponse((prevState) => ({
                        ...getInfo.response,
                        data: {
                            ...prevState.data,
                            experience: info.experience.filter((exp) => exp.id !== id),
                        },
                    }))
                    showMessage(<FormattedMessage id="successFullyDeleted" />, 'success-msg')
                }
            }).catch(({ response }) => {
                showMessage(response.data.message, 'error-msg')
            })
    }

    return !getInfo.loading ? (
        <div className={css(mode(theme).boxWrap)}>
            <div className={css(s.boxHeader)}>
                <div className="is-flex align-center gap-3">
                    <LightingSizeL color={theme === 'dark' ? COLORS.white : COLORS.dark} />

                    <h3 className={css(mode(theme).headTitle)}><FormattedMessage id="workExperience" /></h3>
                </div>

                <div className={css(s.toolWrap)}>
                    <AmazingTooltip
                        position="right"
                        className={css(s.tooltip)}
                        trigger={(
                            <div onClick={() => setShowModal({
                                act: true,
                                type: 'add',
                            })} className={css(mode(theme).plusIcon)}>
                                <PlusRoundedSizeL />
                            </div>
                        )}
                    >
                        <h3 style={{ whiteSpace: 'nowrap' }}><FormattedMessage id="addExperience" /></h3>
                    </AmazingTooltip>
                </div>

                <div className={css(s.mobileIcon)}>
                    <div
                        onClick={() => setShowModal({
                            act: true,
                            type: 'add',
                        })}
                        className={css(mode(theme).plusIcon)}
                    >
                        <PlusRoundedSizeL />
                    </div>
                </div>
            </div>

            {info.experience && info.experience.length ? (
                <div className={css(mode(theme).experiencesWrap)}>
                    {info.experience.map((exp, ind) => (
                        <div key={uuid()} className="is-flex align-center justify-between">
                            <div className={cn('is-flex align-center gap-7', css(s.right))}>
                                <img className={css(s.logo)} src={logo} alt="#ExpLogo" />

                                <div className="is-flex direction-column gap-2">
                                    <h3 className={css(mode(theme).position)}>{exp.position}</h3>

                                    <h3 className={css(mode(theme).company)}>{exp.job}</h3>

                                    <h3 className={css(mode(theme).achievements)}>{exp.achievements}</h3>
                                </div>
                            </div>

                            <div className={cn('is-flex align-center gap-7', css(s.left))}>
                                {exp.startDate && (
                                    <div className={css(mode(theme).periods)}>
                                        <span>{exp.startDate && exp.startDate.replace(/-/gi, '/')}</span>

                                        <span>-</span>

                                        <span>
                                            {exp.endDate ? exp.endDate.replace(/-/gi, '/') : 'до сегодняшнево дня'}
                                        </span>
                                    </div>
                                )}

                                <Popup
                                    ref={popUpRef}
                                    items={options}
                                    activeTriggerClassName={css(mode(theme).activeTrig)}
                                    trigger={(
                                        <div className={css(mode(theme).trig)}>
                                            <ThreeDotsMenuHorizontalSizeL />
                                        </div>
                                    )}
                                    renderItem={({
                                        value,
                                        type,
                                        Icon,
                                        onClick,
                                        navigate,
                                    }, setShowPopUp) => (
                                        <li
                                            key={uuid()}
                                            className={cn('justify-between', css(s.cardSetting))}
                                            onClick={
                                                type === 'delete'
                                                    ? () => onClick(onDelete, exp.id, setShowPopUp(false))
                                                    // eslint-disable-next-line max-len
                                                    : () => onClick(setShowModal, true, 'update', ind, exp.id, setShowPopUp(false))
                                            }
                                        >
                                            <div className="align-center" style={{ gap: 8 }}>
                                                <Icon /> {value}
                                            </div>

                                            {navigate && <ChevronNext />}
                                        </li>
                                    )}
                                />
                            </div>
                        </div>
                    ))}
                </div>
            ) : null}

            <Modal
                open={showModal.act}
                closeOnEscape
                closeOnDocumentClick
                contentStyle={{ height: 'calc(100% - 24px)', zIndex: 10 }}
                overlayStyle={{ top: 52 }}
                onClose={() => setShowModal({ act: false, type: '' })}
            >
                <ExperienceModal
                    loading={expCreate.loading}
                    ind={showModal.ind}
                    showModal={showModal}
                    initialValues={showModal.type === 'update' ? initialValues : {}}
                    onSubmit={showModal.type === 'update' ? onUpdate : onCreate}
                    onClose={() => setShowModal({ act: false, type: '' })}
                />
            </Modal>
        </div>
    ) : <Skeleton theme={theme} />
}

const s = StyleSheet.create({
    boxHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '14px 16px 14px 24px',
        '@media (max-width: 768px)': {
            padding: '10px 8px 10px 16px',
        },
    },
    tooltip: {
        zIndex: 0,
    },
    mobileIcon: {
        '@media (min-width: 1100px)': {
            display: 'none',
        },
    },
    right: {
        '@media (max-width: 768px)': {
            gap: 12,
        },
    },
    left: {
        '@media (max-width: 768px)': {
            gap: 8,
        },
    },
    logo: {
        width: 40,
        height: 40,
    },
    cardSetting: {
        width: '10rem',
        userSelect: 'none',
    },
    // popUpWrap: {
    //     borderRadius: 4,
    //     margin: 0,
    //     ':nth-child(1n) > *': {
    //         color: '#fff',
    //         fontSize: 12,
    //         background: 'rgba(65, 70, 68, 0.9)',
    //         backdropFilter: 'blur(8px)',
    //         fontWeight: '500',
    //         padding: '10px 12px',
    //         display: 'flex',
    //         alignItems: 'center',
    //         cursor: 'pointer',
    //         borderBottom: '1px solid #5e6360',
    //         ':hover': {
    //             background: 'rgba(87, 90, 89, 0.9)',
    //         },
    //         ':nth-child(1n) > :first-child': {
    //             marginRight: 8,
    //         },
    //     },
    //     ':nth-child(1n) > :last-child': {
    //         borderBottomWidth: 0,
    //         borderBottomLeftRadius: 4,
    //         borderBottomRightRadius: 4,
    //     },
    //     ':nth-child(1n) > :first-child': {
    //         borderTopLeftRadius: 4,
    //         borderTopRightRadius: 4,
    //     },
    // },
    toolWrap: {
        '@media (max-width: 1100px)': {
            display: 'none',
        },
    },
    mobSkeleton: {
        '@media (max-width: 768px)': {
            ':nth-child(1n) > :only-child': {
                width: 80,
            },
        },
    },
})

const mode = (theme) => StyleSheet.create({
    boxWrap: {
        background: theme === 'dark' ? COLORS.darkModeBg : COLORS.white,
        border: theme === 'dark' ? 'none' : `1px solid ${COLORS.smoothGray}`,
        boxShadow: '0px 2px 4px rgba(130, 130, 130, 0.05)',
        borderRadius: 4,
    },
    headTitle: {
        fontWeight: '600',
        fontSize: 16,
        textTransform: 'uppercase',
        color: theme === 'dark' ? COLORS.white : COLORS.dark,
        '@media (max-width: 768px)': {
            fontSize: 14,
        },
    },
    achievements: {
        fontSize: 13,
        fontStyle: 'italic',
        fontWeight: '400',
        color: theme === 'dark' ? COLORS.gray : COLORS.midGray,
    },
    experiencesWrap: {
        padding: '0 24px 24px 24px',
        ':nth-child(1n) > *': {
            borderBottom: `1px solid ${theme === 'dark' ? COLORS.midGray : COLORS.borderBottom}`,
            padding: '24px 0 24px 0',
        },
        ':nth-child(1n) > :last-child': {
            borderBottom: 'none',
            paddingBottom: 0,
        },
        ':nth-child(1n) > :first-child': {
            paddingTop: 0,
        },
        '@media (max-width: 768px)': {
            padding: '0 16px 16px 16px',
        },
    },
    position: {
        fontWeight: '700',
        fontSize: 18,
        color: theme === 'dark' ? COLORS.white : COLORS.dark,
        '@media (max-width: 768px)': {
            fontSize: 13,
        },
    },
    company: {
        fontWeight: '500',
        fontSize: 16,
        color: theme === 'dark' ? COLORS.gray : COLORS.lightBlack,
        '@media (max-width: 768px)': {
            fontSize: 12,
        },
    },
    plusIcon: {
        padding: '8px 8px 4px 8px',
        cursor: 'pointer',
        border: theme === 'dark' ? 'none' : `1px solid ${COLORS.white}`,
        ':hover': {
            border: theme === 'dark' ? 'none' : `1px solid ${COLORS.smoothGray}`,
            background: theme === 'dark' ? 'rgba(255, 255, 255, 0.10)' : 'inherit',
            boxShadow: '0px 2px 4px rgba(130, 130, 130, 0.05)',
            borderRadius: 4,
            ':nth-child(1n) > :only-child': {
                ':nth-child(1n) > path': {
                    stroke: theme === 'dark' ? COLORS.white : COLORS.gray,
                },
            },
        },
    },
    periods: {
        fontStyle: 'italic',
        fontWeight: '700',
        fontSize: 16,
        display: 'flex',
        alignItems: 'center',
        gap: 3,
        color: theme === 'dark' ? COLORS.lightGray : COLORS.midGray,
        '@media (max-width: 768px)': {
            fontSize: 10,
        },
    },
    activeTrig: {
        ':nth-child(1n) > :first-child': {
            ':nth-child(1n) > :first-child': {
                ':nth-child(1n) > path': {
                    stroke: theme === 'dark' ? COLORS.white : COLORS.dark,
                },
            },
        },
    },
    trig: {
        ':nth-child(1n) > :first-child': {
            cursor: 'pointer',
            ':hover': {
                ':nth-child(1n) > path': {
                    stroke: theme === 'dark' ? COLORS.gray : COLORS.dark,
                },
            },
        },
    },
})
