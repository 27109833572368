import React, { useContext, useLayoutEffect } from 'react'
import { css, StyleSheet } from 'aphrodite'
import { useLocation, useNavigate } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { COLORS } from '../../utils/colors'
import { ChevronPrev } from '../svgs/Svgs'
import { BaseContext } from '../../contexts/BaseContextWrapper'
import { Layout } from '../common/Layout'

export default function RejectInfo() {
    const { state } = useLocation()
    const navigate = useNavigate()
    const { theme } = useContext(BaseContext)

    useLayoutEffect(() => {
        if (state === null) navigate(-1)
    }, [])

    return (
        <Layout>
            <div className={css(mode(theme).wrapper)}>
                <div className={css(mode(theme).modalHeader)}>
                    <div onClick={() => navigate(-1)} className={css(s.chevronWrap)}>
                        <ChevronPrev color={theme === 'dark' ? COLORS.white : COLORS.dark} />
                    </div>

                    <h3>
                        {`${state && state.st} от `} {state && state.item.company.name}
                    </h3>
                </div>

                <div className={css(mode(theme).modalBody)}>
                    { state && state.item.rejectReason
                        ? <p>{state.item.rejectReason}</p>
                        : (
                            <div className="is-flex direction-column gap-7">
                                <p className={css(s.statusTxt)}>
                                    <FormattedMessage id="rejectStatusText" />
                                </p>

                                {/* <p> */}
                                {/*     Добрый день, {state.info.firstName} {state.info.lastName}! */}
                                {/*     Готовы вернуться к вам с обратной связью.
                                 К сожалению, мы не готовы пригласить вас */}
                                {/*     на вакансию {state.item.vacancy.name} в нашей компании. */}
                                {/*     Будем рады оставаться на связи и
                                предлагать вам в будущем другие возможные вакансии. */}
                                {/*     В любом случае я благодарю вас за уделенное время, */}
                                {/*     нам было приятно с вами познакомиться! */}
                                {/*     Желаю удачи и успехов в поиске нового места работы 🙂 */}
                                {/*     С уважением, компания {state.item.company.name}, */}
                                {/*     отдел подбора и адаптации персонала */}
                                {/* </p> */}
                            </div>
                        )}
                </div>
            </div>
        </Layout>
    )
}

const s = StyleSheet.create({
    statusTxt: {
        color: COLORS.gray,
        fontSize: 14,
        fontStyle: 'italic',
        fontWeight: '500',
    },
    chevronWrap: {
        ':nth-child(1n) > :first-child': {
            cursor: 'pointer',
            ':nth-child(1n) > path': {
                strokeWidth: 3,
            },
        },
    },
})

const mode = (theme) => StyleSheet.create({
    wrapper: {
        height: '100%',
        background: theme === 'dark' ? COLORS.darkModeBg : COLORS.white,
    },
    modalHeader: {
        padding: '20px 24px',
        display: 'flex',
        alignItems: 'center',
        gap: 16,
        justifyContent: 'left',
        borderBottom: `1px solid ${theme === 'dark' ? COLORS.lightBlack : COLORS.smoothGray}`,
        background: theme === 'dark' ? COLORS.itemDarkModeBg : COLORS.white,
        ':nth-child(1n) > h3': {
            fontWeight: '700',
            fontSize: 16,
            color: theme === 'dark' ? COLORS.smoothGray : COLORS.dark,
        },
        ':nth-child(1n) > :last-child': {
            cursor: 'pointer',
        },
        '@media (max-width: 768px)': {
            padding: '14px 16px',
        },
    },
    modalBody: {
        height: 'calc(100% - 113px)',
        padding: 24,
        borderTop: `1px solid ${theme === 'dark' ? COLORS.lightBlack : COLORS.smoothGray}`,
        borderBottom: `1px solid ${theme === 'dark' ? COLORS.lightBlack : COLORS.smoothGray}`,
        background: 'inherit',
        boxShadow: '0px 2px 4px 0px rgba(130, 130, 130, 0.05)',
        '@media (max-width: 768px)': {
            padding: 16,
        },
        color: theme === 'dark' ? COLORS.smoothGray : COLORS.dark,
        fontSize: 14,
        fontWeight: '400',
    },
})
