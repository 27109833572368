/* eslint-disable max-len */
import { EducationHatGreen, TablerBank,
    TablerBriefcase,
    TablerBuilding,
    TablerCarCrane,
    TablerDeviceLaptop,
    TablerFactory,
    TablerHeartHandShake,
    TablerLayout,
    TablerMessageUp,
    TablerRocket,
    TablerStore } from '../components/svgs/SvgPictures'

export const industries = [
    { id: 1, uz: 'Axborot texnologiyalari', en: 'Information Technologies', ru: 'Информационные технологии', Icon: TablerDeviceLaptop },
    { id: 2, uz: 'Ishlab chiqarish', en: 'Manufacturing', ru: 'Производство', Icon: TablerFactory },
    { id: 3, uz: 'Moliyaviy muassasa va banklar', en: 'Financial institution & Banks', ru: 'Банки и финансовые учереждения', Icon: TablerBank },
    { id: 4, uz: 'Konsalting', en: 'Consulting', ru: 'Консалтинг', Icon: TablerMessageUp },
    { id: 5, uz: 'Marketing va reklama', en: 'Marketing and Ads', ru: 'Маркетинг и реклама', Icon: TablerRocket },
    { id: 6, uz: 'HoReCa', en: 'HoReCa', ru: 'HoReCa', Icon: TablerBuilding },
    { id: 7, uz: 'Hukumat tashkiloti', en: 'Governmental organization', ru: 'Государственные учереждения', Icon: TablerBriefcase },
    { id: 8, uz: 'Notijorat tashkilot', en: 'Non-commercial organization', ru: 'Некоммерческие организации', Icon: TablerLayout },
    { id: 9, uz: 'Tibbiyot va sog\'liqni saqlash tashkiloti', en: 'Medical and health organization', ru: 'Медицина и здравоохранение', Icon: TablerHeartHandShake },
    { id: 10, uz: 'Qurilish', en: 'Construction', ru: 'Строительство', Icon: TablerCarCrane },
    { id: 11, uz: 'Chakana savdo', en: 'Retail', ru: 'Торговля', Icon: TablerStore },
    { id: 13, uz: 'Taʼlim', en: 'Education', ru: 'Образование', Icon: EducationHatGreen },
    { id: 99999, uz: 'Boshqa', en: 'Other', ru: 'Другое', Icon: TablerLayout },
]
