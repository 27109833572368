import React, { useContext, useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { Form, Formik } from 'formik'
import uuid from 'react-uuid'
import { Oval } from 'react-loader-spinner'
import { FormattedMessage, useIntl } from 'react-intl'
import { COLORS } from '../../utils/colors'
import { CloseSidebar } from '../svgs/Svgs'
import Button from '../common/Button'
import SelectForm from '../common/SelectForm'
import { AvailableLanguages, LanguageLevels } from '../../utils/options'
import AmazingTooltip from '../common/AmazingTooltip'
import { BaseContext } from '../../contexts/BaseContextWrapper'

function LanguageForm({ languageList, values, setFieldValue, index, info, theme }) {
    const blockValue = values.languageLevel[index] ? values.languageLevel[index].lang : ''
    const intl = useIntl()
    const { lang } = useContext(BaseContext)

    const languageLevelArray = Object.values(info && info.languages)

    const restLanguages = languageList.filter(
        (language) => !languageLevelArray.some(
            (level) => level && level.lang === language.short,
        ),
    )

    return (
        <div className={css(s.langForm)}>
            <SelectForm
                theme={theme}
                value={languageList.find((e) => (e.short === blockValue))}
                labelClassName={css(s.lb)}
                placeholder={intl.formatMessage({ id: 'chooseLanguage' })}
                name={`languageLevel[${index}]`}
                options={restLanguages}
                label={<FormattedMessage id="candidateLanguages" />}
                labelContClassName={css(s.lab)}
                indicatorStyle={{ paddingRight: 10 }}
                getOptionLabel={(option) => option[lang]}
                className={css(mode(theme).selectInp)}
                valueContainerStyles={{ padding: '11.5px 16px' }}
                placeholderStyles={{ color: COLORS.gray, fontFamily: 'Roboto, sans-serif' }}
                onChange={(value) => {
                    setFieldValue(
                        'languageLevel',
                        { ...values.languageLevel, [index]: { lang: value.short, level: 'A1' } },
                    )
                }}
            />

            <div className={cn('is-flex direction-column gap-2', css(mode(theme).wrap))}>
                <h3><FormattedMessage id="langLvl" /></h3>

                <div className="is-flex">
                    {LanguageLevels.map((e) => (
                        <AmazingTooltip
                            key={e.level}
                            className={css(s.tooltip)}
                            trigger={(
                                <span
                                    key={uuid()}
                                    onMouseDown={() => {
                                        setFieldValue(
                                            'languageLevel', {
                                                ...values.languageLevel,
                                                [index]: { ...values.languageLevel[index], level: e.level },
                                            },
                                        )
                                    }}
                                    className={cn(
                                        css(mode(theme).lvl),
                                        { [css(s.selected)]:
                                            // eslint-disable-next-line max-len
                                            values.languageLevel[index] && values.languageLevel[index].level === e.level },
                                    )}>
                                    {e.level}
                                </span>
                            )}
                        >
                            {e[lang]}
                        </AmazingTooltip>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default function LanguageModal({ onClose, showModal, num, initialValues, onSubmit, modalRef, info, loading }) {
    const [languageList, setLanguageList] = useState(AvailableLanguages)
    const index = showModal.type === 'update' ? num : 0
    const { theme } = useContext(BaseContext)

    return (
        <Formik
            enableReinitialize
            initialValues={{
                languageLevel: [{ lang: '', level: '' }],
                ...initialValues,
            }} onSubmit={(values) => onSubmit(values, index, showModal.id)}>
            {({ values, setFieldValue }) => {
                const required = Object.values(values.languageLevel[index]).some((val) => val !== '')

                return (
                    <Form>
                        <div ref={modalRef} className={css(s.modalWrap)}>
                            <div className={css(mode(theme).modalHeader)}>
                                <h3>
                                    {showModal.type === 'add'
                                        ? <FormattedMessage id="addLanguage" />
                                        : <FormattedMessage id="updateLanguage" />}
                                </h3>

                                <CloseSidebar color={theme === 'dark' ? COLORS.white : COLORS.dark} onClick={onClose} />
                            </div>

                            <div className={css(mode(theme).modalBody)}>
                                <LanguageForm
                                    theme={theme}
                                    languageList={languageList}
                                    setLanguageList={setLanguageList}
                                    values={values}
                                    index={index}
                                    info={info}
                                    setFieldValue={setFieldValue}
                                />
                            </div>

                            <div className={css(mode(theme).modalFooter)}>
                                <Button
                                    type="button"
                                    onClick={onClose}
                                    className={cn(css(mode(theme).cancelBtn), css(s.btn))}>
                                    <FormattedMessage id="cancel" />
                                </Button>

                                <Button
                                    type="submit"
                                    /* eslint-disable-next-line max-len */
                                    className={cn(css(s.disabledBtn, s.btn), { [css(s.saveBtn)]: required })}
                                >
                                    {!loading ? <FormattedMessage id="save" /> : <FormattedMessage id="saving" />}

                                    {loading ? (
                                        <Oval
                                            height={16}
                                            width={16}
                                            color={COLORS.white}
                                            visible
                                            ariaLabel="oval-loading"
                                            secondaryColor={COLORS.white}
                                            strokeWidth={7}
                                            strokeWidthSecondary={7}
                                        />
                                    ) : null}
                                </Button>
                            </div>
                        </div>
                    </Form>
                )
            }}
        </Formik>
    )
}

const s = StyleSheet.create({
    modalWrap: {
        width: '37.25rem',
        borderRadius: '4px 4px 0px 0px',
        '@media (max-width: 768px)': {
            width: '90vw',
        },
    },
    btn: {
        borderRadius: 4,
        padding: '12px 16px',
        fontWeight: '500',
        fontSize: 14,
        display: 'flex',
        alignItems: 'center',
        gap: 8,
    },
    saveBtn: {
        cursor: 'pointer',
        color: COLORS.white,
        background: `linear-gradient(180deg, ${COLORS.mainColor} 0%, ${COLORS.lightMainColor} 100%)`,
        // eslint-disable-next-line max-len
        boxShadow: '0px 4px 12px rgba(44, 67, 50, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.25), inset 0px 1px 0px rgba(255, 255, 255, 0.1)',
    },
    langForm: {
        display: 'flex',
        flexDirection: 'column',
        gap: 24,
    },
    lab: {
        ':nth-child(1n) > span': {
            display: 'inline-flex',
        },
    },
    lvlWrap: {
        width: '70%',
    },
    selected: {
        background: `linear-gradient(180deg, ${COLORS.mainColor} 0%, ${COLORS.lightMainColor} 100%)`,
        borderRight: '1px solid rgba(0, 0, 0, 0.15)',
        pointerEvents: 'none',
        color: COLORS.white,
        backgroundClip: 'padding-box',
    },
    disabledBtn: {
        background: COLORS.gray,
        border: 'none',
        // eslint-disable-next-line max-len
        boxShadow: '0px 4px 12px rgba(44, 67, 50, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.25), inset 0px 1px 0px rgba(255, 255, 255, 0.1)',
        cursor: 'not-allowed',
        fontWeight: '500',
        fontSize: 14,
        color: COLORS.white,
    },
    tooltip: {
        width: '100%',
        whiteSpace: 'nowrap',
        zIndex: 0,
    },
})

const mode = (theme) => StyleSheet.create({
    modalHeader: {
        padding: '20px 24px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        background: theme === 'dark' ? COLORS.itemDarkModeBg : COLORS.white,
        borderBottom: `1px solid ${theme === 'dark' ? COLORS.lightBlack : COLORS.lightGray}`,
        borderRadius: '4px 4px 0px 0px',
        ':nth-child(1n) > h3': {
            fontWeight: '700',
            fontSize: 20,
            color: theme === 'dark' ? COLORS.white : COLORS.dark,
            '@media (max-width: 768px)': {
                fontSize: 16,
            },
        },
        ':nth-child(1n) > :last-child': {
            cursor: 'pointer',
        },
        '@media (max-width: 768px)': {
            padding: '14px 16px',
        },
    },
    modalBody: {
        maxHeight: '28rem',
        // overflowY: 'auto',
        padding: 24,
        background: theme === 'dark' ? COLORS.darkModeBg : COLORS.smoothGray,
        '@media (max-width: 768px)': {
            height: '60vh',
            padding: 16,
        },
    },
    cancelBtn: {
        color: COLORS.gray,
        border: `1px solid ${COLORS.gray}`,
        background: 'inherit',
    },
    wrap: {
        ':nth-child(1n) > h3': {
            fontWeight: '500',
            fontSize: 12,
            color: theme === 'dark' ? COLORS.lightGray : COLORS.midGray,
        },
    },
    lvl: {
        flex: 1,
        textAlign: 'center',
        background: theme === 'dark' ? 'rgba(255, 255, 255, 0.10)' : COLORS.white,
        border: 'solid rgba(0, 0, 0, 0.15)',
        borderTopWidth: 1,
        borderBottomWidth: 1,
        borderLeftWidth: 0.5,
        borderRightWidth: 0.5,
        padding: '13px 0',
        fontWeight: '500',
        fontSize: 14,
        color: COLORS.lightGray,
        cursor: 'pointer',
        ':hover': {
            background: COLORS.smoothGray,
            color: theme === 'dark' ? COLORS.lightBlack : COLORS.gray,
        },
        ':first-of-type': {
            borderLeftWidth: 1,
            borderRadius: '3px 0 0 3px',
        },
        ':last-of-type': {
            borderRightWidth: 1,
            borderRadius: '0 3px 3px 0',
        },
    },
    selectInp: {
        ':nth-child(1n) > div': {
            // styles for div inside select component
            background: theme === 'dark' ? COLORS.itemDarkModeBg : COLORS.white,
            border: theme === 'dark' ? 'none' : `1px solid ${COLORS.lightGray}`,
            color: theme === 'dark' ? COLORS.white : COLORS.dark,
            ':nth-child(1n) *': {
                color: theme === 'dark' ? COLORS.white : COLORS.dark,
            },
        },
    },
    modalFooter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'end',
        gap: 16,
        background: theme === 'dark' ? COLORS.itemDarkModeBg : COLORS.white,
        borderTop: `1px solid ${theme === 'dark' ? COLORS.lightBlack : COLORS.lightGray}`,
        borderRadius: '0px 0px 4px 4px',
        padding: '16px 24px',
        '@media (max-width: 768px)': {
            padding: '8px 16px',
        },
    },
})
