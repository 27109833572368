import React, { useRef } from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { COLORS } from '../../utils/colors'

export function Radio({ name, value, onCheck, label, theme, checked = false }) {
    const ref = useRef()

    const onChange = () => {
        ref.current.checked = true // eslint-disable-line no-param-reassign
        if (typeof onCheck === 'function') onCheck(value)
    }

    return (
        <div onClick={onChange} className="align-center gap-2 pointer">
            <div className={cn('flex-cent', css(s.radio))}>
                <input
                    readOnly
                    ref={ref}
                    name={name}
                    type="radio"
                    value={value}
                    checked={checked}
                    id={`${name}-${value}`}
                    className={css(s.radio)} />
            </div>

            <h3 className={css(mode(theme).label, !checked && mode(theme).labelColor)}>
                {label}
            </h3>
        </div>
    )
}

const s = StyleSheet.create({
    radio: {
        border: `1px solid ${COLORS.mainColor}`,
        width: 16,
        height: 16,
        borderRadius: '50%',
        boxSizing: 'border-box',
        ':nth-child(1n) > input': {
            '-webkit-appearance': 'none',
            '-moz-appearance': 'none',
            appearance: 'none',
            borderRadius: '50%',
            cursor: 'pointer',
            outline: 'none',
            margin: 0,
            width: 8,
            height: 8,
            border: 'none',
            ':checked': {
                background: COLORS.mainColor,
            },
        },
    },
})

const mode = (theme) => StyleSheet.create({
    label: {
        fontSize: 11,
        fontWeight: '500',
        color: theme === 'dark' ? COLORS.lightGray : COLORS.lightBlack,
    },
    labelColor: {
        color: COLORS.gray,
    },
})
